import {
  getDashbaordStats,
  getAllOrders,
  getSettingsList,
  updateSettingList,
  rejectAcknowledge,
  approveAcknowledge,
  createOrder,
  orderAvailableItems,
  getdefaultOrderValues,
  getOrderDetail,
  getAllAddress,
  getAcknowledgedetail,
  getAllBuildPreparation,
  unAssignLineItem,
  AssignPreparationKit,
  getShippingStats,
  AssignPreparationLineItem,
  updateOrder,
  getAllShipmentForOrderId,
  updateShipment,
  createNewShipment,
  getInvenotryItemforBuild,
  getAssembbliesforItem,
  getShipmentDetails,
  deletePackage,
  createPackage,
  updatePackage,
  getGreenLightdetail,
  AddFiletoGreenLight,
  deletegreenLightDocument,
  submitGreenLight,
  orderShipDocument,
  attachFiletoGreenLight,
  uploadfileSeedGreenlight,
  getQualityControlItem,
  updateQualityStatus,
  shiptheShipment,
  getPostShipmentTemplate,
  updatePostShipmentTemplate,
  sendPostShipping,
  attachPreSeededFile,
  getdocumentDetail,
  generateAllTypeDocument,
  unAssignedItemsFromShuioment,
  deleteShipment,
  getpackingDocumentDetail,
  unlockPackge,
  unlockShipping,
  getshipmentDocumentDetails,
  generateShippmentDcoument,
  createAddress,
  updateGreenLight,
  getAllDocumentsForOrder,
  getAllType,
  createOrderType,
  createNewDoument,
  cancelGreenLightApproval,
  courierServiceType,
  getdynamicdocs,
  getDimenisonsForPackage,
  generateManualUpload,
  reSubmitGreenLightApproval,
  getkittemplateViewItem,
} from '../Services/order';

import {
  setQualityControlItems,
  setDashbaord,
  setAllOrder,
  setDefaultValues,
  setItemAvailable,
  setAlladdresses,
  addNewOrder,
  setacknowledgedetails,
  setAllBuildPreparation,
  setAllStagingPreparation,
  setAllShipping,
  setActiveOrder,
  setAllAvailableShipment,
  setActiveShipment,
  setGreenLightdetails,
  setPostShippingTemplate,
  setPostShippingTemplateSubmission,
  setkmcTemplateSubmission,
  deleteShimentReducer,
  setAllDocument,
  setAllTypes,
  addtypeOrderNew,
  setKMCTemplate,
  setCouriertype,
  setdynamicdocuments,
  setPackageDimension,
  setAllSettingList,
} from '../Store/reducers/orders';
import { setAllItemsReducer } from '../Store/reducers/items';
import { editFormReducer } from '../Store/reducers/uiSettings';
import { caseInsensitiveSortStudy, caseInsensitiveSortItem } from '../helpers/commonHelper';

import { store } from '../Store';

const getAllOrderAction = async () => {
  const result = await getAllOrders();
  if (result.status === 200) {
    store.dispatch(setAllOrder(result.data?.sort(caseInsensitiveSortStudy)));
  }
  return result;
};

const getSettingListAction = async () => {
  const result = await getSettingsList();
  if (result.status === 200) {
    store.dispatch(setAllSettingList(result.data?.sort(caseInsensitiveSortItem)));
  }
  return result;
};
const updateSettingListAction = async (settingId, data) => {
  const result = await updateSettingList(settingId, data);
  return result;
};
const getAllDocumentsForOrderAction = async (id) => {
  const result = await getAllDocumentsForOrder(id);
  if (result.status === 200) {
    store.dispatch(setAllDocument(result.data.sort(caseInsensitiveSortStudy)));
  }
  return result;
};

const getkittemplateViewItemAction = async (id) => {
  const result = await getkittemplateViewItem(id);
  if (result.status === 200) {
    return result;
  }
};

const getAllTypeAction = async () => {
  const result = await getAllType();
  if (result.status === 200) {
    store.dispatch(setAllTypes(result.data.sort(caseInsensitiveSortStudy)));
  }
  return result;
};

const createOrderTypeAction = async (data) => {
  const result = await createOrderType(data);
  if (result.status === 200) {
    store.dispatch(addtypeOrderNew(result.data));
  }

  return result;
};

const getOrderDetailAction = async (id) => {
  const result = await getOrderDetail(id);
  if (result.status === 200) {
    store.dispatch(setActiveOrder(result.data));
  }
  return result;
};

const createAddressAction = async (data) => {
  const result = await createAddress(data);

  return result;
};

const getDimenisonsForPackageAction = async (data) => {
  const result = await getDimenisonsForPackage(data);
  if (result.status === 200) {
    store.dispatch(setPackageDimension(result.data));
  }
  return result;
};

const getQualityControlItemAction = async (id, shipmentId, data) => {
  const result = await getQualityControlItem(id, shipmentId, data);
  if (result.status === 200) {
    store.dispatch(setQualityControlItems(result.data));
  }
  return result;
};
const shiptheShipmentAction = async (id, shipmentId) => {
  const result = await shiptheShipment(id, shipmentId);
  return result;
};
const getdocumentDetailAction = async (orderId, itemId, type, fullyId) => {
  const result = await getdocumentDetail(orderId, itemId, type, fullyId);
  return result;
};

const generateManualUploadAction = async (orderId, shipmentId, type, data) => {
  const result = await generateManualUpload(orderId, shipmentId, type, data);
  return result;
};

const getshipmentDocumentDetailsAction = async (orderId, shipId, type) => {
  const result = await getshipmentDocumentDetails(orderId, shipId, type);
  return result;
};

const getpackingDocumentDetailAction = async (orderId, shipId, packid, data) => {
  const result = await getpackingDocumentDetail(orderId, shipId, packid, data);
  return result;
};

const updateQualityStatusAction = async (id, shipmentId, data) => {
  const result = await updateQualityStatus(id, shipmentId, data);
  return result;
};

const unlockPackgeAction = async (id, shipmentId, packageId) => {
  const result = await unlockPackge(id, shipmentId, packageId);
  return result;
};

const getAcknowledgedetailAction = async (id, rejectProp) => {
  const result = await getAcknowledgedetail(id, rejectProp);
  if (result.status === 200) {
    store.dispatch(setacknowledgedetails(result.data));
  }
  return result;
};
const getGreenLightAction = async (orderId, shipId) => {
  const result = await getGreenLightdetail(orderId, shipId);
  if (result.status === 200) {
    store.dispatch(setGreenLightdetails(result.data));
  }
  return result;
};
const getOrderShipDocumentAction = async (orderId, shipId) => {
  const result = await orderShipDocument(orderId, shipId);
  return result;
};

const getAllAddressAction = async (id) => {
  const result = await getAllAddress(id);
  if (result.status === 200) {
    console.log(result);
    store.dispatch(setAlladdresses(result.data));
  }
  return result;
};
const getAssemblyforItemAction = async (orderId, itemId) => {
  const result = await getAssembbliesforItem(orderId, itemId);
  return result;
};

const getAllDashboardStatsAction = async () => {
  const result = await getDashbaordStats();
  if (result.status === 200) {
    store.dispatch(setDashbaord(result.data));
  }
  return result;
};

const createOrderAction = async (data) => {
  const result = await createOrder(data);
  if (result.status === 200) {
    store.dispatch(addNewOrder(result.data));

    store.dispatch(setActiveOrder(result.data));
  }
  return result;
};
const getReadyInvenotryItemAction = async (itemId, data) => {
  const result = await getInvenotryItemforBuild(itemId, data);
  return result;
};
const updateGreenLightAction = async (id, shipmentId, data) => {
  const result = await updateGreenLight(id, shipmentId, data);
  return result;
};

const getdefaultOrderValuesAction = async (siteId, typeId) => {
  const result = await getdefaultOrderValues(siteId, typeId);
  if (result.status === 200) {
    store.dispatch(setDefaultValues(result.data));
  }
  return result;
};
const getAllbuildPreparationAction = async (state, orderId) => {
  const result = await getAllBuildPreparation(state, orderId);
  if (result.status === 200) {
    if (state === 'building') {
      store.dispatch(setAllBuildPreparation(result.data.sort(caseInsensitiveSortItem)));
    } else {
      store.dispatch(setAllStagingPreparation(result.data.sort(caseInsensitiveSortItem)));
    }
  }
  return result;
};

const orderAvailableItemsAction = async (siteId, typeId) => {
  const result = await orderAvailableItems(siteId, typeId);
  if (result.status === 200) {
    store.dispatch(setItemAvailable(result.data));
    store.dispatch(setAllItemsReducer(result?.data));
  }
  return result;
};
const submitGreenLightApprovalAction = async (orderId, shipid, data) => {
  const result = await submitGreenLight(orderId, shipid, data);
  return result;
};
const reSubmitGreenLightApprovalAction = async (orderId, shipid, greenId, data) => {
  const result = await reSubmitGreenLightApproval(orderId, shipid, greenId, data);
  return result;
};

const cancelGreenLightApprovalAction = async (orderId, shipid, gid, data) => {
  const result = await cancelGreenLightApproval(orderId, shipid, gid, data);
  return result;
};

const unAssignLineItemAction = async (orderId, itemId, assignType) => {
  const result = await unAssignLineItem(orderId, itemId, assignType);
  // if (result.status === 200) {
  //   store.dispatch(setItemAvailable(result.data));
  //   store.dispatch(setAllItemsReducer(result?.data));
  // }
  return result;
};
const AssignPreparationKitAction = async (orderId, data, fullfillmentId) => {
  const result = await AssignPreparationKit(orderId, data, fullfillmentId);
  // if (result.status === 200) {
  //   store.dispatch(setItemAvailable(result.data));
  //   store.dispatch(setAllItemsReducer(result?.data));
  // }
  return result;
};
const AssignPreLineItemAction = async (orderId, itemId, fullyId) => {
  const result = await AssignPreparationLineItem(orderId, itemId, fullyId);
  return result;
};
const generateAlltypeDocumentAction = async (orderId, itemId, fullyId, type, data) => {
  const result = await generateAllTypeDocument(orderId, itemId, fullyId, type, data);
  return result;
};
const generateShippmentDcoumentAction = async (orderId, shipId, type, data) => {
  const result = await generateShippmentDcoument(orderId, shipId, type, data);
  return result;
};

const getAllShippingStatsAction = async (orderId) => {
  const result = await getShippingStats(orderId);
  if (result.status === 200) {
    store.dispatch(setAllShipping(result.data?.sort(caseInsensitiveSortItem)));
  }
  return result;
};
const updateOrderAction = async (id, orderId) => {
  const result = await updateOrder(id, orderId);
  if (result.status === 200) {
    store.dispatch(setActiveOrder(result.data));
  }
  return result;
};
const RemoveGreenLightFileAction = async (orderId, shipmentId, greenLightId, fileId) => {
  const result = await deletegreenLightDocument(orderId, shipmentId, greenLightId, fileId);
  // if (result.status === 200) {
  //   store.dispatch(setActiveOrder(result.data));
  // }
  return result;
};

const approveAction = async (id, data) => {
  const result = await approveAcknowledge(id, data);
  if (result.status === 200) {
    store.dispatch(setActiveOrder(result.data));
  }
  return result;
};

const rejectAction = async (id, data) => {
  const result = await rejectAcknowledge(id, data);
  if (result.status === 200) {
    store.dispatch(setActiveOrder(result.data));
  }
  return result;
};
const getAllShipmentForOrderIdAction = async (id, data) => {
  const result = await getAllShipmentForOrderId(id, data);
  if (result.status === 200) {
    store.dispatch(setAllAvailableShipment(result.data?.sort(caseInsensitiveSortItem)));
  }
  return result;
};

const updateShipmentAction = async (id, shipId, data) => {
  const result = await updateShipment(id, shipId, data);
  // if (result.status === 200) {
  //   store.dispatch(setAllAvailableShipment(result.data));
  // }
  return result;
};

const createNewShipmentAction = async (id, data) => {
  const result = await createNewShipment(id, data);
  // if (result.status === 200) {
  //   store.dispatch(setAllAvailableShipment(result.data));
  // }
  return result;
};

const getShipmentDetailAction = async (id, shippingId, editForm) => {
  const result = await getShipmentDetails(id, shippingId);
  if (result.status === 200) {
    if (editForm) {
      store.dispatch(editFormReducer(result.data));
    } else {
      store.dispatch(setActiveShipment(result.data));
    }
  }
  return result;
};
const createPackageAction = async (id, shippingId, data) => {
  const result = await createPackage(id, shippingId, data);

  return result;
};
const uploadPreSeedFileAction = async (orderId, shippingId, greenId, subId, data) => {
  const result = await uploadfileSeedGreenlight(orderId, shippingId, greenId, subId, data);

  return result;
};
const AddFiletoGreenLightAction = async (orderId, shippingId, greenId, desc, data) => {
  const result = await AddFiletoGreenLight(orderId, shippingId, greenId, desc, data);
  return result;
};
const attachFiletoGreenLightAction = async (orderId, shippingId, greenId, data) => {
  const result = await attachFiletoGreenLight(orderId, shippingId, greenId, data);
  return result;
};
const attachPreSeededFileAction = async (orderId, shippingId, greenId, subId, data) => {
  const result = await attachPreSeededFile(orderId, shippingId, greenId, subId, data);
  return result;
};

const updatePackageAction = async (id, shippingId, packageId, data) => {
  const result = await updatePackage(id, shippingId, packageId, data);
  return result;
};

const deletePackageAction = async (id, shippingId, packageId) => {
  const result = await deletePackage(id, shippingId, packageId);

  return result;
};

const getPostShipmentTemplateAction = async (id, shippingId, typeId) => {
  const result = await getPostShipmentTemplate(id, shippingId, typeId);
  if (result.status === 200) {
    if (typeId === 1) {
      store.dispatch(setPostShippingTemplate(result.data));
    } else {
      store.dispatch(setKMCTemplate(result.data));
    }
  }
  return result;
};

const updatePostShipmentTemplateAction = async (id, shippingId, data, typeId) => {
  const result = await updatePostShipmentTemplate(id, shippingId, data, typeId);

  return result;
};

const sendPostShippingAction = async (id, shippingId, typeId) => {
  const result = await sendPostShipping(id, shippingId, typeId);
  if (result.status === 200) {
    if (typeId === 1) {
      store.dispatch(setPostShippingTemplateSubmission(result.data));
    } else {
      store.dispatch(setkmcTemplateSubmission(result.data));
    }
  }
  return result;
};

const unAssignedItemsFromShuiomentAction = async (id, shippingId, data) => {
  const result = await unAssignedItemsFromShuioment(id, shippingId, data);
  // if (result.status === 200) {
  //   store.dispatch(setPostShippingTemplateSubmission(result.data));
  // }
  return result;
};

const deleteShipmentAction = async (id, shippingId) => {
  const result = await deleteShipment(id, shippingId);
  if (result.status === 200) {
    store.dispatch(deleteShimentReducer(shippingId));
  }
  return result;
};

const unlockShippingAction = async (id, shippingId) => {
  const result = await unlockShipping(id, shippingId);
  // if (result.status === 200) {
  //   store.dispatch(deleteShimentReducer(shippingId));
  // }
  return result;
};
const createNewDoumentAction = async (id, docId, data) => {
  const result = await createNewDoument(id, docId, data);
  // if (result.status === 200) {
  //   store.dispatch(deleteShimentReducer(shippingId));
  // }
  return result;
};

const getcourierServiceType = async (id) => {
  const result = await courierServiceType(id);
  if (result.status === 200) {
    store.dispatch(setCouriertype(result.data?.sort(caseInsensitiveSortItem)));
  }
  return result;
};

const getdynamicdocsAction = async (id, shipmentId) => {
  const result = await getdynamicdocs(id, shipmentId);
  if (result.status === 200) {
    store.dispatch(setdynamicdocuments(result.data));
  }
  return result;
};

export {
  getSettingListAction,
  updateSettingListAction,
  generateManualUploadAction,
  getDimenisonsForPackageAction,
  getdynamicdocsAction,
  getcourierServiceType,
  createNewDoumentAction,
  getAllTypeAction,
  createOrderTypeAction,
  getAllDocumentsForOrderAction,
  createAddressAction,
  unlockShippingAction,
  unlockPackgeAction,
  deleteShipmentAction,
  unAssignedItemsFromShuiomentAction,
  getPostShipmentTemplateAction,
  updatePostShipmentTemplateAction,
  sendPostShippingAction,
  shiptheShipmentAction,
  getQualityControlItemAction,
  updateQualityStatusAction,
  createPackageAction,
  updatePackageAction,
  deletePackageAction,
  updateShipmentAction,
  getShipmentDetailAction,
  createNewShipmentAction,
  getAllShipmentForOrderIdAction,
  updateOrderAction,
  getAcknowledgedetailAction,
  getAllAddressAction,
  getOrderDetailAction,
  getdefaultOrderValuesAction,
  orderAvailableItemsAction,
  createOrderAction,
  getAllDashboardStatsAction,
  getAllOrderAction,
  getAllbuildPreparationAction,
  approveAction,
  rejectAction,
  unAssignLineItemAction,
  AssignPreparationKitAction,
  getAllShippingStatsAction,
  AssignPreLineItemAction,
  getReadyInvenotryItemAction,
  getAssemblyforItemAction,
  getGreenLightAction,
  AddFiletoGreenLightAction,
  RemoveGreenLightFileAction,
  submitGreenLightApprovalAction,
  getOrderShipDocumentAction,
  attachFiletoGreenLightAction,
  uploadPreSeedFileAction,
  attachPreSeededFileAction,
  getdocumentDetailAction,
  generateAlltypeDocumentAction,
  getpackingDocumentDetailAction,
  getshipmentDocumentDetailsAction,
  generateShippmentDcoumentAction,
  updateGreenLightAction,
  cancelGreenLightApprovalAction,
  reSubmitGreenLightApprovalAction,
  getkittemplateViewItemAction,
};
