import React from 'react';

export const SliderRightIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" fill="white" />
      <path
        d="M9 6L15 12L9 18"
        stroke="#414753"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#414753" />
    </svg>
  );
};
