import React from 'react';
import { FormSelect } from '../../utils';
import {
  PaginationRightArrow,
  PaginationLeftArrow,
} from '../.././HemeIconLibrary';

const Pagination = (props) => {
  const { e, updateData, activePage, setActivePageRows, totalRows } = props;
  return (
    <div className="flex items-center justify-between pagination-wrapper px-[10px] pt-[10px] border-t-[1px] border-solid border-[#DEE2E6] ">
      {e?.rowCount && (
        <div className="flex items-center gap-[8px]">
          <p className="font-medium text-sm text-[#454545] m-0">Showing</p>
          <FormSelect
            options={
              !updateData
                ? [
                    { name: 10, id: 10 },
                    { name: 20, id: 20 },
                    { name: 30, id: 30 },
                    { name: 'All', id: 'All' },
                  ]
                : [
                    { name: 10, id: 10 },
                    { name: 20, id: 20 },
                    { name: 30, id: 30 },
                    { name: 50, id: 50 },
                  ]
            }
            placeholder={
              e?.rowsPerPage === e?.rowCount
                ? !updateData
                  ? 'All'
                  : 50
                : e?.rowsPerPage
            }
            setFieldValue={(f, total) => {
              if (total === 'All') {
                e.onChangeRowsPerPage(e?.rowCount, 1);
                if (setActivePageRows) {
                  setActivePageRows(totalRows);
                }
              } else {
                e.onChangeRowsPerPage(total, 1);
                if (setActivePageRows) {
                  setActivePageRows(total);
                }
              }
            }}
            className="w-[100px]"
          />
          <p className="font-semibold text-sm  text-[#454545] m-0">
            of {e?.rowCount}
          </p>
        </div>
      )}

      <div className="flex items-center gap-[8px]">
        <div
          className={
            e.rowsPerPage >= e.rowCount ? 'cursor-default' : 'cursor-pointer'
          }
          onClick={() => {
            if (e.currentPage !== 1) {
              e.onChangePage(e.currentPage - 1, e?.rowsPerPage);
            }
            if (updateData) {
              if (activePage !== 1) {
                updateData(activePage - 1, e);
              }
            }
          }}
        >
          {e.rowsPerPage >= e.rowCount ? (
            <PaginationLeftArrow color="#cccccc" />
          ) : (
            <PaginationLeftArrow />
          )}
        </div>
        <div className="flex items-center gap-[8px]">
          {[...Array(Math.ceil(e.rowCount / e?.rowsPerPage)).keys()].map(
            (numb) => {
              return numb + 1 <= 20 ? (
                <div
                  className={`w-7 h-7 flex items-center justify-center flex-shrink-0 rounded-[5px] font-normal text-[12px] leading-[18px]  cursor-pointer hover:bg-primary1 hover:text-[white]  ${
                    activePage
                      ? activePage === numb + 1 && 'bg-primary1 text-[white]'
                      : e.currentPage === numb + 1 && 'bg-primary1 text-[white]'
                  }`}
                  onClick={() => {
                    e.onChangePage(numb + 1, e?.rowsPerPage);
                    if (updateData) {
                      updateData(numb + 1, e);
                    }
                  }}
                >
                  {numb + 1}
                </div>
              ) : (

                  numb + 1===activePage &&
                  <>
                  <div> .... </div>
                  <div
                    className={`w-7 h-7 flex items-center justify-center flex-shrink-0 rounded-[5px] font-normal text-[12px] leading-[18px]  cursor-pointer hover:bg-primary1 hover:text-[white]  ${
                      activePage
                        ? activePage === numb + 1 && 'bg-primary1 text-[white]'
                        : e.currentPage === numb + 1 &&
                          'bg-primary1 text-[white]'
                    }`}
                    onClick={() => {
                      e.onChangePage(numb + 1, e?.rowsPerPage);
                      if (updateData) {
                        updateData(numb + 1, e);
                      }
                    }}
                  >
                    {numb + 1}

                  </div>
                  </>

              );
            }
          )}
        </div>
        <div
          className={
            e.rowsPerPage >= e.rowCount ? 'cursor-default' : 'cursor-pointer '
          }
          onClick={() => {
            // if(e.currentPage!==1) {
            e.onChangePage(e.currentPage + 1, e?.rowsPerPage);
            // }
            // e.onChangePage(
            //   Math.ceil(e.rowCount / e?.rowsPerPage),
            //   e?.rowsPerPage
            // );
            if (updateData) {
              updateData(activePage + 1, e);
            }
          }}
        >
          {e.rowsPerPage >= e.rowCount ? (
            <PaginationRightArrow color="#cccccc" />
          ) : (
            <PaginationRightArrow />
          )}
        </div>
      </div>
    </div>
  );
};
export default Pagination;
