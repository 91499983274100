import React from 'react';

export const CityIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8401_299211)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 14V4.00003C2 3.81853 2.0985 3.65103 2.257 3.56303L6.75699 1.06303C6.91199 0.977034 7.10099 0.979034 7.25349 1.06903C7.40599 1.15903 7.49998 1.32303 7.49998 1.50003V6.24252L7.70949 6.09302C7.88299 5.96902 8.11699 5.96902 8.29049 6.09302L8.49998 6.24252V1.50003C8.49998 1.32303 8.59349 1.15903 8.74649 1.06903C8.89899 0.979034 9.08798 0.977034 9.24298 1.06303L13.743 3.56303C13.9015 3.65103 14 3.81853 14 4.00003V14H14.4985C14.7745 14 14.9985 14.224 14.9985 14.5C14.9985 14.776 14.7745 15 14.4985 15H1.5C1.224 15 1 14.776 1 14.5C1 14.224 1.224 14 1.5 14H2ZM13 14V4.29403L9.49998 2.35003V6.95702L11.7905 8.59302C11.922 8.68702 12 8.83852 12 9.00001V14H13ZM6.49999 6.95702V2.35003L3 4.29403V14H3.99999V9.00001C3.99999 8.83852 4.078 8.68702 4.2095 8.59302L6.49999 6.95702ZM11 14V9.25751L7.99998 7.11452L4.99999 9.25751V14H11ZM6.49999 12H6.99999C7.27598 12 7.49998 11.776 7.49998 11.5C7.49998 11.224 7.27598 11 6.99999 11H6.49999C6.22399 11 5.99999 11.224 5.99999 11.5C5.99999 11.776 6.22399 12 6.49999 12ZM8.99998 12H9.49998C9.77598 12 9.99998 11.776 9.99998 11.5C9.99998 11.224 9.77598 11 9.49998 11H8.99998C8.72398 11 8.49998 11.224 8.49998 11.5C8.49998 11.776 8.72398 12 8.99998 12ZM6.49999 10H6.99999C7.27598 10 7.49998 9.77601 7.49998 9.50001C7.49998 9.22401 7.27598 9.00001 6.99999 9.00001H6.49999C6.22399 9.00001 5.99999 9.22401 5.99999 9.50001C5.99999 9.77601 6.22399 10 6.49999 10ZM8.99998 10H9.49998C9.77598 10 9.99998 9.77601 9.99998 9.50001C9.99998 9.22401 9.77598 9.00001 9.49998 9.00001H8.99998C8.72398 9.00001 8.49998 9.22401 8.49998 9.50001C8.49998 9.77601 8.72398 10 8.99998 10Z"
          fill="url(#paint0_linear_8401_299211)"
          stroke="url(#paint1_linear_8401_299211)"
          stroke-width="0.5"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8401_299211"
          x1="1"
          y1="7.99999"
          x2="14.9985"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8401_299211"
          x1="1"
          y1="7.99999"
          x2="14.9985"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <clipPath id="clip0_8401_299211">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
