import React from 'react';

export const LotNumberIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2 2H3.8C3.32261 2 2.86477 2.18964 2.52721 2.52721C2.18964 2.86477 2 3.32261 2 3.8V12.2C2 12.6774 2.18964 13.1352 2.52721 13.4728C2.86477 13.8104 3.32261 14 3.8 14H12.2C12.6774 14 13.1352 13.8104 13.4728 13.4728C13.8104 13.1352 14 12.6774 14 12.2V3.8C14 3.32261 13.8104 2.86477 13.4728 2.52721C13.1352 2.18964 12.6774 2 12.2 2ZM6.8 3.2H9.2V5.078L8.33 4.478C8.23203 4.41348 8.1173 4.3791 8 4.3791C7.8827 4.3791 7.76797 4.41348 7.67 4.478L6.8 5.078V3.2ZM12.8 12.2C12.8 12.3591 12.7368 12.5117 12.6243 12.6243C12.5117 12.7368 12.3591 12.8 12.2 12.8H3.8C3.64087 12.8 3.48826 12.7368 3.37574 12.6243C3.26321 12.5117 3.2 12.3591 3.2 12.2V3.8C3.2 3.64087 3.26321 3.48826 3.37574 3.37574C3.48826 3.26321 3.64087 3.2 3.8 3.2H5.6V6.2C5.60029 6.30854 5.63002 6.41497 5.68602 6.50796C5.74202 6.60094 5.82219 6.67699 5.918 6.728C6.01182 6.77618 6.11669 6.79877 6.22203 6.7935C6.32736 6.78823 6.42945 6.75529 6.518 6.698L8 5.72L9.47 6.698C9.56024 6.75743 9.66485 6.79139 9.77279 6.79629C9.88073 6.80119 9.98799 6.77685 10.0832 6.72585C10.1785 6.67484 10.2582 6.59906 10.314 6.5065C10.3697 6.41394 10.3994 6.30805 10.4 6.2V3.2H12.2C12.3591 3.2 12.5117 3.26321 12.6243 3.37574C12.7368 3.48826 12.8 3.64087 12.8 3.8V12.2Z"
        fill="url(#paint0_linear_8401_299363)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8401_299363"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
