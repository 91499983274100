const CustomInvoiceValidation = (errors, values, store) => {
  if (values.kitContent && values.kitContent?.length > 100) {
    errors.kitContent = 'Max 100 characters allowed';
  }
  if (values.descOfgoods && values.descOfgoods?.length > 100) {
    errors.descOfgoods = 'Max 100 characters allowed';
  }
  if (values.items && values.items?.toString().length > 10) {
    errors.items = 'Max 10 characters allowed';
  }
  if (values.valueUsd && values.valueUsd?.toString().length > 10) {
    errors.valueUsd = 'Max 10 characters allowed';
  }

  return errors;
};

export default CustomInvoiceValidation;
