export const AddDocument = [
  {
    label: 'Upload and attach files to this request',
    type: 'download-bulk',
    downloadType: null,
    fluid: true,
    onClick: () => {},
    // buttonText: 'Download Sample Template',
  },
  {
    name: 'docFile',
    // label: 'Upload Excel',
    // Icon: <Upload />,
    type: 'upload-image',
    initialValue: undefined,
    placeholder: 'Excel',
    fluid: true,
    accept: ['application/pdf', 'image/png', 'image/jpeg, image/svg', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  },
  {
    name: 'attachFile',
    label: 'Select File from the existing document',
    // Icon: <Upload />,
    type: 'upload-image',
    initialValue: undefined,
    placeholder: 'Excel',
    fluid: true,
  },
];
