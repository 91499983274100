import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
const Nodata = () => {
  return (
    <BootstrapAlert variant="warning" className="mt-3">
      The selected item is not a kit template and there are no items to show.
    </BootstrapAlert>
  );
};

export default Nodata;
