import React from "react";
const Button = (props) => {
  const { text, Icon, color, bg, cta, border, disabled, className, p, borderText, btntext } = props;
  return (
    <button
      {...props}
      disabled={disabled ? true : false}
      onClick={(e) => {
        if (cta) {
          cta(e);
        }
      }}
      className={` ${border ? ` fit-content solid border ${border}` : "fit-content"} ${
        disabled && "opacity-30"
      } flex w-fit fit-content items-center  ${bg} ${color} gap-[8px] rounded-[5px] ${text ? " fit-content py-[11px] px-[20px]" : "p-[8px]"} ${
        p ? "py-[12px] px-[16px] " : "p-[8px] "
      } ${className}  fit-content text-[14px] leading-[17px] font-medium`}
    >
      {Icon}
      {text}
      {borderText && (
        <p className=" m-0 flex border rounded-[5px] solid w-[150px] min-h-[24px] py-[3px] px-[6px] text-placeholderColor text-xs leading-[18px] font-medium  ">{btntext}</p>
      )}
    </button>
  );
};
export default Button;
