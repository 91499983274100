import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { HemaLabel, Button, Alert, FormCheckbox, HemaValue, FormText, ImageUpload } from '../../../utils';
import { generateManualUploadAction, getAllDocumentsForOrderAction, getAllShippingStatsAction, getShipmentDetailAction } from '../../../Actions/order';
import { Cancel, NextIcon, CrossIcon, TrackingNumber, Upload, SelectUpload, UploadIcon, Confirm } from '../../../HemeIconLibrary';

import { setForm, showSuccessReducer, setBuildTemplate } from '../../../Store/reducers/uiSettings';
import Heading from '../../../pages/logistOrchestra/logisticComponents/heading';
import DataTable from 'react-data-table-component';
import { compareArrays } from '../../../helpers/commonHelper';

const ShippingUpload = () => {
  const dispatch = useDispatch();
  const [optionSelected, setOptionSelected] = useState();
  const { orders, uisettings } = useSelector((state) => state);
  const formikRef = useRef();

  useEffect(() => {
    (async () => {
      console.log(await formikRef.current?.validateForm());
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm]);
  return (
    <>
      {optionSelected ? (
        <Formik
          initialValues={{
            TrackingNumber: '',
            Packages: orders?.activeShipment?.packages,
            notebookdocs: '',
          }}
          innerRef={formikRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.TrackingNumber) {
              errors.TrackingNumber = 'Required';
            }
            if (values.TrackingNumber && values.TrackingNumber?.length > 100) {
              errors.TrackingNumber = 'Max 100 character allowed';
            }

            if (!values.notebookdocs) {
              errors.notebookdocs = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            formData.append('file', values.notebookdocs);

            const payloadData = {
              TrackingNumber: values.TrackingNumber,
              Packages: values.Packages.map((data) => {
                return {
                  PackageId: data?.id,
                  ParcelId: data.parcelId,
                };
              }),
            };
            formData.append('model', JSON.stringify(payloadData));
            var typedoc = orders.activeDynamicDoc?.buildType === 'awb' ? 'air-way-bill' : 'depot-air-way-bill';
            const result = await generateManualUploadAction(orders?.activeOrder?.id, orders?.activeShipment?.id, typedoc, formData);
            setSubmitting(false);
            if (result.status === 200) {
              getAllDocumentsForOrderAction(orders.activeOrder?.id);
              getAllShippingStatsAction(orders.activeOrder?.id);
              await getShipmentDetailAction(orders.activeOrder?.id, orders?.activeShipment?.id);
              dispatch(showSuccessReducer('document is upload successfully'));
              dispatch(
                setForm({
                  state: true,
                  type: 'shipping-assembly-preparation',
                }),
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            initialValues,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                <div className="w-full mb-[30px]">
                  <Heading heading={'Upload Airway Bill Manually'} />
                </div>

                <div className="w-full items-start gap-[16px] mb-[25px]">
                  <HemaLabel color="#454545" text={'Tracking Number'} Icon={<TrackingNumber />} className={`mb-[10px]`} required />
                  <FormText
                    placeholder={'Enter'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.TrackingNumber}
                    name="TrackingNumber"
                    type="text"
                    className="w-full"
                    setFieldValue={setFieldValue}
                    crossIcon={values?.TrackingNumber ? <CrossIcon /> : ''}
                  />
                  {errors.TrackingNumber && touched.TrackingNumber && <div className="error text-[red] text-[12px] pt-[2px]">{errors.TrackingNumber}</div>}
                </div>

                <div className="w-full items-start gap-[16px] mb-[25px]">
                  <HemaLabel text={'Upload File'} Icon={<UploadIcon purple />} className={`mb-[10px]`} color="#454545" />

                  <ImageUpload name={'notebookdocs'} onChange={handleChange} onBlur={handleBlur} value={values.notebookdocs} setFieldValue={setFieldValue} />
                  {errors.notebookdocs && touched.notebookdocs && <div className="error text-[red] text-[12px] pt-[2px]">{errors.notebookdocs}</div>}
                </div>

                <Heading heading={'Package Details'} border />

                <div className="w-full flex items-start gap-[16px] border border-solid border-[#DEE2E6] rounded">
                  <DataTable
                    data={values?.Packages}
                    className="airway-bill-table"
                    columns={[
                      {
                        name: <HemaValue className="text-xs font-normal" color="text-[#000000]" text="Package Name" />,
                        selector: (row) => <HemaValue className="text-xs " text={row?.name} />,
                      },
                      {
                        style: {
                          div: {
                            width: '100% !important',
                          },
                        },
                        name: (
                          <HemaValue className="text-xs font-normal" color="text-[#000000]" text={orders.activeDynamicDoc?.buildType === 'awb' ? 'Parcel ID' : 'Depot Parcel ID'} />
                        ),

                        selector: (row) => (
                          <FormText
                            className="parcel-id-input"
                            type="text"
                            placeholder="Enter"
                            onChange={(e) => {
                              setFieldValue(
                                'Packages',
                                values.Packages.map((data) => {
                                  if (data.id === row.id) {
                                    return {
                                      ...data,
                                      parcelId: e.target.value,
                                    };
                                  } else {
                                    return data;
                                  }
                                }),
                              );
                            }}
                          />
                        ),
                      },
                    ]}
                  />
                </div>
              </div>

              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    dispatch(
                      setForm({
                        state: true,
                        type: 'shipping-assembly-preparation',
                      }),
                    );
                  }}
                  type="button"
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  Icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <Button
                    type="submit"
                    text="Confirm"
                    bg="bg-primary1"
                    color="text-white"
                    Icon={<Confirm />}
                    disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                  />
                )}
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{
            options: '',
          }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.options) {
              errors.options = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            if (values.options === '1') {
              dispatch(
                setForm({
                  state: true,
                  type: 'shipping-assembly-preparation',
                }),
              );
              dispatch(
                setBuildTemplate({
                  open: true,
                  orderItemDetail: {
                    ...uisettings?.editForm,
                    buildType: orders.activeDynamicDoc?.buildType,
                  },
                }),
              );
            } else {
              setOptionSelected(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                <div className={'w-full'}>
                  <Heading heading={'Airway Bill Details'} />
                  <div className="mt-[30px] mb-[25px]">
                    <HemaLabel
                      text={'Please select whether you would like to auto-generate the airway bill for this shipment or if you want to manually upload it.'}
                      className={`mb-[10px]`}
                    />
                  </div>

                  <label className="flex items-center gap-[10px] mb-[20px]">
                    <FormCheckbox type="radio" name="options" onChange={handleChange} onBlur={handleBlur} value={1} />
                    Auto-Generate
                  </label>
                  <label className="flex items-center gap-[10px]">
                    <FormCheckbox type="radio" name="options" onChange={handleChange} onBlur={handleBlur} value={2} />
                    Manual Upload
                  </label>
                </div>

                <div className={'w-full'}>
                  <div className="mb-[30px]"></div>
                  {errors.options && touched.options && <div className="error text-[red] text-[12px] pt-[2px]">{errors.options}</div>}
                </div>
              </div>

              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    dispatch(
                      setForm({
                        state: true,
                        type: 'shipping-assembly-preparation',
                      }),
                    );
                  }}
                  type="button"
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  Icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <Button type="submit" text="Next" bg="bg-primary1" color="text-white" Icon={<NextIcon />} />
                )}
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ShippingUpload;
