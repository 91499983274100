import {
  getAllEmailTemplates,
  createEmailTemplates,
  getEmailTemplatesTypes,
  updateEmailTemplates,
  removeEmailTemplatesItem,
  getMergefieldforEmail
} from '../Services/emailTemplate';
import {
  setAllEmailTemplatesAction,
  deleteEmailTemplates,
  addNewEmailTemplates,
  editEmailTemplates,
  setAllEmailTemplatesTypes,
  setAllMergeField
} from '../Store/reducers/emailTemplate';

import { store } from '../Store';
import { caseInsensitiveSort } from '../helpers/commonHelper';

const getAllEmailTemplateAction = async () => {
  const result = await getAllEmailTemplates();
  if (result.status === 200) {
    store.dispatch(
      setAllEmailTemplatesAction(result.data?.sort(caseInsensitiveSort))
    );
  }
  return result;
};

const getEmailTemplateTypeAction = async () => {
  const result = await getEmailTemplatesTypes();
  if (result.status === 200) {
    store.dispatch(
      setAllEmailTemplatesTypes(result.data?.sort(caseInsensitiveSort))
    );
  }
  return result;
};

const getMergefieldforEmailAction = async (id) => {
  const result = await getMergefieldforEmail(id);
  if (result.status === 200) {
    store.dispatch(
      setAllMergeField(result.data?.sort(caseInsensitiveSort))
    );
  }
  return result;
};



const createEmailTemplateAction = async (data) => {
  const result = await createEmailTemplates(data);
  if (result.status === 200) {
    store.dispatch(addNewEmailTemplates(result.data));
  }
  return result;
};

const updateEmailTemplateAction = async (id, data) => {
  const result = await updateEmailTemplates(id, data);
  if (result.status === 200) {
    store.dispatch(editEmailTemplates(result.data));
  }
  return result;
};

const removeEmailTemplateAction = async (id, data) => {
  const result = await removeEmailTemplatesItem(id, data);
  if (result.status === 200) {
    store.dispatch(deleteEmailTemplates(id));
  }
  return result;
};

export {
  getMergefieldforEmailAction,
  updateEmailTemplateAction,
  getEmailTemplateTypeAction,
  createEmailTemplateAction,
  getAllEmailTemplateAction,
  removeEmailTemplateAction,
};
