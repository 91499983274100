import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';

import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { Button, FormSearch, Alert, Pagination, HemaValue, FilterColumn, NoPermissionAlert, NoDataComponent } from '../utils';
import { Add, Edit, ExpiryAlertPurple, CreateSite, SearchColor, Confirm, TableSortIcon } from '../HemeIconLibrary';
import FormContainer from '../components/Formik/formContainer';
import { setForm, editFormReducer, showSuccessReducer, setFormLoaderReducer, setFormCloseReducer } from '../Store/reducers/uiSettings';
import { setStudyFilter } from '../Store/reducers/study';

import { sortedData } from '../helpers/sort';
import { getAllStudyAction, createNewStudyAction, updateStudyAction } from '../Actions/study';
import { createNewStudy } from '../components/Formik/AllForms/createNewStudy';
import createNewItemValidator from '../components/Formik/allFormValidation/createItem';
import { StudyManagementColumns } from '../components/Tables';
import { getAllSponserAction } from '../Actions/sponsers';

const StudyManagement = () => {
  const dispatch = useDispatch();
  const { uisettings, sponsers, studies, common } = useSelector((state) => state);

  /* form states */
  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [formValidation, setFormValidation] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [searchQuery, setsearchQuery] = useState('');
  const [okBtnText, setokBtnText] = useState();
  const [okBtnIcon, setokBtnIcon] = useState();
  const [filteredData, setfilteredData] = useState([]);
  //callallEnventoryRequiredAPI
  useEffect(() => {
    (async () => {
      getAllSponserAction();
      getAllStudyAction();
    })();
  }, []);

  // control item thresold switch
  // useEffect(() => {
  //   createNewStudy[2].disabled = !common.switch.threshold;
  // }, [common.switch.threshold]);

  // CTA item add
  const addStudyCTA = (edit) => {
    setCTA(() => async (data) => {
      const fildata = {
        ...data,
        logisticOrchestratorStatus: data.logisticOrchestratorStatus === undefined ? false : data.logisticOrchestratorStatus,
        nameAlias: data.nameAlias === undefined ? '' : data.nameAlias,
        studyCodeAlias: data.studyCodeAlias === undefined ? '' : data.studyCodeAlias,
      };
      if (edit) {
        dispatch(setFormLoaderReducer(true));
        const editSponser = await updateStudyAction(edit, {
          ...fildata,
          changeReason: fildata.changeReason.trim(),
        });
        dispatch(setFormLoaderReducer(false));
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${editSponser.data.name} Study updated.`));
      } else {
        dispatch(setFormLoaderReducer(true));
        const createdSponser = await createNewStudyAction(fildata);
        if (createdSponser?.data?.id) {
          dispatch(setFormCloseReducer());
          dispatch(showSuccessReducer(`${createdSponser.data.name} Study added.`));
        }
        dispatch(setFormLoaderReducer(false));
      }
    });
    setFormValidation((error, values) => (errors, values) => {
      createNewItemValidator(errors, values);
      if (values?.nameAlias?.length > 50) {
        errors.nameAlias = 'Max 50 characters allowed';
      }
      if (values?.studyCodeAlias?.length > 50) {
        errors.studyCodeAlias = 'Max 50 characters allowed';
      }
      if (values.studycode?.length > 50) {
        errors.studycode = 'Max 50 characters allowed';
      }
      return errors;
    });
  };

  // CTA for inventory for selected item
  // filter and search

  useEffect(() => {
    const regex = new RegExp(searchQuery, 'i');
    (async () => {
      if (searchQuery) {
        const data = studies?.allStudies?.filter((obj) => {
          for (const key in obj) {
            if (regex.test(obj[key])) {
              return true;
            }
            if (key === 'sponsor') {
              const childObj = obj[key];
              for (const k in childObj) {
                if (regex.test(childObj[k])) {
                  return true;
                }
                // return false;
              }
            }
          }
          return false;
        });
        setfilteredData(data);
      } else {
        setfilteredData(studies?.allStudies);
      }
    })();
  }, [searchQuery, studies?.allStudies]);

  // study filter
  useEffect(() => {
    if (Object.keys(studies?.studyFilter)?.length) {
      const filterResult = studies?.allStudies?.filter((port) => {
        if (
          (studies?.studyFilter?.name?.length ? studies?.studyFilter?.name.includes(port.name) : true) &&
          (studies?.studyFilter?.sponsor?.length ? studies?.studyFilter.sponsor?.includes(port.sponsor?.name) : true) &&
          (studies?.studyFilter?.studyCode?.length ? studies?.studyFilter.studyCode?.includes(port.studyCode) : true) &&
          (studies?.studyFilter?.nameAlias?.length ? studies?.studyFilter.nameAlias?.includes(port.nameAlias) : true) &&
          (studies?.studyFilter?.nameAlias?.length ? studies?.studyFilter?.nameAlias.includes(port.nameAlias) : true) &&
          (studies?.studyFilter?.studyCodeAlias?.length ? studies?.studyFilter.studyCodeAlias?.includes(port.studyCodeAlias) : true) &&
          (studies?.studyFilter?.isActive?.length ? studies?.studyFilter.isActive?.includes(port.isActive) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setfilteredData(filterResult);
    } else {
      setfilteredData(studies?.allStudies);
    }
  }, [JSON.stringify(studies?.studyFilter)]);

  if (!common?.permissions?.['Study Management']?.read) {
    return <NoPermissionAlert />;
  }

  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText heading="Study Management" sub="Manage your studies here." />
        <div className="flex gap-[10px] ">
          {common?.permissions?.['Study Management']?.write && (
            <Button
              text="Create New Study"
              Icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              cta={() => {
                setformName('Create Study');
                createNewStudy[0].options = sponsers?.allSponsers?.filter((sponsor) => sponsor.isActive === true);
                createNewStudy[0].disabled = false;
                createNewStudy[1].initialValue = '';
                createNewStudy[2].initialValue = '';
                createNewStudy[3].initialValue = '';
                createNewStudy[4].initialValue = '';
                // setFormIcon(<CreateSite />);
                setokBtnText('Create');
                setokBtnIcon(<Add color="white" />);
                setUpdatedData(createNewStudy?.filter((item) => item.name !== 'changeReason'));
                addStudyCTA();
                dispatch(setForm({ state: true, type: 'create-Study' }));
              }}
            />
          )}
        </div>
      </div>

      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
        <>
          <div className="mb-[16px] pl-[16px]">
            <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
          </div>

          {studies?.allStudies ? (
            filteredData?.length > 0 || Object.keys(studies?.studyFilter)?.length ? (
              <DataTable
                customStyles={{
                  responsiveWrapper: {
                    style: { overflow: 'visible !important' },
                  },
                }}
                noDataComponent={<NoDataComponent setActiveFilterTab={setfilteredData} activeFilterOriginalData={studies?.allStudies} resetRedux={setStudyFilter} />}
                data={filteredData}
                className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
                columns={[
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Sponsor Name'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="sponsor"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter.sponsor?.name)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.sponsor?.name} />,
                    sortId: 'sponsor.name',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Name'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="name"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.name)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.name} />,
                    sortId: 'name',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Code'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="studyCode"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.studyCode)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.studyCode} />,
                    sortId: 'studyCode',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Name Alias'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="nameAlias"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.nameAlias)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.nameAlias} />,
                    sortId: 'nameAlias',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Study Code Alias'} color="text-[#000000]" className="text-xs font-normal whitespace-pre" />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="studyCodeAlias"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.studyCodeAlias)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.studyCodeAlias} />,
                    sortId: 'studyCodeAlias',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Active'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="isActive"
                            setRedux={setStudyFilter}
                            reduxValues={studies?.studyFilter || []}
                            options={Array.from(new Set(studies?.allStudies.map((filter) => filter?.isActive)))}
                            type="boolean"
                            boolTrueText="Active"
                            boolFalseText="In-Active"
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue className="text-xs font-normal" text={row?.isActive ? 'Yes' : 'No'} />,
                    sortId: 'isActive',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal" />
                        </div>
                      </div>
                    ),
                    selector: (row, index) => (
                      <div className="flex-grow four">
                        <div className="flex justify-end gap-[5px] meta">
                          {common?.permissions?.['Study Management']?.update && (
                            <Button
                              Icon={<Edit color="#775FD5" />}
                              color="text-white"
                              bg="bg-bgActionDots"
                              cta={() => {
                                createNewStudy[0].initialValue = row?.sponsor?.id;
                                createNewStudy[0].placeholder = row?.sponsor?.name;
                                createNewStudy[0].disabled = true;
                                createNewStudy[1].initialValue = row?.name;
                                createNewStudy[2].initialValue = row?.studyCode;
                                createNewStudy[3].initialValue = row?.nameAlias;
                                createNewStudy[4].initialValue = row?.studyCodeAlias;
                                createNewStudy[5].initialValue = row?.isActive;
                                createNewStudy[6].initialValue = row?.logisticOrchestratorStatus;
                                setUpdatedData(createNewStudy);
                                setformName('Edit Study');
                                // setFormIcon(<ExpiryAlertPurple />);
                                dispatch(editFormReducer(row));
                                setokBtnText('Save');
                                setokBtnIcon(<Confirm />);
                                addStudyCTA(row?.id);
                                dispatch(setForm({ state: true, type: 'edit-study' }));
                              }}
                            />
                          )}
                          {/* <Button Icon={<SearchColor />} color="text-white" bg="bg-bgActionDots" cta={() => {}} /> */}
                        </div>
                      </div>
                    ),
                  },
                ]}
                pagination
                onSort={(row, direction, sorted) => {
                  setfilteredData(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
                }}
                paginationComponent={(e) => {
                  return <Pagination e={e} />;
                }}
              />
            ) : (
              <BootstrapAlert variant="warning" className="mt-3 text-center">
                There are no records to display.
              </BootstrapAlert>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </>
      </div>

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          Icon={formIcon}
          formValidation={formValidation}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
          setokBtnIcon={setokBtnIcon}
          setokBtnText={setokBtnText}
        />
      )}
    </>
  );
};

export default StudyManagement;
