export const quotes = [
    "Vision inspires impactful actions.",
    "Persevere amidst every failure.",
    "Adaptability ensures professional survival.",
    "Action always beats intention.",
    "Drive change dont wait.",
    "Innovation distinguishes leaders, not followers.",
    "Success follows persistent effort.",
    "Optimism fuels persistent progress.",
    "Embrace change evolve constantly.",
    "Patience builds professional excellence.",
    "Passion overrides monetary gains.",
    "Collaboration trumps individual brilliance.",
    "Integrity is non-negotiable.",
    "Resilience paves way to success.",
    "Quality work trumps quantity.",
    "Curiosity sparks continuous learning.",
    "Constructive criticism fuels growth.",
    "Dedication beats talent alone.",
    "Proactivity preempts potential crises",
    "Persistence outlasts immediate success.",
    "Wisdom comes through experience.",
    "Reliability establishes professional trust.",
    "Consistency is key to success.",
    "Competence trumps credentials.",
    "Embrace feedback foster improvement.",
    "Effective communication fosters teamwork.",
    "Respect cultivates workplace harmony.",
    "Simplicity triumphs complexity.",
    "Prioritize health over hustle.",
    "Attitude determines professional altitude.",
    "Value time achieve more.",
    "Trust the currency of business.",
    "Negativity drains productivity.",
    "Efficiency do more with less.",
    "Mindset matters choose growth.",
    "Honesty elevates professional credibility.",
    "Goal-setting propels career advancement.",
    "Calmness fuels effective decision making.",
    "Empathy enhances leadership.",
  ];