import React from 'react';

export const Bell = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C5.5032 0 5.1 0.4032 5.1 0.9V1.31719C3.54791 1.71726 2.4 3.12288 2.4 4.8V8.4L1.2 9.6V10.2H4.96172C4.85607 10.3823 4.80029 10.5893 4.8 10.8C4.8 11.1183 4.92643 11.4235 5.15147 11.6485C5.37652 11.8736 5.68174 12 6 12C6.31826 12 6.62348 11.8736 6.84853 11.6485C7.07357 11.4235 7.2 11.1183 7.2 10.8C7.19936 10.5892 7.14317 10.3822 7.03711 10.2H10.8V9.6L9.6 8.4V4.8C9.6 3.12288 8.45209 1.71726 6.9 1.31719V0.9C6.9 0.4032 6.4968 0 6 0ZM2.34492 0.0492188C0.921122 1.14602 0 2.8632 0 4.8H1.2C1.2 3.2508 1.93737 1.87681 3.07617 0.999609L2.34492 0.0492188ZM9.65508 0.0492188L8.92383 0.999609C10.0626 1.87681 10.8 3.2508 10.8 4.8H12C12 2.8632 11.0789 1.14602 9.65508 0.0492188ZM6 2.4C7.3236 2.4 8.4 3.4764 8.4 4.8V8.4V8.89688L8.50313 9H3.49688L3.6 8.89688V8.4V4.8C3.6 3.4764 4.6764 2.4 6 2.4Z"
        fill="url(#paint0_linear_3315_84511)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3315_84511"
          x1="5.44823e-08"
          y1="5.99999"
          x2="12"
          y2="5.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
