import React from 'react';
import { Alert } from 'react-bootstrap';

const NoPermissionAlert = () => {
  return (
    <Alert variant="danger">
      You dont have permission to access this module. kindly request customer
      support
    </Alert>
  );
};
export default NoPermissionAlert;
