import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';

import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { Button, FormSearch, Alert, Pagination, HemaValue, FilterColumn, NoPermissionAlert, NoDataComponent } from '../utils';
import { Add, CreateSite, Edit, SearchColor, ExpiryAlertPurple, Confirm, TableSortIcon } from '../HemeIconLibrary';
import FormContainer from '../components/Formik/formContainer';
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from '../Store/reducers/uiSettings';
import { createNeSponserAction, getAllSponserAction, updateNewSponserAction } from '../Actions/sponsers';
import { setSponsorsFilter } from '../Store/reducers/sponser';
import { createNewSponser } from '../components/Formik/AllForms/createNewSponser';
import { SponserManagementColumns } from '../components/Tables';
import { sortedData } from '../helpers/sort';

const SponserManagement = () => {
  const dispatch = useDispatch();
  const { uisettings, sponsers, common } = useSelector((state) => state);
  const { sponsorFilter } = sponsers;
  /* form states */
  const [CTA, setCTA] = useState();
  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [okBtnText, setokBtnText] = useState();
  const [okBtnIcon, setokBtnIcon] = useState();
  const [formValidation, setFormValidation] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [searchQuery, setsearchQuery] = useState('');
  const [filteredData, setfilteredData] = useState([]);
  const [sortIcon, setSortIcon] = useState();

  //callallEnventoryRequiredAPI
  useEffect(() => {
    (async () => {
      getAllSponserAction();
    })();
  }, []);

  // control item thresold switch
  useEffect(() => {
    createNewSponser[2].disabled = !common.switch.threshold;
  }, [common.switch.threshold]);

  // CTA item add
  const addSponserCTA = (edit) => {
    setCTA(() => async (data) => {
      if (edit) {
        dispatch(setFormLoaderReducer(true));
        const editSponser = await updateNewSponserAction(edit, {
          ...data,
          changeReason: data.changeReason.trim(),
        });
        dispatch(setFormLoaderReducer(false));
        dispatch(showSuccessReducer(`${editSponser.data.name} Sponsor updated.`));
        dispatch(setFormCloseReducer());
      } else {
        dispatch(setFormLoaderReducer(true));
        const createdSponser = await createNeSponserAction({
          ...data,
          ChangeReason: '',
        });
        if (createdSponser?.data?.id) {
          dispatch(setFormCloseReducer());
          dispatch(showSuccessReducer(`${createdSponser.data.name} Sponsor added.`));
        }
        dispatch(setFormLoaderReducer(false));
      }
    });
    setFormValidation(() => (errors, values) => {
      if (values?.name?.length > 100) {
        errors.name = 'Max 100 characters allowed';
      }
      if (values?.abbreviation?.length > 6) {
        errors.abbreviation = 'Max 6 characters allowed';
      }
    });
  };

  // CTA for inventory for selected item
  // filter and search
  useEffect(() => {
    const regex = new RegExp(searchQuery, 'i');
    (async () => {
      if (searchQuery) {
        const data = sponsers?.allSponsers?.filter((obj) => {
          for (const key in obj) {
            if (regex.test(obj[key])) {
              return true;
            }
          }
          return false;
        });
        setfilteredData(data);
      } else {
        setfilteredData(sponsers?.allSponsers);
      }
    })();
  }, [searchQuery, sponsers?.allSponsers]);

  // sponser filter
  useEffect(() => {
    if (Object.keys(sponsorFilter)?.length) {
      const filterResult = sponsers?.allSponsers?.filter((port) => {
        if (
          (sponsorFilter?.name?.length ? sponsorFilter?.name.includes(port.name) : true) &&
          (sponsorFilter?.abbreviation?.length ? sponsorFilter.abbreviation?.includes(port.abbreviation) : true) &&
          (sponsorFilter?.isActive?.length ? sponsorFilter.isActive?.includes(port.isActive) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setfilteredData(filterResult);
    } else {
      setfilteredData(sponsers?.allSponsers);
    }
  }, [JSON.stringify(sponsorFilter)]);
  if (!common?.permissions?.['Sponsor Management']?.read) {
    return <NoPermissionAlert />;
  }
  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText heading="Sponsor Management" sub="Manage your sponsors here." />
        <div className="flex gap-[10px] ">
          {common?.permissions?.['Sponsor Management']?.write && (
            <Button
              text="Create New Sponsor"
              Icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              cta={() => {
                setformName('Create Sponsor');
                setokBtnText('Create');
                setokBtnIcon(<Add color="white" />);
                createNewSponser[0].initialValue = '';
                createNewSponser[1].initialValue = '';
                createNewSponser[2].initialValue = true;
                // setFormIcon(<CreateSite />);
                setUpdatedData(createNewSponser?.filter((item) => item.name !== 'changeReason'));
                addSponserCTA();
                dispatch(setForm({ state: true, type: 'addSponser' }));
              }}
            />
          )}
        </div>
      </div>

      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
        <>
          <div className="mb-[16px] pl-[16px]">
            <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
          </div>

          {sponsers?.allSponsers ? (
            filteredData?.length > 0 || Object.keys(sponsorFilter)?.length ? (
              <DataTable
                data={filteredData}
                className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
                noDataComponent={<NoDataComponent setActiveFilterTab={setfilteredData} activeFilterOriginalData={sponsers?.allSponsers} resetRedux={setSponsorsFilter} />}
                columns={[
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Sponsor Name'} color="text-[#000000]" className="text-xs font-normal" />
                          {sortIcon === 'asc' ? (
                            <span className="short-icon rotate-180">
                              <TableSortIcon />
                            </span>
                          ) : (
                            <span className="short-icon ">
                              <TableSortIcon />
                            </span>
                          )}
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="name"
                            setRedux={setSponsorsFilter}
                            reduxValues={sponsorFilter || []}
                            options={Array.from(new Set(sponsers?.allSponsers.map((filter) => filter.name)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-ellipsis max-w-[-webkit-fill-available] overflow-hidden whitespace-pre font-medium text-xs"
                        color="text-textColor"
                        text={row?.name}
                      />
                    ),
                    sortId: 'name',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Sponsor Abbreviation'} color="text-[#000000]" className="text-xs font-normal" />
                          {sortIcon === 'asc' ? (
                            <span className="short-icon rotate-180">
                              <TableSortIcon />
                            </span>
                          ) : (
                            <span className="short-icon ">
                              <TableSortIcon />
                            </span>
                          )}
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px] ">
                          <FilterColumn
                            columnName="abbreviation"
                            setRedux={setSponsorsFilter}
                            reduxValues={sponsorFilter || []}
                            options={Array.from(new Set(sponsers?.allSponsers.map((filter) => filter.abbreviation)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => <HemaValue text={row?.abbreviation} color="text-textColor" className="text-xs font-medium" />,
                    sortId: 'abbreviation',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue text={'Active'} color="text-[#000000]" className="text-xs font-normal" />
                          {sortIcon === 'asc' ? (
                            <span className="short-icon rotate-180">
                              <TableSortIcon />
                            </span>
                          ) : (
                            <span className="short-icon ">
                              <TableSortIcon />
                            </span>
                          )}
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="isActive"
                            type="boolean"
                            boolTrueText="Active"
                            boolFalseText="In-Active"
                            setRedux={setSponsorsFilter}
                            reduxValues={sponsorFilter || []}
                            options={Array.from(new Set(sponsers?.allSponsers.map((filter) => filter.isActive)))}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,

                    selector: (row, index) => <HemaValue text={row?.isActive ? 'Yes' : 'No'} className="text-xs font-normal" />,
                    sortId: 'isActive',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal pr-7" />
                        </div>
                      </div>
                    ),
                    cell: (row, index) => {
                      return (
                        <div className="flex-grow four">
                          <div className="flex justify-center gap-[16px] meta pr-[40px]">
                            {common?.permissions?.['Sponsor Management']?.update && (
                              <Button
                                Icon={<Edit color="#775FD5" />}
                                color="text-white"
                                bg="bg-bgActionDots"
                                cta={() => {
                                  createNewSponser[0].initialValue = row?.name;
                                  createNewSponser[1].initialValue = row?.abbreviation;
                                  createNewSponser[2].initialValue = row?.isActive;
                                  setUpdatedData(createNewSponser);
                                  setformName('Edit Sponsor');
                                  // setFormIcon(<ExpiryAlertPurple />);
                                  setokBtnText('Save');
                                  setokBtnIcon(<Confirm />);
                                  dispatch(editFormReducer(row));
                                  addSponserCTA(row?.id);
                                  dispatch(
                                    setForm({
                                      state: true,
                                      type: 'edit-sponser',
                                    }),
                                  );
                                }}
                              />
                            )}
                            {/* <Button Icon={<SearchColor />} color="text-white" bg="bg-bgActionDots" cta={() => {}} /> */}
                          </div>
                        </div>
                      );
                    },
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  },
                ]}
                customStyles={{
                  responsiveWrapper: {
                    style: { overflow: 'visible !important' },
                  },
                }}
                pagination
                onSort={(row, direction, sorted) => {
                  setSortIcon(direction);
                  setfilteredData(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
                }}
                paginationComponent={(e) => {
                  return <Pagination e={e} />;
                }}
              />
            ) : (
              <BootstrapAlert variant="warning" className="mt-3 text-center">
                There are no records to display.
              </BootstrapAlert>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </>
      </div>

      {uisettings?.openform && (
        <FormContainer cta={CTA} formType={updatedData} formName={formName} Icon={formIcon} formValidation={formValidation} okBtnIcon={okBtnIcon} okBtnText={okBtnText} />
      )}
    </>
  );
};

export default SponserManagement;
