import {
  LabelName,
  ChangeReason,
  Unit,
  Length,
  Height,
} from "../../../HemeIconLibrary";

export const AddPackageLabSettings = [
  {
    label: "Package Number",
    Icon: <LabelName />,
    type: "text",
    initialValue: "",
    placeholder: "Enter Name",
    required: true,
    name: "name",
    fluid: true,
  },
  {
    type: "heading-only",
    initialValue: "Package Dimensions",
    fluid: true,
  },
  {
    label: "Unit",
    Icon: <Unit />,
    type: "text",
    initialValue: "",
    fluid: false,
    placeholder: "Enter Unit",
    required: true,
    name: "unit",
  },
  {
    label: "Length",
    Icon: <Length />,
    type: "number",
    initialValue: "",
    fluid: false,
    placeholder: "Enter Length",
    required: true,
    name: "length",
    min: 1,
  },
  {
    label: "Width",
    Icon: <Length />,
    type: "number",
    initialValue: "",
    fluid: false,
    placeholder: "Enter Width",
    required: true,
    name: "width",
    min: 1,
  },
  {
    label: "Height",
    Icon: <Height />,
    type: "number",
    initialValue: "",
    fluid: false,
    placeholder: "Enter Height",
    required: true,
    name: "height",
    min: 1,
  },
  {
    name: "changeReason",
    label: "Change Reason",
    Icon: <ChangeReason />,
    type: "textarea",
    fluid: true,
    initialValue: "",
    required: true,
    placeholder: "Type reason",
  },
];
export const DeletePackageLabSettings = [
  {
    type: "heading-only",
    initialValue: "Package Details",
    fluid: true,
  },
  {
    label: "Package Number",
    type: "table",
    initialValue: "",
    placeholder: "Enter Name",
    name: "name",
    fluid: true,
  },
  {
    label: "Unit",
    type: "table",
    initialValue: "",
    fluid: true,
    placeholder: "Enter Unit",
    name: "unit",
  },
  {
    label: "Length",
    type: "table",
    initialValue: "",
    fluid: true,
    placeholder: "Enter Length",
    name: "length",
  },
  {
    label: "Width",
    type: "table",
    initialValue: "",
    fluid: true,
    placeholder: "Enter Width",
    name: "width",
  },
  {
    label: "Height",
    type: "table",
    initialValue: "",
    fluid: true,
    placeholder: "Enter Height",
    name: "height",
  },
  {
    name: "changeReason",
    label: "Change Reason",
    Icon: <ChangeReason />,
    type: "textarea",
    fluid: true,
    required: true,
    initialValue: "",
    placeholder: "Type reason",
  },
];
