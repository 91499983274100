import React from 'react';
export const CheckReady = ({ purple, width, height }) => {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.9983L13.3337 21.1412L24 10.8555"
        stroke={purple ? 'url(#paint0_linear_5498_184897)' : '#414753'}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5498_184897"
          x1="9.86436"
          y1="17.3281"
          x2="22.2468"
          y2="17.6352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
