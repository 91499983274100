import {
  Barcode,
  LabelQuantity,
  Location,
  SerialNumber,
  UserGroup,
  LabelName,
  LabelNumber,
  LabelDate,
  DaysCalender,
  ExpiryNotification,
} from '../../../HemeIconLibrary'

export const addNewInventory = [
  {
    label: 'Item Name',
    Icon: <LabelName />,
    type: 'select',
    initialValue: '',
    placeholder: 'Enter Name',
    required: true,
    name: 'ItemName',
    options: [],
  },
  {
    label: 'Quantity',
    Icon: <LabelQuantity />,
    type: 'number',
    initialValue: '',
    placeholder: 'Enter quantity',
    required: true,
    name: 'Quantity',
    min: 1,
  },
  {
    label: 'Lot Number ',
    Icon: <LabelNumber />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter lot number',
    required: true,
    name: 'LotNumber',
  },
  {
    label: 'Expiration Date ',
    Icon: <LabelDate />,
    type: 'date',
    initialValue: '',
    placeholder: 'mm/dd/yyyy',
    min: new Date().toISOString().split('T')[0],
    required: false,
    name: 'ExpirationDate',
  },
  {
    label: 'Serial Number',
    Icon: <SerialNumber />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter serial number',
    required: false,
    name: 'SerialNumber',
  },
  {
    label: 'Barcode',
    Icon: <Barcode />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter barcode',
    required: false,
    name: 'Barcode',
  },
  {
    label: 'Storage Location',
    Icon: <Location />,
    type: 'Storage-Locations',
    placeholder: 'Browse',
    required: true,
    name: 'StorageLocation',
    fluid: true,
  },
  {
    label: 'Close to Expiry Alert',
    Icon: <ExpiryNotification />,
    type: 'switch',
    initialValue: false,
    placeholder: '',
    name: 'CloseToExpiryAlert',
  },
  {
    label: 'Number of days',
    Icon: <DaysCalender />,
    type: 'number',
    initialValue: '',
    placeholder: 'Enter number of days',
    required: false,
    name: 'CloseToExpiryNumberOfDays',
    min: '1',
    step: '1',
  },
  {
    name: 'userIds',
    label: 'Send Alert To',
    Icon: <UserGroup />,
    type: 'multiSelect',
    initialValue: undefined,
    placeholder: 'Select user(s)',
    disabled: true,
    required: false,
    fluid: true,
    options: [],
  },
]
