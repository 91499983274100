import {
  LabelName,
  Barcode,
  Logistics,
  StudyAlias,
  ExpiryNotification,
  NameKit,
  ChangeReason,
  TickOrange,
  CheckReady,
} from '../../../HemeIconLibrary';

export const createNewStudy = [
  {
    label: 'Sponsor Name',
    Icon: <NameKit />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select sponsor name',
    required: true,
    name: 'sponsorId',
    fluid: true,
    options: [],
  },
  {
    label: 'Study Name (Protocol Name)',
    Icon: <LabelName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter study name',
    required: true,
    name: 'name',
  },
  {
    label: 'Study Code (Protocol Code)',
    Icon: <ChangeReason />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter study code',
    required: true,
    name: 'studycode',
  },
  {
    label: 'Study Name Alias (Study Name)',
    Icon: <StudyAlias purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter study name alias',
    required: false,
    name: 'nameAlias',
  },
  {
    label: 'Study Code Alias (Study Code)',
    Icon: <Barcode />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter study code alias',
    required: false,
    name: 'studyCodeAlias',
  },
  {
    name: 'isActive',
    label: 'Active',
    Icon: <CheckReady purple width="18" height="18" />,
    type: 'switch',
    initialValue: true,
    placeholder: '',
  },
  {
    name: 'logisticOrchestratorStatus',
    label: 'Logistics Orchestrator',
    Icon: <Logistics />,
    type: 'switch',
    initialValue: false,
    placeholder: '',
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    placeholder: 'Type Reason',
    Icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
  },
];
