import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert as BootstrapAlert } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import { filter } from "smart-array-filter";
import { Button, FormSearch, HemaValue, Pagination, FilterColumn, NoDataComponent } from "../../../utils";
import { ExpiryAlertPurple, DeletePurple, Edit, TableSortIcon } from "../../../HemeIconLibrary";
import deleteIcon from "../../../assets/images/deleteIcon.svg";

import { sortedData } from "../../../helpers/sort";
import { SystemUserValidation } from "../../../components/Formik/allFormValidation";
import { addPortalUserFields } from "../../../components/Formik/AllForms/addPortalUserFields";
import { Remove } from "../../../components/Formik/AllForms/remove";
import { setportalUsersFilter } from "../../../Store/reducers/settings";
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from "../../../Store/reducers/uiSettings";

// Actions
import { getPortalUserAction, getPortalUserByIdAction, updatePortalUserAction, deletePortalUserAction } from "../../../Actions/settings";

// Utils
import { getFullName } from "../Utils";

const SystemUserList = ({ sites, setCTA, setformName, setFormIcon, setUpdatedData, setokBtnIcon, setokBtnText, setFormValidation }) => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);
  const { isLoading, portalUsers, portalUsersFilter } = settings;

  useEffect(() => {
    getPortalUserAction();
  }, []);

  const [searchQuery, setsearchQuery] = useState("");
  const [dataList, setDataList] = useState(null);

  //senpnarch for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(portalUsers, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(portalUsers);
      }
    })();
  }, [searchQuery, portalUsers]);

  const editSystemUser = (row) => {
    setCTA(() => async (payload) => {
      const filterpayload = {
        ...payload,
        siteIds: payload.siteIds
          ?.filter((site) => site.checked === true)
          ?.map((site) => {
            return site.id;
          }),
      };
      dispatch(setFormLoaderReducer(true));
      const resp = await updatePortalUserAction(row.id, filterpayload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`User updated.`));
        getPortalUserAction();
      }
    });
    setFormValidation((error, values) => (error, values) => {
      SystemUserValidation(error, values);
    });
  };

  const deleteSystemUser = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deletePortalUserAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`User deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  useEffect(() => {
    if (Object.keys(portalUsersFilter)?.length) {
      const filterResult = portalUsers?.filter((port) => {
        if (
          (portalUsersFilter.email?.length ? portalUsersFilter.email.includes(port.email) : true) &&
          (portalUsersFilter.status?.length ? portalUsersFilter.status?.includes(port.status) : true) &&
          (portalUsersFilter.firstName?.length ? portalUsersFilter.firstName?.includes(getFullName(port)) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setDataList(filterResult);
    } else {
      setDataList(portalUsers);
    }
  }, [JSON.stringify(portalUsersFilter)]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>

        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 || Object.keys(portalUsersFilter)?.length ? (
          <DataTable
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={portalUsers} resetRedux={setportalUsersFilter} />}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Name"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="firstName"
                          secondColumnName="lastName"
                          setRedux={setportalUsersFilter}
                          reduxValues={portalUsersFilter || []}
                          options={Array.from(new Set(portalUsers.map((filter) => getFullName(filter))))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={getFullName(row)} color="text-textColor" className="text-xs font-medium" />,
                sortId: "firstName",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Email"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="email"
                          setRedux={setportalUsersFilter}
                          reduxValues={portalUsersFilter || []}
                          options={Array.from(new Set(portalUsers.map((filter) => filter.email)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.email} color="text-textColor" className="text-xs font-medium" />,
                sortId: "email",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={"Status"} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="status"
                          options={Array.from(new Set(portalUsers.map((filter) => filter.status)))}
                          setRedux={setportalUsersFilter}
                          reduxValues={portalUsersFilter || []}
                          // type="boolean"
                          // boolTrueText="Active"
                          // boolFalseText="In-Active"
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => (
                  <div
                    className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${
                      (row?.status === "Pending Invitation" && "bg-[#F6EEE6]") ||
                      (row?.status === "Active" && "bg-[#E6F6EB]") ||
                      (row?.status === "Inactive" && "bg-[#EFEFEF]") ||
                      (row?.status === "Locked" && "bg-[#EFEFEF]")
                    }`}
                  >
                    <div
                      className={`w-2 h-2 rounded-full ${
                        (row?.status === "Pending Invitation" && "bg-[#F97316]") ||
                        (row?.status === "Active" && "bg-[#138846]") ||
                        (row?.status === "Inactive" && "bg-[#454545]") ||
                        (row?.status === "Locked" && "bg-[#454545]")
                      }`}
                    />

                    <HemaValue
                      className="text-xs"
                      color={
                        (row?.status === "Pending Invitation" && "text-[#F97316]") ||
                        (row?.status === "Active" && "text-[#138846]") ||
                        (row?.status === "Inactive" && "text-[#454545]") ||
                        (row?.status === "Locked" && "text-[#454545]")
                      }
                      text={row?.status}
                    />
                  </div>
                ),

                sortId: "status",
              },
              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {" "}
                      <HemaValue text={"Actions"} color="text-[#000000]" className="text-xs font-normal pr-7" />{" "}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[16px] meta">
                        {common?.permissions?.["Portal User Management"]?.update && (
                          <Button
                            Icon={<Edit color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row));
                              setformName("Edit user");
                              setokBtnIcon();
                              setokBtnText("Update");
                              setFormIcon(<ExpiryAlertPurple />);
                              const resp = await getPortalUserByIdAction(row.id);
                              const selectedSites = resp.data;

                              addPortalUserFields[0].initialValue = row.firstName;
                              addPortalUserFields[1].initialValue = row.lastName;
                              addPortalUserFields[2].initialValue = row.email;
                              addPortalUserFields[2].disabled = true;
                              addPortalUserFields[3].initialValue = row.isActive;
                              addPortalUserFields[4].initialValue = row.isLocked;
                              const selectedEdit =
                                settings?.sites?.map((obj) => {
                                  const found = selectedSites?.sites?.find((objSite) => objSite.site.id === obj.id);
                                  return {
                                    ...obj,
                                    checked: found ? true : false,
                                  };
                                }) || [];
                              addPortalUserFields[5].initialValue = selectedEdit;

                              setUpdatedData(addPortalUserFields);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "create-document-language",
                                }),
                              );
                              editSystemUser(row);
                            }}
                          />
                        )}
                        {/* <Button
                            Icon={<SearchColor color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                          /> */}
                        {common?.permissions?.["Portal User Management"]?.delete && (
                          <Button
                            Icon={<img src={deleteIcon} />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              Remove[0].label = "User Name";
                              Remove[0].initialValue = row?.firstName + " " + row?.lastName;
                              setUpdatedData(Remove);
                              setokBtnIcon();
                              setokBtnText("Confirm");
                              setformName("Delete user");
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: "deleteItem",
                                }),
                              );
                              deleteSystemUser(row);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: "visible !important" },
                },
              },

              cells: {
                style: { overflow: "visible !important" },
              },

              responsiveWrapper: {
                style: { overflow: "visible !important" },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <BootstrapAlert variant="warning" className="mt-3 text-center">
            There is no data found.
          </BootstrapAlert>
        )}
      </>
    </div>
  );
};

export default SystemUserList;
