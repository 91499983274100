import React from 'react';

export const ItemCatagories = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0703 6.5C10.0703 8.433 8.50331 10 6.57031 10C4.63732 10 3.07031 8.433 3.07031 6.5C3.07031 4.567 4.63732 3 6.57031 3C8.50331 3 10.0703 4.567 10.0703 6.5Z"
        stroke="url(#paint0_linear_4431_209615)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0703 6.5C21.0703 8.433 19.5033 10 17.5703 10C15.6373 10 14.0703 8.433 14.0703 6.5C14.0703 4.567 15.6373 3 17.5703 3C19.5033 3 21.0703 4.567 21.0703 6.5Z"
        stroke="url(#paint1_linear_4431_209615)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0703 17.5C10.0703 19.433 8.50331 21 6.57031 21C4.63732 21 3.07031 19.433 3.07031 17.5C3.07031 15.567 4.63732 14 6.57031 14C8.50331 14 10.0703 15.567 10.0703 17.5Z"
        stroke="url(#paint2_linear_4431_209615)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0703 17.5C21.0703 19.433 19.5033 21 17.5703 21C15.6373 21 14.0703 19.433 14.0703 17.5C14.0703 15.567 15.6373 14 17.5703 14C19.5033 14 21.0703 15.567 21.0703 17.5Z"
        stroke="url(#paint3_linear_4431_209615)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4431_209615"
          x1="3.07031"
          y1="12"
          x2="21.0703"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4431_209615"
          x1="3.07031"
          y1="12"
          x2="21.0703"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4431_209615"
          x1="3.07031"
          y1="12"
          x2="21.0703"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4431_209615"
          x1="3.07031"
          y1="12"
          x2="21.0703"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
