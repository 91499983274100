import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { setDropdownValue } from "../../Store/reducers/common";
import Dropdown from "react-bootstrap/Dropdown";
import { getIcon } from "../../helpers/commonHelper";
import { SelectDownArrow } from "../../HemeIconLibrary";
import "./style.scss";
import { useDispatch } from "react-redux";
import { addNewItemsReducer } from "../../Store/reducers/items";
import { useSelector } from "react-redux";

const FormSelect = forwardRef((props, ref) => {
  const { options, placeholder, setFieldValue, setFieldValueFull, name, onChange, value, disabled, className, dispatch, notRefresh, selectType } = props;
  const [selectLabel, setSelectLabel] = useState(value !== "" ? value : placeholder);
  const [searchLabel, setSearchLabel] = useState();
  const [listOptions, setOptionsList] = useState(options);
  const inputRef = useRef();
  const newInputId = useRef();
  const selectValue = useRef();
  const reduxDispatch = useDispatch();
  const { uisettings } = useSelector((state) => state);

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  useImperativeHandle(ref, () => ({
    clearSelect() {
      setSelectLabel(placeholder);
      setSearchLabel("");
      inputRef.current.value = "";
    },
  }));
  const [Icon, setIcon] = useState();
  useEffect(() => {
    let label = placeholder;
    if (value !== "") {
      const selected = options?.find((ele) => ele.id === parseInt(value));
      if (selected) {
        label = selected.name;
      }
    }
    if (!notRefresh) {
      setSelectLabel(label);
    }
    const checkImage = getIcon(value);
    if (checkImage) {
      setIcon(getIcon(value));
    } else if (value?.length === 2) {
      setIcon(<img width={20} height={20} alt="United States" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`} />);
    }
  }, [placeholder, value]);

  function viewWithIcon(val) {
    return (
      <>
        <span className="inline-block mr-2 align-middle">{val.icon}</span>
        <span className="">{val.name}</span>
      </>
    );
  }

  function onChangeHandler(event) {
    if (event?.target) {
      if (event.target.value) {
        setFieldValue(name, event?.target?.value);
      }
      if (onChange) {
        event.target.value = event.target.id;
        event.target.selectedObject = selectValue.current;
        onChange(event);
      }
    }
  }

  useEffect(() => {
    if (selectType === "itemQuantity") {
      if (searchLabel && listOptions.length > 0) {
        var newOptions = options.filter((item) => item.name.toLocaleLowerCase()?.includes(searchLabel.trim().toLocaleLowerCase()));
        if (newOptions.length === 0 && uisettings.formName === "create-assembly") {
          newInputId.current = String(Date.now());
          newOptions.push({
            id: newInputId.current,
            name: searchLabel,
            val: "+ Create Custom Line Item",
          });
        }
        setOptionsList(newOptions);
      } else {
        setOptionsList([...options]);
      }
    }
  }, [searchLabel]);

  return (
    <Dropdown
      className={`hema-form-dropdown-custom  `}
      disabled={disabled}
      onSelect={(ek, eo) => {
        onChangeHandler(eo);
      }}
      {...props}
    >
      <Dropdown.Toggle disabled={disabled} className={`hema-form-dropdown-custom ${className}`}>
        {selectType === "itemQuantity" ? (
          <input
            ref={inputRef}
            type="text"
            name="Search"
            value={searchLabel}
            placeholder="Item Name"
            onChange={(e) => setSearchLabel(e.target.value)}
            className="w-full text-black outline-none"
            onKeyUp={(e) => e.preventDefault()}
          />
        ) : (
          <div className={`flex gap-[10px] ${selectLabel === placeholder ? " placeholderColor" : " nonePlaceholderColor"}`}>
            {!!Icon && Icon}
            {selectLabel}
          </div>
        )}
        {props.crossIcon && !disabled && (
          <span onClick={() => props?.setFieldValue(props.name, "")} className={`absolute top-[13px] right-[30px] cursor-pointer`}>
            {props.crossIcon}
          </span>
        )}

        {!disabled && <span className={`absolute top-[15px] right-[10px] cursor-pointer`}>{props.dropIcon ? props.dropIcon : <SelectDownArrow />}</span>}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {listOptions?.map((val) => {
          return (
            <Dropdown.Item
              key={val.id}
              className="bg-slate-950 text-xs"
              id={val.id}
              name={name}
              active={selectLabel === val.name}
              disabled={val.id === "disabled" ? true : false}
              onClick={() => {
                let obj = {
                  id: val.id,
                  name: val.name,
                  cutomItem: val.val ? true : false,
                };
                selectValue.current = obj;
                if (val.icon) {
                  setIcon(val.icon);
                }
                if (val.iconName) {
                  setIcon(val.iconName);
                }
                if (setFieldValue) {
                  setFieldValue(name, val.id);
                }
                if (setFieldValueFull) {
                  setFieldValueFull(name, val);
                }
                if (dispatch) {
                  if (uisettings.formName === "create-assembly") {
                    dispatch(setDropdownValue({ ...val }));
                  } else {
                    dispatch(setDropdownValue({ ...val, name }));
                  }
                }
                if (val.id === newInputId.current && uisettings.formName === "create-assembly") {
                  delete val.val;
                  //reduxDispatch(addNewItemsReducer(val))
                }
                setSelectLabel(val.name || val.lotNumber);
                setSearchLabel(val.name || val.lotNumber);
              }}
            >
              {" "}
              {val.icon ? viewWithIcon(val) : uisettings.formName === "create-assembly" && val.id === newInputId.current ? val.val : val.name || val.lotNumber}{" "}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default FormSelect;
