import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { setDropdownValue } from "../../Store/reducers/common";
import Dropdown from "react-bootstrap/Dropdown";
import { getIcon } from "../../helpers/commonHelper";
import { SelectDownArrow } from "../../HemeIconLibrary";
import "./style.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const FormSearchableSelect = forwardRef((props, ref) => {
  const { options, placeholder, setFieldValue, setFieldValueFull, name, onChange, value, disabled, className, dispatch, notRefresh, customItem, clickNewItem, formikRef } = props;
  const [selectLabel, setSelectLabel] = useState(() => (value !== "" ? value : placeholder));
  const [searchLabel, setSearchLabel] = useState(() => (value !== "" ? value : ""));
  const [listOptions, setOptionsList] = useState(options);
  const [init, setInit] = useState(true);
  const inputRef = useRef();
  const newInputId = useRef();
  const selectValue = useRef();
  const reduxDispatch = useDispatch();
  const { uisettings } = useSelector((state) => state);
  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  useImperativeHandle(ref, () => ({
    clearSelect() {
      setSelectLabel(placeholder);
      setSearchLabel("");
      inputRef.current.value = "";
    },
  }));
  const [Icon, setIcon] = useState();
  useEffect(() => {
    let label = placeholder;
    if (value !== "") {
      const selected = options?.find((ele) => ele.id === value);
      if (selected) {
        label = selected.name;
        if (selected?.icon) {
          setIcon(selected.icon);
        }
      }
    }
    if (!notRefresh) {
      setSelectLabel(label);
      setSearchLabel(label);
    }
  }, [placeholder, value]);

  function viewWithIcon(val) {
    return (
      <>
        <span className="inline-block mr-2 align-middle">{val.icon}</span>
        <span className="">{val.name}</span>
      </>
    );
  }

  function onChangeHandler(event) {
    event.preventDefault();
    if (event?.target) {
      if (event.target.value) {
        setFieldValue(name, event?.target?.value);
      }
      if (onChange) {
        event.target.value = event.target.id;
        event.target.selectedObject = selectValue.current;
        onChange(event);
      }
    }
  }

  useEffect(() => {
    if (searchLabel && listOptions.length > 0 && !init) {
      var newOptions = options.filter((item) => item.name?.toLowerCase().includes(searchLabel.toLowerCase()));
      if (newOptions.length === 0 && uisettings.formName === "create-assembly" && customItem) {
        newInputId.current = String(Date.now());
        newOptions.push({
          id: newInputId.current,
          name: searchLabel,
          val: "+ Create Custom Line Item",
        });
      } else if (newOptions.length === 0 && uisettings?.formName === "addInventory1stStep" && customItem) {
        newOptions.push({
          id: newInputId.current,
          name: searchLabel,
          val: "+ Create New Item",
        });
      }
      setOptionsList(newOptions);
    } else {
      setOptionsList([...options]);
    }
    setInit(false);
  }, [searchLabel]);
  return (
    <Dropdown
      className={`hema-form-dropdown-custom  `}
      disabled={disabled}
      onSelect={(ek, eo) => {
        eo.preventDefault();
        onChangeHandler(eo);
      }}
      {...props}
    >
      <Dropdown.Toggle disabled={disabled} className={`hema-form-dropdown-custom ${className}`}>
        {!!Icon ? (
          <div className={`flex gap-[10px] ${selectLabel === placeholder ? " placeholderColor" : " nonePlaceholderColor"}`}>
            {!!Icon && Icon}
            {selectLabel}
          </div>
        ) : (
          <input
            ref={inputRef}
            type="text"
            name="Search"
            value={searchLabel}
            placeholder={
              uisettings?.formName === "add-company-location"
                ? "Select Flag"
                : uisettings?.formName === "Add Depot"
                ? "Select country"
                : uisettings?.formName === "addInventory1stStep"
                ? "Select Item Name"
                : "Item Name"
            }
            onChange={(e) => {
              e.preventDefault();
              setSearchLabel(e.target.value);
              if (!e.target.value) {
                formikRef.current?.setFieldTouched(name, true);
              } else {
                formikRef.current?.setFieldTouched(name, false);
              }
            }}
            className="w-full text-black outline-none"
          />
        )}
        {props.crossIcon && selectLabel && (
          <span
            onClick={() => {
              formikRef.current?.setFieldTouched(name, true);
              setSelectLabel(placeholder);
              setSearchLabel("");
              setIcon("");
              if (setFieldValue) {
                setFieldValue(name, "");
              } else if (setFieldValueFull) {
                setFieldValueFull(name, "");
              }
              if (inputRef.current?.value) {
                inputRef.current.value = "";
              }
            }}
            className={`absolute top-[13px] right-[30px] cursor-pointer`}
          >
            {props.crossIcon}
          </span>
        )}

        <span className={`absolute top-[15px] right-[10px] cursor-pointer`}>{props.dropIcon ? props.dropIcon : <SelectDownArrow />}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {listOptions?.map((val) => {
          return (
            <Dropdown.Item
              key={val.id}
              className="bg-slate-950 text-xs"
              id={val.id}
              name={name}
              active={selectLabel === val.name}
              onClick={() => {
                let obj = {
                  id: val.id,
                  name: val.name,
                  cutomItem: val.val ? true : false,
                };
                selectValue.current = obj;
                if (val.icon) {
                  setIcon(val.icon);
                }
                if (val.iconName) {
                  setIcon(val.iconName);
                }
                if (setFieldValue) {
                  setFieldValue(name, val.id);
                }
                if (setFieldValueFull) {
                  setFieldValueFull(name, val);
                }
                if (val.id === newInputId.current) {
                  if (clickNewItem) {
                    clickNewItem();
                  }
                }
                // if (dispatch) {
                //   if (uisettings.formName === 'create-assembly') {
                //     dispatch(setDropdownValue({ ...val }));
                //   } else {
                //     dispatch(setDropdownValue({ ...val, name }));
                //   }
                // }
                if (val.id === newInputId.current && uisettings.formName === "create-assembly" && customItem) {
                  delete val.val;
                  //reduxDispatch(addNewItemsReducer(val))
                }
                setSelectLabel(val.name || val.lotNumber);
                setSearchLabel(val.name || val.lotNumber);
              }}
            >
              {" "}
              {val.icon
                ? viewWithIcon(val)
                : (uisettings.formName === "create-assembly" || uisettings?.formName === "addInventory1stStep") && val.id === newInputId.current
                ? val.val
                : val.name || val.lotNumber}{" "}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default FormSearchableSelect;
