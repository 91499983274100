import React from 'react';

export const GreenLightTab = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5891 20.1221H14.4006M25.0849 15.6611H23.1722M8.82679 15.6611H6.91406M22.4251 9.30469L21.069 10.6436M10.9262 20.6788L9.57394 22.0177M22.425 22.0177L21.0727 20.6788M10.9301 10.6436L9.57394 9.30469M17.5936 21.8197C17.6148 22.2799 17.26 22.6706 16.7999 22.6938H15.1999C14.7398 22.6706 14.385 22.2799 14.4061 21.8197V20.1222C12.1503 19.2944 10.8703 16.91 11.4269 14.5724C11.9835 12.2349 14.2007 10.6832 16.5875 10.9609C18.9743 11.2386 20.7761 13.2578 20.7812 15.6607C20.7749 17.6742 19.4964 19.4636 17.5936 20.1222V21.8197Z"
        stroke={purple ? 'url(#paint0_linear_5498_186617)' : '#414753'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5498_186617"
          x1="9.03137"
          y1="17.7303"
          x2="23.096"
          y2="18.0346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
