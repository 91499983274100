import React from 'react';

export const BuildingTab = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80078 11.6808L16.0008 14.5608L19.6008 13.1208M8.80078 11.6808L12.4008 10.2408M8.80078 11.6808V20.3211L16.0008 23.2011L23.2008 20.3211V11.6808M12.4008 10.2408L16.0008 8.80078L23.2008 11.6808M12.4008 10.2408L19.6008 13.1208M23.2008 11.6808L19.6008 13.1208M19.6008 13.1208V15.4867M16.0022 23.2006V14.5606"
        stroke={purple ? 'url(#paint0_linear_4065_132230)' : '#414753'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4065_132230"
          x1="16.002"
          y1="23.106"
          x2="16.002"
          y2="8.89515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
