import { LabelName, PhoneNumber, StudyName } from '../../../HemeIconLibrary';

export const PackagingSlip = [
  {
    label: '',
    type: 'heading-only',
    initialValue: 'General Information',
  },
  {
    label: 'Project Name',
    Icon: <PhoneNumber />,
    type: 'text',
    initialValue: '',
    placeholder: 'Project Name',
    fluid: true,
    required: true,
    name: 'projName',
  },
  {
    label: 'Study Name (Protocol Name)',
    Icon: <StudyName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Study Name (Protocol Name)',
    fluid: true,
    name: 'reference',
  },
  {
    label: 'Items’ Description',
    Icon: <PhoneNumber />,
    type: 'text',
    initialValue: '',
    fluid: true,
    required: true,
    placeholder: 'Items’ Description',
    name: 'itemdesc',
  },
  {
    label: '',
    Icon: '',
    fluid: true,
    type: 'packaging-content',
    initialValue: '',
    name: 'packaging-content',
  },
];
