import React from 'react';

export const Protocol = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.605469C4.48 0.605469 0 5.08547 0 10.6055C0 16.1255 4.48 20.6055 10 20.6055C15.52 20.6055 20 16.1255 20 10.6055C20 5.08547 15.52 0.605469 10 0.605469ZM2 10.6055C2 9.99547 2.08 9.39547 2.21 8.82547L6.99 13.6055V14.6055C6.99 15.7055 7.89 16.6055 8.99 16.6055V18.5355C5.06 18.0355 2 14.6755 2 10.6055ZM15.89 16.0055C15.63 15.1955 14.89 14.6055 13.99 14.6055H12.99V11.6055C12.99 11.0555 12.54 10.6055 11.99 10.6055H5.99V8.60547H7.99C8.54 8.60547 8.99 8.15547 8.99 7.60547V5.60547H10.99C12.09 5.60547 12.99 4.70547 12.99 3.60547V3.19547C15.92 4.37547 18 7.25547 18 10.6055C18 12.6855 17.19 14.5855 15.89 16.0055Z"
        fill="url(#paint0_linear_2380_35965)"
      />
      <defs>
        <linearGradient id="paint0_linear_2380_35965" x1="10.0016" y1="20.4734" x2="10.0016" y2="0.736535" gradientUnits="userSpaceOnUse">
          <stop stop-color="#E7006A" />
          <stop offset="1" stop-color="#EAC4BE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
