import React from 'react';

export const CopyColor = ({ color }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 6.4513C15.4913 6.37475 15.4746 6.29933 15.45 6.2263V6.1513C15.4099 6.06562 15.3565 5.98686 15.2917 5.91797L10.2917 0.917969C10.2228 0.853149 10.144 0.799703 10.0583 0.759635C10.0335 0.756102 10.0082 0.756102 9.98333 0.759635C9.89868 0.711086 9.80519 0.679922 9.70833 0.667969H6.33333C5.67029 0.667969 5.03441 0.931361 4.56557 1.4002C4.09673 1.86904 3.83333 2.50493 3.83333 3.16797V4.0013H3C2.33696 4.0013 1.70107 4.26469 1.23223 4.73354C0.763392 5.20238 0.5 5.83826 0.5 6.5013V14.8346C0.5 15.4977 0.763392 16.1336 1.23223 16.6024C1.70107 17.0712 2.33696 17.3346 3 17.3346H9.66667C10.3297 17.3346 10.9656 17.0712 11.4344 16.6024C11.9033 16.1336 12.1667 15.4977 12.1667 14.8346V14.0013H13C13.663 14.0013 14.2989 13.7379 14.7678 13.2691C15.2366 12.8002 15.5 12.1643 15.5 11.5013V6.5013C15.5 6.5013 15.5 6.5013 15.5 6.4513ZM10.5 3.50964L12.6583 5.66797H11.3333C11.1123 5.66797 10.9004 5.58017 10.7441 5.42389C10.5878 5.26761 10.5 5.05565 10.5 4.83464V3.50964ZM10.5 14.8346C10.5 15.0556 10.4122 15.2676 10.2559 15.4239C10.0996 15.5802 9.88768 15.668 9.66667 15.668H3C2.77899 15.668 2.56702 15.5802 2.41074 15.4239C2.25446 15.2676 2.16667 15.0556 2.16667 14.8346V6.5013C2.16667 6.28029 2.25446 6.06833 2.41074 5.91205C2.56702 5.75577 2.77899 5.66797 3 5.66797H3.83333V11.5013C3.83333 12.1643 4.09673 12.8002 4.56557 13.2691C5.03441 13.7379 5.67029 14.0013 6.33333 14.0013H10.5V14.8346ZM13.8333 11.5013C13.8333 11.7223 13.7455 11.9343 13.5893 12.0906C13.433 12.2468 13.221 12.3346 13 12.3346H6.33333C6.11232 12.3346 5.90036 12.2468 5.74408 12.0906C5.5878 11.9343 5.5 11.7223 5.5 11.5013V3.16797C5.5 2.94696 5.5878 2.73499 5.74408 2.57871C5.90036 2.42243 6.11232 2.33464 6.33333 2.33464H8.83333V4.83464C8.83333 5.49768 9.09672 6.13356 9.56557 6.6024C10.0344 7.07124 10.6703 7.33464 11.3333 7.33464H13.8333V11.5013Z"
        fill={color || 'url(#paint0_linear_3263_89412)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_89412"
          x1="0.5"
          y1="9.0013"
          x2="15.5"
          y2="9.0013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EE318A" />
          <stop offset="1" stop-color="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};
