import React, { useEffect, useState } from 'react'
import Switch from 'react-switch'
import { useDispatch } from 'react-redux'

import { setThresholdReducer } from '../../Store/reducers/common'

const FormSwitch = (props) => {
  const { setFieldValue, name, initialValue, disabled, formikRef } = props
  const [checked, setChecked] = useState(initialValue)
  useEffect(() => {
    setChecked(initialValue)
  }, [initialValue])
  const dispatch = useDispatch()
  return (
    <div className="custom-switch">
      <Switch
        onColor="#3D88E0"
        className={
          checked ? 'react-switch true-switch' : 'react-switch false-switch'
        }
        disabled={disabled}
        onChange={(e) => {
          if (formikRef) {
            if (!e) {
              // Item
              formikRef.current.setFieldValue('userIds', [])
              formikRef.current.setFieldValue('lowInventoryThreshold', '')
              formikRef.current.setFieldTouched('userIds', false)
              formikRef.current.setFieldTouched('lowInventoryThreshold', false)

              // Inventory
              formikRef.current.setFieldValue('CloseToExpiryNumberOfDays', '')
              formikRef.current.setFieldTouched(
                'CloseToExpiryNumberOfDays',
                false,
              )
              formikRef.current.setFieldTouched('ExpirationDate', false)
              setTimeout(() => {
                formikRef.current?.validateForm()
              }, 600)
            }
          }
          setFieldValue(name, e)
          setChecked(e)
          dispatch(setThresholdReducer(e))
        }}
        checked={checked}
      />
    </div>
  )
}
export default FormSwitch
