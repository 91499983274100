import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { compareArrays } from "../../../helpers/commonHelper";

import { HemaLabel, FormSelect, Button, Alert, FormTextarea, SingleSelect, FormMultiSelect } from "../../../utils";
import { Cancel, Confirm, Country, StudyAlias, ChangeReason, CreateSite, SiteName, CrossIcon } from "../../../HemeIconLibrary";
import Heading from "../../../pages/logistOrchestra/logisticComponents/heading";
import ShippingRules from "../../../pages/logistOrchestra/logisticComponents/shippingRules";
import { createSideLogisticAction, updateSiteLogisticAction, protocolDetailIdAction } from "../../../Actions/logistic";
import { setForm, showSuccessReducer } from "../../../Store/reducers/uiSettings";
import { getAllUnconfiguredSiteAction } from "../../../Actions/siteManagment";
import { setSelectedSite } from "../../../Store/reducers/logistic";
const AddSite = () => {
  const { common, assembly, logistic, sites, uisettings } = useSelector((state) => state);
  const dispatch = useDispatch();

  const formikRef = useRef();

  useEffect(() => {
    (async () => {
      console.log(await formikRef.current?.validateForm());
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm]);

  return (
    <Formik
      initialValues={{
        sitesIds: uisettings.editForm?.siteId || "",
        initialRequestAssemblyId: uisettings?.editForm
          ? uisettings.editForm?.initialRequestAssembly?.id || "N/A"
          : logistic.selectedCountryDetail?.initialRequestAssembly?.id || "N/A",
        shippingRules: uisettings.editForm
          ? common.allShipping?.map((data) => {
              return {
                //  checked: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.length || false,
                shippingConditionId: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.shippingCondition || data,
                inBoundCourierId: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.inBoundCourier,
                outBoundCourierId: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.outBoundCourier,
              };
            })
          : common.allShipping?.map((data) => {
              return {
                //   checked: logistic.selectedRegionDetail?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.length || false,
                shippingConditionId: logistic.selectedCountryDetail?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.shippingCondition || data,
                inBoundCourierId: logistic.selectedCountryDetail?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.inBoundCourier,
                outBoundCourierId: logistic.selectedCountryDetail?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.outBoundCourier,
              };
            }),
        // changeReason: '',
      }}
      innerRef={formikRef}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        // if (!values.initialRequestAssemblyId) {
        //   errors.initialRequestAssemblyId = 'Required';
        // }
        if (values.sitesIds.length === 0 && !uisettings.editForm?.siteId) {
          errors.sitesIds = "Required";
        }
        if (!values.changeReason?.trim() && logistic.selectedProtocolDetail?.qc?.status?.id === 3) {
          errors.changeReason = "Required";
        }

        return errors;
      }}
      onSubmit={async (values, setFieldValue) => {
        if (uisettings.editForm?.siteId) {
          const result = await updateSiteLogisticAction(uisettings.editForm?.id, {
            ...values,
            initialRequestAssemblyId: values?.initialRequestAssemblyId === "N/A" ? undefined : values?.initialRequestAssemblyId,
            shippingRules: values.shippingRules?.map((ship) => {
              return {
                shippingConditionId: ship.shippingConditionId?.id || ship?.shippingConditionId,
                outBoundCourierId: ship?.outBoundCourierId?.id || ship?.outBoundCourierId,
                inBoundCourierId: ship?.inBoundCourierId?.id || ship?.inBoundCourierId,
              };
            }),
          });
          if (result.status === 200) {
            dispatch(setForm({ state: false, type: "" }));
            dispatch(showSuccessReducer(`${result?.data?.siteName} information updated.`));
          }
        } else {
          const result = await createSideLogisticAction(logistic.selectedCountry?.id, values.sitesIds, {
            ...values,
            sitesIds: values?.sitesIds?.map((site) => site.id),
            initialRequestAssemblyId: values?.initialRequestAssemblyId === "N/A" ? undefined : values?.initialRequestAssemblyId,
            shippingRules: values.shippingRules?.map((ship) => {
              return {
                shippingConditionId: ship.shippingConditionId?.id || ship?.shippingConditionId,
                outBoundCourierId: ship?.outBoundCourierId?.id || ship?.outBoundCourierId,
                inBoundCourierId: ship?.inBoundCourierId?.id || ship?.inBoundCourierId,
              };
            }),
          });
          if (result.status === 200) {
            dispatch(setForm({ state: false, type: "" }));
            dispatch(showSuccessReducer(`${result?.data?.length > 1 ? "Sites" : result?.data?.[0]?.siteCode} information added.`));
            if (!uisettings?.editForm) {
              dispatch(
                setSelectedSite({
                  id: result?.data?.[0]?.id,
                  name: result?.data?.[0]?.siteName,
                }),
              );
            }
          }
          await protocolDetailIdAction(logistic?.selectedProtocol?.id);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        initialValues,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="max-w-[600px]  gap-[10px] overflow-auto m-auto min-h-[calc(100%-50px)]  justify-between  flex flex-col ">
          <div className="pr-4">
            {!uisettings.editForm?.siteId && (
              <>
                <Heading heading="Site Details" mb />

                <div className=" items-center gap-[16px] mb-3">
                  <div className="w-full">
                    <HemaLabel text="Site Number" className="mb-[10px]" Icon={<SiteName />} required />
                    <FormMultiSelect
                      formikRef={formikRef}
                      options={
                        logistic?.unConfigSite?.map((opt) => {
                          return { ...opt, name: opt.siteCode + " - " + opt.name };
                        }) || []
                      }
                      placeholder="Multi Select"
                      setFieldValue={setFieldValue}
                      name="sitesIds"
                      value={values?.sitesIds}
                    />
                  </div>
                  {errors.sitesIds && touched.sitesIds && <div className="error text-[red] text-[12px] pt-[2px]">{errors.sitesIds}</div>}
                </div>
              </>
            )}

            <Heading heading="Shipping Rules" mb />
            <div className="mb-[25px]">
              <ShippingRules shipping={values.shippingRules} curiors={common.allCuriors} values={values} dispatch={dispatch} setFieldValue={setFieldValue} name={"shippingRules"} />

              {errors.shippingRules && touched.shippingRules && <div className="error text-[red] text-[12px] pt-[2px]">{errors.shippingRules}</div>}
            </div>

            <Heading heading="Initial Request" mb />
            <div className={"w-full h-full"}>
              <div className="">
                <HemaLabel text={"Select Kit Assembly"} Icon={<StudyAlias color="blue" />} className="mb-[10px]" />

                <div className=" gap-[15px] items-center w-full block">
                  <SingleSelect
                    options={[{ id: "N/A", name: "N/A" }, ...assembly?.allAssemblies?.filter((data) => data.assemblyType.name === "Initial Request Assembly")] || []}
                    placeholder="Single Select General Assembly"
                    setFieldValue={setFieldValue}
                    name={"initialRequestAssemblyId"}
                    value={values?.initialRequestAssemblyId}
                    crossIcon={values?.initialRequestAssemblyId ? <CrossIcon /> : ""}
                  />
                </div>
                {errors.initialRequestAssemblyId && touched.initialRequestAssemblyId && (
                  <div className="error text-[red] text-[12px] pt-[2px]">{errors.initialRequestAssemblyId}</div>
                )}
              </div>
              {logistic.selectedProtocolDetail?.qc?.status?.id === 3 && (
                <div className=" gap-[15px] items-center w-full block mt-[20px]">
                  <HemaLabel text={"Change Reason"} Icon={<ChangeReason />} required={true} className={`mb-[10px]`} />

                  <FormTextarea placeholder="write edit reason" onChange={handleChange} onBlur={handleBlur} name={"changeReason"} value={values?.changeReason} />

                  {errors.changeReason && touched.changeReason && <div className="error text-[red] text-[12px] pt-[2px]">{errors.changeReason}</div>}
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-[8px] justify-end pt-10 pb-[50px] pr-[14px]">
            <Button
              cta={() => {
                dispatch(setForm({ state: false, type: "" }));
              }}
              type="button"
              text="Cancel"
              bg="bg-white"
              border="border-primary1"
              color="text-primary1"
              Icon={<Cancel />}
            />

            {isSubmitting ? (
              <Button
                type="submit"
                bg="bg-primary1"
                text={
                  <>
                    <Spinner animation="grow" size="sm" variant="light" />
                    <Spinner animation="grow" size="sm" variant="light" />
                    <Spinner animation="grow" size="sm" variant="light" />
                  </>
                }
              />
            ) : (
              <Button
                type="submit"
                disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                text="Save"
                bg="bg-primary1"
                color="text-white"
                Icon={<Confirm />}
              />
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddSite;
