import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert as BootstrapAlert } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import DataTable from 'react-data-table-component'
import { bulkFileImport } from '../components/Formik/AllForms/addUserFields'
import ImportIcon from '../HemeIconLibrary/settingsIcons/import'
import { Address, Import } from '../HemeIconLibrary'
import { sortedData } from '../helpers/sort'
import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText'
import {
  Button,
  FormSearch,
  Alert,
  Pagination,
  HemaValue,
  FilterColumn,
  NoPermissionAlert,
  NoDataComponent,
} from '../utils'
import {
  Add,
  Confirm,
  CreateSite,
  Edit,
  TableSortIcon,
} from '../HemeIconLibrary'
import FormContainer from '../components/Formik/formContainer'
import {
  setForm,
  editFormReducer,
  showSuccessReducer,
  setFormLoaderReducer,
  setFormCloseReducer,
} from '../Store/reducers/uiSettings'
import { setSiteFilter } from '../Store/reducers/siteManagment'
import { getCountriesLogisticBuilder } from '../Actions/logistic'
import { getAllStudyAction } from '../Actions/study'

import { createNewSite } from '../components/Formik/AllForms/addNewSite'
import createNewItemValidator from '../components/Formik/allFormValidation/createItem'
import { CreateSiteValidation } from '../components/Formik/allFormValidation'

import {
  getAllSiteAction,
  createNewSiteAction,
  updateSiteAction,
  sitesUploadAction,
  sitesDownloadAction,
} from '../Actions/siteManagment'

const SiteManagement = () => {
  const dispatch = useDispatch()
  const { uisettings, studies, sites, common } = useSelector((state) => state)

  /* form states */
  const [CTA, setCTA] = useState()
  const [formName, setformName] = useState()
  const [formIcon, setFormIcon] = useState()
  const [okBtnText, setokBtnText] = useState()
  const [okBtnIcon, setokBtnIcon] = useState()
  const [formValidation, setFormValidation] = useState()
  const [updatedData, setUpdatedData] = useState()
  const [searchQuery, setsearchQuery] = useState('')
  const [filteredData, setfilteredData] = useState([])
  //callallEnventoryRequiredAPI
  useEffect(() => {
    ;(async () => {
      getAllStudyAction()
      getAllSiteAction()
      getCountriesLogisticBuilder()
    })()
  }, [])

  // CTA item add
  const addSiteCTA = (edit) => {
    setCTA(() => async (data) => {
      const sitePayload = {
        siteCode: data?.siteCode,
        name: data?.name,
        studyId: data?.studyId,
        isActive: data?.isActive,
        principalInvestigator: {
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email || '',
          personnelTypeId: 2,
          phone: data?.phone?.toString() || '',
          fax: data?.fax || '',
          institutionName: data?.institutionName || '',
          department: data?.department || '',
          address: {
            address1: data?.address1,
            address2: data?.address2 || '',
            city: data?.city,
            stateProvince: data?.stateProvince || '',
            postalCode: data?.postalCode || '',
            countryId: data?.countryId,
            phone: data?.phone?.toString() || '',
          },
        },
      }
      if (edit) {
        dispatch(setFormLoaderReducer(true))
        const editSponser = await updateSiteAction(edit, sitePayload)
        dispatch(setFormLoaderReducer(false))
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`${editSponser.data.name} Site updated.`))
      } else {
        dispatch(setFormLoaderReducer(true))
        const createdSponser = await createNewSiteAction(sitePayload)
        if (createdSponser?.data?.id) {
          dispatch(setFormCloseReducer())
          dispatch(
            showSuccessReducer(`${createdSponser.data.name} Site added.`),
          )
        }
        dispatch(setFormLoaderReducer(false))
      }
    })
    setFormValidation((error, values) => (error, values) => {
      createNewItemValidator(error, values)
      CreateSiteValidation(error, values)
    })
  }

  // CTA for inventory for selected item
  // filter and search
  useEffect(() => {
    const regex = new RegExp(searchQuery, 'i')
    ;(async () => {
      if (searchQuery) {
        const data = sites?.allSites?.filter((obj) => {
          for (const key in obj) {
            if (regex.test(obj[key])) {
              return true
            }
            if (
              key === 'sponsor' ||
              key === 'study' ||
              key === 'principalInvestigator'
            ) {
              const childObj = obj[key]
              for (const k in childObj) {
                if (regex.test(childObj[k])) {
                  return true
                }
                // return false;
              }
            }
          }
          return false
        })
        setfilteredData(data)
      } else {
        setfilteredData(sites?.allSites)
      }
    })()
  }, [searchQuery, sites?.allSites])
  console.log('sites?.siteFilter', sites?.siteFilter)
  // study filter
  useEffect(() => {
    if (Object.keys(sites?.siteFilter)?.length) {
      const filterResult = sites?.allSites?.filter((port) => {
        if (
          (sites?.siteFilter?.sponsor?.length
            ? sites?.siteFilter?.sponsor?.includes(port?.sponsor?.name)
            : true) &&
          (sites?.siteFilter?.study?.length
            ? sites?.siteFilter.study?.includes(port.study?.name)
            : true) &&
          (sites?.siteFilter?.name?.length
            ? sites?.siteFilter.name?.includes(port.name)
            : true) &&
          (sites?.siteFilter?.siteCode?.length
            ? sites?.siteFilter?.siteCode?.includes(port.siteCode)
            : true) &&
          (sites?.siteFilter?.firstName?.length
            ? sites?.siteFilter.firstName?.includes(
                port.principalInvestigator?.firstName,
              )
            : true) &&
          (sites?.siteFilter?.country?.length
            ? sites?.siteFilter.country?.includes(
                port?.principalInvestigator?.address?.country?.name,
              )
            : true) &&
          (sites?.siteFilter?.isActive?.length
            ? sites?.siteFilter.isActive?.includes(port.isActive)
            : true)
        ) {
          return true
        } else {
          return false
        }
      })
      setfilteredData(filterResult)
    } else {
      setfilteredData(sites?.allSites)
    }
  }, [JSON.stringify(sites?.siteFilter)])

  const bulkImportSystemUser = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const formData = new FormData()
      formData.append('file', payload.userFile)
      const resp = await sitesUploadAction(formData)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(` Sites are currently being uploaded. You will receive an email notification to confirm that the sites were uploaded successfully or to indicate any issues.`))
        getAllSiteAction()
      }
    })
  }

  if (!common?.permissions?.['Site Management']?.read) {
    return <NoPermissionAlert />
  }

  return (
    <>
      <div className="flex gap-[10px] justify-between items-end">
        <HemaHeadingWithSubText
          heading="Site Management"
          sub="Manage your sites here."
        />
        <div className="flex gap-[10px] ">
          {common?.permissions?.['Site Management']?.write && (
            <>
              <Button
                text="Bulk Import"
                Icon={<ImportIcon color="white" />}
                color="text-white"
                bg="bg-primary1"
                cta={() => {
                  setformName('Bulk Import')
                  setFormIcon(<Import />)
                  setokBtnText('Import')
                  setokBtnIcon(<ImportIcon color="white" />)
                  bulkFileImport[0].label =
                    'Please download and use the template to bulk upload sites.'
                  bulkFileImport[0].onClick = async () => {
                    dispatch(setFormLoaderReducer(true))
                    const resp = await sitesDownloadAction()
                    dispatch(setFormLoaderReducer(false))
                    if (resp?.status === 200) {
                      //dispatch(setFormCloseReducer());
                      dispatch(showSuccessReducer(`File downloaded.`))
                      const url = window.URL.createObjectURL(
                        new Blob([resp.data]),
                      )
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', 'AllSites.xlsx')
                      document.body.appendChild(link)
                      link.click()
                    }
                  }
                  setUpdatedData(bulkFileImport)
                  bulkImportSystemUser()
                  dispatch(setForm({ state: true, type: 'additem' }))
                }}
              />
              <Button
                text="Create New Site"
                Icon={<Add color="white" />}
                color="text-white"
                bg="bg-primary1"
                cta={() => {
                  setformName('Create Site')
                  setokBtnText('Create')
                  setokBtnIcon(<Add color="white" />)
                  createNewSite[0].options = studies?.allStudies?.filter(
                    (site) => site.isActive === true,
                  )
                  createNewSite[0].disabled = false
                  createNewSite[1].initialValue = ''
                  createNewSite[2].initialValue = ''
                  createNewSite[3].initialValue = ''
                  createNewSite[4].initialValue = ''
                  createNewSite[5].initialValue = ''
                  createNewSite[6].initialValue = ''
                  createNewSite[7].initialValue = ''
                  createNewSite[8].placeholder = 'Select country'
                  createNewSite[8].options = common?.allCountries
                  createNewSite[8].initialValue = ''
                  createNewSite[9].initialValue = ''
                  createNewSite[10].initialValue = ''
                  createNewSite[11].initialValue = true
                  createNewSite[12].initialValue = ''
                  createNewSite[13].initialValue = ''
                  createNewSite[14].initialValue = ''
                  // setFormIcon(<CreateSite />);
                  setUpdatedData(
                    createNewSite?.filter(
                      (item) => item.name !== 'changeReason',
                    ),
                  )
                  addSiteCTA()
                  dispatch(setForm({ state: true, type: 'create-site' }))
                }}
              />
            </>
          )}
        </div>
      </div>

      <Alert />
      <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] mt-[27px] mb-[13px] inventory-tabs">
        <>
          <div className="mb-[16px] pl-[16px]">
            <FormSearch
              w="w-[400px]"
              searchQuery={searchQuery}
              setsearchQuery={setsearchQuery}
            />
          </div>

          {sites?.allSites ? (
            filteredData?.length > 0 ||
            Object.keys(sites?.siteFilter)?.length ? (
              <DataTable
                data={filteredData}
                className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
                customStyles={{
                  responsiveWrapper: {
                    style: { overflow: 'auto !important' },
                  },
                }}
                noDataComponent={
                  <NoDataComponent
                    setActiveFilterTab={setfilteredData}
                    activeFilterOriginalData={sites?.allSites}
                    resetRedux={setSiteFilter}
                  />
                }
                columns={[
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Sponsor Name'}
                            color="text-[#000000]"
                            className="text-xs font-normal whitespace-pre"
                          />
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="sponsor"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map(
                                  (filter) => filter?.sponsor?.name,
                                ),
                              ),
                            )}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={row?.sponsor?.name}
                      />
                    ),
                    sortId: 'sponsor.name',
                  },

                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Study Code'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="study"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map(
                                  (filter) => filter?.study?.name,
                                ),
                              ),
                            )}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={row.study?.name}
                      />
                    ),
                    sortId: 'study.name',
                  },

                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Site Name'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="name"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map((filter) => filter?.name),
                              ),
                            )}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={row?.name}
                      />
                    ),
                    sortId: 'name',
                  },

                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Site Code'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="siteCode"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map(
                                  (filter) => filter?.siteCode,
                                ),
                              ),
                            )}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={row.siteCode}
                      />
                    ),
                    sortId: 'siteCode',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Principal Investigator'}
                            color="text-[#000000]"
                            className="text-xs font-normal whitespace-pre"
                          />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="firstName"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map(
                                  (filter) =>
                                    filter?.principalInvestigator?.firstName,
                                ),
                              ),
                            )}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={`${row?.principalInvestigator?.firstName} ${row?.principalInvestigator?.lastName}`}
                      />
                    ),
                    sortId: 'principalInvestigator.firstName',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Country'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="country"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map(
                                  (filter) =>
                                    filter?.principalInvestigator?.address
                                      ?.country?.name,
                                ),
                              ),
                            )}
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={
                          row?.principalInvestigator?.address?.country?.name
                        }
                      />
                    ),
                    sortId: 'principalInvestigator.address.country.name',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                          <HemaValue
                            text={'Active'}
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />{' '}
                          <span className="short-icon">
                            <TableSortIcon />
                          </span>
                        </div>
                        <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <FilterColumn
                            columnName="isActive"
                            setRedux={setSiteFilter}
                            reduxValues={sites?.siteFilter || []}
                            options={Array.from(
                              new Set(
                                sites?.allSites?.map(
                                  (filter) => filter?.isActive,
                                ),
                              ),
                            )}
                            type="boolean"
                            boolTrueText="Active"
                            boolFalseText="In-Active"
                          />
                        </div>
                      </div>
                    ),
                    sortable: true,
                    selector: (row, index) => (
                      <HemaValue
                        className="text-xs font-normal"
                        text={row.isActive ? 'Yes' : 'No'}
                      />
                    ),
                    sortId: 'isActive',
                  },
                  {
                    name: (
                      <div className="w-full">
                        <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                          <HemaValue
                            text="Actions"
                            color="text-[#000000]"
                            className="text-xs font-normal"
                          />
                        </div>
                      </div>
                    ),
                    selector: (row, index) => (
                      <div className="flex-grow four">
                        <div className="flex justify-end gap-[5px] meta">
                          {common?.permissions?.['Site Management']?.update && (
                            <Button
                              Icon={<Edit color="#775FD5" />}
                              color="text-white"
                              bg="bg-bgActionDots"
                              cta={() => {
                                createNewSite[0].initialValue = row?.study?.id
                                createNewSite[0].placeholder = row?.study?.name
                                createNewSite[0].disabled = true
                                createNewSite[1].initialValue = row?.name
                                createNewSite[2].initialValue = row?.siteCode
                                createNewSite[3].initialValue =
                                  row?.principalInvestigator?.address?.address1
                                createNewSite[4].initialValue =
                                  row?.principalInvestigator?.address?.address2
                                createNewSite[5].initialValue =
                                  row?.principalInvestigator?.department
                                createNewSite[6].initialValue =
                                  row?.principalInvestigator?.address?.city
                                createNewSite[7].initialValue =
                                  row?.principalInvestigator?.address?.stateProvince
                                createNewSite[8].placeholder =
                                  row?.principalInvestigator?.address?.country?.name
                                createNewSite[8].initialValue =
                                  row?.principalInvestigator?.address?.country?.id
                                createNewSite[8].options = common?.allCountries
                                createNewSite[9].initialValue =
                                  row?.principalInvestigator?.address?.postalCode
                                createNewSite[10].initialValue =
                                  row?.principalInvestigator?.phone
                                createNewSite[11].initialValue = row?.isActive
                                createNewSite[12].initialValue =
                                  row?.principalInvestigator?.firstName
                                createNewSite[13].initialValue =
                                  row?.principalInvestigator?.lastName
                                createNewSite[14].initialValue =
                                  row?.principalInvestigator?.email
                                setUpdatedData(createNewSite)
                                setformName('Edit Site')
                                // setFormIcon(<ExpiryAlertPurple />);
                                dispatch(editFormReducer(row))
                                setokBtnIcon(<Confirm />)
                                setokBtnText('Save')
                                addSiteCTA(row?.id)
                                dispatch(
                                  setForm({ state: true, type: 'edit-site' }),
                                )
                              }}
                            />
                          )}
                          {/* <Button Icon={<SearchColor />} color="text-white" bg="bg-bgActionDots" cta={() => {}} /> */}
                        </div>
                      </div>
                    ),
                  },
                ]}
                pagination
                onSort={(row, direction, sorted) => {
                  setfilteredData(
                    sortedData(row.sortId, direction, sorted)?.filter(
                      (data) => Object.keys(data)?.length,
                    ),
                  )
                }}
                paginationComponent={(e) => {
                  return <Pagination e={e} />
                }}
              />
            ) : (
              <BootstrapAlert variant="warning" className="mt-3 text-center">
                No Site to show. Please add by clicking on Create New Site.
              </BootstrapAlert>
            )
          ) : (
            <>
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
              <br />
              <Skeleton count={4} />
            </>
          )}
        </>
      </div>

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          Icon={formIcon}
          formValidation={formValidation}
          okBtnIcon={okBtnIcon}
          okBtnText={okBtnText}
        />
      )}
    </>
  )
}

export default SiteManagement
