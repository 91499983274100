/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: true,
  isSubmitted: true,
  companyLocations: null,
  systemUsers: null,
  portalUsers: null,
  portalUsersFilter: {},
  systemUsersFilter: {},
  userRole: null,
  sites: [],
  permission: null,
  showOrderConfig: null,
  portalSiteFilter: {},
  allCouriers: null,
  allCouriersFilter: {},
  allDepotSettings: null,
  allDepotSettingsFilter: {},
  alltestingLab: null,
  alltestingLabFilter: {},
  allpackageLab: null,
  allpackageLabFilter: {},
}

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCompanyLoaction: (state, action) => {
      state.isLoading = false
      state.companyLocations = action.payload
    },
    setShowOrderConfig: (state, action) => {
      state.showOrderConfig = action.payload
    },

    removeCompanyLocation: (state, action) => {
      state.isSubmitted = false
      state.companyLocations = state.companyLocations.filter(
        (y) => y.id !== action.payload,
      )
    },
    removeCourierSettings: (state, action) => {
      state.allCouriers = state.allCouriers.filter(
        (y) => y.id !== action.payload,
      )
    },
    removeLabSettingsReducer: (state, action) => {
      state.alltestingLab = state.alltestingLab.filter(
        (y) => y.id !== action.payload,
      )
    },
    removePackageSettingsReducer: (state, action) => {
      state.allpackageLab = state.allpackageLab.filter(
        (y) => y.id !== action.payload,
      )
    },

    removeDepotSettings: (state, action) => {
      state.allDepotSettings = state.allDepotSettings.filter(
        (y) => y.id !== action.payload,
      )
    },
    setSystemUser: (state, action) => {
      state.isLoading = false
      state.systemUsers = action.payload
    },
    setportalUsersFilter: (state, action) => {
      state.portalUsersFilter = action.payload
    },
    setSystemUsersFilter: (state, action) => {
      state.systemUsersFilter = action.payload
    },
    setPortalSiteFilter: (state, action) => {
      state.portalSiteFilter = action.payload
    },
    setallCouriersFilter: (state, action) => {
      state.allCouriersFilter = action.payload
    },
    setallDepotSettingsFilter: (state, action) => {
      state.allDepotSettingsFilter = action.payload
    },
    setalltestingLabFilter: (state, action) => {
      state.alltestingLabFilter = action.payload
    },
    setallpackageLabFilter: (state, action) => {
      state.allpackageLabFilter = action.payload
    },
    setallDepotSettings: (state, action) => {
      state.allDepotSettings = action.payload
    },
    addNewDepotSettings: (state, action) => {
      if (state.allDepotSettings) {
        state.allDepotSettings = [action.payload, ...state.allDepotSettings]
      } else {
        state.allDepotSettings = action.payload
      }
    },
    setallPackageSettings: (state, action) => {
      state.allpackageLab = action.payload
    },
    addNewPackageSettings: (state, action) => {
      if (state.allpackageLab) {
        state.allpackageLab = [action.payload, ...state.allpackageLab]
      } else {
        state.allpackageLab = action.payload
      }
    },
    removeSystemUser: (state, action) => {
      state.isSubmitted = false
      state.systemUsers = state.systemUsers.filter(
        (y) => y.id !== action.payload,
      )
    },
    setPortalUser: (state, action) => {
      state.isLoading = false
      state.portalUsers = action.payload
    },
    removePortalUser: (state, action) => {
      state.isSubmitted = false
      state.portalUsers = state.portalUsers.filter(
        (y) => y.id !== action.payload,
      )
    },
    setUserRoles: (state, action) => {
      state.isLoading = false
      state.userRole = action.payload
    },
    setSites: (state, action) => {
      state.isLoading = false
      state.sites = action.payload
    },
    setPermission: (state, action) => {
      state.permission = action.payload
    },

    setaddNewRole: (state, action) => {
      if (state.userRole) {
        state.userRole = [...state.userRole, action.payload]
      } else {
        state.userRole = action.payload
      }
    },
    setallCourier: (state, action) => {
      state.allCouriers = action.payload
    },
    setaddNewCourier: (state, action) => {
      if (state.allCouriers) {
        state.allCouriers = [action.payload, ...state.allCouriers]
      } else {
        state.allCouriers = action.payload
      }
    },
    setdeleteRole: (state, action) => {
      state.userRole = state.userRole.filter(
        (data) => data.id !== action.payload,
      )
    },
    setupdateRole: (state, action) => {
      state.userRole = state.userRole?.map((data) => {
        if (data.id === action.payload?.id) {
          return action.payload
        } else {
          return data
        }
      })
    },
    updateCourierSettingsReducer: (state, action) => {
      state.allCouriers = state.allCouriers?.map((data) => {
        if (data.id === action.payload?.id) {
          return action.payload
        } else {
          return data
        }
      })
    },
    updateLabSettingsReducer: (state, action) => {
      state.alltestingLab = state.alltestingLab?.map((data) => {
        if (data.id === action.payload?.id) {
          return action.payload
        } else {
          return data
        }
      })
    },
    updateDepotSettingsReducer: (state, action) => {
      state.allDepotSettings = state.allDepotSettings?.map((data) => {
        if (data.id === action.payload?.data?.id) {
          return action.payload?.data
        } else {
          return data
        }
      })
    },
    setTestingLabSetting: (state, action) => {
      state.alltestingLab = action.payload
    },
    addTestingLabSetting: (state, action) => {
      if (state.alltestingLab) {
        state.alltestingLab = [action.payload, ...state.alltestingLab]
      } else {
        state.alltestingLab = action.payload
      }
    },
    updatePackageSettingsReducer: (state, action) => {
      state.allpackageLab = state.allpackageLab?.map((data) => {
        if (data.id === action.payload?.id) {
          return action.payload
        } else {
          return data
        }
      })
    },
  },
})

export const {
  setShowOrderConfig,
  setaddNewRole,
  setdeleteRole,
  setupdateRole,
  setPermission,
  setCompanyLoaction,
  removeCompanyLocation,
  setSystemUser,
  removeSystemUser,
  setPortalUser,
  removePortalUser,
  setUserRoles,
  setSites,
  setportalUsersFilter,
  setSystemUsersFilter,
  setPortalSiteFilter,
  removeLabSettingsReducer,
  removePackageSettingsReducer,
  setallCouriersFilter,
  setallDepotSettingsFilter,
  setallpackageLabFilter,
  setalltestingLabFilter,
  alltestingLabFilter,
  allpackageLabFilter,
  setaddNewCourier,
  setallPackageSettings,
  setallDepotSettings,
  allDepotSettingsFilter,
  updateCourierSettingsReducer,
  removeCourierSettings,
  updateDepotSettingsReducer,
  removeDepotSettings,
  setTestingLabSetting,
  updateLabSettingsReducer,
  updatePackageSettingsReducer,
  addTestingLabSetting,
  addNewPackageSettings,
  setallCourier,
  addNewDepotSettings,
} = settings.actions

export default settings.reducer
