import React from 'react';

export const TabNotebook = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79688 12.9151H11.8826M13.9397 12.9151H20.1112M15.9969 16.0008H20.1112M18.054 19.0865H20.1112M8.79688 16.0008H11.8826M8.79688 19.0865H11.8826M10.854 21.1436V10.8579C10.854 9.7218 11.775 8.80078 12.9112 8.80078H21.1397C22.2759 8.80078 23.1969 9.7218 23.1969 10.8579V21.1436C23.1969 22.2798 22.2759 23.2008 21.1397 23.2008H12.9112C11.775 23.2008 10.854 22.2798 10.854 21.1436Z"
        stroke={purple ? 'url(#paint0_linear_5498_187282)' : '#414753'}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5498_187282"
          x1="10.4748"
          y1="17.8625"
          x2="21.6231"
          y2="18.0402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
