import React from 'react';
import Skeleton from 'react-loading-skeleton';
export const SkelatonCoponent = () => {
  return (
    <>
      <br />
      <Skeleton count={4} />
      <br />
      <Skeleton count={4} />
      <br />
      <Skeleton count={4} />
      <br />
    </>
  );
};
