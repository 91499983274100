import React from 'react';

export const Reject = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4922 2.01979C18.8827 2.41032 18.8827 3.04348 18.4922 3.43401L11.9262 10L18.4922 16.566C18.8827 16.9565 18.8827 17.5897 18.4922 17.9802L17.482 18.9904C17.0915 19.3809 16.4583 19.3809 16.0678 18.9904L9.50181 12.4244L2.93582 18.9904C2.5453 19.3809 1.91213 19.3809 1.52161 18.9904L0.511454 17.9802C0.12093 17.5897 0.12093 16.9565 0.511454 16.566L7.07744 10L0.511455 3.43401C0.120931 3.04349 0.12093 2.41032 0.511454 2.0198L1.52161 1.00964C1.91213 0.619118 2.5453 0.619119 2.93582 1.00964L9.50181 7.57563L16.0678 1.00964C16.4583 0.619119 17.0915 0.619117 17.482 1.00964L18.4922 2.01979Z"
        fill="url(#paint0_linear_3002_91167)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3002_91167"
          x1="1.01652"
          y1="18.4853"
          x2="17.9871"
          y2="1.51471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
