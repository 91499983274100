import React from 'react';

export const QualityControlTab = ({ purple }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9112 18.0579L14.9683 20.1151L19.0826 16.0008M8.79688 13.2319L9.82545 8.80078H15.9969M8.79688 13.2319V23.2008H23.1969V13.2319M8.79688 13.2319H15.9969M15.9969 8.80078V13.2319M15.9969 8.80078H22.1683L23.1969 13.2319M23.1969 13.2319H15.9969M15.9969 13.2319V14.9722"
        stroke={purple ? 'url(#paint0_linear_5498_186130)' : '#414753'}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5498_186130"
          x1="10.4748"
          y1="17.8625"
          x2="21.6231"
          y2="18.0402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
