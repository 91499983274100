import { HemaValue, HemaLabel } from '../../utils';
import { HemaDateFormat } from '../../helpers/commonHelper';
export const InventoryItemColumns = [
  {
    cell: (row) => {
      return (
        <div>
          <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed_last">
            <HemaLabel text="Unit Type" textSize="text-[12px]" showCaret />
            <HemaValue text={row?.unitType?.name || '--'} color="text-[#595959]" />
          </div>
          <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed_last">
            <HemaLabel text="Unit Size" textSize="text-[12px]" showCaret />
            <HemaValue text={row?.unitSize || '--'} color="text-[#595959]" />
          </div>
          <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed_last">
            <HemaLabel text="Last used" textSize="text-[12px]" showCaret />
            <HemaValue text={HemaDateFormat(row?.lastUsed) === 'N/A' ? '--' : HemaDateFormat(row?.lastUsed)} color="text-[#595959]" />
          </div>
          <div className="flex items-center gap-[11px] meta mb-[0px] table_lable_hed_last">
            <HemaLabel text="Low Inventory Threshold" textSize="text-[12px]" showCaret />
            <HemaValue text={row?.lowInventoryThreshold || '--'} color="text-[#595959]" />
          </div>
        </div>
      );
    },
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    // sortable: true,
    // selector: (row) => row?.unitSize,
    sortable: true,
    sortFunction: (a, b) => a.unitSize - b.unitSize,
  },

  {
    cell: (row) => {
      return (
        <div>
          <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed">
            <HemaLabel text="Ready" textSize="text-[12px]" showCaret />
            <HemaValue text={row?.ready || '--'} color="text-[#595959]" />
          </div>
          <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed">
            <HemaLabel text="Expiring" textSize="text-[12px]" showCaret />
            <HemaValue bg="bg-[#E4DFF7]" color="text-[#775FD5]" text={row?.expiring || '--'} />
          </div>
          <div className="flex items-center gap-[11px] meta mb-[12px] table_lable_hed">
            <HemaLabel text="Expired" textSize="text-[12px]" showCaret />

            <HemaValue text={row?.expired || '--'} bg="bg-[#FDDAE8]" color="text-[#F5488F]" />
          </div>
          <div className="flex items-center gap-[11px] meta table_lable_hed">
            <HemaLabel text="Used" textSize="text-[12px]" showCaret />
            <HemaValue text={row?.used || '--'} bg="bg-[#DFF6EB]" color="text-[#5DD099]" />
          </div>
        </div>
      );
    },
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];
