import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { filter } from 'smart-array-filter';
import { Button, FormSearch, HemaValue, Pagination, FilterColumn, NoDataComponent } from '../../../utils';
import { ExpiryAlertPurple, DeletePurple, Edit, TableSortIcon } from '../../../HemeIconLibrary';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import { PackageValidation } from '../../../components/Formik/allFormValidation';
import { AddDepotSettings } from '../../../components/Formik/AllForms/addDepotSettings';
import { AddPackageLabSettings } from '../../../components/Formik/AllForms/addPackageLabSettings';
import { DeletePackageLabSettings } from '../../../components/Formik/AllForms/addPackageLabSettings';
import { sortedData } from '../../../helpers/sort';
import { sortedTableData } from '../../../helpers/sortedData';
import { addPortalUserFields } from '../../../components/Formik/AllForms/addPortalUserFields';
import { Remove } from '../../../components/Formik/AllForms/remove';
import { setallpackageLabFilter } from '../../../Store/reducers/settings';
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from '../../../Store/reducers/uiSettings';

// Actions
import { getPackageSettingsAction, updatePackageSettingsAction, deletePackageSettingsAction } from '../../../Actions/settings';

// Utils
import { getFullName } from '../Utils';

const PackageSettings = ({ sites, setCTA, setformName, setFormIcon, setUpdatedData, setokBtnIcon, setokBtnText, setFormValidation }) => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);
  const { isLoading, allpackageLabFilter, allpackageLab } = settings;

  useEffect(() => {
    getPackageSettingsAction();
  }, []);

  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);

  //senpnarch for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(allpackageLab, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(allpackageLab);
      }
    })();
  }, [searchQuery, allpackageLab]);

  const editPackageSettings = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await updatePackageSettingsAction(row.id, payload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`Package successfully edited.`));
        // getPortalUserAction();
      }
    });
    setFormValidation((error, values) => (error, values) => {
      PackageValidation(error, values);
    });
  };
  const deletePackageSettings = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deletePackageSettingsAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`Package deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  useEffect(() => {
    if (Object.keys(allpackageLabFilter)?.length) {
      const filterResult = allpackageLab?.filter((port) => {
        if (
          (allpackageLabFilter.unit?.length ? allpackageLabFilter.unit.includes(port.unit) : true) &&
          (allpackageLabFilter.name?.length ? allpackageLabFilter.name?.includes(port.name) : true) &&
          (allpackageLabFilter.width?.length ? allpackageLabFilter.width.includes(port.width) : true) &&
          (allpackageLabFilter.height?.length ? allpackageLabFilter.height?.includes(port.height) : true) &&
          (allpackageLabFilter.length?.length ? allpackageLabFilter.length?.includes(port.length) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setDataList(filterResult);
    } else {
      setDataList(allpackageLab);
    }
  }, [JSON.stringify(allpackageLabFilter)]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>

        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 || Object.keys(allpackageLabFilter)?.length ? (
          <DataTable
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={allpackageLab} resetRedux={setallpackageLabFilter} />}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Name'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="name"
                          setRedux={setallpackageLabFilter}
                          reduxValues={allpackageLabFilter || []}
                          options={Array.from(new Set(allpackageLab?.map((filter) => filter.name)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.name || 'N/A'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'name',
              },

              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Length'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="length"
                          setRedux={setallpackageLabFilter}
                          reduxValues={allpackageLabFilter || []}
                          options={Array.from(new Set(allpackageLab?.map((filter) => filter.length)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.length || 'N/A'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'length',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Width'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="width"
                          setRedux={setallpackageLabFilter}
                          reduxValues={allpackageLabFilter || []}
                          options={Array.from(new Set(allpackageLab?.map((filter) => filter.width)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.width || 'N/A'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'width',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Height'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="height"
                          setRedux={setallpackageLabFilter}
                          reduxValues={allpackageLabFilter || []}
                          options={Array.from(new Set(allpackageLab?.map((filter) => filter.height)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.height || 'N/A'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'height',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Unit'} color="text-[#000000]" className="text-xs font-normal" />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="unit"
                          setRedux={setallpackageLabFilter}
                          reduxValues={allpackageLabFilter || []}
                          options={Array.from(new Set(allpackageLab?.map((filter) => filter.unit)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.unit || 'N/A'} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'unit',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <HemaValue text={'Actions'} color="text-[#000000]" className="text-xs font-normal pr-7" />{' '}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[16px] meta">
                        {common?.permissions?.['Package Settings']?.update && (
                          <Button
                            Icon={<Edit color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row));
                              setformName('Edit Package');
                              setokBtnIcon();
                              setokBtnText('Update');
                              setFormIcon(<ExpiryAlertPurple />);
                              AddPackageLabSettings[0].initialValue = row?.name;
                              AddPackageLabSettings[2].initialValue = row?.unit;
                              AddPackageLabSettings[3].initialValue = row?.length;
                              AddPackageLabSettings[4].initialValue = row?.width;
                              AddPackageLabSettings[5].initialValue = row?.height;
                              AddPackageLabSettings[6].initialValue = row?.change_reason;
                              setUpdatedData(AddPackageLabSettings);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'edit-package-settings',
                                }),
                              );
                              editPackageSettings(row);
                            }}
                          />
                        )}
                        {/* <Button
                            Icon={<SearchColor color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                          /> */}
                        {common?.permissions?.['Package Settings']?.delete && (
                          <Button
                            Icon={<img src={deleteIcon} />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              DeletePackageLabSettings[1].initialValue = row?.name;
                              DeletePackageLabSettings[2].initialValue = row?.unit;
                              DeletePackageLabSettings[3].initialValue = row?.length;
                              DeletePackageLabSettings[4].initialValue = row?.width;
                              DeletePackageLabSettings[5].initialValue = row?.height;
                              DeletePackageLabSettings[6].initialValue = '';
                              setUpdatedData(DeletePackageLabSettings);
                              setokBtnIcon();
                              setokBtnText('Confirm');
                              setformName('Delete Package');
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'deleteItem',
                                }),
                              );
                              deletePackageSettings(row);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: 'visible !important' },
                },
              },

              cells: {
                style: { overflow: 'visible !important' },
              },

              responsiveWrapper: {
                style: { overflow: 'visible !important' },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <NoDataComponent text="There are no package setting to show. Add by clicking on Add Package." />
        )}
      </>
    </div>
  );
};

export default PackageSettings;
