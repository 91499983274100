import React, { useEffect, useRef } from 'react'
import { Formik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { compareArrays } from '../../../helpers/commonHelper'
import { setDropdownValue } from '../../../Store/reducers/common'
import {
  HemaLabel,
  FormSelect,
  Button,
  FormTextarea,
  FormText,
  Alert,
  FormMultiSelect,
  FormTags,
  SingleSelect,
} from '../../../utils'
import {
  Language,
  Cancel,
  Confirm,
  Location,
  Country,
  StudyAlias,
  OutGoing,
  InComing,
  Broker,
  Telephone,
  Fax,
  Email,
  IORContact,
  PostShipping,
  ChangeReason,
  CrossIcon,
  IncotermIcon,
} from '../../../HemeIconLibrary'
import Heading from '../../../pages/logistOrchestra/logisticComponents/heading'
import ShippingRules from '../../../pages/logistOrchestra/logisticComponents/shippingRules'
import KitConfiguration from '../../../pages/logistOrchestra/logisticComponents/kitConfiguration'
import {
  createNewCountryLogisticAction,
  updateCountryLogisticAction,
  getShipingDepotbyIdAction,
} from '../../../Actions/logistic'

import {
  setForm,
  showSuccessReducer,
  editFormReducer,
} from '../../../Store/reducers/uiSettings'
import { setcountryConfigured } from '../../../Store/reducers/logistic'

const FormikForm = () => {
  const { common, assembly, logistic, uisettings } = useSelector(
    (state) => state,
  )
  const dispatch = useDispatch()
  const formikRef = useRef()

  useEffect(() => {
    ;(async () => {
      console.log(await formikRef.current?.validateForm())
    })()
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm])

  useEffect(() => {
    ;(async () => {
      if (
        common?.activeDropdownValue?.name === 'shippingDepotId' &&
        common?.activeDropdownValue?.id !== 0
      ) {
        const res = await getShipingDepotbyIdAction(
          common?.activeDropdownValue?.id,
        )
        if (res?.status === 200) {
          dispatch(
            editFormReducer({
              ...uisettings?.editForm,
              brokerContact: res?.data?.brokerContact,
              ioRContact: res?.data?.ioRContact?.split(','),
              shippingDepot: common?.activeDropdownValue,
              broker: {
                ...res?.data?.brokerAddress,
                brokerTaxID: res?.data?.brokerTaxID,
                brokerEORI: res?.data?.brokerEORI,
                companyName: res?.data?.brokerCompanyName,
              },
              ioR: {
                ...res?.data?.ioRAddress,
                iorTaxID: res?.data?.iorTaxID,
                ioreori: res?.data?.ioreori,
                companyNameIOR: res?.data?.ioRCompanyName,
              },
            }),
          )
        }
      }
    })()
  }, [common?.activeDropdownValue])

  // const createCountryValidation = (errors, values) => {

  // };

  return (
    <Formik
      initialValues={{
        changeReason: '',
        counrtyId: uisettings.editForm?.country?.id || undefined,
        languageId: uisettings.editForm?.language?.id || undefined,
        outgoingHtsCode: uisettings.editForm?.outgoingHtsCode || undefined,
        incomingHtsCode: uisettings.editForm?.incomingHtsCode || undefined,
        incoterm: uisettings.editForm?.incoterm || undefined,
        shippingDepotId: uisettings.editForm?.shippingDepot?.id || 0,
        eorI:
          uisettings.editForm?.eori || uisettings.editForm?.eorI || undefined,

        contact:
          uisettings.editForm?.broker?.contact ||
          uisettings.editForm?.brokerContact ||
          undefined,
        address1: uisettings.editForm?.broker?.address1 || undefined,
        address2: uisettings.editForm?.broker?.address2 || undefined,
        city: uisettings.editForm?.broker?.city || undefined,
        stateProvince: uisettings.editForm?.broker?.stateProvince || undefined,
        postalCode: uisettings.editForm?.broker?.postalCode || undefined,
        country: uisettings.editForm?.broker?.country?.id || undefined,
        phone: uisettings.editForm?.broker?.phone || undefined,
        fax: uisettings.editForm?.broker?.fax || undefined,
        email: uisettings.editForm?.broker?.email || undefined,
        taxId:
          uisettings.editForm?.broker?.taxId ||
          uisettings?.editForm?.broker?.brokerTaxID ||
          undefined,
        eoribroker:
          uisettings.editForm?.broker?.eori ||
          uisettings?.editForm?.broker?.brokerEORI ||
          undefined,
        companyName: uisettings.editForm?.broker?.companyName || undefined,

        contactIOR:
          uisettings.editForm?.ioR?.contact?.split(',') ||
          uisettings?.editForm?.ioRContact ||
          [],
        address1IOR: uisettings.editForm?.ioR?.address1 || undefined,
        address2IOR: uisettings.editForm?.ioR?.address2 || undefined,
        cityIOR: uisettings.editForm?.ioR?.city || undefined,
        stateProvinceIOR: uisettings.editForm?.ioR?.stateProvince || undefined,
        postalCodeIOR: uisettings.editForm?.ioR?.postalCode || undefined,
        countryIOR: uisettings.editForm?.ioR?.country?.id || undefined,
        phoneIOR: uisettings.editForm?.ioR?.phone || undefined,
        faxIOR: uisettings.editForm?.ioR?.fax || undefined,
        emailIOR: uisettings.editForm?.ioR?.email?.split(',') || [],
        taxIdIOR:
          uisettings.editForm?.ioR?.taxId ||
          uisettings.editForm?.ioR?.iorTaxID ||
          undefined,
        eoriIOR:
          uisettings.editForm?.ioR?.eori ||
          uisettings?.editForm?.ioR?.ioreori ||
          undefined,
        companyNameIOR:
          uisettings.editForm?.ioR?.companyName ||
          uisettings?.editForm?.ioR?.companyNameIOR ||
          undefined,

        shippingRules:
          common.allShipping?.map((data) => {
            return {
              //  checked: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.length || false,
              shippingConditionId:
                uisettings.editForm?.shippingRules.filter(
                  (check) => check?.shippingCondition?.id === data.id,
                )?.[0]?.shippingCondition || data,
              inBoundCourierId: uisettings.editForm?.shippingRules.filter(
                (check) => check?.shippingCondition?.id === data.id,
              )?.[0]?.inBoundCourier,
              outBoundCourierId: uisettings.editForm?.shippingRules.filter(
                (check) => check?.shippingCondition?.id === data.id,
              )?.[0]?.outBoundCourier,
            }
          }) || [],
        initialRequestAssemblyId:
          uisettings.editForm?.initialRequestAssembly?.id ||
          logistic?.selectedRegionDetail?.initialRequestAssembly?.id ||
          'N/A',
        shippingAssemblyId: uisettings.editForm?.shippingAssembly?.id || '',
        IsPostShippingRequired: uisettings.editForm?.isPostShippingRequired
          ? 1
          : 0,
        greenLightRequiredTypeId:
          uisettings.editForm?.greenLightRequiredType?.id || undefined,
        greenLightContact:
          uisettings.editForm?.greenLightContact?.split(',') || [],
        postShippingContact:
          uisettings.editForm?.postShippingContact?.split(',') || [],
      }}
      innerRef={formikRef}
      enableReinitialize
      validate={(values) => {
        const errors = {}
        if (values.shippingDepotId.length === 0) {
          errors.shippingDepotId = 'Required'
        }
        if (
          values.email &&
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Please enter a valid email address'
        }

        // if (values.contactIOR) {
        //   values.contactIOR?.map((contact) => {
        //     if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contact)) {
        //       errors.contactIOR = "Please enter a valid email address";
        //     }
        //   });
        // }
        if (values.postShippingContact) {
          values.postShippingContact?.map((contact) => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contact)) {
              errors.postShippingContact = 'Please enter a valid email address'
            }
          })
        }
        if (values.greenLightContact) {
          values.greenLightContact?.map((contact) => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contact)) {
              errors.greenLightContact = 'Please enter a valid email address'
            }
          })
        }
        if (values.emailIOR) {
          values.emailIOR?.map((contact) => {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(contact)) {
              errors.emailIOR = 'Please enter a valid email address'
            }
          })
        }
        if (!values.languageId) {
          errors.languageId = 'Required'
        }
        if (values.IsPostShippingRequired.length === 0) {
          errors.IsPostShippingRequired = 'Required'
        }
        if (
          values.IsPostShippingRequired &&
          values.postShippingContact.length === 0
        ) {
          errors.postShippingContact = 'Required'
        }
        if (!values.initialRequestAssemblyId) {
          errors.initialRequestAssemblyId = 'Required'
        }
        if (!values.greenLightRequiredTypeId) {
          errors.greenLightRequiredTypeId = 'Required'
        }
        if (values.emailIOR?.join(',').length > 200) {
          errors.emailIOR = 'max 200 characters allowed.'
        }
        if (values.incoterm?.length > 100) {
          errors.incoterm = 'max 100 characters allowed.'
        }
        if (values.eorI?.length > 100) {
          errors.eorI = 'max 100 characters allowed.'
        }
        if (values.eoribroker?.length > 100) {
          errors.eoribroker = 'max 100 characters allowed.'
        }
        if (values.eoriIOR?.length > 100) {
          errors.eoriIOR = 'max 100 characters allowed.'
        }
        if (values.companyName?.length > 100) {
          errors.companyName = 'max 100 characters allowed.'
        }
        if (values.companyNameIOR?.length > 100) {
          errors.companyNameIOR = 'max 100 characters allowed.'
        }
        if (values.taxId?.length > 100) {
          errors.taxId = 'max 100 characters allowed.'
        }
        if (values.taxIdIOR?.length > 100) {
          errors.taxIdIOR = 'max 100 characters allowed.'
        }
        if (
          values.greenLightRequiredTypeId !== 1 &&
          values.greenLightContact.length === 0
        ) {
          errors.greenLightContact = 'Required'
        }
        if (!values.shippingAssemblyId) {
          errors.shippingAssemblyId = 'Required'
        }
        if (
          !values.changeReason?.trim() &&
          logistic.selectedProtocolDetail?.qc?.status?.id === 3
        ) {
          errors.changeReason = 'Required'
        }

        return errors
      }}
      onSubmit={async (values) => {
        if (!!uisettings.editForm) {
          const result = await updateCountryLogisticAction(
            logistic.selectedCountry?.id,
            {
              ...values,
              shippingDepotId:
                values?.shippingDepotId === 0
                  ? undefined
                  : values?.shippingDepotId,
              initialRequestAssemblyId:
                values?.initialRequestAssemblyId === 'N/A'
                  ? undefined
                  : values?.initialRequestAssemblyId,
              // shippingAssemblyId: values?.shippingAssemblyId === 'N/A' ? undefined : values?.shippingAssemblyId,
              IsPostShippingRequired:
                values?.IsPostShippingRequired === 1 ? true : false,
              ioRContact: {
                contact:
                  values.contactIOR?.length > 0
                    ? values.contactIOR?.join(',')
                    : undefined,
                address1: values.address1IOR || undefined,
                address2: values.address2IOR || undefined,
                city: values.cityIOR || undefined,
                stateProvince: values.stateProvinceIOR || undefined,
                postalCode: values.postalCodeIOR || undefined,
                phone: values.phoneIOR || undefined,
                fax: values.faxIOR || undefined,
                email: values.emailIOR?.length
                  ? values.emailIOR.join(',')
                  : undefined,
                countryId: values?.countryIOR || undefined,
                taxId: values.taxIdIOR || undefined,
                eori: values.eoriIOR || undefined,
                companyName: values.companyNameIOR || undefined,
              },
              brokerContact: {
                contact: values.contact || undefined,
                address1: values.address1 || undefined,
                address2: values.address2 || undefined,
                city: values.city || undefined,
                stateProvince: values.stateProvince || undefined,
                postalCode: values.postalCode || undefined,
                phone: values.phone || undefined,
                fax: values.fax || undefined,
                email: values.email || undefined,
                countryId: values?.country || undefined,
                taxId: values.taxId || undefined,
                eori: values.eoribroker || undefined,
                companyName: values.companyName || undefined,
              },
              emailIOR: values.emailIOR?.length
                ? values.emailIOR.join(',')
                : undefined,
              greenLightContact: values.greenLightContact?.length
                ? values.greenLightContact.join(',')
                : undefined,
              postShippingContact: values.postShippingContact?.length
                ? values.postShippingContact.join(',')
                : undefined,
              shippingRules: values.shippingRules?.map((ship) => {
                return {
                  shippingConditionId:
                    ship.shippingConditionId?.id || ship?.shippingConditionId,
                  outBoundCourierId:
                    ship?.outBoundCourierId?.id || ship?.outBoundCourierId,
                  inBoundCourierId:
                    ship?.inBoundCourierId?.id || ship?.inBoundCourierId,
                }
              }),
            },
          )

          if (result.status === 200) {
            dispatch(setForm({ state: false, type: '' }))
            dispatch(
              showSuccessReducer(
                `${result?.data?.country?.name} information updated.`,
              ),
            )
            dispatch(setcountryConfigured(result.data.country))
            dispatch(setDropdownValue({}))
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        initialValues,
        /* and other goodies */
      }) => (
        <form
          onSubmit={handleSubmit}
          className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]"
        >
          <div className="pr-4">
            <Heading heading=" Country Details" mb />
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="Country"
                  className="mb-[10px]"
                  Icon={<Country />}
                />
                <FormText
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.name}
                  placeholder={uisettings.editForm?.country?.name || 'Enter'}
                  disabled
                />
              </div>
              <div className="w-full">
                <HemaLabel
                  text="Language"
                  className="mb-[10px]"
                  Icon={<Language />}
                  required
                />
                <FormSelect
                  formikRef={formikRef}
                  options={common?.Inventorylanguages || []}
                  placeholder={
                    uisettings.editForm?.language?.name || 'Single Select'
                  }
                  setFieldValue={setFieldValue}
                  name={'languageId'}
                  value={values?.languageId}
                  crossIcon={values?.languageId ? <CrossIcon /> : ''}
                />
                {errors.languageId && touched.languageId && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.languageId}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Outgoing HS/HTS Code"
                  Icon={<OutGoing />}
                />
                <FormText
                  type="text"
                  name="outgoingHtsCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.outgoingHtsCode}
                  placeholder="Enter"
                  crossIcon={values?.outgoingHtsCode ? <CrossIcon /> : ''}
                />
              </div>
              <div className="w-full">
                <HemaLabel
                  text="Incoming HS/HTS Code"
                  className="mb-[10px]"
                  Icon={<InComing />}
                />
                <FormText
                  type="text"
                  name="incomingHtsCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.incomingHtsCode}
                  placeholder="Enter "
                  crossIcon={values?.incomingHtsCode ? <CrossIcon /> : ''}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-[257.6px]">
                <HemaLabel
                  className="mb-[10px]"
                  text="Ship Through Depot"
                  Icon={<OutGoing />}
                  required
                />
                <FormSelect
                  options={[
                    { id: 0, name: 'No' },
                    ...logistic?.allShippingDeports,
                  ]}
                  placeholder={values?.shippingDepot?.name || 'Single Select'}
                  setFieldValue={setFieldValue}
                  name={'shippingDepotId'}
                  value={values?.shippingDepotId}
                  crossIcon={values?.shippingDepotId ? <CrossIcon /> : ''}
                  dispatch={dispatch}
                />
                {errors.shippingDepotId && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.shippingDepotId}
                  </div>
                )}
              </div>
              <div className="w-[257px]">
                <HemaLabel
                  text="Incoterm"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="incoterm"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.incoterm}
                  placeholder="Enter "
                  crossIcon={values?.incoterm ? <CrossIcon /> : ''}
                />
                {errors.incoterm && touched?.incoterm && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.incoterm}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-[25px]">
              <div className="w-[257px]">
                <HemaLabel
                  text="EORI"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="eorI"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.eorI}
                  placeholder={'Enter '}
                  crossIcon={values?.eorI ? <CrossIcon /> : ''}
                />
                {errors.eorI && touched?.eorI && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.eorI}
                  </div>
                )}
              </div>
            </div>
            <Heading heading=" Communication Plan Details" mb />
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker Contact"
                  Icon={<Broker />}
                />
                <FormText
                  type="text"
                  name="contact"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.contact}
                  placeholder="Enter"
                  crossIcon={values?.contact ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.contact && touched?.contact && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.contact}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="broker company Name"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="companyName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.companyName}
                  placeholder="Enter "
                  crossIcon={values?.companyName ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.companyName && touched?.companyName && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.companyName}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="Broker Email"
                  className="mb-[10px]"
                  Icon={<Email />}
                />
                <FormText
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.email}
                  placeholder="Enter "
                  crossIcon={values?.email ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.email && values.email && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="Broker Telephone"
                  className="mb-[10px]"
                  Icon={<Telephone />}
                  required={values?.shippingDepotId ? true : false}
                />
                <FormText
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.phone}
                  placeholder="Enter "
                  crossIcon={values?.phone ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {/* {errors.phone && values.shippingDepotId && <div className="error text-[red] text-[12px] pt-[2px]">{errors.phone}</div>} */}
              </div>

              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker Fax"
                  Icon={<Fax />}
                />
                <FormText
                  type="text"
                  name="fax"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.fax}
                  placeholder="Enter "
                  crossIcon={values?.fax ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker Address 1"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="address1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.address1}
                  placeholder="Enter"
                  disabled={values?.shippingDepotId ? true : false}
                  crossIcon={values?.address1 ? <CrossIcon /> : ''}
                />
                {/* {errors.address1 && values.shippingDepotId && <div className="error text-[red] text-[12px] pt-[2px]">{errors.address1}</div>} */}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker Address 2"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="address2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.address2}
                  placeholder="Enter "
                  crossIcon={values?.address2 ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker City"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.city}
                  placeholder="Enter "
                  crossIcon={values?.city ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker State"
                  Icon={<Country />}
                />
                <FormText
                  type="text"
                  name="stateProvince"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.stateProvince}
                  placeholder="Enter "
                  crossIcon={values?.stateProvince ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker Country"
                  Icon={<Country />}
                />
                <SingleSelect
                  options={common?.allCountries || []}
                  placeholder="Select Country"
                  setFieldValue={setFieldValue}
                  name={'country'}
                  value={values?.country}
                  disabled={values?.shippingDepotId ? true : false}
                  crossIcon={values?.country ? <CrossIcon /> : ''}
                />
              </div>
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Broker Postal Code"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="postalCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.postalCode}
                  placeholder="Enter "
                  crossIcon={values?.postalCode ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="broker EORI"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="eoribroker"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.eoribroker}
                  placeholder="Enter "
                  crossIcon={values?.eoribroker ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.eoribroker && touched?.eoribroker && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.eoribroker}
                  </div>
                )}
              </div>
              <div className="w-full">
                <HemaLabel
                  text="broker Tax ID"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="taxId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.taxId}
                  placeholder="Enter "
                  crossIcon={values?.taxId ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.taxId && touched?.taxId && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.taxId}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="IOR Contact"
                  className="mb-[10px]"
                  Icon={<IORContact />}
                />

                <FormTags
                  placeholder={'Multi Select'}
                  name={'contactIOR'}
                  setFieldValue={setFieldValue}
                  value={values?.contactIOR}
                  classNames={'h-auto w-auto'}
                  disabled={values?.shippingDepotId ? true : false}
                  formikRef={formikRef}
                />
                {errors.contactIOR && values.contactIOR && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.contactIOR}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="IOR company Name"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="companyNameIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.companyNameIOR}
                  placeholder="Enter "
                  crossIcon={values?.companyNameIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.companyNameIOR && touched?.companyNameIOR && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.companyNameIOR}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR Email"
                  Icon={<Email />}
                />
                {/* <FormText
                  type="text"
                  name="emailIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.emailIOR}
                  placeholder="Enter"
                  crossIcon={values?.emailIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                /> */}
                <FormTags
                  placeholder={'Multi Select'}
                  name={'emailIOR'}
                  setFieldValue={setFieldValue}
                  value={values?.emailIOR}
                  classNames={'h-auto w-auto'}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.emailIOR && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.emailIOR}
                  </div>
                )}
              </div>
              <div className="w-full">
                <HemaLabel
                  text="IOR Telephone"
                  className="mb-[10px]"
                  Icon={<Telephone />}
                />
                <FormText
                  type="text"
                  name="phoneIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.phoneIOR}
                  placeholder="Enter"
                  crossIcon={values?.phoneIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR Address 1"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="address1IOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.address1IOR}
                  placeholder="Enter"
                  crossIcon={values?.address1IOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR Address 2"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="address2IOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.address2IOR}
                  placeholder="Enter "
                  crossIcon={values?.address2IOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR City"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="cityIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.cityIOR}
                  placeholder="Enter "
                  crossIcon={values?.cityIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR State"
                  Icon={<Country />}
                />
                <FormText
                  type="text"
                  name="stateProvinceIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.stateProvinceIOR}
                  placeholder="Enter "
                  crossIcon={values?.stateProvinceIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR Country"
                  Icon={<Country />}
                />
                <SingleSelect
                  options={common?.allCountries || []}
                  placeholder="Select Country"
                  setFieldValue={setFieldValue}
                  name={'countryIOR'}
                  value={values?.countryIOR}
                  disabled={values?.shippingDepotId ? true : false}
                  crossIcon={values?.countryIOR ? <CrossIcon /> : ''}
                />
              </div>
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="IOR Postal Code"
                  Icon={<Location />}
                />
                <FormText
                  type="text"
                  name="postalCodeIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.postalCodeIOR}
                  placeholder="Enter "
                  crossIcon={values?.postalCodeIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  text="IOR EORI"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="eoriIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.eoriIOR}
                  placeholder="Enter "
                  crossIcon={values?.eoriIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.eoriIOR && touched?.eoriIOR && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.eoriIOR}
                  </div>
                )}
              </div>
              <div className="w-full">
                <HemaLabel
                  text="IOR tax ID"
                  className="mb-[10px]"
                  Icon={<IncotermIcon />}
                />
                <FormText
                  type="text"
                  name="taxIdIOR"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  value={values.taxIdIOR}
                  placeholder="Enter "
                  crossIcon={values?.taxIdIOR ? <CrossIcon /> : ''}
                  disabled={values?.shippingDepotId ? true : false}
                />
                {errors.taxIdIOR && touched?.taxIdIOR && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.taxIdIOR}
                  </div>
                )}
              </div>
            </div>
            {/* End */}
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Post Shipping Required"
                  Icon={<PostShipping />}
                  required
                />

                <FormSelect
                  formikRef={formikRef}
                  options={[
                    { name: 'Yes', id: 1 },
                    { name: 'No', id: 0 },
                  ]}
                  placeholder="Single Select"
                  setFieldValue={setFieldValue}
                  name={'IsPostShippingRequired'}
                  value={values?.IsPostShippingRequired}
                  crossIcon={
                    values?.IsPostShippingRequired ? <CrossIcon /> : ''
                  }
                />
                {errors.IsPostShippingRequired &&
                  touched.IsPostShippingRequired && (
                    <div className="error text-[red] text-[12px] pt-[2px]">
                      {errors.IsPostShippingRequired}
                    </div>
                  )}
              </div>
            </div>

            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  isLower
                  className="mb-[10px]"
                  text="Post Shipping Contact(s)"
                  Icon={<PostShipping />}
                  required={values?.IsPostShippingRequired === 1 ? true : false}
                />

                <FormTags
                  placeholder={'Multi Select'}
                  name={'postShippingContact'}
                  setFieldValue={setFieldValue}
                  value={values?.postShippingContact}
                />
                {errors.postShippingContact && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.postShippingContact}
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-3">
              <div className="w-full">
                <HemaLabel
                  className="mb-[10px]"
                  text="Green Light Required"
                  Icon={<Location />}
                  required
                />
                <FormSelect
                  formikRef={formikRef}
                  options={logistic?.allGreenTypeTypes}
                  placeholder={
                    values?.greenLightRequiredType?.name || 'Single Select'
                  }
                  setFieldValue={setFieldValue}
                  name={'greenLightRequiredTypeId'}
                  value={values?.greenLightRequiredTypeId}
                  crossIcon={
                    values?.greenLightRequiredTypeId ? <CrossIcon /> : ''
                  }
                />
                {errors.greenLightRequiredTypeId &&
                  touched.greenLightRequiredTypeId && (
                    <div className="error text-[red] text-[12px] pt-[2px]">
                      {errors.greenLightRequiredTypeId}
                    </div>
                  )}
              </div>
            </div>
            <div className="flex items-center gap-[16px] mb-[25px]">
              <div className="w-full">
                <HemaLabel
                  isLower
                  className="mb-[10px]"
                  text="Green Light Contact(s)"
                  Icon={<IORContact />}
                  required={
                    values?.greenLightRequiredTypeId === 1 ? false : true
                  }
                />

                <FormTags
                  placeholder={'Enter contacts in email format'}
                  name={'greenLightContact'}
                  setFieldValue={setFieldValue}
                  value={values?.greenLightContact}
                />
                {errors.greenLightContact && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.greenLightContact}
                  </div>
                )}
              </div>
            </div>
            <Heading heading="Shipping Rules" mb />
            <div className="mb-[25px]">
              <ShippingRules
                shipping={values.shippingRules}
                curiors={common.allCuriors}
                values={values}
                dispatch={dispatch}
                setFieldValue={setFieldValue}
                name={'shippingRules'}
              />

              {errors.shippingRules && touched.shippingRules && (
                <div className="error text-[red] text-[12px] pt-[2px]">
                  {errors.shippingRules}
                </div>
              )}
            </div>

            <Heading heading="Outbound Assemblies" mb />
            <div className={'flex items-start gap-[16px]'}>
              <div className="w-full">
                <HemaLabel
                  text={'Initial Request Assembly'}
                  Icon={<StudyAlias color="blue" />}
                  required
                  className="mb-[10px]"
                />

                <div className=" gap-[15px] items-center w-full block">
                  <SingleSelect
                    formikRef={formikRef}
                    options={
                      [
                        { id: 'N/A', name: 'N/A' },
                        ...assembly?.allAssemblies?.filter(
                          (data) =>
                            data.assemblyType.name ===
                            'Initial Request Assembly',
                        ),
                      ] || []
                    }
                    placeholder="Select General Assembly"
                    setFieldValue={setFieldValue}
                    name={'initialRequestAssemblyId'}
                    value={values?.initialRequestAssemblyId}
                    crossIcon={
                      values?.initialRequestAssemblyId ? <CrossIcon /> : ''
                    }
                  />
                </div>
                {errors.initialRequestAssemblyId &&
                  touched.initialRequestAssemblyId && (
                    <div className="error text-[red] text-[12px] pt-[2px]">
                      {errors.initialRequestAssemblyId}
                    </div>
                  )}
              </div>
              <div className="w-full">
                <HemaLabel
                  text={'Shipping Assembly'}
                  Icon={<PostShipping />}
                  required
                  className="mb-[10px]"
                />

                <div className=" gap-[15px] items-center w-full block">
                  <SingleSelect
                    formikRef={formikRef}
                    options={
                      assembly?.allAssemblies?.filter(
                        (data) =>
                          data.assemblyType.name === 'Shipping Assembly',
                      ) || []
                    }
                    placeholder="Select Shipping Assembly"
                    setFieldValue={setFieldValue}
                    name={'shippingAssemblyId'}
                    value={values?.shippingAssemblyId}
                    crossIcon={values?.shippingAssemblyId ? <CrossIcon /> : ''}
                  />
                  {errors.shippingAssemblyId && touched.shippingAssemblyId && (
                    <div className="error text-[red] text-[12px] pt-[2px]">
                      {errors.shippingAssemblyId}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {logistic.selectedProtocolDetail?.qc?.status?.id === 3 && (
              <div className="mt-[20px] gap-[15px] items-center w-full block">
                <HemaLabel
                  text={'Change Reason'}
                  Icon={<ChangeReason />}
                  required={true}
                  className={`mb-[10px]`}
                />

                <FormTextarea
                  crossIcon={values?.changeReason ? <CrossIcon /> : ''}
                  placeholder="write edit reason"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={'changeReason'}
                  value={values?.changeReason}
                  setFieldValue={setFieldValue}
                />

                {errors.changeReason && touched.changeReason && (
                  <div className="error text-[red] text-[12px] pt-[2px]">
                    {errors.changeReason}
                  </div>
                )}
              </div>
            )}
            {/* <Alert type="error" /> */}
            <div className="flex gap-[8px] justify-end mt-10 mb-[34px]">
              <Button
                cta={() => {
                  dispatch(setForm({ state: false, type: '' }))
                  dispatch(setDropdownValue({}))
                }}
                type="button"
                text="Cancel"
                bg="bg-white"
                border="border-primary1"
                color="text-primary1"
                Icon={<Cancel />}
              />

              {isSubmitting ? (
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                <Button
                  type="submit"
                  // disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                  text="Save"
                  bg="bg-primary1"
                  color="text-white"
                  Icon={<Confirm />}
                />
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default FormikForm
