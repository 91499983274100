import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { HemaValue, FormText } from '../../utils'
import { RowExpand, RowCollapsed } from '../../HemeIconLibrary'

export const ExpendedDataRows = ({ data }) => {
  return (
    <div className=" w-full py-[10px]  border-b border-[#DEE2E6]">
      <DataTable
        customStyles={{
          table: {
            style: { background: 'transparent !important', border: 'none' },
          },
          head: { style: { display: 'none' } },
          cells: {
            style: {
              // borderBottom:"none",
              // flexGrow: '1 !important',
              // justifyContent: 'left !important',
            },
          },
          rows: {
            style: {
              borderBottom: 'none !important',
              paddingLeft: '50px',
              paddingRight: '100px',
            },
          },
          responsiveWrapper: {
            style: { overflow: 'visible !important' },
          },
        }}
        data={data.items}
        columns={[
          {
            selector: (row, index) => (
              <HemaValue text={`${index + 1}. ${row?.name}`} />
            ),
          },
          {
            selector: (row) => <HemaValue text={row?.itemPerKit} />,
          },
          {
            selector: (row) => <HemaValue text={row?.availableToBuild} />,
          },
        ]}
      />
    </div>
  )
}

const KitBuilder = ({ data, name, setFieldValue, showItemPrice }) => {
  return (
    <div className="w-full border border-solid border-[#DEE2E6] rounded">
      <DataTable
        expandableRowDisabled={(row, index) => {
          return row.id ? false : true
        }}
        expandableIcon={{
          expanded: <RowExpand />,
          collapsed: <RowCollapsed />,
        }}
        columns={[
          {
            name: (
              <HemaValue
                text={'Kit Template Name'}
                className="font-normal text-[#000000]"
              />
            ),

            selector: (row, index) => (
              <>
                <HemaValue text={row.kitAliasName} />
              </>
            ),
          },
          {
            name: (
              <HemaValue
                text={'Quantity Per Kit'}
                className="font-normal text-[#000000]"
              />
            ),

            selector: (row, index) => (
              <>
                <HemaValue text={row?.quantity} />
              </>
            ),
          },
          {
            name: showItemPrice && (
              <HemaValue
                text={'Price'}
                className="font-normal text-[#000000]"
              />
            ),

            selector: (row, index) =>
              showItemPrice ? (
                <>
                  <FormText
                    // value={row?.unitPrice}
                    // type="number"
                    // min="0"
                    // onChange={(e) => {
                    //   setFieldValue(
                    //     name,
                    //     data.map((data1) => {
                    //       if (data1.id === row.id) {
                    //         return {
                    //           ...data1,
                    //           unitPrice: parseFloat(e.target.value),
                    //         }
                    //       } else {
                    //         return data1
                    //       }
                    //     }),
                    //   )
                    // }}
                    value={row?.unitPrice}
                    type="number"
                    min="0" // Adjust min to the smallest decimal value you want to allow
                    step="any" // Allow any numeric or decimal value
                    onChange={(e) => {
                      const inputValue = e.target.value

                      // Validate if the input is a valid number (integer or decimal)
                      if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
                        setFieldValue(
                          name,
                          data.map((data1) => {
                            if (data1.id === row.id) {
                              return {
                                ...data1,
                                unitPrice:
                                  inputValue === ''
                                    ? null
                                    : parseFloat(inputValue),
                              }
                            } else {
                              return data1
                            }
                          }),
                        )
                      }
                    }}
                  />
                </>
              ) : (
                ''
              ),
          },
        ]}
        data={data || []}
        expandableRows
        expandableRowsComponent={ExpendedDataRows}
        customStyles={{
          responsiveWrapper: {
            style: { overflow: 'visible !important' },
          },
        }}
      />
    </div>
  )
}

export default KitBuilder
