/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allEmailTemplates: null,
  allEmailTemplateTypes: null,
  allTestingLabs: null,
  emailTemplatesFilter: {},
  mergeField: [],
};

export const emailTemplates = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    setAllEmailTemplatesAction: (state, action) => {
      state.allEmailTemplates = action.payload;
    },
    addNewEmailTemplates: (state, action) => {
      if (state.allEmailTemplates?.length) {
        state.allEmailTemplates = [action.payload, ...state.allEmailTemplates];
      } else {
        state.allEmailTemplates = [action.payload];
      }
    },
    deleteEmailTemplates: (state, action) => {
      state.allEmailTemplates = state.allEmailTemplates.filter(
        (data) => data.id !== action.payload
      );
    },

    editEmailTemplates: (state, action) => {
      console.log(action);
      state.allEmailTemplates = state.allEmailTemplates.map((data) => {
        if (data.id === action.payload?.id) {
          return action.payload;
        } else {
          return data;
        }
      });
    },
    setAllMergeField: (state, action) => {
      state.mergeField = action.payload;
    },
    deleteEmailTemplatesForItem: (state, action) => {
      console.log(action);
      state.allEmailTemplates = state.allEmailTemplates.map((data) => {
        if (data.id === action.payload.parent) {
          return {
            ...data,
            items: data.items?.filter((item) => item.id !== action.payload.id),
          };
        } else {
          return data;
        }
      });
    },
    setEmailTemplatesFilter: (state, action) => {
      state.emailTemplatesFilter = action.payload;
    },
    setAllEmailTemplatesTypes: (state, action) => {
      state.allEmailTemplateTypes = action.payload;
    },
  },
});

export const {
  setAllEmailTemplatesTypes,
  setAllEmailTemplatesAction,
  deleteEmailTemplates,
  addNewEmailTemplates,
  deleteEmailTemplatesForItem,
  setEmailTemplatesFilter,
  editEmailTemplates,
  setAllMergeField,
} = emailTemplates.actions;

export default emailTemplates.reducer;
