import React from 'react';

export const GreenCheck = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5358_241574)">
        <path
          d="M0.269825 10.3508C-0.099012 9.96891 -0.0883883 9.36028 0.293554 8.99144L2.36826 6.98792C2.7502 6.61909 3.35883 6.62971 3.72766 7.01165L11.0739 14.6189L7.61606 17.9581L0.269825 10.3508Z"
          fill="#ABD673"
        />
        <path
          d="M16.2693 2.29355C16.6381 1.91161 17.2467 1.90099 17.6287 2.26983L19.7034 4.27334C20.0853 4.64218 20.0959 5.25081 19.7271 5.63275L11.0452 14.6231L7.58734 11.2839L16.2693 2.29355Z"
          fill="#ABD673"
        />
      </g>
      <defs>
        <clipPath id="clip0_5358_241574">
          <rect width="19.9969" height="19.9581" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
