import React from 'react';

export const WhiteCross = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3583_115241)">
        <path
          d="M12.4325 3.56548C12.7823 3.91536 12.7823 4.48262 12.4325 4.83249L9.26494 8L12.4325 11.1675C12.7823 11.5174 12.7823 12.0846 12.4325 12.4345C12.0826 12.7844 11.5153 12.7844 11.1654 12.4345L7.99794 9.267L4.83043 12.4345C4.48055 12.7844 3.9133 12.7844 3.56342 12.4345C3.21355 12.0846 3.21355 11.5174 3.56342 11.1675L6.73093 8L3.56342 4.83249C3.21355 4.48262 3.21355 3.91536 3.56342 3.56549C3.9133 3.21561 4.48055 3.21561 4.83043 3.56549L7.99794 6.733L11.1654 3.56549C11.5153 3.21561 12.0826 3.21561 12.4325 3.56548Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3583_115241">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
