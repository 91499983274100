import React from "react";

function UserPurple() {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0059 6.12906C17.0059 8.74172 14.7673 10.8597 12.0059 10.8597C9.24444 10.8597 7.00586 8.74172 7.00586 6.12906C7.00586 3.51641 9.24444 1.39844 12.0059 1.39844C14.7673 1.39844 17.0059 3.51641 17.0059 6.12906Z"
        stroke="url(#paint0_linear_73_13595)"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.24939 21.0641C3.22774 20.3741 2.65083 19.8323 1.96081 19.8539C1.2708 19.8756 0.728973 20.4525 0.750614 21.1425L3.24939 21.0641ZM8.15455 26.5977V25.3477C8.14336 25.3477 8.13217 25.3479 8.12098 25.3482L8.15455 26.5977ZM15.8455 26.5977L15.879 25.3482C15.8678 25.3479 15.8566 25.3477 15.8455 25.3477V26.5977ZM23.2494 21.1425C23.271 20.4525 22.7292 19.8756 22.0392 19.8539C21.3492 19.8323 20.7723 20.3741 20.7506 21.0641L23.2494 21.1425ZM20.7506 20.8914C20.7723 21.5814 21.3492 22.1232 22.0392 22.1016C22.7292 22.0799 23.271 21.503 23.2494 20.813L20.7506 20.8914ZM15.8455 15.3578V16.6078C15.8566 16.6078 15.8678 16.6076 15.879 16.6073L15.8455 15.3578ZM8.15455 15.3578L8.12098 16.6073C8.13217 16.6076 8.14336 16.6078 8.15455 16.6078V15.3578ZM0.750614 20.813C0.728973 21.503 1.2708 22.0799 1.96081 22.1016C2.65083 22.1232 3.22774 21.5814 3.24939 20.8914L0.750614 20.813ZM0.750614 21.1425C0.872177 25.0184 4.26339 27.9527 8.18811 27.8473L8.12098 25.3482C5.441 25.4202 3.3238 23.4369 3.24939 21.0641L0.750614 21.1425ZM8.15455 27.8477H15.8455V25.3477H8.15455V27.8477ZM15.8119 27.8473C19.7366 27.9527 23.1278 25.0184 23.2494 21.1425L20.7506 21.0641C20.6762 23.4369 18.559 25.4202 15.879 25.3482L15.8119 27.8473ZM23.2494 20.813C23.1278 16.9371 19.7366 14.0028 15.8119 14.1082L15.879 16.6073C18.559 16.5354 20.6762 18.5186 20.7506 20.8914L23.2494 20.813ZM15.8455 14.1078H8.15455V16.6078H15.8455V14.1078ZM8.18811 14.1082C4.26339 14.0028 0.872177 16.9371 0.750614 20.813L3.24939 20.8914C3.3238 18.5186 5.441 16.5354 8.12098 16.6073L8.18811 14.1082Z"
        fill="url(#paint1_linear_73_13595)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_73_13595"
          x1="7.00586"
          y1="6.12906"
          x2="17.0059"
          y2="6.12906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_73_13595"
          x1="2"
          y1="20.9779"
          x2="22"
          y2="20.9779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default UserPurple;
