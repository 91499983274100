import React from 'react';
export const Threshold = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1V2.16667H3.33333V4.5C3.33333 5.9834 4.24479 7.28451 5.60417 8C4.24479 8.71549 3.33333 10.0166 3.33333 11.5V13.8333H2V15H14V13.8333H12.6667V11.5C12.6667 10.0166 11.7552 8.71549 10.3958 8C11.7552 7.28451 12.6667 5.9834 12.6667 4.5V2.16667H14V1H2ZM4.66667 2.16667H11.3333V4.5C11.3333 6.11784 9.84896 7.41667 8 7.41667C6.15104 7.41667 4.66667 6.11784 4.66667 4.5V2.16667ZM5.33333 3.33333V4.5C5.33333 5.78971 6.52604 6.83333 8 6.83333C9.47396 6.83333 10.6667 5.78971 10.6667 4.5V3.33333H5.33333ZM8 8.58333C9.84896 8.58333 11.3333 9.88216 11.3333 11.5V13.8333H4.66667V11.5C4.66667 9.88216 6.15104 8.58333 8 8.58333Z"
        fill="url(#paint0_linear_1188_38791)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_38791"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
