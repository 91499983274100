import React from 'react';

export const WhereIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9 16C22.9 19.5346 20.0346 22.4 16.5 22.4M22.9 16C22.9 12.4654 20.0346 9.6 16.5 9.6M22.9 16H24.5M16.5 22.4C12.9654 22.4 10.1 19.5346 10.1 16M16.5 22.4V24M10.1 16C10.1 12.4654 12.9654 9.6 16.5 9.6M10.1 16H8.5M16.5 9.6V8M18.9 16C18.9 17.3255 17.8255 18.4 16.5 18.4C15.1745 18.4 14.1 17.3255 14.1 16C14.1 14.6745 15.1745 13.6 16.5 13.6C17.8255 13.6 18.9 14.6745 18.9 16Z"
        stroke="#775FD5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
