import React, { useRef, useState, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { RequestAcknowledgment } from '../../components/Formik/AllForms/requestAcknowledgment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { setForm, showSuccessReducer, showErrorReducer } from '../../Store/reducers/uiSettings';
import NewShipAddrValidation from '../../components/Formik/allFormValidation/newShipAddrValidation';
import { HemaLabel, FormSelect, Button, FormText, HemaValue, FormSwitch, SingleSelect } from '../../utils';
import {
  FirstName,
  Barcode,
  Location,
  Request,
  CrossIcon,
  Email,
  SiteName,
  LabelDate,
  SendIcon,
  LightBulb,
  AddInKit,
  DeleteKit,
  FormReject,
  FormApprove,
  Binocular,
  LoginEmail,
  InManagement,
} from '../../HemeIconLibrary';
import Heading from '../../pages/logistOrchestra/logisticComponents/heading';
import { ViewKitItem } from '../../components/Formik/AllForms/viewKitItem';
import { createOrderAction, getAcknowledgedetailAction, updateOrderAction, createAddressAction, getAllOrderAction, getkittemplateViewItemAction } from '../../Actions/order';
import { RequestAcknowledgmentView } from '../../components/Formik/AllForms/requestAcknowledgmentView';
import { NewShippingAddress } from '../../components/Formik/AllForms/newShippingAddress';
import LocationPurple from '../../HemeIconLibrary/settingsIcons/locationPurple';
import { HemaDateFormat } from '../../helpers/commonHelper';

const RequestInformaction = forwardRef((props, ref) => {
  const { setUpdatedData, setformName, setFormIcon, setokBtnText, setCTA, setCancelBtnText, setItems, items, setRequestLoader, setFormValidation } = props;
  const [studyReSelect, setStudyReSelect] = useState(false);
  const [siteReSelect, setSiteReSelect] = useState(false);
  const navigate = useNavigate();

  const { uisettings, allItems, sponsers, orders, common } = useSelector((state) => state);

  useEffect(() => {
    if (orders?.activeOrder?.items) {
      setItems(orders?.activeOrder.items);
    }
  }, [orders?.activeOrder]);

  function getFormattedDate(submitDate) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const date = new Date(submitDate);

    const dayOfWeek = daysOfWeek[date.getDay()];
    const month = monthsOfYear[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const period = hour >= 12 ? 'PM' : 'AM';

    const formattedDate = `${dayOfWeek}, ${month} ${ordinalSuffix(day)}, ${year} at ${formatHour(hour)}:${formatMinute(minute)} ${period}`;

    return formattedDate;
  }

  function ordinalSuffix(day) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const specialCases = [11, 12, 13];

    const relevantDigits = day > 9 && day < 20 ? day : day % 10;

    const suffix = suffixes[relevantDigits] || 'th';
    if (specialCases.includes(day)) {
      return day + suffix;
    }
    return day + suffix;
  }

  function formatHour(hour) {
    return hour % 12 || 12; // Convert 0 to 12
  }

  function formatMinute(minute) {
    return minute < 10 ? `0${minute}` : minute;
  }

  const dispatch = useDispatch();
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const itemsData = reorder(items, result.source.index, result.destination.index);

    setItems(itemsData);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const customStyles = {
    rows: {
      style: {
        borderBottom: 'none !important',
      },
    },
  };

  return (
    <div className="w-full px-[16px] pb-[34px] mt-[34px]">
      <Formik
        initialValues={{
          requestorName: orders?.activeOrder?.requesterName || '',
          requestorEmail: orders?.activeOrder?.requesterEmail || '',
          sponsor: orders?.activeOrder?.sponsor?.id || '',
          study: orders?.activeOrder?.study?.id || '',
          siteCode: orders?.activeOrder?.site?.id || '',
          requestedDate: orders?.activeOrder?.requestedDate?.split('T')?.[0] || '',
          neededByDate: orders?.activeOrder?.neededDate?.split('T')?.[0] || '',
          shipper: orders?.activeOrder?.testingLab?.id || '',
          initialRequest: orders.defaultValues?.initialRequest || orders?.activeOrder?.initialRequest,

          isKmcDepotRequired: orders.defaultValues?.isKmcDepotRequired || orders?.activeOrder?.isKmcDepotRequired,

          greenLightNeeded: orders.defaultValues?.greenLightRequired || orders?.activeOrder?.greenLightRequired,
          isPostShippingRequired: orders.defaultValues?.isPostShippingRequired || orders?.activeOrder?.isPostShippingRequired,

          shipToSiteAddress: orders.defaultValues?.shipToSiteAddress || orders?.activeOrder?.shipToSiteAddress,

          items: items,
        }}
        innerRef={ref}
        // enableReinitialize
        validate={(values) => {
          const errors = {};

          if (!values.requestorName) {
            errors.requestorName = 'Required';
          }
          if (values.requestorName && values.requestorName?.length > 100) {
            errors.requestorName = 'Max 100 characters allowed';
          }

          if (!values.requestorEmail) {
            errors.requestorEmail = 'Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.requestorEmail)) {
            errors.requestorEmail = 'Invalid email address';
          }

          if (!values.sponsor) {
            errors.sponsor = 'Required';
          }
          if (!values.study) {
            errors.study = 'Required';
          }
          if (!values.siteCode) {
            errors.siteCode = 'Required';
          }
          if (!values.requestedDate) {
            errors.requestedDate = 'Required';
          }
          if (!values.neededByDate) {
            errors.neededByDate = 'Required';
          }
          if (!values.shipper) {
            errors.shipper = 'Required';
          }
          if (items.length === 0) {
            errors.items = 'select atleast one item from dropdown. if there are no options in dropdown, kindly change the site';
          }

          return errors;
        }}
        onSubmit={async (values) => {
          if (setRequestLoader) {
            setRequestLoader(true);
          }

          if (orders?.activeOrder?.id) {
            const createOrder = await updateOrderAction(orders?.activeOrder?.id, {
              requesterName: values.requestorName,
              requesterEmail: values.requestorEmail,
              requestedDate: values.requestedDate,
              neededDate: values.neededByDate,
              siteId: values?.siteCode || orders.activesite,
              typeId: orders.type,
              initialRequest: values.initialRequest !== undefined && values.initialRequest !== null ? values.initialRequest : orders.defaultValues?.initialRequest,
              testingLabId: values.shipper,
              greenLightNeeded: values.greenLightNeeded !== undefined && values.greenLightNeeded !== null ? values.greenLightNeeded : orders.defaultValues?.greenLightRequired,
              shipToSiteAddress: values.shipToSiteAddress !== undefined && values.shipToSiteAddress !== null ? values.shipToSiteAddress : orders.defaultValues?.shipToSiteAddress,
              isPostShippingRequired:
                values.isPostShippingRequired !== undefined && values.isPostShippingRequired !== null
                  ? values.isPostShippingRequired
                  : orders.defaultValues?.isPostShippingRequired,
              isKmcDepotRequired: values.isKmcDepotRequired !== undefined && values.isKmcDepotRequired !== null ? values.isKmcDepotRequired : orders.defaultValues?.isKmcDepotRequired,
              items: items?.map((item) => {
                return {
                  itemId: parseInt(item?.item?.id) || parseInt(item.itemId),
                  quantity: item.quantity,
                };
              }),
            });

            if (createOrder.status === 200) {
              getAllOrderAction();
              dispatch(showSuccessReducer('order is updated'));
              window.scrollTo(0, 0);
            }
          } else {
            const createOrder = await createOrderAction({
              requesterName: values.requestorName,
              requesterEmail: values.requestorEmail,
              requestedDate: values.requestedDate,
              neededDate: values.neededByDate,
              siteId: values?.siteCode || orders.activesite,
              typeId: orders.type,

              initialRequest: values.initialRequest !== undefined && values.initialRequest !== null ? values.initialRequest : orders.defaultValues?.initialRequest,
              testingLabId: values.shipper,
              greenLightNeeded: values.greenLightNeeded !== undefined && values.greenLightNeeded !== null ? values.greenLightNeeded : orders.defaultValues?.greenLightRequired,
              shipToSiteAddress: values.shipToSiteAddress !== undefined && values.shipToSiteAddress !== null ? values.shipToSiteAddress : orders.defaultValues?.shipToSiteAddress,
              isPostShippingRequired:
                values.isPostShippingRequired !== undefined && values.isPostShippingRequired !== null
                  ? values.isPostShippingRequired
                  : orders.defaultValues?.isPostShippingRequired,
              isKmcDepotRequired: values.isKmcDepotRequired !== undefined && values.isKmcDepotRequired !== null ? values.isKmcDepotRequired : orders.defaultValues?.isKmcDepotRequired,
              items: items?.map((item) => {
                return {
                  itemId: parseInt(item?.item?.id) || parseInt(item.itemId),
                  quantity: item.quantity,
                };
              }),
            });

            if (createOrder.status === 200) {
              getAllOrderAction();
              dispatch(showSuccessReducer('New order created'));
              navigate(`/?order=${createOrder?.data?.id}`);
              window.scrollTo(0, 0);
              // setShowDashboard(true)
            }
          }
          if (setRequestLoader) {
            setRequestLoader(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          validateForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className="flex flex-col justify-between w-full m-auto overflow-auto request-order-detail">
            <div className="pr-4">
              <div className="mb-[21px]">
                <Heading heading="Request Details " />
              </div>
              <div className={`flex items-start  ${orders?.activeOrder?.acknowledgment?.isAccepted ? 'gap-0 mb-[16px]' : 'gap-[16px] mb-3 '}`}>
                <div className={`w-full ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Request Type"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <Request purpleColor />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={orders?.activeOrder?.header.type?.name} />
                  ) : (
                    <FormText
                      type="text"
                      name="request"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={orders.type === 1 ? 'Controlled Request' : 'Uncontrolled Request'}
                      disabled
                    />
                  )}
                </div>

                <div className={`w-full ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center gap-0 w-auto '}`}>
                  <HemaLabel
                    text="Requestor Name"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <FirstName />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={orders?.activeOrder?.requesterName} />
                  ) : (
                    <FormText
                      type="text"
                      name="requestorName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.requestorName}
                      placeholder={'Type name'}
                      crossIcon={values.requestorName ? <CrossIcon /> : ''}
                      disabled={orders?.activeOrder?.requesterName ? (!orders.activeOrder?.header?.isPortalOrder ? false : orders.type === 1 ? true : false) : false}
                    />
                  )}

                  {errors.requestorName && touched.requestorName && <div className="error text-[red] text-[12px] pt-[2px]">{errors.requestorName}</div>}
                </div>
              </div>
              <div className={`flex items-start  ${orders?.activeOrder?.acknowledgment?.isAccepted ? 'gap-0 mb-[16px]' : 'gap-[16px] mb-3'}`}>
                <div className={`w-full ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Requestor Email"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <Email purple />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <div className="min-w-[230px]">
                      <HemaValue className="overflow-hidden whitespace-pre w-52 text-ellipsis" text={orders?.activeOrder?.requesterEmail} />
                    </div>
                  ) : (
                    <FormText
                      type="email"
                      name="requestorEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.requestorEmail}
                      placeholder="Type email"
                      crossIcon={values.requestorEmail ? <CrossIcon /> : ''}
                      disabled={orders?.activeOrder?.requesterEmail ? (!orders.activeOrder?.header?.isPortalOrder ? false : orders.type === 1 ? true : false) : false}
                    />
                  )}
                  {errors.requestorEmail && touched.requestorEmail && <div className="error text-[red] text-[12px] pt-[2px]">{errors.requestorEmail}</div>}
                </div>

                <div className={`w-full ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto'}`}>
                  <HemaLabel
                    text="Sponsor"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <FirstName />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={orders?.activeOrder?.sponsor?.name} />
                  ) : (
                    <>
                      <SingleSelect
                        setFieldValue={setFieldValue}
                        name={'sponsor'}
                        value={values?.sponsor}
                        placeholder={orders?.activeOrder?.sponsor?.name || 'Select Sponsor'}
                        options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}
                        crossIcon={values.sponsor ? <CrossIcon /> : ''}
                        disabled={orders?.activeOrder?.sponsor?.id ? (!orders.activeOrder?.header?.isPortalOrder ? false : orders.type === 1 ? true : false) : false}
                        dispatch={dispatch}
                        onChangeNew={() => {
                          setStudyReSelect(true);
                          setSiteReSelect(true);
                          setFieldValue('study', '');
                          setFieldValue('siteCode', '');
                          setItems([]);
                        }}
                        formikRef={ref}
                      />
                    </>
                  )}
                  {/* it is changed due to onChangeNew implementation */}
                  {!values.sponsor && touched.sponsor && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>}
                </div>
              </div>
              <div className={`flex items-start  ${orders?.activeOrder?.acknowledgment?.isAccepted ? 'gap-0 mb-[16px]' : 'gap-[16px] mb-3'}`}>
                <div className={`w-full ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Study #"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <Barcode purple />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={orders?.activeOrder?.study?.name} />
                  ) : (
                    <SingleSelect
                      options={sponsers?.sponerStudy?.filter((data) => data?.logisticOrchestratorQcApproved) || []}
                      placeholder={studyReSelect ? 'Type name to search and select' : orders?.activeOrder?.study?.name || 'Type name to search and select'}
                      setFieldValue={setFieldValue}
                      name={'study'}
                      value={values?.study}
                      crossIcon={values.study ? <CrossIcon /> : ''}
                      dispatch={dispatch}
                      disabled={
                        orders?.activeOrder?.study?.id ? (!orders.activeOrder?.header?.isPortalOrder ? false : orders.type === 1 ? true : false) : values.sponsor ? false : true
                      }
                      onChangeNew={() => {
                        setSiteReSelect(true);
                        setFieldValue('siteCode', '');
                        setItems([]);
                      }}
                    />
                  )}
                  {/* it is changed due to onchangeNew implementation */}
                  {!values.study && values.sponsor && <div className="error text-[red] text-[12px] pt-[2px]">{'Required'}</div>}
                </div>

                <div className={`w-full ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Site Code"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <SiteName purple />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={orders?.activeOrder?.site?.name} />
                  ) : (
                    <SingleSelect
                      options={sponsers?.allSitesForSponser || []}
                      placeholder={siteReSelect ? 'Type name to search and select' : orders?.activeOrder?.site?.name || 'Type name to search and select'}
                      setFieldValue={setFieldValue}
                      name={'siteCode'}
                      value={values?.siteCode}
                      crossIcon={values.siteCode ? <CrossIcon /> : ''}
                      dispatch={dispatch}
                      onChangeNew={() => {
                        setItems([]);
                      }}
                      disabled={
                        orders?.activeOrder?.site?.id ? (!orders.activeOrder?.header?.isPortalOrder ? false : orders.type === 1 ? true : false) : values.study ? false : true
                      }
                    />
                  )}
                  {!values.siteCode && values.study && <div className="error text-[red] text-[12px] pt-[2px]">{'Required'}</div>}
                </div>
              </div>

              <div className={`flex items-start  ${orders?.activeOrder?.acknowledgment?.isAccepted ? 'gap-0 mb-[16px]' : 'gap-[16px] mb-3'}`}>
                <div className={`w-full order-date-history ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Requested Date"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <LabelDate />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={HemaDateFormat(orders?.activeOrder?.requestedDate)} />
                  ) : (
                    <FormText
                      type="date"
                      name="requestedDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.requestedDate}
                      placeholder={'Select a date'}
                      max={new Date()?.toISOString().split('T')[0]}
                      disabled={orders?.activeOrder?.site?.id ? (!orders.activeOrder?.header?.isPortalOrder ? false : orders.type === 1 ? true : false) : false}
                      crossIcon={values.requestedDate ? <CrossIcon /> : ''}
                      //  min={minNeeded}
                    />
                  )}
                  {errors.requestedDate && touched.requestedDate && <div className="error text-[red] text-[12px] pt-[2px]">{errors.requestedDate}</div>}
                </div>
                <div className={`w-full order-date-history ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Needed By Date"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px] ' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <LabelDate />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre" text={HemaDateFormat(orders?.activeOrder?.neededDate)} />
                  ) : (
                    <FormText
                      type="date"
                      name="neededByDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.neededByDate}
                      placeholder={'Type name to search and select'}
                      min={new Date()?.toISOString().split('T')[0]}
                      //  max={maxNeeded}
                      crossIcon={values.neededByDate ? <CrossIcon /> : ''}
                    />
                  )}
                  {errors.neededByDate && touched.neededByDate && <div className="error text-[red] text-[12px] pt-[2px]">{errors.neededByDate}</div>}
                </div>
              </div>
              <div className={`flex items-start  ${orders?.activeOrder?.acknowledgment?.isAccepted ? 'gap-0 mb-[16px]' : 'gap-[16px] mb-3 max-w-[49.5%]'}`}>
                <div className={`w-full mb-[17px] ${orders?.activeOrder?.acknowledgment?.isAccepted && 'flex items-center w-auto '}`}>
                  <HemaLabel
                    text="Fulfillment Facility"
                    className={orders?.activeOrder?.acknowledgment?.isAccepted ? 'w-[190px]' : 'mb-[10px]'}
                    required={orders?.activeOrder?.acknowledgment?.isAccepted ? false : true}
                    Icon={orders?.activeOrder?.acknowledgment?.isAccepted ? '' : <Barcode />}
                  />
                  {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                    <HemaValue className="min-w-[230px] overflow-hidden text-ellipsis whitespace-pre " text={orders?.activeOrder?.testingLab?.name} />
                  ) : (
                    <SingleSelect
                      options={common.allTestingLabs?.filter((data) => data?.isFulfillmentFacility)}
                      placeholder={values?.shipper ? orders?.activeOrder?.testingLab?.name : 'Type name to search and select'}
                      setFieldValue={setFieldValue}
                      name={'shipper'}
                      value={values?.shipper}
                      crossIcon={values.shipper ? <CrossIcon /> : ''}
                      formikRef={ref}
                    />
                  )}
                  {errors.shipper && touched.shipper && <div className="error text-[red] text-[12px] pt-[2px]">{errors.shipper}</div>}
                </div>
              </div>

              <Heading heading="Order Details" mb />
              {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                <div>
                  <div className="order-detail-table-kR">
                    <DataTable
                      customStyles={customStyles}
                      data={orders?.activeOrder.items?.filter((data) => !data?.assemblyId) || []}
                      columns={[
                        {
                          name: <HemaValue text={'Name'} color="text-[#000000]" />,
                          cell: (row) => {
                            return <HemaValue text={row.item?.name} />;
                          },
                        },
                        {
                          name: <HemaValue text={'Total Quantity'} color="text-[#000000]" />,
                          cell: (row) => {
                            return <HemaValue text={row.quantity} />;
                          },
                        },
                      ]}
                    />
                  </div>
                </div>
              ) : (
                <div className="relative">
                  <div className={values.siteCode ? 'hidden' : 'bg-[#372d2d1a] z-[1] absolute w-full h-full'} />
                  <div className="">
                    <div className="flex items-align py-[11px] border border-solid border-[#DEE2E6] w-full rounded-t pl-[15px] pr-[63px]  justify-between ">
                      <div className="pl-[30px] ">
                        <HemaValue text="Name" color="text-[#000000]" className="text-xs font-[400]" />
                      </div>
                      <div className="flex items-center gap-5 ">
                        <div className="w-[172px]">
                          <HemaValue text="Total Quantity " color="text-[#000000]" className="text-xs font-[400]" />
                        </div>
                        <HemaValue text="Action" color="text-[#000000]" className="text-xs font-[400]" />
                      </div>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((item, index) => {
                              return (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <DataTableRowItems
                                        index={index}
                                        item={item}
                                        setItems={setItems}
                                        allItems={allItems}
                                        items={items}
                                        formName={uisettings.formName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        ViewKitItem={ViewKitItem}
                                        dispatch={dispatch}
                                        setCancelBtnText={setCancelBtnText}
                                        setformName={setformName}
                                        setUpdatedData={setUpdatedData}
                                        setForm={setForm}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  <NewRow
                    addRow={(data) =>
                      setItems([
                        ...items,
                        {
                          id: String(Date.now()),
                          sort: items.length + 1,
                          itemId: data?.itemName,
                          quantity: data.quantity || 1,
                          name: allItems.allItems?.filter((itm) => String(itm.id) === data?.itemName)?.[0]?.name,
                        },
                      ])
                    }
                  />
                </div>
              )}
              {errors.items && touched.items && <div className="error text-[red] text-[12px] pt-[2px]">{errors.items}</div>}
              {orders?.activeOrder?.header?.isPortalOrder && (
                <div className="mt-[16px]">
                  <HemaValue text={`Order Notes/Special Instructions: ${orders?.activeOrder?.note}`} color="text-[#000000]" className="font-normal text-xs mt-[16px]" />
                </div>
              )}

              <div className="mt-[30px] mb-[21px]">
                <Heading heading="Request Options" />
              </div>
              <div className={`${orders?.activeOrder?.acknowledgment?.isAccepted ? 'max-w-auto' : 'max-w-[95%]'} flex items-center justify-between `}>
                {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                  <div>
                    <div className=" w-auto flex items-center mb-[16px] gap-y-4 flex-wrap">
                      <div className={`w-auto flex items-center`}>
                        <HemaLabel text="Initial Request" color="text-[#000000]" className="w-[190px]" />

                        <HemaValue className="min-w-[230px]" text={orders?.activeOrder?.initialRequest ? 'Yes' : 'No'} />
                      </div>
                      <div className={`w-auto flex items-center`}>
                        <HemaLabel text="Green Light Required" color="text-[#000000]" className="w-[190px]" />

                        <HemaValue className="min-w-[230px]" text={orders?.activeOrder?.greenLightNeeded ? 'Yes' : 'No'} />
                      </div>
                      <div className={`w-auto flex items-center`}>
                        <HemaLabel text="KMC Depot Required" color="text-[#000000]" className="w-[190px]" />

                        <HemaValue className="min-w-[230px]" text={orders?.activeOrder?.isKmcDepotRequired ? 'Yes' : 'No'} />
                      </div>

                      <div className={`w-auto flex items-center`}>
                        <HemaLabel text="Post shipping Required" color="text-[#000000]" className="w-[190px]" />

                        <HemaValue className="min-w-[230px]" text={orders?.activeOrder?.isPostShippingRequired ? 'Yes' : 'No'} />
                      </div>
                      <div className={`w-auto flex items-center`}>
                        <HemaLabel text="Ship to Site Address" color="text-[#000000]" className="w-[190px]" />

                        <HemaValue className="min-w-[230px]" text={orders?.activeOrder?.shipToSiteAddress ? 'Yes' : 'No'} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap items-center justify-between w-full gap-[20px] ">
                    <div className="flex items-center gap-[10px]">
                      <HemaLabel text="Initial Request" className="mb-0" Icon={<Request purple />} />

                      <FormSwitch
                        name={'initialRequest'}
                        setFieldValue={setFieldValue}
                        initialValue={orders.defaultValues?.initialRequest || values.initialRequest}
                        disabled={orders.type === 1 ? true : false}
                      />
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <HemaLabel text="green Light Required" className="mb-0" Icon={<LightBulb purple />} />

                      <FormSwitch
                        name={'greenLightNeeded'}
                        setFieldValue={setFieldValue}
                        initialValue={
                          orders.defaultValues?.greenLightRequired || values?.greenLightNeeded
                          //  orders.defaultValues?.greenLightRequired ? true : false
                        }
                        disabled={orders.type === 1 ? true : false}
                      />
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <HemaLabel text="Ship to Site Address" className="mb-0" Icon={<Location purple />} />

                      <FormSwitch
                        name={'shipToSiteAddress'}
                        setFieldValue={(name, e) => {
                          setFieldValue(name, e);
                          if (e && orders.type === 2) {
                            setformName('Complete the new shipping address');
                            setFormIcon(<LocationPurple />);
                            setokBtnText('Save');

                            setCTA(() => async (data) => {
                              const result = await createAddressAction({
                                address1: data.street1,
                                address2: data.street2,
                                city: data.city,
                                stateProvince: data.stateRegion,
                                postalCode: String(data.postalCode),
                                country: common.allCountries.filter((count) => count.id === data.selectCountry)?.[0]?.name || '',
                              });
                              if (result.status === 200) {
                                dispatch(showSuccessReducer('New address added.'));
                                dispatch(
                                  setForm({
                                    state: false,
                                    type: '',
                                  }),
                                );
                              }
                            });
                            NewShippingAddress[5].options = common.allCountries;
                            setUpdatedData(NewShippingAddress);
                            setFormValidation(() => (error, values, store) => {
                              NewShipAddrValidation(error, values, store);
                            });
                            dispatch(
                              setForm({
                                state: true,
                                type: 'add-ship-address',
                              }),
                            );
                          }
                        }}
                        initialValue={orders.defaultValues?.shipToSiteAddress || values.shipToSiteAddress}
                        disabled={orders.type === 1 ? true : false}
                      />
                    </div>

                    <div className="flex items-center gap-[10px]">
                      <HemaLabel text="Post Shipping Notification Required" className="mb-0" Icon={<Email />} />

                      <FormSwitch
                        name={'isPostShippingRequired'}
                        setFieldValue={setFieldValue}
                        initialValue={
                          orders.defaultValues?.isPostShippingRequired || values?.isPostShippingRequired
                          //  orders.defaultValues?.greenLightRequired ? true : false
                        }
                        disabled={orders.type === 1 ? true : false}
                      />
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <HemaLabel text="Depot Required" className="mb-0" Icon={<InManagement w="18" h="18" />} />

                      <FormSwitch
                        name={'isKmcDepotRequired'}
                        setFieldValue={setFieldValue}
                        initialValue={
                          orders.defaultValues?.isKmcDepotRequired || values?.isKmcDepotRequired
                          //  orders.defaultValues?.greenLightRequired ? true : false
                        }
                        disabled={orders.type === 1 ? true : false}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-[30px]">
                <Heading heading="Request Acknowledgment" />
              </div>
              {orders?.activeOrder?.acknowledgment?.isAccepted ? (
                <div className="flex items-center gap-[15px] mt-[20px]">
                  <Button
                    cta={async (e) => {
                      const tempHolder = e.target.innerHTML;
                      e.target.innerHTML = 'Loading ...';
                      setformName('View Acknowledgment');
                      setFormIcon(<FormApprove />);
                      setokBtnText('');
                      setCancelBtnText('Close');
                      const result = await getAcknowledgedetailAction(orders?.activeOrder?.id);
                      e.target.innerHTML = tempHolder;
                      if (result.status === 200) {
                        RequestAcknowledgmentView[0].initialValue = result.data?.to?.map((data) => data.email)?.join(',');
                        RequestAcknowledgmentView[1].initialValue = result.data?.cc?.map((data) => data.email)?.join(',') || '';
                        if (result.data?.cc?.length === 0) {
                          RequestAcknowledgmentView[1].placeholder = '';
                        }
                        if (result.data?.bcc?.length === 0) {
                          RequestAcknowledgmentView[2].placeholder = '';
                        }
                        RequestAcknowledgmentView[2].initialValue = result.data?.bcc?.map((data) => data.email)?.join(',') || '';
                        RequestAcknowledgmentView[3].initialValue = result.data?.subject;
                        RequestAcknowledgmentView[4].initialValue = result.data?.body;
                        RequestAcknowledgmentView[4].rows = '15';

                        setCTA(() => () => {
                          dispatch(
                            setForm({
                              state: false,
                              type: '',
                            }),
                          );
                        });
                        setUpdatedData(RequestAcknowledgmentView);
                        dispatch(
                          setForm({
                            state: true,
                            type: 'view-acknowledge',
                          }),
                        );
                      }
                    }}
                    type="button"
                    text="View Acknowledgment"
                    bg="bg-primary1"
                    color="text-white"
                    Icon={<SendIcon />}
                  />
                  Request Acknowledgment was sent by {orders?.activeOrder?.acknowledgment?.acknowledgmentBy?.name} on{' '}
                  {orders?.activeOrder?.acknowledgment?.sendUtc && getFormattedDate(orders?.activeOrder?.acknowledgment?.sendUtc)}.
                </div>
              ) : (
                common?.permissions?.['Kit Order Process']?.update && (
                  <div className="flex items-center gap-[15px]">
                    <div className="flex items-center gap-[10px] py-[14px] pr-4">
                      <HemaLabel text="Approve" className="mb-0" Icon={<FormApprove />} />

                      <Button
                        type="button"
                        text="Send Acknowledgment"
                        bg="bg-primary1"
                        color="text-white"
                        Icon={<SendIcon />}
                        disabled={
                          items.length !== 0 &&
                          values.shipper &&
                          values.neededByDate &&
                          values.requestedDate &&
                          values.sponsor &&
                          values.study &&
                          values.siteCode &&
                          values.requestorEmail &&
                          values.requestorName &&
                          values.requestorName?.length < 101
                            ? false
                            : true
                        }
                        cta={async (e) => {
                          const tempHolder = e.target.innerHTML;
                          const checkFormValid = await validateForm();
                          const checkItem = items?.filter((item) => item?.quantity > 2147483647);

                          if (checkItem?.length > 0) {
                            dispatch(showErrorReducer('Quantity must be less than 2147483647 for each item.'));
                            window.scrollTo(0, 0);
                            return false;
                          }

                          if (Object.keys(checkFormValid)?.length && !orders?.activeOrder?.acknowledgment?.isAccepted) {
                            dispatch(showErrorReducer('Complete all required fields first.'));
                            window.scrollTo(0, 0);
                            return;
                          }
                          e.target.innerHTML = 'Loading ....';

                          setformName('Send Acknowledgment');
                          setFormIcon(<FormApprove />);
                          setokBtnText('Send');
                          setUpdatedData(RequestAcknowledgment);

                          if (orders?.activeOrder?.id) {
                            const createOrder = await updateOrderAction(orders?.activeOrder?.id, {
                              requesterName: values.requestorName,
                              requesterEmail: values.requestorEmail,
                              requestedDate: values.requestedDate,
                              neededDate: values.neededByDate,
                              siteId: values?.siteCode || orders.activesite,
                              typeId: orders.type,
                              initialRequest:
                                values.initialRequest !== 'undefined' || values.initialRequest !== 'null' ? values.initialRequest : orders.defaultValues?.initialRequest,
                              testingLabId: values.shipper,
                              greenLightNeeded:
                                values.greenLightNeeded !== 'undefined' || values.greenLightNeeded !== 'null' ? values.greenLightNeeded : orders.defaultValues?.greenLightRequired,
                              shipToSiteAddress:
                                values.shipToSiteAddress !== 'undefined' || values.shipToSiteAddress !== 'null'
                                  ? values.shipToSiteAddress
                                  : orders.defaultValues?.shipToSiteAddress,
                              isPostShippingRequired:
                                values.isPostShippingRequired !== 'undefined' || values.isPostShippingRequired !== 'null'
                                  ? values.isPostShippingRequired
                                  : orders.defaultValues?.isPostShippingRequired,
                              isKmcDepotRequired:
                                values.isKmcDepotRequired !== 'undefined' || values.isKmcDepotRequired !== 'null' ? values.isKmcDepotRequired : orders.defaultValues?.isKmcDepotRequired,
                              items: items?.map((item) => {
                                return {
                                  itemId: parseInt(item?.item?.id) || parseInt(item.itemId),
                                  quantity: item.quantity,
                                };
                              }),
                            });

                            e.target.innerHTML = tempHolder;
                            if (createOrder.status === 200) {
                              getAllOrderAction();

                              await getAcknowledgedetailAction(createOrder?.data?.id);

                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'approve-acknowledge',
                                }),
                              );
                            }
                          } else {
                            const createOrder = await createOrderAction({
                              requesterName: values.requestorName,
                              requesterEmail: values.requestorEmail,
                              requestedDate: values.requestedDate,
                              neededDate: values.neededByDate,
                              siteId: values?.siteCode || orders.activesite,
                              typeId: orders.type,
                              initialRequest: values.initialRequest !== undefined && values.initialRequest !== null ? values.initialRequest : orders.defaultValues?.initialRequest,
                              testingLabId: values.shipper,
                              greenLightNeeded:
                                values.greenLightNeeded !== undefined && values.greenLightNeeded !== null ? values.greenLightNeeded : orders.defaultValues?.greenLightRequired,
                              shipToSiteAddress:
                                values.shipToSiteAddress !== undefined && values.shipToSiteAddress !== null ? values.shipToSiteAddress : orders.defaultValues?.shipToSiteAddress,
                              isPostShippingRequired:
                                values.isPostShippingRequired !== undefined && values.isPostShippingRequired !== null
                                  ? values.isPostShippingRequired
                                  : orders.defaultValues?.isPostShippingRequired,
                              isKmcDepotRequired: values.isKmcDepotRequired !== undefined && values.isKmcDepotRequired !== null ? values.isKmcDepotRequired : orders.defaultValues?.isKmcDepotRequired,
                              items: items?.map((item) => {
                                return {
                                  itemId: parseInt(item?.item?.id) || parseInt(item.itemId),
                                  quantity: item.quantity,
                                };
                              }),
                            });
                            if (createOrder.status === 200) {
                              getAllOrderAction();

                              await getAcknowledgedetailAction(createOrder?.data?.id);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'approve-acknowledge',
                                }),
                              );
                              navigate(`/?order=${createOrder.data?.id}`);
                            }
                            e.target.innerHTML = tempHolder;
                          }
                        }}
                      />
                    </div>

                    <div className="flex items-center gap-[10px] py-[14px] pr-4">
                      <HemaLabel text="Reject" className="mb-0" Icon={<FormReject />} />
                      <div>
                        <Button
                          disabled={!orders?.activeOrder?.header?.isPortalOrder}
                          type="button"
                          text="Request more information"
                          bg="bg-primary1"
                          color="text-white"
                          cta={async (e) => {
                            e.target.innerHTML = 'Loading ...';
                            setformName('Request more information');
                            setFormIcon(<FormApprove />);
                            setokBtnText('Reject');

                            await getAcknowledgedetailAction(orders?.activeOrder?.id, 'reject');
                            e.target.innerHTML = 'Request more information';
                            dispatch(
                              setForm({
                                state: true,
                                type: 'reject-acknowledge',
                              }),
                            );
                          }}
                          Icon={<Request color="white" />}
                        />{' '}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
});

export default RequestInformaction;

export const NewRow = ({ addRow }) => {
  const selectRef = useRef();

  const { allItems } = useSelector((state) => state);
  return (
    <Formik
      initialValues={{ sort: '', itemName: '', quantity: '' }}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!values.itemName) {
          errors.itemName = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        addRow(values);
        resetForm({ values: '' });
        selectRef.current.clearSelect();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldTouched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex items-start gap-[16px] border-b-[1px] border-l-[1px] border-r-[1px] border-solid rounded-b-[5px] border-[#DEE2E6] py-2 pl-2 pr-[35px] w-full">
            <div className="flex items-start w-full">
              <Button type="button" className="flex items-center justify-center flex-shrink-0 w-6 h-6 p-0 " cta={() => handleSubmit()} Icon={<AddInKit />} />
              <div className="w-full ml-[15px]">
                <div className=" grow-[2] remove-img-kit-order border border-solid border-[#DEE2E6] rounded-[5px] ">
                  <FormSelect
                    ref={selectRef}
                    placeholder="Item name"
                    className="kit-dropdown-custom custom-dropdown-kit-Details-2"
                    setFieldValue={setFieldValue}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    value={values.itemName}
                    name="itemName"
                    options={allItems.allItems}
                    // crossIcon={values.itemName ? <CrossIcon /> : ''}
                    notRefresh
                  />
                </div>
                {errors.itemName && touched.itemName && <div className="error text-[red] text-[12px] pt-[2px]">{errors.itemName}</div>}
              </div>
            </div>
            <div className="w-[350px] ">
              <div className=" w-[140px] kit-dropdown-custom-number ">
                <FormText
                  placeholder="Type quantity"
                  type="number"
                  name="quantity"
                  value={values.quantity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  min={1}
                  // crossIcon={values.quantity ? <CrossIcon /> : ''}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export const DataTableRowItems = ({
  index,
  item,
  setItems,
  allItems,
  items,
  hideSort,
  formName = '',
  onChange,
  values,
  onBlur,
  setFieldValue,
  dispatch,
  setCancelBtnText,
  setformName,
  setUpdatedData,
  setForm,
}) => {
  return (
    <div className="flex items-center gap-[16px] border-b border-l border-r  border-solid border-[#DEE2E6] py-[6px] pl-[40px] pr-[28px] justify-between">
      {/*{!hideSort && (
        <div className="flex">
          <Button type="button" Icon={<DragDrop />} />
          <div className="self-center">
            <div>
              <HemaValue text={++index} />
            </div>
          </div>
        </div>
      )}*/}
      <div className="grow-[2]  remove-img-kit-order border border-solid border-[#DEE2E6] rounded-[5px]">
        <FormSelect
          className="kit-dropdown-custom custom-dropdown-kit-Details"
          placeholder={item.name || item?.item?.name}
          value={item.itemName || item?.item?.id}
          options={allItems.allItems}
          setFieldValue={(name, data) => {
            setItems(
              items.map((up) => {
                if (up.id === item.id) {
                  return { ...up, itemId: data };
                } else {
                  return up;
                }
              }),
            );
          }}
          name="item-name-kil-builder"
          //  crossIcon={item.name || item?.item?.name ? <CrossIcon /> : ''}
        />
      </div>
      <div className="w-[293px] flex items-center justify-between ">
        <div className="w-[140px] kit-dropdown-custom-number">
          <FormText
            type="number"
            name="quantity"
            value={item.quantity}
            placeholder={'Type quantity'}
            // crossIcon={'' ? <CrossIcon /> : ''}

            min={1}
            onBlur={onBlur}
            onChange={(e) => {
              setItems(
                items.map((up) => {
                  if (up.id === item.id) {
                    return { ...up, quantity: e.target.value };
                  } else {
                    return up;
                  }
                }),
              );
            }}
          />
        </div>

        <div className="flex items-start gap-[16px]">
          <Button
            type="button"
            className="p-0 min-w-[28px] h-7  flex-shrink-0 flex items-center justify-center"
            bg="bg-[#F1F5F7]"
            Icon={<Binocular />}
            // disabled={kitItem?.itemCategory?.id === 1 ? false : true}
            cta={async () => {
              // const kitItem=await
              const res = await getkittemplateViewItemAction(parseInt(item?.itemId || item?.item?.id));
              setformName('View Kit Template');
              if (res?.status === 200) {
                ViewKitItem[1].initialValue = res?.data?.name;
                ViewKitItem[2].initialValue = res?.data?.items;
                setUpdatedData(ViewKitItem);
              } else {
                setUpdatedData([
                  {
                    initialValue: '',
                    name: 'shipment-name',
                    fluid: true,
                    type: 'table',
                    type: 'no-data',
                  },
                ]);
              }
              setCancelBtnText('Close');
              dispatch(
                setForm({
                  state: true,
                  type: 'view-kit-item-order',
                }),
              );
            }}
          />
          <Button
            type="button"
            className="min-w-[28px] h-7 flex-shrink-0 flex items-center justify-center p-0"
            bg="bg-[#F1F5F7]"
            Icon={<DeleteKit width="16" height="16" color="#F87FB1" />}
            cta={() => {
              setItems(items.filter((up) => up.id !== item.id));
            }}
          />
        </div>
      </div>
    </div>
  );
};
