import React from 'react';

export const SiteManage = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72031 12.8664H18.0703M11.9453 5.24121V18.7102M5.57031 8.27082V18.9664C5.57031 19.0493 5.63747 19.1164 5.72031 19.1164H18.4203C18.5032 19.1164 18.5703 19.0493 18.5703 18.9664V8.27082C18.5703 8.21442 18.5387 8.16279 18.4884 8.13718L12.1384 4.90112C12.0956 4.87931 12.045 4.87931 12.0022 4.90112L5.6522 8.13718C5.60195 8.16279 5.57031 8.21442 5.57031 8.27082Z"
        stroke="url(#paint0_linear_4431_182326)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4431_182326"
          x1="5.57031"
          y1="12.0006"
          x2="18.5703"
          y2="12.0006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
