import React from 'react';

export const DeleteKit = ({ width, height, color }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '26'}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.39687 20.8C9.74166 20.8 10.0723 20.663 10.3161 20.4192C10.5599 20.1754 10.6969 19.8448 10.6969 19.5V11.7C10.6969 11.3552 10.5599 11.0246 10.3161 10.7808C10.0723 10.537 9.74166 10.4 9.39687 10.4C9.05209 10.4 8.72143 10.537 8.47764 10.7808C8.23384 11.0246 8.09687 11.3552 8.09687 11.7V19.5C8.09687 19.8448 8.23384 20.1754 8.47764 20.4192C8.72143 20.663 9.05209 20.8 9.39687 20.8ZM22.3969 5.2H17.1969V3.9C17.1969 2.86566 16.786 1.87368 16.0546 1.14228C15.3232 0.410892 14.3312 0 13.2969 0H10.6969C9.66253 0 8.67055 0.410892 7.93916 1.14228C7.20777 1.87368 6.79688 2.86566 6.79688 3.9V5.2H1.59687C1.25209 5.2 0.921433 5.33696 0.677636 5.58076C0.433839 5.82456 0.296875 6.15522 0.296875 6.5C0.296875 6.84478 0.433839 7.17544 0.677636 7.41924C0.921433 7.66304 1.25209 7.8 1.59687 7.8H2.89687V22.1C2.89687 23.1343 3.30777 24.1263 4.03916 24.8577C4.77055 25.5891 5.76253 26 6.79688 26H17.1969C18.2312 26 19.2232 25.5891 19.9546 24.8577C20.686 24.1263 21.0969 23.1343 21.0969 22.1V7.8H22.3969C22.7417 7.8 23.0723 7.66304 23.3161 7.41924C23.5599 7.17544 23.6969 6.84478 23.6969 6.5C23.6969 6.15522 23.5599 5.82456 23.3161 5.58076C23.0723 5.33696 22.7417 5.2 22.3969 5.2ZM9.39687 3.9C9.39687 3.55522 9.53384 3.22456 9.77764 2.98076C10.0214 2.73696 10.3521 2.6 10.6969 2.6H13.2969C13.6417 2.6 13.9723 2.73696 14.2161 2.98076C14.4599 3.22456 14.5969 3.55522 14.5969 3.9V5.2H9.39687V3.9ZM18.4969 22.1C18.4969 22.4448 18.3599 22.7754 18.1161 23.0192C17.8723 23.263 17.5417 23.4 17.1969 23.4H6.79688C6.45209 23.4 6.12143 23.263 5.87764 23.0192C5.63384 22.7754 5.49687 22.4448 5.49687 22.1V7.8H18.4969V22.1ZM14.5969 20.8C14.9417 20.8 15.2723 20.663 15.5161 20.4192C15.7599 20.1754 15.8969 19.8448 15.8969 19.5V11.7C15.8969 11.3552 15.7599 11.0246 15.5161 10.7808C15.2723 10.537 14.9417 10.4 14.5969 10.4C14.2521 10.4 13.9214 10.537 13.6776 10.7808C13.4338 11.0246 13.2969 11.3552 13.2969 11.7V19.5C13.2969 19.8448 13.4338 20.1754 13.6776 20.4192C13.9214 20.663 14.2521 20.8 14.5969 20.8Z"
        fill={color || 'url(#paint0_linear_3002_66097)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3002_66097"
          x1="0.296875"
          y1="13"
          x2="23.6969"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
