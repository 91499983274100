import { post, get, put, remove } from './http';
import { errorHandling } from './error';

const getAllTasks = async (id) => {
  try {
    return await get(`task/record-tasks/${id}`);
  } catch (err) {
    return err?.response?.data;
  }
};

const createNewTask = async (data) => {
  try {
    return await post(`task/tasks`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const updateTask = async (id, data) => {
  try {
    return await put(`task/tasks/${id}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getTaskStatus = async () => {
  try {
    return await get(`task/statuses`);
  } catch (err) {

    return err?.response?.data;
  }
};

const deleteTask = async (id) => {
  try {
    return await remove(`task/tasks/${id}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

export { getAllTasks, createNewTask, updateTask, deleteTask, getTaskStatus };
