import React from 'react';

export const KitTemplate = ({ purple, width }) => {
  return (
    <svg
      width={width || '32'}
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3384 10.8664V11.3664H19.8384H23.6784C24.4583 11.3664 25.0984 12.0065 25.0984 12.7864V24.3064C25.0984 25.0862 24.4583 25.7264 23.6784 25.7264H8.31844C7.53858 25.7264 6.89844 25.0862 6.89844 24.3064V12.7864C6.89844 12.0065 7.53858 11.3664 8.31844 11.3664H12.1584H12.6584V10.8664V8.94637C12.6584 8.16651 13.2986 7.52637 14.0784 7.52637H17.9184C18.6983 7.52637 19.3384 8.16651 19.3384 8.94637V10.8664ZM14.0784 8.44637H13.5784V8.94637V10.8664V11.3664H14.0784H17.9184H18.4184V10.8664V8.94637V8.44637H17.9184H14.0784ZM23.6784 24.8064H24.1784V24.3064V12.7864V12.2864H23.6784H8.31844H7.81844V12.7864V24.3064V24.8064H8.31844H23.6784Z"
        fill={purple ? 'url(#paint0_linear_6029_158421)' : '#414753'}
        stroke={purple ? 'url(#paint1_linear_6029_158421)' : '#414753'}
      />
      <path
        d="M16.9602 14.7061H15.0402V17.5861H12.1602V19.5061H15.0402V22.3861H16.9602V19.5061H19.8402V17.5861H16.9602V14.7061Z"
        fill={purple ? 'url(#paint2_linear_6029_158421)' : '#414753'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_6029_158421"
          x1="8.63567"
          y1="19.1087"
          x2="23.5"
          y2="19.3456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6029_158421"
          x1="8.63567"
          y1="19.1087"
          x2="23.5"
          y2="19.3456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6029_158421"
          x1="13.055"
          y1="19.539"
          x2="19.0008"
          y2="19.6338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
