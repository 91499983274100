import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { HemaValue } from '..';

const ViewTable = (props) => {
  const { allItems, heading } = props;
  const [filteredData, setfilteredData] = useState();

  useEffect(() => {
    setfilteredData(allItems);
  }, [allItems]);

  return (
    <div className="w-full border border-[#DEE2E6] rounded-[4px]">
      {heading && (
        <HemaValue
          text={'Package Contents'}
          color="text-[#605DAF]"
          className="w-full font-bold px-[16px] py-[10px] text-[14px] leading-[24px] border-b border-solid border-[#DEE2E6]"
        />
      )}
      <DataTable
        columns={[
          {
            name: <HemaValue text={'Item Name'} color="text-[#000000]" className="font-normal text-xs leading-[18px]" />,
            // sortable: true,
            sortId: 'item.name',
            selector: (row, index) => {
              return (
                <div className="flex-shrink-0 flex items-center gap-[10px] check_box">
                  <HemaValue text={row?.name} className="font-medium text-xs leading-[18px]" />
                </div>
              );
            },
          },

          {
            name: <HemaValue text={'Lot Number'} color="text-[#000000]" className="font-normal text-xs leading-[18px]" />,
            // sortable: true,
            selector: (row) => <HemaValue text={row?.lotNumber} className="font-medium text-xs leading-[18px]" />,
            sortId: 'lotNumber',
          },
        ]}
        data={filteredData || []}
      />
    </div>
  );
};

export default ViewTable;
