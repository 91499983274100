import { getAllTypes, getAllCategories, getAllinventorylangs, getAllFormats, droptownItemsForAssembly, itemCategoryFormat, getInventoryLanguages } from '../Services/common';
import {
  setAllCategoriesReducer,
  setAllTypesReducer,
  setAllInventoryLangsReducer,
  setAllAllFormatsReducer,
  droptownItemsForAssemblyRedcuer,
  itemCategoryFormatReducer,
  setInventoryLangsReducer,
} from '../Store/reducers/common';
import { store } from '../Store';
import { caseInsensitiveSort } from '../helpers/commonHelper';
const getTypesAction = async (data) => {
  const result = await getAllTypes();
  if (result?.data) {
    store.dispatch(setAllTypesReducer(result.data?.sort(caseInsensitiveSort)));
  }
};

const getAllItemCategoriesAction = async (data) => {
  const result = await getAllCategories(data);
  if (result.status === 200) {
    store.dispatch(setAllCategoriesReducer(result?.data?.sort(caseInsensitiveSort)));
  }
};

const getAllInventoryLanguages = async (data) => {
  const result = await getInventoryLanguages(data);
  if (result.status === 200) {
    store.dispatch(setAllInventoryLangsReducer(result?.data?.sort(caseInsensitiveSort)));
  }
};

const getAllFormatsActions = async () => {
  const result = await getAllFormats();
  store.dispatch(setAllAllFormatsReducer(result?.data?.sort(caseInsensitiveSort)));
};

const droptownItemsForAssemblyAction = async () => {
  const result = await droptownItemsForAssembly();
  store.dispatch(droptownItemsForAssemblyRedcuer(result?.data?.sort(caseInsensitiveSort)));
};

const itemCategoryFormatAction = async () => {
  const result = await itemCategoryFormat();
  store.dispatch(itemCategoryFormatReducer(result?.data?.sort(caseInsensitiveSort)));
};
const getInventoryLanguagesAction = async (data) => {
  const result = await getInventoryLanguages(data);
  store.dispatch(setInventoryLangsReducer(result?.data?.sort(caseInsensitiveSort)));
};

export {
  itemCategoryFormatAction,
  droptownItemsForAssemblyAction,
  getTypesAction,
  getAllItemCategoriesAction,
  getAllInventoryLanguages,
  getAllFormatsActions,
  getInventoryLanguagesAction,
};
