import React from "react";
export const Import = () => {
  return (

<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.996 17.5777C12.7858 17.5777 12.5756 17.5026 12.4104 17.3487L6.70041 12.1455C6.53837 11.9875 6.44409 11.7727 6.43749 11.5465C6.43088 11.3203 6.51247 11.1004 6.66501 10.9332C6.81756 10.7661 7.02911 10.6648 7.25498 10.6507C7.48085 10.6366 7.70334 10.7109 7.87545 10.8578L12.996 15.528L18.1204 10.8578C18.477 10.535 19.0251 10.5613 19.3518 10.9142C19.6746 11.2708 19.6483 11.8189 19.2954 12.1455L13.5817 17.3525C13.4165 17.5026 13.2063 17.5777 12.996 17.5777Z" fill="url(#paint0_linear_3804_132564)"/>
<path d="M12.996 17.5777C12.5155 17.5777 12.1252 17.1864 12.1252 16.7059V2.22252C12.1252 1.74199 12.5156 1.35156 12.9961 1.35156C13.4767 1.35156 13.8671 1.74199 13.8671 2.22252V16.7059C13.8671 17.1864 13.4766 17.5777 12.996 17.5777Z" fill="url(#paint1_linear_3804_132564)"/>
<path d="M24.1253 22.664H1.87095C1.39043 22.664 1 22.2736 1 21.7931V13.3614C1 12.8808 1.39043 12.4904 1.87095 12.4904C2.35148 12.4904 2.74191 12.8808 2.74191 13.3614V20.9221H23.2581V13.3614C23.2581 12.8808 23.6485 12.4904 24.129 12.4904C24.6096 12.4904 25 12.8808 25 13.3614V21.7931C24.9983 22.0242 24.9055 22.2453 24.7417 22.4084C24.5779 22.5715 24.3564 22.6633 24.1253 22.664Z" fill="url(#paint2_linear_3804_132564)"/>
<path d="M12.996 17.5777C12.7858 17.5777 12.5756 17.5026 12.4104 17.3487L6.70041 12.1455C6.53837 11.9875 6.44409 11.7727 6.43749 11.5465C6.43088 11.3203 6.51247 11.1004 6.66501 10.9332C6.81756 10.7661 7.02911 10.6648 7.25498 10.6507C7.48085 10.6366 7.70334 10.7109 7.87545 10.8578L12.996 15.528L18.1204 10.8578C18.477 10.535 19.0251 10.5613 19.3518 10.9142C19.6746 11.2708 19.6483 11.8189 19.2954 12.1455L13.5817 17.3525C13.4165 17.5026 13.2063 17.5777 12.996 17.5777ZM12.996 17.5777C12.5155 17.5777 12.1252 17.1864 12.1252 16.7059V2.22252C12.1252 1.74199 12.5156 1.35156 12.9961 1.35156C13.4767 1.35156 13.8671 1.74199 13.8671 2.22252V16.7059C13.8671 17.1864 13.4766 17.5777 12.996 17.5777ZM24.1253 22.664H1.87095C1.39043 22.664 1 22.2736 1 21.7931V13.3614C1 12.8808 1.39043 12.4904 1.87095 12.4904C2.35148 12.4904 2.74191 12.8808 2.74191 13.3614V20.9221H23.2581V13.3614C23.2581 12.8808 23.6485 12.4904 24.129 12.4904C24.6096 12.4904 25 12.8808 25 13.3614V21.7931C24.9983 22.0242 24.9055 22.2453 24.7417 22.4084C24.5779 22.5715 24.3564 22.6633 24.1253 22.664Z" stroke="url(#paint3_linear_3804_132564)"/>
<defs>
<linearGradient id="paint0_linear_3804_132564" x1="1" y1="12.0078" x2="25" y2="12.0078" gradientUnits="userSpaceOnUse">
<stop stop-color="#775FD5"/>
<stop offset="1" stop-color="#F03A85"/>
</linearGradient>
<linearGradient id="paint1_linear_3804_132564" x1="1" y1="12.0078" x2="25" y2="12.0078" gradientUnits="userSpaceOnUse">
<stop stop-color="#775FD5"/>
<stop offset="1" stop-color="#F03A85"/>
</linearGradient>
<linearGradient id="paint2_linear_3804_132564" x1="1" y1="12.0078" x2="25" y2="12.0078" gradientUnits="userSpaceOnUse">
<stop stop-color="#775FD5"/>
<stop offset="1" stop-color="#F03A85"/>
</linearGradient>
<linearGradient id="paint3_linear_3804_132564" x1="1" y1="12.0078" x2="25" y2="12.0078" gradientUnits="userSpaceOnUse">
<stop stop-color="#775FD5"/>
<stop offset="1" stop-color="#F03A85"/>
</linearGradient>
</defs>
</svg>

  );
};
