import React from 'react';
export const PostShipping = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22727 3.90918C2.55064 3.90918 2 4.45982 2 5.13645V9.77282C2 10.4495 2.55064 11.0001 3.22727 11.0001H3.42223C3.60206 11.6269 4.18189 12.091 4.86364 12.091C5.54538 12.091 6.12521 11.6269 6.30504 11.0001H8.68182C8.90764 11.0001 9.09091 10.8171 9.09091 10.591V5.13645C9.09091 4.45982 8.54027 3.90918 7.86364 3.90918H3.22727ZM9.63636 5.00009V10.591C9.63636 11.4145 10.3128 12.091 11.1364 12.091C11.8181 12.091 12.3979 11.6269 12.5778 11.0001H12.7727C13.4505 11.0001 14 10.4505 14 9.77282V8.03738C14 7.86556 13.9638 7.69561 13.894 7.5388L13.0897 5.72931C12.8928 5.28586 12.4533 5.00009 11.9684 5.00009H9.63636ZM10.7273 5.81827H11.9684C12.1301 5.81827 12.2763 5.91335 12.3418 6.06117L12.7903 7.07111C12.8705 7.25138 12.7387 7.45463 12.5415 7.45463H10.7273C10.5767 7.45463 10.4545 7.33245 10.4545 7.18191V6.091C10.4545 5.94045 10.5767 5.81827 10.7273 5.81827ZM4.86364 9.90918C5.24447 9.90918 5.54545 10.2102 5.54545 10.591C5.54545 10.9718 5.24447 11.2728 4.86364 11.2728C4.4828 11.2728 4.18182 10.9718 4.18182 10.591C4.18182 10.2102 4.4828 9.90918 4.86364 9.90918ZM11.1364 9.90918C11.5172 9.90918 11.8182 10.2102 11.8182 10.591C11.8182 10.9718 11.5172 11.2728 11.1364 11.2728C10.7555 11.2728 10.4545 10.9718 10.4545 10.591C10.4545 10.2102 10.7555 9.90918 11.1364 9.90918Z"
        fill={color || 'url(#paint0_linear_2850_42575)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2850_42575"
          x1="2"
          y1="8.00008"
          x2="14"
          y2="8.00008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
