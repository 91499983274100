import { LabelQuantity } from "../../../HemeIconLibrary";

export const KitAssemblyPreparation = [
  {
    name: "heading",
    label: "",
    initialValue: "Kit Details",
    fluid: true,
    type: "heading-only",
  },
  {
    label: "Kit Name",
    initialValue: "Screening Kit",
    name: "name",
    fluid: true,
    type: "table",
  },
  // {
  //   name: 'qty',
  //   label: 'Assembly Name',
  //   // Icon: <LabelQuantity />,
  //   initialValue: '',
  //   fluid: true,
  //   type: 'table',
  // },
  // {
  //   name: 'ready',
  //   label: 'Testing Lab',
  //   // Icon: <LabelQuantity />,
  //   initialValue: '',
  //   fluid: true,
  //   type: 'table',
  // },
  // {
  //   name: 'build-qty',
  //   label: 'Inbound Condition',
  //   type: 'table',
  //   initialValue: '',
  //   fluid: true,
  // },
  {
    name: "build-qty",
    type: "kitpreparationTable",
    initialValue: "",
    fluid: true,
  },
];
export const ShippmentPreparation = [
  // {
  //   name: 'heading',
  //   label: '',
  //   initialValue: 'Shipment Details',
  //   fluid: true,
  //   type: 'heading-only',
  //   hidden:true
  // },
  {
    label: "Shipment Name",
    initialValue: "",
    name: "name",
    fluid: true,
    type: "table",
  },
  {
    name: "heading",
    label: "",
    initialValue: "Details",
    fluid: true,
    type: "heading-only",
  },

  {
    name: "build-qty",
    type: "kitpreparationTable",
    initialValue: "",
    fluid: true,
  },
];
export const shippingdocs = [
  {
    id: 1,
    item: {
      name: "Airway Bill",
    },
    quantity: 1,
    status: {
      id: 1,
      name: "Not Ready",
    },
    buildType: "awb",
  },
  {
    id: 2,
    item: {
      name: "Pro Forma Invoice",
    },

    quantity: 1,
    status: {
      id: 1,
      name: "Not Ready",
    },
    buildType: "pro-forma",
  },

  {
    id: 3,
    item: {
      name: "Shipment Manifest",
    },

    quantity: 1,
    status: {
      id: 1,
      name: "Not Ready",
    },
    buildType: "shipment-menifest",
  },
];
