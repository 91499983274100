const NewShipAddrValidation = (errors, values, store) => {
  if (values?.street1?.length > 100) {
    errors.street1 = 'Max 100 characters allowed';
  }
  if (values?.street2?.length > 100) {
    errors.street2 = 'Max 100 characters allowed';
  }
  if (values?.city?.length > 100) {
    errors.city = 'Max 100 characters allowed';
  }
  if (values?.stateRegion?.length > 100) {
    errors.stateRegion = 'Max 100 characters allowed';
  }
  if (values?.postalCode?.toString()?.length > 100) {
    errors.postalCode = 'Max 100 characters allowed';
  }
  return errors;
};

export default NewShipAddrValidation;
