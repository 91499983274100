import React from 'react';
export const ExpiryNotification = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2C7.5032 2 7.1 2.4032 7.1 2.9V3.31719C5.54791 3.71726 4.4 5.12288 4.4 6.8V10.4L3.2 11.6V12.2H6.96172C6.85607 12.3823 6.80029 12.5893 6.8 12.8C6.8 13.1183 6.92643 13.4235 7.15147 13.6485C7.37652 13.8736 7.68174 14 8 14C8.31826 14 8.62348 13.8736 8.84853 13.6485C9.07357 13.4235 9.2 13.1183 9.2 12.8C9.19936 12.5892 9.14317 12.3822 9.03711 12.2H12.8V11.6L11.6 10.4V6.8C11.6 5.12288 10.4521 3.71726 8.9 3.31719V2.9C8.9 2.4032 8.4968 2 8 2ZM4.34492 2.04922C2.92112 3.14602 2 4.8632 2 6.8H3.2C3.2 5.2508 3.93737 3.87681 5.07617 2.99961L4.34492 2.04922ZM11.6551 2.04922L10.9238 2.99961C12.0626 3.87681 12.8 5.2508 12.8 6.8H14C14 4.8632 13.0789 3.14602 11.6551 2.04922ZM8 4.4C9.3236 4.4 10.4 5.4764 10.4 6.8V10.4V10.8969L10.5031 11H5.49688L5.6 10.8969V10.4V6.8C5.6 5.4764 6.6764 4.4 8 4.4Z"
        fill="url(#paint0_linear_1188_38784)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_38784"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
