import React from 'react';

export const CloseLock = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5039 12L16.5039 5.5C16.5039 3.01472 14.4892 1 12.0039 1V1C9.51865 1 7.50395 3.0147 7.50393 5.49996L7.50391 12"
        stroke="#2C2C2C"
        stroke-width="2"
      />
      <rect
        x="3.03906"
        y="13"
        width="17.926"
        height="11"
        rx="2"
        stroke="#2C2C2C"
        stroke-width="2"
      />
      <circle cx="12.0039" cy="18.5" r="1.75" fill="#2C2C2C" />
    </svg>
  );
};
