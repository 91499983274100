import React, { useState, useEffect, forwardRef } from "react";

import { Tab, Tabs, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { NavDropdown, DropdownButton, Dropdown } from "react-bootstrap";
import { Button, HemaValue } from "../../../utils";
import {
  ShippmentPreparation,
  shippingdocs,
} from "../../../components/Formik/AllForms/kitAssemblyPreparation";
import { setForm, editFormReducer } from "../../../Store/reducers/uiSettings";
import { KitShippingReqCol } from "../../../components/Tables/shippingKitRequestCol";
import { KitPackagingCol } from "../../../components/Tables/pakagingKitCol";
import { DeleteRequestPackage } from "../../../components/Formik/AllForms/deleteRequestPackage";
import {
  RemoveShippingItems,
  DeleteShippingItems,
} from "../../../components/Formik/AllForms/ScreeningShipping";
import {
  viewPackageItems,
  UnloackPackage,
} from "../../../components/Formik/AllForms/viewPackageInformation";

import {
  GreenLightTab,
  QualityControlTab,
  CheckReady,
  DeleteColor,
  Add,
  Reject,
  CreateItemPurple,
  DotsIcon,
  Binocular,
  RemoveIcon,
  DeleteKit,
  RemoveItems,
  Confirm,
  LockOpen,
  BuildingTab,
  InManagement,
} from "../../../HemeIconLibrary";
import {
  getShipmentDetailAction,
  getGreenLightAction,
  getQualityControlItemAction,
  deletePackageAction,
  deleteShipmentAction,
  unAssignedItemsFromShuiomentAction,
  unlockPackgeAction,
  unlockShippingAction,
  getAllShippingStatsAction,
  getAllbuildPreparationAction,
} from "../../../Actions/order";
import GreenLight from "./greenLight";
import QualityControl from "./qualityControl";

import PostShipping from "./postShipping";
import KMC from "./kmc";
import {
  setFormCloseReducer,
  setFormLoaderReducer,
  showSuccessReducer,
  showErrorReducer,
} from "../../../Store/reducers/uiSettings";
import { setBuildTemplate } from "../../../Store/reducers/uiSettings";
import { setAssemblyForStagingBuild } from "../../../Store/reducers/orders";
const Index = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { common } = useSelector((state) => state);
  const [btnLaoder, setbtnLoader] = useState(false);
  const {
    subTab,
    setsubTab,
    setformName,
    setCTA,
    setFormIcon,
    setUpdatedData,
    setokBtnText,
    setCancelBtnText,
    setokBtnIcon,
    orders,
    setPayloadQc,
    payloadQc,
  } = props;

  useEffect(() => {
    if (orders?.activeShipment?.id) {
      getGreenLightAction(orders?.activeOrder?.id, orders?.activeShipment?.id);
    }
  }, [orders?.activeShipment?.id]);

  const qualityControlCTA = (row) => {
    setformName("Quality Control");
    dispatch(editFormReducer(row));
    setokBtnText("Mark QC");
    setCancelBtnText("Cancel");
    dispatch(
      setForm({
        state: true,
        type: "mark-qc",
      }),
    );
  };

  return (
    <div className="pb-[16px]">
      <DataTable
        className="border-y-[1px] border-solid border-[#DEE2E6]"
        data={orders.allShipping || []}
        conditionalRowStyles={[
          {
            when: (row) => row?.id === orders.activeShipment?.id,
            style: {
              backgroundColor: "#ececec",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
        ]}
        columns={[
          ...KitShippingReqCol,
          {
            name: "Actions",
            cell: (row) => {
              return (
                <div className="flex-grow four">
                  <div className="flex justify-center gap-[5px] meta">
                    <Button
                      Icon={
                        btnLaoder === row.id ? (
                          <Spinner variant="primary" size="sm" />
                        ) : (
                          <Binocular />
                        )
                      }
                      color="text-white"
                      bg="bg-bgActionDots"
                      cta={async () => {
                        setbtnLoader(row.id);
                        await getShipmentDetailAction(
                          orders.activeOrder?.id,
                          row.id,
                        );
                        setbtnLoader(false);
                        setsubTab("Packaging");
                        getQualityControlItemAction(
                          orders.activeOrder?.id,
                          row.id,
                        );
                      }}
                    />
                    {common?.permissions?.["Kit Order Process"]?.update && (
                      <DropdownButton
                        title={
                          <Button
                            Icon={<DotsIcon />}
                            color="text-white"
                            bg="bg-bgActionDots"
                          />
                        }
                        className="hema-ellipse-dropdown"
                        // id="navbarScrollingDropdown"
                      >
                        <Dropdown.Item
                          onClick={async () => {
                            //await getdynamicdocsAction(orders.activeOrder?.id, row.id)
                            await getShipmentDetailAction(
                              orders.activeOrder?.id,
                              row.id,
                            );
                            setbtnLoader(false);
                            setsubTab("Packaging");
                            getQualityControlItemAction(
                              orders.activeOrder?.id,
                              row.id,
                            );
                            setformName("Shipment Preparation");
                            dispatch(editFormReducer(row));
                            setFormIcon(<CreateItemPurple />);
                            setokBtnText("Confirm");
                            setCancelBtnText("Cancel");
                            dispatch(setAssemblyForStagingBuild(shippingdocs));

                            ShippmentPreparation[0].initialValue = row?.name;

                            setUpdatedData(ShippmentPreparation);
                            dispatch(
                              setForm({
                                state: true,
                                type: "shipping-assembly-preparation",
                              }),
                            );
                            setCTA(() => async () => {
                              dispatch(
                                setForm({
                                  state: false,
                                  type: "",
                                }),
                              );
                            });
                          }}
                          disabled={
                            row?.isAllShippingDocsGenerated ||
                            !(
                              row.unassignedItemCount === 0 &&
                              row.unlockedPackageCount === 0
                            )
                              ? true
                              : false
                          }
                          className={
                            row?.isAllShippingDocsGenerated ||
                            !(
                              row.unassignedItemCount === 0 &&
                              row.unlockedPackageCount === 0
                            )
                              ? "opacity-[.4] w-full"
                              : "opacity-[1] w-full"
                          }
                        >
                          <div className="flex gap-[10px] items-center ">
                            <HemaValue text="Generate Shipping Documents" />
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={async () => {
                            setformName("Unlock Shipment");
                            setFormIcon(<LockOpen />);
                            setokBtnText("Confirm");
                            setokBtnIcon(<Confirm />);
                            setCancelBtnText("Cancel");
                            UnloackPackage[0].initialValue = "Shipment Details";
                            UnloackPackage[1].initialValue = row?.name;
                            UnloackPackage[4].label = `Please confirm that you want to unlock this shipment. Unlocking it will require the regeneration of its shipping documents and resubmission of the green light request if it is required.`;
                            UnloackPackage[4].fluid = true;
                            UnloackPackage[4].type = "";
                            setUpdatedData(
                              UnloackPackage.filter(
                                (data) => data.label !== "Package Number",
                              ),
                            );
                            dispatch(
                              setForm({
                                state: true,
                                type: "unlock Shioment",
                              }),
                            );
                            setCTA(() => async () => {
                              dispatch(setFormLoaderReducer(true));

                              const resp = await unlockShippingAction(
                                orders.activeOrder?.id,

                                row?.id,
                              );
                              if (resp?.status === 200) {
                                dispatch(setFormLoaderReducer(false));
                                dispatch(setFormCloseReducer());
                                dispatch(
                                  showSuccessReducer(
                                    `${row?.name} is successfully unlocked.`,
                                  ),
                                );
                                getShipmentDetailAction(
                                  orders.activeOrder?.id,
                                  orders.activeShipment?.id,
                                );
                                getAllShippingStatsAction(
                                  orders.activeOrder?.id,
                                );
                              }
                            });
                          }}
                          disabled={row?.isLocked ? false : true}
                          className={
                            row?.isLocked
                              ? "opacity-[1] w-full"
                              : "opacity-[.4] w-full"
                          }
                        >
                          <div className="">
                            <HemaValue text="Unlock Shipment" />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={async () => {
                            await getShipmentDetailAction(
                              orders.activeOrder?.id,
                              row.id,
                              "edit",
                            );

                            setformName("Remove Items");
                            setFormIcon(<RemoveItems />);
                            setokBtnText("Remove");
                            setokBtnIcon(<RemoveIcon />);
                            setCancelBtnText("Cancel");
                            RemoveShippingItems[1].initialValue = row?.name;
                            RemoveShippingItems[3].initialValue = "";

                            setUpdatedData(RemoveShippingItems);
                            dispatch(
                              setForm({
                                state: true,
                                type: "remove-shipping-items",
                              }),
                            );
                            setCTA(() => async (data) => {
                              if (data?.recordItemId?.length) {
                                dispatch(setFormLoaderReducer(true));
                                const resp =
                                  await unAssignedItemsFromShuiomentAction(
                                    orders?.activeOrder?.id,
                                    row?.id,
                                    data?.recordItemId,
                                  );

                                if (resp?.status === 200) {
                                  getAllbuildPreparationAction(
                                    "building",
                                    orders.activeOrder?.id,
                                  );
                                  getAllbuildPreparationAction(
                                    "staging",
                                    orders.activeOrder?.id,
                                  );
                                  dispatch(setFormLoaderReducer(false));
                                  dispatch(setFormCloseReducer());
                                  dispatch(
                                    showSuccessReducer(
                                      `items removed from ${row?.name}`,
                                    ),
                                  );
                                }
                              } else {
                                dispatch(
                                  showErrorReducer(
                                    `Please select an item first from list.`,
                                  ),
                                );
                              }
                            });
                          }}
                          disabled={row?.isLocked ? true : false}
                          className={
                            row?.isLocked
                              ? "opacity-[.4] w-full"
                              : "opacity-[1] w-full"
                          }
                        >
                          <div className="">
                            <HemaValue text="Remove Items" />
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={async () => {
                            setformName("Delete Shipment");
                            setFormIcon(<DeleteKit />);
                            setokBtnText("Confirm");
                            setCancelBtnText("Cancel");
                            DeleteShippingItems[1].initialValue = row?.name;
                            DeleteShippingItems[2].initialValue = "";
                            setUpdatedData(DeleteShippingItems);
                            dispatch(
                              setForm({
                                state: true,
                                type: "delete-shipping-items",
                              }),
                            );
                            setCTA(() => async (data) => {
                              dispatch(setFormLoaderReducer(true));
                              const resp = await deleteShipmentAction(
                                orders?.activeOrder?.id,
                                row?.id,
                                data?.change_reason,
                              );

                              if (resp?.status === 200) {
                                getAllbuildPreparationAction(
                                  "building",
                                  orders.activeOrder?.id,
                                );
                                getAllbuildPreparationAction(
                                  "staging",
                                  orders.activeOrder?.id,
                                );
                                dispatch(setFormLoaderReducer(false));
                                dispatch(setFormCloseReducer());
                                dispatch(
                                  showSuccessReducer(
                                    `${row?.name} successfully deleted.`,
                                  ),
                                );
                              }
                            });
                          }}
                          disabled={
                            row?.numberOfPackages
                              ? row?.unlockedPackageCount ===
                                row?.numberOfPackages
                                ? false
                                : true
                              : false
                          }
                          className={
                            row?.numberOfPackages
                              ? row?.unlockedPackageCount ===
                                row?.numberOfPackages
                                ? "opacity-[1] w-full"
                                : "opacity-[.4] w-full"
                              : "opacity-[1] w-full"
                          }
                        >
                          <HemaValue text="Delete Shipment" />
                        </Dropdown.Item>
                      </DropdownButton>
                    )}
                  </div>
                </div>
              );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ]}
        customStyles={{
          responsiveWrapper: {
            style: { overflow: "visible !important" },
          },
        }}
        // onSort={(row, direction, sorted) => {
        //   setfilteredData(sortedData(row.sortId, direction, sorted));
        // }}
      />
      {orders.activeShipment && (
        <div className="bg-white rounded-[5px] mt-[32px] inventory-tabs tabs_head_space">
          <Tabs
            activeKey={subTab}
            id="controlled-tab-example"
            className="mb-4"
            onSelect={(key) => {
              setsubTab(key);
            }}
          >
            <Tab
              eventKey="Packaging"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {subTab === "Packaging " ? (
                    <BuildingTab purple />
                  ) : (
                    <BuildingTab />
                  )}
                  Packaging
                </div>
              }
            >
              <div className="">
                {/* {updatedData ?
                <HemaForm

                  data={updatedData}
                  formName="request-details"
                  cta={(val) => {
                  }}
                />: <Skeleton count="5" />} */}
                {/* <div className="flex items-center mb-[45px]">
        <div className="flex items-center gap-[10px]">
          <HemaLabel textSize="text-[14px]" text="Status" />
          <div className="min-w-[250px] flex items-center  gap-1">
            <div
              className={`w-2 h-2 rounded-full flex-shrink-0 ${'bg-[#F97316]'} `}
            />
            <HemaValue color="text-[#F97316]" className="" text={orders?.activeShipment?.status?.name} />
          </div>
        </div>
        <div className="flex items-center gap-[10px]">
          <HemaLabel textSize="text-[14px]" text="Stage" />
          <HemaValue className="min-w-[250px]" text={orders?.activeShipment?.stage?.name} />
        </div>
        <div className="flex items-center gap-[10px]">
          <HemaLabel textSize="text-[14px]" text="Packages Count" />
          <HemaValue className="min-w-[250px]" text={orders?.activeShipment?.packages?.length} />
        </div>
      </div> */}
                <div className="flex items-center justify-between mb-3 px-[16px]">
                  <h2 className="m-0 text-base font-bold text-primary1">
                    Package Information
                  </h2>

                  {common?.permissions?.["Kit Order Process"]?.update && (
                    <div className="flex items-center justify-end gap-2">
                      <Button
                        cta={() => {
                          dispatch(editFormReducer());
                          setformName("Create Package");
                          setFormIcon(<CreateItemPurple />);
                          setokBtnText("Save");

                          dispatch(
                            setForm({
                              state: true,
                              type: "create-package",
                            }),
                          );
                        }}
                        type="submit"
                        text="Add Package"
                        bg="bg-primary1"
                        disabled={
                          orders?.activeShipment?.unAssignedItems?.length > 0
                            ? false
                            : true
                        }
                        className=" max-w-[200px] w-max gap-1 py-[12px] px-[16px] "
                        color="text-white"
                        Icon={<Add color="#fff" />}
                      />
                    </div>
                  )}
                </div>
                <DataTable
                  data={orders?.activeShipment?.packages || []}
                  className="border border-t border-t-black "
                  columns={[
                    ...KitPackagingCol,
                    {
                      name: "Actions",
                      cell: (row) => {
                        return (
                          <div className=" flex gap-[5px] ">
                            <Button
                              Icon={<Binocular />}
                              color="text-white"
                              bg="bg-bgActionDots"
                              cta={async () => {
                                setformName("View Package");
                                setFormIcon(<RemoveItems />);
                                // setokBtnText('ok');
                                setokBtnIcon(<Confirm />);
                                setCancelBtnText("Close");
                                viewPackageItems[1].initialValue = row?.name;
                                viewPackageItems[4].initialValue =
                                  row?.items || [];
                                viewPackageItems[2].initialValue =
                                  row?.weight || [];
                                viewPackageItems[3].initialValue =
                                  row?.packageDimension?.name || [];
                                setUpdatedData(viewPackageItems);
                                dispatch(
                                  setForm({
                                    state: true,
                                    type: "view-package-information",
                                  }),
                                );
                                setCTA(() => async (data) => {
                                  dispatch(setFormCloseReducer());
                                });
                              }}
                            />
                            {common?.permissions?.["Kit Order Process"]
                              ?.update && (
                              <NavDropdown
                                title={
                                  <Button
                                    Icon={<DotsIcon />}
                                    color="text-white"
                                    bg="bg-bgActionDots"
                                  />
                                }
                                className="flex justify-end package-ellipse-dropdown "
                              >
                                <NavDropdown.Item
                                  className="mb-1"
                                  onClick={() => {}}
                                >
                                  <div className="flex flex-col justify-end gap-[5px] meta">
                                    <Button
                                      text="Generate Packaging Slip"
                                      className={
                                        !row?.isLocked
                                          ? "opacity-[1] w-full"
                                          : "opacity-[.5] w-full"
                                      }
                                      disabled={row?.isLocked ? true : false}
                                      cta={() => {
                                        dispatch(
                                          setBuildTemplate({
                                            open: true,
                                            orderItemDetail: {
                                              ...row,
                                              buildType: "packaging-slip",
                                            },
                                          }),
                                        );
                                      }}
                                    />
                                    <Button
                                      cta={() => {
                                        dispatch(editFormReducer(row));
                                        setformName("Edit Package");
                                        setFormIcon(<Reject />);
                                        setokBtnText("Save");

                                        dispatch(
                                          setForm({
                                            state: true,
                                            type: "create-package",
                                          }),
                                        );
                                      }}
                                      text="Edit"
                                      disabled={row?.isLocked ? true : false}
                                      className={
                                        !row?.isLocked
                                          ? "opacity-[1] w-full"
                                          : "opacity-[.5] w-full"
                                      }
                                    />
                                    <Button
                                      text="Delete"
                                      cta={() => {
                                        setformName("Delete Package");
                                        setFormIcon(<DeleteColor />);
                                        setokBtnText("Confirm");
                                        setCancelBtnText("Cancel");
                                        dispatch(editFormReducer(row));
                                        DeleteRequestPackage[1].initialValue =
                                          orders?.activeShipment?.name;
                                        DeleteRequestPackage[2].initialValue =
                                          row?.name;
                                        setCTA(
                                          () =>
                                            async (data, undefined, store) => {
                                              dispatch(
                                                setFormLoaderReducer(true),
                                              );

                                              const resp =
                                                await deletePackageAction(
                                                  orders.activeOrder?.id,
                                                  orders.activeShipment?.id,
                                                  store?.uisettings?.editForm
                                                    ?.id,
                                                );
                                              if (resp?.status === 200) {
                                                dispatch(
                                                  setFormLoaderReducer(false),
                                                );
                                                dispatch(setFormCloseReducer());
                                                dispatch(
                                                  showSuccessReducer(
                                                    `package deleted`,
                                                  ),
                                                );
                                                getShipmentDetailAction(
                                                  orders.activeOrder?.id,
                                                  orders.activeShipment?.id,
                                                );
                                              }
                                            },
                                        );
                                        setUpdatedData(DeleteRequestPackage);
                                        //  addInventoryCTA();
                                        dispatch(
                                          setForm({
                                            state: true,
                                            type: "delete-package",
                                          }),
                                        );
                                      }}
                                      disabled={row?.isLocked ? true : false}
                                      className={
                                        !row?.isLocked
                                          ? "opacity-[1] w-full"
                                          : "opacity-[.5] w-full"
                                      }
                                    />
                                    <Button
                                      disabled={!row?.isLocked ? true : false}
                                      className={
                                        row?.isLocked
                                          ? "opacity-[1] w-full"
                                          : "opacity-[.5] w-full"
                                      }
                                      text="Unlock Package"
                                      cta={async () => {
                                        setformName("Unlock Package");
                                        setFormIcon(<LockOpen />);
                                        setokBtnText("Confirm");
                                        setokBtnIcon(<Confirm />);
                                        setCancelBtnText("Cancel");
                                        UnloackPackage[1].initialValue =
                                          orders?.activeShipment?.name;
                                        UnloackPackage[2].initialValue =
                                          row?.name;
                                        UnloackPackage[4].label = `Please confirm that you want to unlock this package. Unlocking it will require the regeneration of its Packaging Slip.`;
                                        UnloackPackage[4].fluid = true;
                                        UnloackPackage[4].type = "";
                                        setUpdatedData(UnloackPackage);
                                        dispatch(
                                          setForm({
                                            state: true,
                                            type: "unlock Package",
                                          }),
                                        );
                                        setCTA(() => async () => {
                                          dispatch(setFormLoaderReducer(true));

                                          const resp = await unlockPackgeAction(
                                            orders.activeOrder?.id,
                                            orders.activeShipment?.id,
                                            row?.id,
                                          );
                                          if (resp?.status === 200) {
                                            dispatch(
                                              setFormLoaderReducer(false),
                                            );
                                            dispatch(setFormCloseReducer());
                                            dispatch(
                                              showSuccessReducer(
                                                `package is successfully unlocked`,
                                              ),
                                            );
                                            getShipmentDetailAction(
                                              orders.activeOrder?.id,
                                              orders.activeShipment?.id,
                                            );
                                            getAllShippingStatsAction(
                                              orders.activeOrder?.id,
                                            );
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                </NavDropdown.Item>
                              </NavDropdown>
                            )}
                          </div>
                        );
                      },
                      ignoreRowClick: true,
                      allowOverflow: true,
                      button: true,
                    },
                  ]}
                  customStyles={{
                    responsiveWrapper: {
                      style: { overflow: "visible !important" },
                    },
                  }}
                  // expandableRows
                  // expandableRowsComponent={ExpendedDataRows}
                  // expandableRowsComponentProps={{
                  //   addAssemblyAction,}}
                />
              </div>
            </Tab>
            {orders?.activeOrder?.greenLightNeeded && (
              <Tab
                eventKey="GreenLight"
                title={
                  <div className="flex items-center title-icon gap-[7px] ">
                    {subTab === "GreenLight" ? (
                      <GreenLightTab purple />
                    ) : (
                      <GreenLightTab />
                    )}
                    Green Light
                  </div>
                }
                disabled={
                  orders?.activeShipment?.builtShippingDocuments?.filter(
                    (data) => data.id === 5,
                  )?.length === 1
                    ? false
                    : true
                }
              >
                <GreenLight
                  orders={orders}
                  setformName={setformName}
                  subTab={subTab}
                  setsubTab={setsubTab}
                  setFormIcon={setFormIcon}
                  setUpdatedData={setUpdatedData}
                  setokBtnText={setokBtnText}
                  setCancelBtnText={setCancelBtnText}
                  setokBtnIcon={setokBtnIcon}
                  setCTA={setCTA}
                />
              </Tab>
            )}

            <Tab
              eventKey="QualityControl"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {subTab === "QualityControl" ? (
                    <QualityControlTab purple />
                  ) : (
                    <QualityControlTab />
                  )}{" "}
                  Quality Control
                </div>
              }
              disabled={
                orders?.activeShipment?.isLocked
                  ? orders.activeOrder?.greenLightNeeded
                    ? orders?.allGreenLights?.greenLightStatus?.status?.id ===
                        3 && orders.activeOrder?.greenLightNeeded
                      ? false
                      : true
                    : false
                  : true
              }
            >
              <div ref={ref}>
                <QualityControl
                  setPayloadQc={setPayloadQc}
                  payloadQc={payloadQc}
                  ctaDetail={qualityControlCTA}
                />
              </div>
            </Tab>

            {orders.activeOrder?.isPostShippingRequired && (
              <Tab
                eventKey="PostShipping"
                title={
                  <div className="flex items-center title-icon gap-[7px]">
                    {subTab === "PostShipping" ? (
                      <CheckReady purple />
                    ) : (
                      <CheckReady />
                    )}
                    Post Shipping
                  </div>
                }
                disabled={
                  !(
                    orders?.activeShipment?.status?.id === 1 ||
                    orders?.activeShipment?.status?.id === 7
                  )
                    ? false
                    : true
                }
              >
                <PostShipping
                  orders={orders}
                  setformName={setformName}
                  subTab={subTab}
                  setsubTab={setsubTab}
                  setFormIcon={setFormIcon}
                  setUpdatedData={setUpdatedData}
                  setokBtnText={setokBtnText}
                  setCancelBtnText={setCancelBtnText}
                  setokBtnIcon={setokBtnIcon}
                  setCTA={setCTA}
                />
              </Tab>
            )}
            {orders.activeOrder?.isKmcDepotRequired && (
              <Tab
                eventKey="Depot Notification"
                title={
                  <div className="flex items-center title-icon gap-[7px]">
                    {subTab === "Depot Notification" ? (
                      <InManagement />
                    ) : (
                      <InManagement color="#414753" />
                    )}
                    Depot Notification
                  </div>
                }
                disabled={
                  !(
                    orders?.activeShipment?.status?.id === 1 ||
                    orders?.activeShipment?.status?.id === 7
                  )
                    ? false
                    : true
                }
              >
                <KMC
                  orders={orders}
                  setformName={setformName}
                  subTab={subTab}
                  setsubTab={setsubTab}
                  setFormIcon={setFormIcon}
                  setUpdatedData={setUpdatedData}
                  setokBtnText={setokBtnText}
                  setCancelBtnText={setCancelBtnText}
                  setokBtnIcon={setokBtnIcon}
                  setCTA={setCTA}
                />
              </Tab>
            )}
          </Tabs>
        </div>
      )}
    </div>
  );
});

export const ExpendedDataRows = ({ data }) => {
  return (
    <div className=" w-full py-[10px]  border-b border-[#DEE2E6]">
      <DataTable
        customStyles={{
          table: {
            style: { background: "transparent !important", border: "none" },
          },
          head: { style: { display: "none" } },

          rows: {
            style: {
              borderBottom: "none !important",
              paddingLeft: "50px",
              paddingRight: "100px",
            },
          },
        }}
        data={data.items}
        columns={[
          {
            selector: (row) => <HemaValue text={` ${row?.name}`} />,
          },
          {
            selector: (row) => <HemaValue text={row?.itemPerKit} />,
          },
          {
            selector: (row) => <HemaValue text={row?.itemPerKit} />,
          },
          {
            selector: (row) => <HemaValue text={row?.lotNumber} />,
          },
        ]}
      />
    </div>
  );
};
export default Index;
