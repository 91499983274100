import {
  UserGroup,
  DaysCalender,
  Bell,
  ChangeReason,
} from '../../../HemeIconLibrary'

export const editExpiryAlertForm = [
  {
    label: 'Item Name',
    initialValue: '',
    name: 'name',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Location',
    initialValue: '',
    name: 'location',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Lot Number',
    initialValue: '',
    name: 'lot',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Expiration Date',
    initialValue: '',
    name: 'expirationDate',
    placeholder: 'mm/dd/yyyy',
    fluid: true,
    required: false,
    type: 'table',
  },
  {
    label: 'Quantity',
    initialValue: '',
    name: 'Quantity',
    fluid: true,
    type: 'table',
  },
  {
    label: 'Close to Expiry Alert',
    Icon: <Bell />,
    type: 'switch',
    initialValue: false,
    placeholder: '',
    fluid: true,
    name: 'closeToExpiryAlert',
  },

  {
    name: 'closeToExpiryNumberOfDays',
    label: 'Number of days',
    Icon: <DaysCalender />,
    type: 'number',
    initialValue: '',
    placeholder: 'Enter number of days',
    required: false,
    disabled: true,
  },
  {
    name: 'userIds',
    label: 'Send Alert To',
    Icon: <UserGroup />,
    type: 'multiSelect',
    initialValue: undefined,
    placeholder: 'Select user(s)',
    disabled: true,
    required: false,
    fluid: true,
  },

  {
    name: 'change_reason',
    label: 'Change Reason',
    Icon: <ChangeReason />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    required: true,
  },
]
