import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Heading from "../../logistOrchestra/logisticComponents/heading";
import { Spinner } from "react-bootstrap";
import { HemaLabel, Button, FormText, FormTextarea, TagsInput } from "../../../utils";

import { FirstName, CrossIcon, Email, SendIcon, LabelName, NameKit, Format, Edit, FormApprove, Confirm, ShippingEdit } from "../../../HemeIconLibrary";
import { sendPostShippingAction, updatePostShipmentTemplateAction } from "../../../Actions/order";
import { showSuccessReducer, setForm } from "../../../Store/reducers/uiSettings";
import { getPostShipmentTemplateAction } from "../../../Actions/order";
import { RequestAcknowledgmentView } from "../../../components/Formik/AllForms/requestAcknowledgmentView";

const PostShipping = (props) => {
  const { orders, common } = useSelector((state) => state);
  const [activeEdit, setActiveEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const { setFormIcon, setokBtnText, setCTA, setUpdatedData, setCancelBtnText, setformName } = props;
  const dispatch = useDispatch();
  const [to, setTo] = useState([]);
  const [previousPost, setPreviousPost] = useState([]);
  useEffect(() => {
    getPostShipmentTemplateAction(orders.activeOrder?.id, orders?.activeShipment?.id, 1);
  }, [orders?.activeShipment]);

  useEffect(() => {
    if (orders.postShippingTemplate?.to) {
      setPreviousPost(orders.postShippingTemplate?.to?.split(","));
    }
  }, [orders.postShippingTemplate?.to]);

  return (
    <div className="w-full px-[16px]">
      {/* <div className="mb-[21px]">
        <Heading
          heading="Shipment 1"
          buttonData={undefined}
          border={undefined}
        />
      </div> */}
      {/* <div className="flex items-center mb-[45px]">
        <div className="flex items-center gap-[10px]">
          <HemaLabel textSize="text-[14px]" text="Status" />
          <div className="min-w-[250px] flex items-center  gap-1">
            <div
              className={`w-2 h-2 rounded-full flex-shrink-0 ${'bg-[#F97316]'} `}
            />
            <HemaValue color="text-[#F97316]" className="" text="In Process" />
          </div>
        </div>
        <div className="flex items-center gap-[10px]">
          <HemaLabel textSize="text-[14px]" text="Stage" />
          <HemaValue className="min-w-[250px]" text="Shipment QC’ED" />
        </div>
        <div className="flex items-center gap-[10px]">
          <HemaLabel textSize="text-[14px]" text="Packages Count" />
          <HemaValue className="min-w-[250px]" text="2" />
        </div>
      </div> */}
      <div className="mb-[37px]">
        <Heading heading="Post Shipping Notification" />
      </div>

      {/* formik */}

      <div className="w-full">
        {!orders.postShippingTemplate?.submission ? (
          <Formik
            initialValues={{
              postShippingCc: orders.postShippingTemplate?.cc ? orders.postShippingTemplate?.cc?.split(",") : [],

              multiSelectTo: [...previousPost, ...to],

              postShippingBcc: orders.postShippingTemplate?.bcc ? orders.postShippingTemplate?.bcc?.split(",") : [],
              postShippingSubject: orders.postShippingTemplate?.subject,
              postShippingEmailBody: orders.postShippingTemplate?.body,
            }}
            enableReinitialize
            validate={(values) => {
              const errors = {};

              if (!values.postShippingEmailBody) {
                errors.postShippingEmailBody = "Required";
              }

              if (values.multiSelectTo.length === 0) {
                errors.multiSelectTo = "Required";
              }

              if (!values.postShippingSubject) {
                errors.postShippingSubject = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              await updatePostShipmentTemplateAction(
                orders.activeOrder?.id,
                orders?.activeShipment?.id,
                {
                  to: values.multiSelectTo?.join(","),
                  cc: values.postShippingCc?.join(","),
                  bcc: values.postShippingBcc?.join(","),
                  subject: values.postShippingSubject,
                  body: values.postShippingEmailBody,
                },
                1,
              );

              const result = await sendPostShippingAction(orders.activeOrder?.id, orders?.activeShipment?.id, 1);
              setSubmitting(false);
              if (result.status === 200) {
                setActiveEdit(false);
                getPostShipmentTemplateAction(orders.activeOrder?.id, orders?.activeShipment?.id, 1);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              validateForm,

              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} className="flex flex-col justify-between w-full m-auto overflow-auto ">
                <div className="pr-4">
                  <div className="flex items-start gap-[16px] mb-[16px]">
                    <div className={`w-full `}>
                      <HemaLabel text="To" className="mb-[10px]" required Icon={<FirstName />} />
                      <div className=" border-[1px] border-[#ccc] rounded-[5px] border-solid mt-[10px]">
                        {!!orders.postShippingTemplate?.to && (
                          <TagsInput
                            setFieldValue={(_, dataArry) => {
                              setPreviousPost(dataArry);
                            }}
                            name="multiSelectTo"
                            value={previousPost}
                            classNames="hideborder hideinput"
                            disabled={orders?.activeOrder?.type.id === 1 ? true : false}
                          />
                        )}
                        <TagsInput
                          placeholder="multi select"
                          setFieldValue={(_, name) => {
                            setTo(name);
                          }}
                          name="multiSelectTo"
                          value={to}
                          classNames="hideborder"
                        />
                      </div>
                      {errors.multiSelectTo && touched.multiSelectTo && <div className="error text-[red] text-[12px] pt-[2px]">{errors.multiSelectTo}</div>}
                    </div>

                    <div className={`w-full `}>
                      <HemaLabel text="Cc" className="mb-[10px]" Icon={<NameKit />} />

                      <TagsInput placeholder="multi select" setFieldValue={setFieldValue} name="postShippingCc" value={values?.postShippingCc} />
                    </div>
                  </div>

                  <div className="flex items-start gap-[16px] mb-[16px]">
                    <div className="w-full">
                      <HemaLabel text="Bcc" className="mb-[10px]" Icon={<Format />} />
                      <TagsInput placeholder="multi select" setFieldValue={setFieldValue} name="postShippingBcc" value={values?.postShippingBcc} />
                    </div>
                    <div className={`w-full`}>
                      <HemaLabel text="Subject" className="mb-[10px]" Icon={<LabelName />} required />

                      <div className="relative">
                        <FormText
                          disabled={activeEdit ? false : true}
                          type="text"
                          name="postShippingSubject"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          value={values.postShippingSubject}
                          placeholder={"Type name to search and select"}
                          crossIcon={values.postShippingSubject ? <CrossIcon /> : ""}
                        />

                        <div className="absolute top-[10px] right-[10px]">{!activeEdit && <ShippingEdit />}</div>
                      </div>

                      {errors.postShippingSubject && touched.postShippingSubject && <div className="error text-[red] text-[12px] pt-[2px]">{errors.postShippingSubject}</div>}
                    </div>
                  </div>

                  <div className=" gap-[15px] items-center w-full block">
                    <HemaLabel text={"Email Body"} Icon={<Email purple />} required={true} className={`mb-[10px]`} />

                    <div className="relative">
                      <FormTextarea
                        disabled={activeEdit ? false : true}
                        placeholder="enter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"postShippingEmailBody"}
                        className="min-h-[250px]"
                        value={values?.postShippingEmailBody || ""}
                        rows="13"
                        setFieldValue={setFieldValue}
                        crossIcon={values.postShippingEmailBody ? <CrossIcon /> : ""}
                      />
                      <div className="absolute top-[10px] right-[10px]">{!activeEdit && <ShippingEdit />}</div>
                    </div>

                    {errors.postShippingEmailBody && touched.postShippingEmailBody && <div className="error text-[red] text-[12px] pt-[2px]">{errors.postShippingEmailBody}</div>}
                  </div>
                  <div className="flex items-center gap-[10px] mt-[16px] justify-end">
                    {common?.permissions?.["Kit Order Process"]?.update && !activeEdit && (
                      <Button
                        type="button"
                        text="Edit"
                        bg="bg-primary1"
                        color="text-white"
                        Icon={<Edit color="#fff" />}
                        cta={async (e) => {
                          setActiveEdit(true);
                        }}
                      />
                    )}
                    {activeEdit && (
                      <>
                        <Button
                          type="button"
                          text="Cancel"
                          bg="bg-primary1"
                          color="text-white"
                          Icon={<Edit color="#fff" />}
                          cta={async (e) => {
                            setActiveEdit(false);
                          }}
                        />
                        <Button
                          type="button"
                          text={
                            loader ? (
                              <>
                                <Spinner animation="grow" size="sm" variant="light" />
                                <Spinner animation="grow" size="sm" variant="light" />
                                <Spinner animation="grow" size="sm" variant="light" />
                              </>
                            ) : (
                              "Save"
                            )
                          }
                          bg="bg-primary1"
                          color="text-white"
                          Icon={loader ? "" : <Confirm color="#fff" />}
                          disabled={loader ? true : false}
                          cta={async (e) => {
                            setLoader(true);
                            const result = await updatePostShipmentTemplateAction(
                              orders.activeOrder?.id,
                              orders?.activeShipment?.id,
                              {
                                to: values.multiSelectTo?.join(","),
                                cc: values.postShippingCc?.join(","),
                                bcc: values.postShippingBcc?.join(","),
                                subject: values.postShippingSubject,
                                body: values.postShippingEmailBody,
                              },
                              1,
                            );
                            setLoader(false);
                            if (result.status === 200) {
                              window.scrollTo(0, 0);
                              setActiveEdit(false);
                              dispatch(showSuccessReducer(`post shipping updated.`));
                            }
                          }}
                        />
                      </>
                    )}
                    {isSubmitting ? (
                      <Button
                        type="submit"
                        bg="bg-primary1"
                        text={
                          <>
                            <Spinner animation="grow" size="sm" variant="light" />
                            <Spinner animation="grow" size="sm" variant="light" />
                            <Spinner animation="grow" size="sm" variant="light" />
                          </>
                        }
                      />
                    ) : (
                      common?.permissions?.["Kit Order Process"]?.update && (
                        <Button type="submit" text="Send" bg="bg-primary1" color="text-white" Icon={<SendIcon />} disabled={activeEdit ? true : false} cta={() => {}} />
                      )
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <div className="flex items-center gap-[10px] mt-[16px]">
            <Button
              type="button"
              text="View Post Shipping Notification"
              bg="bg-primary1"
              color="text-white"
              Icon={<SendIcon />}
              cta={async (e) => {
                const tempHolder = e.target.innerHTML;
                e.target.innerHTML = "Loading ...";
                setformName("View Post Shipping Notification");
                setFormIcon(<FormApprove />);
                setokBtnText("Ok");
                setCancelBtnText("Close");
                e.target.innerHTML = tempHolder;

                setCTA(() => () => {
                  dispatch(
                    setForm({
                      state: false,
                      type: "",
                    }),
                  );
                });
                RequestAcknowledgmentView[0].initialValue =
                  orders.postShippingTemplate?.to
                    ?.split(",")
                    ?.map((data) => data)
                    ?.join(",") || "NA";
                RequestAcknowledgmentView[1].initialValue =
                  orders.postShippingTemplate?.cc
                    ?.split(",")
                    ?.map((data) => data)
                    ?.join(",") || "NA";
                RequestAcknowledgmentView[2].initialValue =
                  orders.postShippingTemplate?.bcc
                    ?.split(",")
                    ?.map((data) => data)
                    ?.join(",") || "NA";
                RequestAcknowledgmentView[3].initialValue = orders.postShippingTemplate?.subject;
                RequestAcknowledgmentView[4].initialValue = orders.postShippingTemplate?.body;
                RequestAcknowledgmentView[4].rows = "18";
                setUpdatedData(RequestAcknowledgmentView);
                dispatch(
                  setForm({
                    state: true,
                    type: "view-acknowledge",
                  }),
                );
              }}
            />
            <HemaLabel
              textSize="text-[14px]"
              text={`Shipment and Post Shipping Notification were completed by  ${orders.postShippingTemplate?.submission?.submittedBy?.name} on ${
                orders.postShippingTemplate?.submission?.submittedUtc?.split("T")[0]
              }.`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PostShipping;
