import { useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { setDropdownValue } from '../../Store/reducers/common';
import Dropdown from 'react-bootstrap/Dropdown';
import { SelectDownArrow } from '../../HemeIconLibrary';
import './style.scss';

import HemaValue from '../HemaValue';
import { HemaDateFormat } from '../../helpers/commonHelper';
const KitBuilderDropDown = forwardRef((props, ref) => {
  const {
    placeholder,
    setFieldValue,
    name,
    onChange,
    value,
    disabled,
    className,
    dispatch,

    setFieldValueFull,
    data,
  } = props;
  const [selectLabel, setSelectLabel] = useState(value !== '' ? value : placeholder);
  const { uisettings } = useSelector((state) => state);
  useImperativeHandle(ref, () => ({
    clearSelect() {
      setSelectLabel(placeholder);
    },
  }));

  function onChangeHandler(event) {
    event.preventDefault();
    if (event?.target) {
      if (event.target.value) {
        setFieldValue(name, event?.target?.value);
      }
      if (onChange) {
        event.target.value = event.target.id;
        onChange(event);
      }
    }
  }

  return (
    <Dropdown
      className={`hema-form-dropdown-custom  `}
      disabled={disabled}
      onSelect={(ek, eo) => {
        eo.preventDefault();
        onChangeHandler(eo);
      }}
      {...props}
    >
      <Dropdown.Toggle disabled={disabled} className={`hema-form-dropdown-custom ${className}`}>
        <div className={`flex gap-[10px] ${selectLabel === placeholder ? ' placeholderColor' : ' nonePlaceholderColor'}`}>
          {selectLabel || placeholder}
          {!disabled && (
            <span className={`absolute top-[15px] right-[10px] cursor-pointer`}>
              <SelectDownArrow />
            </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="kitbuilder_dropdownshow">
        {data.map((val) => (
          <Dropdown.Item
            className="dropdown-item-data"
            key={val.id}
            id={val.id}
            name={name}
            active={selectLabel === val.name}
            onClick={() => {
              if (setFieldValue) {
                setFieldValue(name, val.id);
              }
              if (setFieldValueFull) {
                setFieldValueFull(name, val);
              }
              if (dispatch) {
                if (uisettings.formName === 'create-assembly') {
                  dispatch(setDropdownValue({ ...val }));
                } else {
                  dispatch(setDropdownValue({ ...val, name }));
                }
              }
              setSelectLabel(val.name || val.lotNumber);
            }}
          >
            <div className="flex flex-col items-center flex-shrink-0 gap-0 cursor-pointer check_box">
              <HemaValue text={'Lot Number'} className="font-medium text-[#989898] text-xs" />
              <HemaValue text={val?.lotNumber} className="whitespace-break-spaces font-medium text-[#232323] text-sm" />
            </div>

            <div className="flex flex-col items-center flex-shrink-0 gap-0 cursor-pointer check_box">
              <HemaValue text={'Expiration Date'} className="font-medium text-[#989898] text-xs" />
              <HemaValue text={HemaDateFormat(val?.expirationDate)} className="font-medium text-[#232323] text-sm" />
            </div>

            <div className="flex flex-col items-center flex-shrink-0 gap-0 cursor-pointer check_box">
              <HemaValue text={'Storage Location'} className="font-medium text-[#989898] text-xs" />
              <HemaValue text={val?.storageLocation?.name} className="font-medium text-[#232323] text-sm" />
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
});

export default KitBuilderDropDown;
