import React from 'react';
export const InComing = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 3.2358C12.394 4.18678 13.5 5.96422 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8C2.5 5.96422 3.60605 4.18678 5.25 3.2358M5.8 8L8 10.2M8 10.2L10.2 8M8 10.2V2.5"
        stroke="url(#paint0_linear_2850_40767)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2850_40767"
          x1="2.5"
          y1="7.99999"
          x2="13.5"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
