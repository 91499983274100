import React, { useEffect, useRef } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Spinner, Alert as AlertBootstrap } from "react-bootstrap";
import isEqual from "lodash/isEqual";
import { HemaLabel, Button, FormTextarea, SingleSelect } from "../../../utils";
import { StudyAlias, Cancel, Confirm, ChangeReason, CrossIcon } from "../../../HemeIconLibrary";
import Heading from "../../../pages/logistOrchestra/logisticComponents/heading";
import ShippingRules from "../../../pages/logistOrchestra/logisticComponents/shippingRules";
import { updateProtocolLogisticBuilderAction } from "../../../Actions/logistic";
import { setForm, showSuccessReducer } from "../../../Store/reducers/uiSettings";
import { compareArrays } from "../../../helpers/commonHelper";

const FormikForm = ({
  // setformName,
  // setFormIcon,
  // setconfirmSlide,
  confirmSlide,
  setisFormikChange,
  //isFormikChange,
}) => {
  const { common, assembly, logistic, uisettings } = useSelector((state) => state);
  const dispatch = useDispatch();

  const formikRef = useRef();

  useEffect(() => {
    (async () => {
      console.log(await formikRef.current?.validateForm());
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm]);

  return (
    <Formik
      initialValues={{
        shippingRules:
          common.allShipping?.map((data) => {
            return {
              //    checked: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.length || false,
              shippingConditionId: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.shippingCondition || data,
              inBoundCourierId: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.inBoundCourier,
              outBoundCourierId: uisettings.editForm?.shippingRules.filter((check) => check?.shippingCondition?.id === data.id)?.[0]?.outBoundCourier,
            };
          }) || [],
        initialRequestAssemblyId: uisettings?.editForm ? uisettings?.editForm?.initialRequestAssembly?.id || "N/A" : "",
        // changeReason: '',
      }}
      innerRef={formikRef}
      enableReinitialize
      validate={(values, initialValues) => {
        const errors = {};

        if (!values.initialRequestAssemblyId) {
          errors.initialRequestAssemblyId = "Required";
        }
        if (!values.changeReason && logistic.selectedProtocolDetail?.qc?.status?.id === 3) {
          errors.changeReason = "Required";
        }
        // if (values.shippingRules?.filter((ship) => ship.checked)?.length === 0) {
        //   errors.shippingRules = 'Required';
        // }
        console.log("errors", errors);
        if (!isEqual(initialValues, values)) {
          setisFormikChange(true);
        } else {
          setisFormikChange(false);
        }
        return errors;
      }}
      onSubmit={async (values, errors) => {
        const result = await updateProtocolLogisticBuilderAction(logistic.selectedProtocol?.id, {
          ...values,
          initialRequestAssemblyId: values?.initialRequestAssemblyId === "N/A" ? undefined : values?.initialRequestAssemblyId,
          shippingRules: values.shippingRules?.map((ship) => {
            return {
              shippingConditionId: ship.shippingConditionId?.id || ship?.shippingConditionId,
              outBoundCourierId: ship?.outBoundCourierId?.id || ship?.outBoundCourierId,
              inBoundCourierId: ship?.inBoundCourierId?.id || ship?.inBoundCourierId,
            };
          }),
        });
        if (result.status === 200) {
          dispatch(setForm({ state: false, type: "" }));
          dispatch(showSuccessReducer(`Study ${result.data?.study.name} is updated.`));
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        initialValues,
        dirty,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
          <div className=" h-[calc(100%-60px)] flex  flex-col justify-between">
            {confirmSlide ? (
              <div>
                <HemaLabel text={"Do you want to save the changes you made in this study? Unsaved changes will be lost."} className={`mb-[10px]`} />
                {errors.changeReason && (
                  <AlertBootstrap variant="danger" className="text-left mt-[20px]">
                    Change reason is required.
                  </AlertBootstrap>
                )}
              </div>
            ) : (
              <div>
                <Heading heading="Shipping Rules" mb />
                <div className="mb-[29px]">
                  <ShippingRules
                    shipping={values.shippingRules}
                    curiors={common.allCuriors}
                    values={values}
                    dispatch={dispatch}
                    setFieldValue={setFieldValue}
                    name={"shippingRules"}
                  />
                  {errors.shippingRules && touched.shippingRules && <div className="error text-[red] text-[12px] pt-[2px]">{errors.shippingRules}</div>}
                </div>

                <Heading heading="Initial Request" mb />
                <div className={"w-full"}>
                  <div className="mb-[30px]">
                    <HemaLabel text={"Select Kit Assembly"} Icon={<StudyAlias color="blue" />} required={true} className={`mb-[10px]`} />

                    <div className=" gap-[15px] items-center w-full block">
                      <SingleSelect
                        formikRef={formikRef}
                        options={[{ id: "N/A", name: "N/A" }, ...assembly?.allAssemblies?.filter((data) => data.assemblyType.name === "Initial Request Assembly")] || []}
                        placeholder="Single Select General Assembly"
                        setFieldValue={setFieldValue}
                        name={"initialRequestAssemblyId"}
                        value={values?.initialRequestAssemblyId}
                        crossIcon={values?.initialRequestAssemblyId ? <CrossIcon /> : ""}
                      />
                    </div>
                    {errors.initialRequestAssemblyId && touched.initialRequestAssemblyId && (
                      <div className="error text-[red] text-[12px] pt-[2px]">{errors.initialRequestAssemblyId}</div>
                    )}
                  </div>
                  {logistic.selectedProtocolDetail?.qc?.status?.id === 3 && (
                    <div className=" gap-[15px] items-center w-full block">
                      <HemaLabel text={"Change Reason"} Icon={<ChangeReason />} required={true} className={`mb-[10px]`} />

                      <FormTextarea
                        placeholder="write edit reason"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={"changeReason"}
                        value={values?.changeReason}
                        setFieldValue={setFieldValue}
                        crossIcon={values?.changeReason ? <CrossIcon /> : ""}
                      />

                      {errors.changeReason && touched.changeReason && <div className="error text-[red] text-[12px] pt-[2px]">{errors.changeReason}</div>}
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* <Alert type="error" /> */}
            <div className="flex gap-[8px] justify-end ">
              <Button
                cta={() => {
                  dispatch(setForm({ state: false, type: "" }));
                  // if (isFormikChange && !confirmSlide) {
                  //   setformName('Save Changes?');
                  //   setFormIcon('');
                  //   setconfirmSlide(true);
                  // } else if (confirmSlide) {
                  //   setconfirmSlide(false);
                  //   setformName('Edit Study');
                  //   setFormIcon(<ExpiryAlertPurple />);
                  // } else {
                  //   dispatch(setForm({ state: false, type: '' }));
                  // }
                }}
                type="button"
                text={confirmSlide ? "Back" : "Cancel"}
                bg="bg-white"
                border="border-primary1"
                color="text-primary1"
                Icon={<Cancel />}
              />
              {isSubmitting ? (
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                <Button
                  type="submit"
                  text={confirmSlide ? "Confirm" : "Save"}
                  disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                  bg="bg-primary1"
                  color="text-white"
                  Icon={<Confirm />}
                />
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FormikForm;
