import { Caret, CaretDown, Delete, DotsIcon, Pencil } from "../../HemeIconLibrary";
import NavDropdown from "react-bootstrap/NavDropdown";
import { getIcon } from "../../helpers/commonHelper";
import TextWithIcon from "../../pages/storage/components/textWithIcon";
import { useSelector } from "react-redux";

function Explorer({ explorer, onItemClick, showOptions, selectedLocation, editCTA, deleteCTA }) {
  const { common } = useSelector((state) => state);
  const showChildren = explorer.children ? true : false;
  function expandFolder(explorer) {
    onItemClick(explorer);
  }

  const editCTAHandler = (location) => {
    if (editCTA) {
      editCTA(location);
    }
  };

  const deleteCTAHandler = (location) => {
    if (deleteCTA) {
      deleteCTA(location);
    }
  };

  return (
    <>
      <div className={`input-group flex-nowrap explorer`}>
        <div
          // onClick={() => expandFolder(explorer)}
          className={`flex-shrink-0 border-0   ${selectedLocation?.id === explorer.id ? "location-icon back-color" : "location-icon no-back-color"}`}
          id="btnGroupAddon"
        >
          <div className="d-inline-block btn float-left leading-[18px] font-medium text-[black]" onClick={() => expandFolder(explorer)}>
            {explorer.expand ? <CaretDown color={selectedLocation?.id === explorer.id ? "#5B5B5B" : "#5B5B5B"} /> : <Caret color="#5B5B5B" />}
          </div>
        </div>
        <div className={`input-group-bx w-full  flex gap-2 py-[3px] pl-[4px] items-center ${selectedLocation?.id === explorer.id ? "selected-item-bg" : "not-selected-bg"}`}>
          <div onClick={() => expandFolder(explorer)}>
            {!explorer?.parentId ? (
              <div className="w-[30px] h-[30px] flex-shrink-0 flex items-center justify-center">
                <img className="w-auto h-auto " alt="United States" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${explorer.iconName}.svg`} />
              </div>
            ) : (
              <div className="w-[30px] h-[30px] flex-shrink-0 flex items-center justify-center">{getIcon(explorer.iconName)}</div>
            )}
          </div>
          <span
            className="d-inline-block text-[12px] leading-[18px] text-[#5B5B5B] font-medium cursor-pointer max-w-[200px] overflow-x-hidden text-ellipsis"
            onClick={() => expandFolder(explorer)}
          >
            {explorer.name}
          </span>
          {showOptions && explorer?.parentId !== null && (
            <NavDropdown
              className="h-[30px] dropdown storage-location-dropdown"
              title={<DotsIcon />}
              disabled={common?.permissions?.["Storage Locations"]?.update || common?.permissions?.["Storage Locations"]?.delete ? false : true}
            >
              {common?.permissions?.["Storage Locations"]?.update && (
                <NavDropdown.Item className="dropdown-item" onClick={() => editCTAHandler(explorer)}>
                  <TextWithIcon text="Edit" icon={<Pencil />} />
                </NavDropdown.Item>
              )}
              {common?.permissions?.["Storage Locations"]?.delete && (
                <NavDropdown.Item className="dropdown-item" onClick={() => deleteCTAHandler(explorer)}>
                  <TextWithIcon text="Delete" icon={<Delete />} />
                </NavDropdown.Item>
              )}
            </NavDropdown>
          )}
        </div>
      </div>
      {showChildren && explorer.expand && explorer.children && (
        <ul className={`m-0 list-unstyled child-list ${explorer.children.length > 1 ? "multi-child" : ""}`}>
          {explorer.children.map(
            (explore, index) =>
              explore.id && (
                <li
                  className={` ${explore.expand && explore.children.length ? "open" : ""} ${index > 0 && index < explorer.children.length - 1 ? "multi-child-open" : ""} `}
                  key={explore.id}
                >
                  <Explorer explorer={explore} onItemClick={onItemClick} showOptions={showOptions} selectedLocation={selectedLocation} editCTA={editCTA} deleteCTA={deleteCTA} />
                </li>
              ),
          )}
        </ul>
      )}
    </>
  );
}

export default Explorer;
