import { ItemName } from "../../../HemeIconLibrary";

export const addNewInventoryCheck = [
  {
    label: "Item Name",
    Icon: <ItemName purple />,
    type: "searchableSelect",
    initialValue: "",
    placeholder: "Select Item Name",
    required: true,
    name: "itemName",
    options: [],
    fluid: true,
  },
];
