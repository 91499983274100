import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { HemaValue, FormCheckbox } from "../../utils";

const ScreeningShippingTable = (props) => {
  const { allItems, name, setFieldValue } = props;
  const [filteredData, setfilteredData] = useState();

  useEffect(() => {
    setfilteredData(allItems);
  }, [allItems]);

  return (
    <div className="w-full border border-[#DEE2E6] rounded-[4px]">
      <DataTable
        selectableRows
        onSelectedRowsChange={(e) => {
          setFieldValue(
            name,
            e?.selectedRows?.map((data) => data?.id),
          );
        }}
        columns={[
          {
            name: <HemaValue text={"Item Name"} className="font-normal text-[#000000] text-xs leading-[18px] whitespace-break-spaces" />,
            sortable: true,
            sortId: "item.name",
            selector: (row, index) => {
              return (
                <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                  <HemaValue text={row?.name} className="font-medium text-xs leading-[18px] whitespace-break-spaces" />
                </div>
              );
            },
          },
          {
            name: <HemaValue text={"Quantity "} className="font-normal text-[#000000] text-xs leading-[18px] whitespace-break-spaces" />,
            sortable: true,
            selector: (row) => <HemaValue text={row?.quantity} />,
            sortId: "quantity",
          },
          {
            name: <HemaValue text={"Lot Number"} className="font-normal text-[#000000] text-xs leading-[18px] whitespace-break-spaces" />,
            sortable: true,
            selector: (row) => <HemaValue text={row?.lotNumber} />,
            sortId: "lotNumber",
          },
        ]}
        data={filteredData || []}
        selec
        se
      />
    </div>
  );
};

export default ScreeningShippingTable;
