import React from 'react';

export const ReadyIcon = ({ purple }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.00003L7.33371 12.1429L18 1.85718"
        stroke={purple ? 'url(#paint0_linear_5413_181983)' : '#414753'}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5413_181983"
          x1="9.86436"
          y1="17.3298"
          x2="22.2468"
          y2="17.6369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
