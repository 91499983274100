import { ChangeReason, Broker, FirstName } from '../../../HemeIconLibrary';

export const AddCourier = [
  {
    label: 'Name',
    Icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter Name',
    required: true,
    name: 'name',
    fluid: true,
  },

  {
    label: 'Account Number',
    Icon: <Broker />,
    type: 'text',
    initialValue: false,
    placeholder: 'Enter acount number',
    required: true,
    name: 'accountNumber',
    fluid: true,
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    Icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
    placeholder: 'Type reason',
  },
];
export const DeleteCourier = [
  {
    label: 'Name',
    type: 'table',
    initialValue: '',
    placeholder: 'Enter Name',
    name: 'name',
    fluid: true,
  },

  {
    label: 'Account Number',
    type: 'table',
    initialValue: false,
    placeholder: 'Enter acount number',
    name: 'accountNumber',
    fluid: true,
  },
  {
    name: 'changeReason',
    label: 'Change Reason',
    Icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
    placeholder: 'Type reason',
  },
];
