import React from "react";

function CompanyLocation({ color }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6984 15.4672V15.9672H19.1984H25.0984V25.6339H6.89844V11.7005H12.7984H13.2984V11.2005V9.27429L15.9984 6.57429L18.6984 9.27429V15.4672ZM10.6651 24.5005H11.1651V24.0005V21.8672V21.3672H10.6651H8.53177H8.03177V21.8672V24.0005V24.5005H8.53177H10.6651ZM10.6651 20.2339H11.1651V19.7339V17.6005V17.1005H10.6651H8.53177H8.03177V17.6005V19.7339V20.2339H8.53177H10.6651ZM10.6651 15.9672H11.1651V15.4672V13.3339V12.8339H10.6651H8.53177H8.03177V13.3339V15.4672V15.9672H8.53177H10.6651ZM17.0651 24.5005H17.5651V24.0005V21.8672V21.3672H17.0651H14.9318H14.4318V21.8672V24.0005V24.5005H14.9318H17.0651ZM17.0651 20.2339H17.5651V19.7339V17.6005V17.1005H17.0651H14.9318H14.4318V17.6005V19.7339V20.2339H14.9318H17.0651ZM17.0651 15.9672H17.5651V15.4672V13.3339V12.8339H17.0651H14.9318H14.4318V13.3339V15.4672V15.9672H14.9318H17.0651ZM17.0651 11.7005H17.5651V11.2005V9.06719V8.56719H17.0651H14.9318H14.4318V9.06719V11.2005V11.7005H14.9318H17.0651ZM23.4651 24.5005H23.9651V24.0005V21.8672V21.3672H23.4651H21.3318H20.8318V21.8672V24.0005V24.5005H21.3318H23.4651ZM23.4651 20.2339H23.9651V19.7339V17.6005V17.1005H23.4651H21.3318H20.8318V17.6005V19.7339V20.2339H21.3318H23.4651Z"
        fill={color || "url(#paint0_linear_6027_158396)"}
        stroke={color || "url(#paint1_linear_6027_158396)"}
      />
      <defs>
        <linearGradient id="paint0_linear_6027_158396" x1="6.39844" y1="16.0005" x2="25.5984" y2="16.0005" gradientUnits="userSpaceOnUse">
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient id="paint1_linear_6027_158396" x1="6.39844" y1="16.0005" x2="25.5984" y2="16.0005" gradientUnits="userSpaceOnUse">
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CompanyLocation;
