import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Transition, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { setForm } from '../../Store/reducers/uiSettings';
import { Back, Persevere } from '../../HemeIconLibrary';
import HemaForm from '../../components/Formik';
import { FormTitle, Button, Alert } from '../../utils';
import { quotes } from '../../helpers/quotes';
import { setDropdownValue } from '../../Store/reducers/common';
const duration = 300;

// const defaultStyle = {
//   transition: `opacity ${duration}ms ease-in-out`,
//   minWidth: '600px',
// };

// const transitionStyles = {
//   entering: { minWidth: '600px' },
//   entered: { minWidth: '600px' },
//   exiting: { minWidth: '0px' },
//   exited: { minWidth: '0px' },
// };
export const FormContainer = (props) => {
  const dispatch = useDispatch();
  const { cta, formType, formName, Icon, formValidation, formTitle, in: inProp } = props;
  const { uisettings } = useSelector((state) => state);
  const [confirmSlide, setconfirmSlide] = useState(false);
  const [isFormikChange, setisFormikChange] = useState(false);
  const [formTransition, setformTransition] = useState(false);
  useEffect(() => {
    if (!formTransition) {
      setformTransition(true);
    }
  }, [props]);
  return (
    <div className="h-full z-[999] bg-[#000000c4] border-[#DEE2E6] fixed top-0 left-0 w-full flex justify-end  ">
      <div className={`bg-white screen-height transition-all ${formTransition ? 'w-[600px]' : 'w-0'}`}>
        <div className=" px-4 py-2 h-[70px] flex items-center justify-between bg-primary1  border-b">
          <div
            className="cursor-pointer"
            onClick={() => {
              // if (isFormikChange) {
              //   setconfirmSlide(true);
              // } else {
                if (props.onFormClose) {
                  props.onFormClose();
                }
                dispatch(setForm(false));
                dispatch(setDropdownValue({}));
              //}
            }}
          >
            <Back />
          </div>
          <div className="flex items-center gap-4">
            <p className="m-0 text-base font-normal text-white">{quotes[Math.floor(Math.random() * 38)]}</p>
            <Persevere />
          </div>
        </div>
        <div className="h-[calc(100%-75px)] px-4 py-8 overflow-auto ">
          {formName && (
            <div className="pb-[25px]">
              <FormTitle Icon={''} text={formName} className="text-mainheadingColor font-semibold text-[26px] leading-[31px] " />
            </div>
          )}
          <Alert type="error" />
          <HemaForm
            data={formType}
            cta={cta}
            formValidation={formValidation}
            formName={formName}
            {...props}
            setconfirmSlide={setconfirmSlide}
            confirmSlide={confirmSlide}
            setisFormikChange={setisFormikChange}
            isFormikChange={isFormikChange}
          />
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
