export const ViewKitItem = [
  {
    type: 'heading-only',
    initialValue: 'Kit Details',
    fluid: true,
  },
  {
    label: 'Kit Template Name',
    initialValue: '',
    name: 'shipment-name',
    fluid: true,
    type: 'table',
  },
  {
    label: '',
    initialValue: '',
    name: 'viewKitItem',
    fluid: true,
    type: 'viewKitItem',
  },
];
