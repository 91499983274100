import ChangeReasonIcon from "../../../assets/images/change-reason.svg";

export const DeleteRequestPackage = [
  {
    type: "heading-only",
    initialValue: "Package Details",
    fluid: true,
  },
  {
    label: "Shipment Name",
    initialValue: "",
    name: "item-name",
    fluid: true,
    type: "table",
  },
  {
    name: "Package Name",
    label: "Package Number",
    // Icon: <LabelQuantity />,
    initialValue: "",
    fluid: true,
    type: "table",
  },
  {
    name: "changeReason",
    label: "Change Reason",
    Icon: <img src={ChangeReasonIcon} alt="ChangeReasonIcon" />,
    placeholder: "Type Reason",
    initialValue: "",
    fluid: true,
    required: true,
    type: "textarea",
  },
];
