import React from 'react';
export const AssemblySideIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.20517 3.57344H13.9189M3.63853 6.14526V13.859M16.4929 6.14526V13.859M6.20517 16.4299H13.9189M6.21281 3.57223C6.21281 4.99229 5.06162 6.14347 3.64156 6.14347C2.2215 6.14347 1.07031 4.99229 1.07031 3.57223C1.07031 2.15216 2.2215 1.00098 3.64156 1.00098C4.3235 1.00098 4.97751 1.27188 5.45971 1.75408C5.94191 2.23628 6.21281 2.89029 6.21281 3.57223ZM19.0713 3.57223C19.0713 4.99229 17.9201 6.14347 16.5001 6.14347C15.08 6.14347 13.9288 4.99229 13.9288 3.57223C13.9288 2.15216 15.08 1.00098 16.5001 1.00098C17.9201 1.00098 19.0713 2.15216 19.0713 3.57223ZM6.21281 16.4276C6.21281 17.8477 5.06162 18.9989 3.64156 18.9989C2.2215 18.9989 1.07031 17.8477 1.07031 16.4276C1.07031 15.0076 2.2215 13.8564 3.64156 13.8564C4.3235 13.8564 4.97751 14.1273 5.45971 14.6095C5.94191 15.0917 6.21281 15.7457 6.21281 16.4276ZM19.0713 16.4276C19.0713 17.8477 17.9201 18.9989 16.5001 18.9989C15.08 18.9989 13.9288 17.8477 13.9288 16.4276C13.9288 15.0076 15.08 13.8564 16.5001 13.8564C17.9201 13.8564 19.0713 15.0076 19.0713 16.4276Z"
        stroke="url(#paint0_linear_11719_277463)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11719_277463"
          x1="1.07031"
          y1="9.99991"
          x2="19.0713"
          y2="9.99991"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
