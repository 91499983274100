import React from "react";

export const CreateItemPurple = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1333 21.8H25.2667V7.06667C25.4965 7.06667 25.717 6.97536 25.8795 6.81283C26.042 6.65029 26.1333 6.42985 26.1333 6.2V1.86667C26.1333 1.63681 26.042 1.41637 25.8795 1.25384C25.717 1.09131 25.4965 1 25.2667 1H2.73333C2.50348 1 2.28304 1.09131 2.12051 1.25384C1.95798 1.41637 1.86667 1.63681 1.86667 1.86667V6.2C1.86667 6.42985 1.95798 6.65029 2.12051 6.81283C2.28304 6.97536 2.50348 7.06667 2.73333 7.06667V21.8H1.86667C1.63681 21.8 1.41637 21.8913 1.25384 22.0538C1.09131 22.2164 1 22.4368 1 22.6667V26.1333C1 26.3632 1.09131 26.5836 1.25384 26.7462C1.41637 26.9087 1.63681 27 1.86667 27H26.1333C26.3632 27 26.5836 26.9087 26.7462 26.7462C26.9087 26.5836 27 26.3632 27 26.1333V22.6667C27 22.4368 26.9087 22.2164 26.7462 22.0538C26.5836 21.8913 26.3632 21.8 26.1333 21.8ZM24.4 5.33333H17.4667V2.73333H24.4V5.33333ZM12.2667 2.73333H15.7333V8.462L14.2773 7.97667C14.0974 7.9159 13.9026 7.9159 13.7227 7.97667L12.2667 8.462V2.73333ZM3.6 2.73333H10.5333V5.33333H3.6V2.73333ZM4.46667 11.998V11.3359C4.46667 11.1172 4.46667 11.5 4.46667 11.3359C4.46667 11.1719 4.46667 11.5 4.46667 11.3359V10.49V9.97V7.06667H10.5333V9.66667C10.534 9.80416 10.5673 9.93954 10.6306 10.0616C10.6939 10.1837 10.7853 10.2889 10.8973 10.3687C11.0085 10.4492 11.1371 10.5022 11.2727 10.5233C11.4083 10.5444 11.547 10.533 11.6773 10.49L14 9.71L16.3227 10.49C16.4127 10.517 16.506 10.5316 16.6 10.5333C16.7812 10.5359 16.9581 10.4779 17.1027 10.3687C17.2147 10.2889 17.3061 10.1837 17.3694 10.0616C17.4327 9.93954 17.466 9.80416 17.4667 9.66667V7.06667H23.5333V12.57V14.598V21.8H4.46667V11.998ZM25.2667 25.2667H2.73333V23.5333H25.2667V25.2667Z"
        fill="url(#paint0_linear_3263_64674)"
      />
      <path
        d="M4.46667 11.3359V11.998V21.8H23.5333V14.598V12.57V7.06667H17.4667V9.66667C17.466 9.80416 17.4327 9.93954 17.3694 10.0616C17.3061 10.1837 17.2147 10.2889 17.1027 10.3687C16.9581 10.4779 16.7812 10.5359 16.6 10.5333C16.506 10.5316 16.4127 10.517 16.3227 10.49L14 9.71L11.6773 10.49C11.547 10.533 11.4083 10.5444 11.2727 10.5233C11.1371 10.5022 11.0085 10.4492 10.8973 10.3687C10.7853 10.2889 10.6939 10.1837 10.6306 10.0616C10.5673 9.93954 10.534 9.80416 10.5333 9.66667V7.06667H4.46667V9.97V10.49V11.3359ZM4.46667 11.3359C4.46667 11.5 4.46667 11.1172 4.46667 11.3359ZM4.46667 11.3359C4.46667 11.5 4.46667 11.1719 4.46667 11.3359ZM26.1333 21.8H25.2667V7.06667C25.4965 7.06667 25.717 6.97536 25.8795 6.81283C26.042 6.65029 26.1333 6.42985 26.1333 6.2V1.86667C26.1333 1.63681 26.042 1.41637 25.8795 1.25384C25.717 1.09131 25.4965 1 25.2667 1H2.73333C2.50348 1 2.28304 1.09131 2.12051 1.25384C1.95798 1.41637 1.86667 1.63681 1.86667 1.86667V6.2C1.86667 6.42985 1.95798 6.65029 2.12051 6.81283C2.28304 6.97536 2.50348 7.06667 2.73333 7.06667V21.8H1.86667C1.63681 21.8 1.41637 21.8913 1.25384 22.0538C1.09131 22.2164 1 22.4368 1 22.6667V26.1333C1 26.3632 1.09131 26.5836 1.25384 26.7462C1.41637 26.9087 1.63681 27 1.86667 27H26.1333C26.3632 27 26.5836 26.9087 26.7462 26.7462C26.9087 26.5836 27 26.3632 27 26.1333V22.6667C27 22.4368 26.9087 22.2164 26.7462 22.0538C26.5836 21.8913 26.3632 21.8 26.1333 21.8ZM24.4 5.33333H17.4667V2.73333H24.4V5.33333ZM12.2667 2.73333H15.7333V8.462L14.2773 7.97667C14.0974 7.9159 13.9026 7.9159 13.7227 7.97667L12.2667 8.462V2.73333ZM3.6 2.73333H10.5333V5.33333H3.6V2.73333ZM25.2667 25.2667H2.73333V23.5333H25.2667V25.2667Z"
        stroke="url(#paint1_linear_3263_64674)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_64674"
          x1="1"
          y1="14"
          x2="27"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3263_64674"
          x1="1"
          y1="14"
          x2="27"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
