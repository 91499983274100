import React from 'react';

import CompanyLogo from '../../assets/images/company-hemo-logo.svg';
const AuthLanding = () => {
  return (
    <div className="flex justify-start items-end pl-[58px] pr-[24px] pb-[44px] w-[50%] h-[100vh] login-banner-bg">
      <div>{/* <img src={CompanyLogo} alt="Company logo" /> */}</div>
      <div className="max-w-[427px]">
        <h2 className="text-2xl font-semibold text-[#E9DFFF] mb-[9px]">
          Hematogenix's mission
        </h2>
        <p className="font-normal text-sm text-[#E9DFFF] m-0">
          Our mission is to assist pharma companies bring novel treatments from
          bench to bedside.
        </p>
      </div>
    </div>
  );
};

export default AuthLanding;
