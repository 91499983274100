import React from 'react';

const HemaCard = ({
  borderColor,
  setActive,
  active,
  heading,
  sub,
  Icon,
  bg,
  className,
  shadeColor,
}) => {
  return (
    <div
      className={` ${active === sub && borderColor} rounded-[15px] grow-[1]  `}
    >
      <div
        className={` cursor-pointer py-[26px] px-[20px]  rounded-[15px] ${bg}  ${className}


      `}
        style={{
          borderBottomColor: shadeColor,
        }}
        onClick={() => {
          if (active === sub) {
            setActive();
          } else {
            setActive(sub);
          }
        }}
      >
        <div className="flex gap-[10px] items-center">
          {!!Icon && <Icon />}
          {heading && (
            <h1
              className={`font-bold text-[30px] leading-[38px] text-mainheadingColor m-0 `}
            >
              {heading}
            </h1>
          )}
        </div>
        {sub && (
          <div className="flex gap-[5px] items-center pt-[20px]">
            <div
              className={`rounded-[100%] w-[8px] h-[8px] bg-[${shadeColor}]`}
            />
            <p className={` m-[0] font-semibold text-sm text-[${shadeColor}]`}>
              {sub}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default HemaCard;
