const TestingLabValidation = (errors, values) => {
  var letters = /^[ A-Za-z_@./#&+-]*$/;
  if (values.name?.length > 100) {
    errors.name = 'Max 100 characters allowed.';
  }
  if (values.address1?.length > 100) {
    errors.address1 = 'Max 100 characters allowed.';
  }
  if (values.address2?.length > 100) {
    errors.address2 = 'Max 100 characters allowed.';
  }
  if (values.city?.length > 100) {
    errors.city = 'Max 100 characters allowed.';
  }
  if (values.stateAddress?.length > 100) {
    errors.stateAddress = 'Max 100 characters allowed.';
  }
  if (values.postalCode?.length > 100) {
    errors.postalCode = 'Max 100 characters allowed.';
  }
  if (values.contactName?.length > 100) {
    errors.contactName = 'Max 100 characters allowed.';
  }
  if (values.phoneNo?.toString()?.length > 20) {
    errors.phoneNo = 'Max 20 characters allowed.';
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email) && values.email) {
    errors.email = 'Please enter correct email format';
  }

  return errors;
};

export default TestingLabValidation;
