import React from 'react';
export const OutGoing = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 12.7642C12.394 11.8132 13.5 10.0358 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 10.0358 3.60605 11.8132 5.25 12.7642M5.8 8L8 5.8M8 5.8L10.2 8M8 5.8V13.5"
        stroke="url(#paint0_linear_2850_40237)"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2850_40237"
          x1="2.5"
          y1="8.00001"
          x2="13.5"
          y2="8.00001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
