import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText';
import StorageLocationsComponent from './storageLocations';
import ItemCategoriesComponent from './itemCategories/itemCategories';
import { Button, Alert, NoPermissionAlert } from '../../utils';
import {
  Add,
  Cancel,
  Save,
  StorageGradient,
  MoveInventory,
  CustomField,
  AddCategory,
  ExpiryAlertPurple,
  DeletePurple,
  Location,
  Delete,
  FormApprove,
  Confirm,
  NameKit,
} from '../../HemeIconLibrary';

import FormContainer from '../../components/Formik/formContainer';
import CompanyLocation from '../../HemeIconLibrary/settingsIcons/companyLocation';
import {
  createLocationStorage,
  changeReason,
  deleteReason,
  moveLocation,
} from '../../components/Formik/AllForms/addStorageLocation';
import { Remove } from '../../components/Formik/AllForms/remove';
import {
  createItemCategory,
  createCustomFields,
} from '../../components/Formik/AllForms/itemCategory';
import {
  setForm,
  setFormLoaderReducer,
  setFormCloseReducer,
  showSuccessReducer,
  showErrorReducer,
} from '../../Store/reducers/uiSettings';

import {
  getStorageLocationTreeAction,
  createStorageLocationAction,
  updateStorageLocationByIdAction,
  deleteStorageLocationByIdAction,
  moveStorageLocationAction,
} from '../../Actions/storageLocation';
import {
  createItemCategoryAction,
  updateBulkCustomFieldsAction,
  createItemCategoryCustomFieldAction,
  deleteItemCategoryByIdAction,
  deleteItemCategoryFieldIdAction,
} from '../../Actions/itemCategory';
import {
  getAllItemCategoriesAction,
  getAllFormatsActions,
  itemCategoryFormatAction,
} from '../../Actions/common';

import { Modal, Tabs, Tab, Alert as AlertBootstrap } from 'react-bootstrap';
import Explorer from '../../components/Explorer/explorer';
import {
  addPropertyToTree,
  markItemInTree,
  flattternTree,
  findPath,
} from '../../helpers/commonHelper';

import { textConstants, dataConstatnt } from '../../constants/formConstants';
import Skeleton from 'react-loading-skeleton';
import {
  ItemCategory,
  StorageLocation,
} from '../../HemeIconLibrary/storageIcons';

import { setActiveLocation } from '../../Store/reducers/common';
import TextWithIcon from './components/textWithIcon';
import { STORAGE_LOCATION_NAME_MAXSIZE } from '../../config';

const Storage = () => {
  const dispatch = useDispatch();
  const itemCategoryRef = useRef(null);
  const { uisettings, storageLocation, common } = useSelector((state) => state);
  const [locationStorageFormData, setLocationStorageFormData] = useState(null);
  const [locationTree, setLocationTree] = useState(null);
  const [popupTree, setPopupTree] = useState([]);
  const [selectedLocation, updateSelectedLocation] = useState(null);
  const [show, setShow] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoryFormData, setcategoryFormData] = useState();
  const [customfieldsFormData, setCustomFieldsFormData] = useState();
  const [copyExploral, setCopyExploral] = useState();
  const [outerBreadrumb, setouterBreadrumb] = useState();
  const [copyTree, setCopyTree] = useState();
  const [subheadingComponent, setFormsubHeading] = useState(null);
  const [locationModalTitleProps, setlocationModalTitleProps] = useState(
    dataConstatnt.defaultLocationModalProps
  );
  const [updatedData, setUpdatedData] = useState();
  const [formValidation, setFormValidation] = useState();
  const [searchParams] = useSearchParams();
  const [initialState, setInitialState] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const tabToOpen = searchParams.get('type');
    if (['Storage Location', 'Item Categories'].includes(tabToOpen)) {
      setKey(tabToOpen);
    }
  }, [searchParams]);

  /* form states */
  const [CTA, setCTA] = useState();

  const [formName, setformName] = useState();
  const [formIcon, setFormIcon] = useState();
  const [okBtnText, setOkBtnText] = useState();
  const [okBtnIcon, setOkBtnIcon] = useState();
  const [categoryName, setcategoryName] = useState('');
  const [key, setKey] = useState('Storage Location');
  //callallEnventoryRequiredAPI
  useEffect(() => {
    // remove catch block later, its for auto sign in
    getStorageLocationTreeAction();
    getAllFormatsActions();
    setLocationStorageFormData([...createLocationStorage]);
    setcategoryFormData(createItemCategory);
    setCustomFieldsFormData(createCustomFields);
    getAllItemCategoriesAction();
    itemCategoryFormatAction();
  }, []);

  function deepEqual(value1, value2) {
    console.log(value1, value2)
    if (typeof value1 !== typeof value2) {
      return false;
    }

    if (typeof value1 !== 'object' || value1 === null || value2 === null) {
      return value1 === value2;
    }

    if (Array.isArray(value1)) {
      if (!Array.isArray(value2) || value1.length !== value2.length) {
        return false;
      }

      for (let i = 0; i < value1.length; i++) {
        if (!deepEqual(value1[i], value2[i])) {
          return false;
        }
      }

      return true;
    }

    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(value1[key], value2[key])) {
        return false;
      }
    }

    return true;
  }

  const addLocationStorage = async (data) => {
    // let payload = { ...data };
    // console.log(data);
    // if (locationStorageFormData[2].disabled) {
    //   payload.parentId = null;
    // }

    // payload.parentId = locationStorageFormData[2].data?.id;
    // payload.changeReason = '';
    if (data.name && data.name.length > STORAGE_LOCATION_NAME_MAXSIZE) {
      dispatch(
        showErrorReducer(
          `storage location name must not be empty or more than ${STORAGE_LOCATION_NAME_MAXSIZE} character`
        )
      );
    } else {
      dispatch(setFormLoaderReducer(true));
      const createdItem = await createStorageLocationAction({
        ...data,
        parentId: data?.StorageLocation?.id,
      });
      dispatch(setFormLoaderReducer(false));
      if (createdItem.status === 200) {
        await getStorageLocationTreeAction();
        // resetValues();
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(`${createdItem.data.name} location created.`)
        );

        setLocationStorageFormData(null);
      }
    }
  };

  const updatedStorageLocation = async (data, currentLocation) => {
    if (currentLocation) {
      // if (locationStorageFormData[2].data.id === currentLocation.id) {
      //   dispatch(
      //     showErrorReducer(`Selected location is the current location!`)
      //   );
      //   return;
      // }
      // const childLocations = flattternTree(currentLocation.children || []);
      // const isChildLocationSelected = childLocations.find(
      //   (ele) => ele.id === locationStorageFormData[2].data.id
      // );
      // if (isChildLocationSelected) {
      //   dispatch(
      //     showErrorReducer(`Child Can not be selected as parent Location!`)
      //   );
      //   return;
      // }
      // let payload = { ...data };

      // if (locationStorageFormData[2].disabled) {
      //   payload.parentId = null;
      // } else if (!locationStorageFormData[2].data) {
      //   dispatch(showErrorReducer(`Please Select Parent Location!`));
      //   return;
      // }
      // payload.parentId = locationStorageFormData[2].data?.id;

      dispatch(setFormLoaderReducer(true));
      const updatedItem = await updateStorageLocationByIdAction(
        currentLocation.id,
        { ...data, parentId: data?.StorageLocation?.id }
      );
      dispatch(setFormLoaderReducer(false));
      if (updatedItem?.status === 200) {
        await getStorageLocationTreeAction();
        resetValues();
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(`${updatedItem.data.name} location updated.`)
        );
        dispatch(setFormLoaderReducer(false));
      }
    }
  };

  const addLocationStorageCTA = () => {
    setCTA(() => async (data) => {
      addLocationStorage(data);
    });
    setFormValidation(() => (error, values, store) => {
      if (values?.name?.length > 20) {
        error.name = 'Max 20 characters allowed';
      }
      return error;
    });
  };

  const resetValues = () => {
    // locationStorageFormData[0]?.initialValue = '';
    // locationStorageFormData[1]?.initialValue = '';
    // locationStorageFormData[2]?.data = null;
    // locationStorageFormData[2]?.text = 'Select Location';
    // if (locationStorageFormData?.length === 4) {
    //   locationStorageFormData.pop();
    // }
    // setLocationStorageFormData(locationStorageFormData);
    // setFormsubHeading(null);
  };

  const editLocationCTA = (location) => {
    const flatData = flattternTree(locationTree);
    const parentLocation = flatData.find((ele) => ele.id === location.parentId);

    createLocationStorage[0].initialValue = location.name;
    createLocationStorage[1].initialValue = location.iconName;
    createLocationStorage[2].initialValue = {
      id: parentLocation.id,
      name: parentLocation.name,
    };
    createLocationStorage[2].text = parentLocation.name;

    updateSelectedLocation(parentLocation);
    setCTA(() => async (data) => {
      updatedStorageLocation(data, location);
    });

    setformName('Edit Storage Location');
    setFormIcon(<ExpiryAlertPurple />);
    setOkBtnText('Update');
    setOkBtnIcon(<Confirm />);
    dispatch(setForm({ state: true, type: 'editLocationStorage' }));
    setUpdatedData([...createLocationStorage, changeReason]);
  };

  const checkHasItem = (location, finalResult) => {
    if (location.hasItems) finalResult.hasItems = true;
    if (location.children.length > 0) {
      for (var i = 0; i < location.children.length; i++) {
        checkHasItem(location.children[i], finalResult);
      }
    }
  };

  const deleteLocationCTA = (location) => {
    let finalResult = { hasItems: false };
    checkHasItem(location, finalResult);

    const flatData = flattternTree(locationTree);
    const parentLocation = flatData.find((ele) => ele.id === location.parentId);
    updateSelectedLocation(parentLocation);
    if (finalResult.hasItems) {
      deleteWithMoveCTA(location);
    } else {
      deleteWithoutMoveCTA(location);
    }
  };

  const deleteWithMoveCTA = (location) => {
    // const subheading = (
    //   <FormSubheading
    //     subheading={textConstants.deleteWithMoveSubheading}
    //     icon={<InfoAlert />}
    //   />
    // );
    // setFormsubHeading(subheading);
    setCTA(() => async (data) => {
      deleteStorageLocationWithMove(data, location);
    });
    console.log(outerBreadrumb, location);
    setUpdatedData([
      {
        label: 'Storage Location Name',
        Icon: <Location />,
        type: 'breadcrumb',
        initialValue: outerBreadrumb,
        placeholder: location?.name,

        fluid: true,
      },
      {
        fluid: true,
        type: 'paragraph',
        initialValue:
          'You have chosen to delete this storage location. If you delete it, any items that are assigned to this storage location or any of its dependent locations will be moved to a new storage location.',
      },
      {
        fluid: true,
        type: 'paragraph',
        initialValue: 'Choose a storage location to move affected items.',
      },
      moveLocation,
      deleteReason,
    ]);
    setformName('Delete Storage Location');
    setFormIcon(<DeletePurple />);
    setOkBtnText('Confirm');
    setOkBtnIcon(<Confirm />);
    dispatch(setForm({ state: true, type: 'deleteStorageLocation' }));
  };

  const deleteWithoutMoveCTA = (location) => {
    // const path = findPath(locationTree, 'name', location.name);

    // const subheading = (
    //   <FormSubheading
    //     subheading={location.name}
    //     icon={<Location />}
    //     path={path}
    //   />
    // );
    // setFormsubHeading(subheading);
    setCTA(() => async (data) => {
      deleteStorageLocationWithoutMove(data, location);
    });

    setUpdatedData([
      {
        label: 'Storage Location Name',
        Icon: <Location />,
        type: 'breadcrumb',
        initialValue: outerBreadrumb,
        placeholder: location?.name,

        fluid: true,
      },
      deleteReason,
    ]);
    setformName('Delete Storage Location');
    setFormIcon(<DeletePurple />);
    setOkBtnText('Confirm');
    setOkBtnIcon(<Confirm />);
    dispatch(setForm({ state: true, type: 'deleteStorageLocation' }));
  };

  const deleteStorageLocationWithoutMove = async (data, location) => {
    dispatch(setFormLoaderReducer(true));
    console.log(location);
    const deletedItem = await deleteStorageLocationByIdAction(
      location.id,
      data?.deleteReason
    );
    dispatch(setFormLoaderReducer(false));
    if (deletedItem?.status === 200) {
      await getStorageLocationTreeAction();
      resetValues();
      dispatch(setFormCloseReducer());
      dispatch(showSuccessReducer(`${location?.name} deleted.`));
    }
  };

  const deleteStorageLocationWithMove = async (data, location) => {
    dispatch(setFormLoaderReducer(true));
    console.log(location, data);
    const movedLocation = await moveStorageLocationAction(
      location?.id,
      data?.StorageLocation?.id
    );

    if (movedLocation.status === 200) {
      const deletedItem = await deleteStorageLocationByIdAction(
        location.id,
        data.deleteReason
      );
      dispatch(setFormLoaderReducer(false));
      if (deletedItem?.status === 200) {
        await getStorageLocationTreeAction();
        resetValues();
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(
            `${location.name} deleted and items moved to ${data?.StorageLocation?.name}`
          )
        );
      }
    } else {
      dispatch(setFormLoaderReducer(false));
    }
  };

  const onFormChange = (event) => {
    if (event.type === 'explorer') {
      handleShow();
    }
  };

  const handleClose = () => {
    setlocationModalTitleProps(dataConstatnt.defaultLocationModalProps);
    setShow(false);
  };

  const handleShow = () => {
    if (uisettings.formName === 'deleteStorageLocation') {
      const modalProps = {
        title: 'Move Items',
        icon: <MoveInventory />,
        subTitle: textConstants.chooseNewLocationText,
      };
      setlocationModalTitleProps(modalProps);
    }
    setShow(true);
  };

  const chooseLocation = () => {
    if (uisettings.formName === 'addlocationStorage') {
      locationStorageFormData[2].text = selectedLocation.name;
      locationStorageFormData[2].data = { ...selectedLocation };
      setLocationStorageFormData([...locationStorageFormData]);
    }

    if (uisettings.formName === 'deleteStorageLocation') {
      moveLocation.text = selectedLocation.name;
      moveLocation.data = { ...selectedLocation };
    }

    dispatch(showErrorReducer(''));
    handleClose();
  };

  useEffect(() => {
    if (storageLocation.locationTree) {
      const modifiedLocationTree = addPropertyToTree(
        storageLocation.locationTree,
        'expand',
        false
      );
      // const modifiedPopupTree = structuredClone(modifiedLocationTree);
      setLocationTree([...modifiedLocationTree]);
      setPopupTree([...modifiedLocationTree]);
    }
  }, [storageLocation.locationTree]);

  useEffect(() => {
    if (common) {
      if (common.allCategories) {
        setCategoriesData(common?.allCategories);
      }
    }
  }, [common]);

  function expandOrSelectItem(explorer, source = 'sidebar') {
    // change 'name' to 'id' when IDs will be the unique key, otherwise issues are comming for duplicate name or ID
    const result = markItemInTree(popupTree, 'name', explorer.name, source);
    updateSelectedLocation({
      name: explorer.name,
      id: explorer.id,
    });
    setPopupTree([...result.data]);
  }

  const addCategoryCTA = (isEdit) => {
    setCTA(() => async (data) => {
      if (isEdit) {
        addCategory(data);
      } else {
        addCategory(data);
      }
    });
    setUpdatedData(categoryFormData);
    setformName('Add Item Category');
    setFormIcon(<AddCategory />);
    setOkBtnText('Create');
    setOkBtnIcon(<Add color="#FFFFFF" />);
    dispatch(setForm({ state: true, type: 'addCategory' }));
    setFormValidation(() => (error, values, store) => {
      if (values.name?.length > 50) {
        error.name = 'Max 50 characters allowed';
      }
      return error;
    });
  };

  const addCustomFieldsCTA = (isEdit) => {
    setCTA(() => async (data) => {
      if (isEdit) {
        addCustomField(data);
      } else {
        addCustomField(data);
      }
    });
    setOkBtnIcon(<Confirm />);
    setOkBtnText('Save');
    createCustomFields[1].options = common.itemCategoryFormat || [];
    createCustomFields[2].disabled = true;
    setUpdatedData(createCustomFields);
    setformName('Add Custom Field');
    setFormIcon(<CustomField />);
    dispatch(setForm({ state: true, type: 'addCustomField' }));
    setFormValidation(() => (error, values, store) => {
      if (
        storageLocation?.itemCategories?.fields?.filter(
          (f) => f.name?.toLowerCase() === values.name?.toLowerCase()
        )?.length > 0
      ) {
        error.name = 'Name is already taken';
      }
      if (values.name?.length > 100) {
        error.name = 'Max 100 characters allowed';
      }
      return error;
    });
  };

  const onFormClose = (data) => {
    resetValues();
  };

  const addCategory = async (data) => {
    let payload = { ...data };
    try {
      // payload.customFields = defaultFields;
      dispatch(setFormLoaderReducer(true));
      const createdItem = await createItemCategoryAction(payload);
      if (createdItem?.data?.id) {
        const newCategories = [...categoriesData];
        newCategories.push(createdItem.data);
        setCategoriesData(newCategories);
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${createdItem.data.name} item created.`));
        dispatch(setFormLoaderReducer(false));
      }
    } catch (error) {
      //dispatch(showErrorReducer(`${error.message}`));
      dispatch(setFormLoaderReducer(false));
    }
  };

  const addCustomField = async (data) => {
    dispatch(setFormLoaderReducer(true));
    const updatedItem = await createItemCategoryCustomFieldAction(
      storageLocation.itemCategories?.id,
      {
        ...data,
        options: data.options?.length
          ? data.options?.split(',')?.map((opt) => {
              return { name: opt };
            })
          : undefined,
      }
    );
    if (updatedItem?.data?.id) {
      dispatch(setFormCloseReducer());
      dispatch(showSuccessReducer(`${updatedItem.data.name} item updated.`));
      dispatch(setFormLoaderReducer(false));
    } else {
      dispatch(setFormLoaderReducer(false));
    }
  };

  const removeCategoryCTA = async (data) => {
    Remove[0].label = 'Item Category Name';
    Remove[0].initialValue = data.name;
    Remove[0].Icon = <NameKit />;
    Remove[1].label = 'Delete Reason';
    setUpdatedData(Remove);
    setformName('Delete Item Category');
    setFormIcon(<DeletePurple />);
    setOkBtnText('Confirm ');
    setOkBtnIcon(<Delete color="#FFFFFF" />);
    dispatch(setForm({ state: true, type: 'DeleteItemCategory' }));
    setCTA(() => async (formValue) => {
      console.log(formValue);
      const change_reason = formValue?.change_reason?.trim();
      dispatch(setFormLoaderReducer(true));
      const deletedItem = await deleteItemCategoryByIdAction(
        data.id,
        change_reason
      );
      if (deletedItem.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${data.name} cetegory deleted.`));
        dispatch(setFormLoaderReducer(false));
      } else {
        dispatch(setFormLoaderReducer(false));
      }
    });
  };

  useEffect(() => {
    localStorage.setItem('activeLocation', JSON.stringify(copyExploral));
    localStorage.setItem('activeTree', JSON.stringify(copyTree));
  }, [copyExploral]);

  const deleteCustomFieldCTA = async (data) => {
    Remove[0].label = 'Item Category Name';
    Remove[0].Icon = <NameKit />;
    Remove[0].initialValue = data.name;
    setUpdatedData(Remove);
    setformName('Delete Custom Field');
    setFormIcon(<DeletePurple />);
    setOkBtnText('Confirm');
    setOkBtnIcon(<Confirm />);
    dispatch(setForm({ state: true, type: 'deleteCustomField' }));
    setCTA(() => async (formValue) => {
      dispatch(setFormLoaderReducer(true));
      const change_reason = formValue?.change_reason?.trim();
      const deletedItem = await deleteItemCategoryFieldIdAction(
        storageLocation.itemCategories?.id,
        data.id,
        change_reason
      );
      if (deletedItem.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`${data.name} field deleted.`));
        dispatch(setFormLoaderReducer(false));
      } else {
        dispatch(setFormLoaderReducer(false));
      }
    });
  };

  const updateItemCategoryFields = async () => {
    Remove[0].initialValue =
      categoryName || storageLocation.itemCategories?.name;
    Remove[0].label = 'Item Category Name';
    Remove[0].Icon = <NameKit />;
    Remove[1].label = 'Edit Reason';
    setUpdatedData(Remove);
    setOkBtnText('Save');
    setOkBtnIcon(<Confirm />);
    setformName('Edit Item Category');
    setFormIcon(<ExpiryAlertPurple />);
    dispatch(setForm({ state: true, type: 'editItemCategory' }));
    setCTA(() => async (formValue) => {
      dispatch(setFormLoaderReducer(true));
      const change_reason = formValue?.change_reason?.trim();
      const editItemItem = await updateBulkCustomFieldsAction(
        storageLocation.itemCategories?.id,
        storageLocation.itemCategories?.fields,
        change_reason,
        categoryName,
        !deepEqual(initialState, storageLocation.itemCategories?.fields)
      );
      if (editItemItem?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(
          showSuccessReducer(`${storageLocation.itemCategories.name} updated`)
        );
        dispatch(setFormLoaderReducer(false));
        itemCategoryRef.current.toggleEditState();
      } else {
        dispatch(setFormLoaderReducer(false));
      }
    });
  };

  useEffect(() => {
    if (
      common.itemCategoryFormat?.name === 'Single Select' ||
      common.itemCategoryFormat?.name === 'Multiple Select'
    ) {
      createCustomFields[2].disabled = false;
      createCustomFields[2].required = true;
    } else {
      createCustomFields[2].disabled = true;
      createCustomFields[2].initialValue = undefined;
      createCustomFields[2].placeholder = 'Enter Options';
    }
  }, [common.itemCategoryFormat]);

  if (
    !(
      common?.permissions?.['Storage Locations']?.read ||
      common?.permissions?.['Item Categories']?.read
    )
  ) {
    return <NoPermissionAlert />;
  }

  return (
    <>
      <div className="flex justify-between mb-[27px] items-align">
        <HemaHeadingWithSubText
          heading="Settings"
          sub="Manage your organization here."
        />
        {key === 'Storage Location' ? (
          common?.permissions?.['Storage Locations']?.write && (
            <Button
              text="Add Storage Location"
              Icon={<Add color="white" />}
              color="text-white"
              bg="bg-primary1"
              className="shrink-[0] h-fit"
              cta={() => {
                createLocationStorage[0].initialValue = '';
                createLocationStorage[1].placeholder = 'Select Icon';
                createLocationStorage[1].initialValue = '';
                createLocationStorage[2].initialValue = '';
                setUpdatedData(createLocationStorage);
                setformName('Add Storage Location');
                setFormIcon(<StorageGradient />);
                setOkBtnText('Create');
                setOkBtnIcon(<Add color="#FFFFFF" />);
                dispatch(setForm({ state: true, type: 'addlocationStorage' }));
                addLocationStorageCTA();
              }}
            />
          )
        ) : (
          <></>
        )}
      </div>

      <Alert />
      <div className="bg-white rounded-[5px]  py-[15px] mt-[27px] mb-[13px] inventory-tabs">
        <Tabs
          activeKey={key}
          id="controlled-tab-example"
          onSelect={(k) => {
            if (k === 'Storage Location') {
              navigate('/storage?type=Storage Location');
            } else if (k === 'Item Categories') {
              navigate('/storage?type=Item Categories');
            }
            setKey(k);
          }}
          className="mb-3 ml-[16px]"
        >
          {common?.permissions?.['Storage Locations']?.read && (
            <Tab
              eventKey="Storage Location"
              title={
                <div className="flex items-center title-icon gap-[7px] ">
                  {key === 'Storage Location' ? (
                    <CompanyLocation />
                  ) : (
                    <StorageLocation />
                  )}
                  Storage Location
                </div>
              }
            >
              {locationTree === null ? (
                <div className="p-[16px]">
                  <br />
                  <Skeleton count={4} />
                  <br />
                  <Skeleton count={4} />
                  <br />
                  <Skeleton count={4} />
                </div>
              ) : locationTree.length ? (
                <StorageLocationsComponent
                  explorer={locationTree}
                  updateSelectedLocation={updateSelectedLocation}
                  selectedLocation={selectedLocation}
                  editCTA={editLocationCTA}
                  deleteCTA={deleteLocationCTA}
                  setCopyExploral={setCopyExploral}
                  setCopyTree={setCopyTree}
                  setouterBreadrumb={setouterBreadrumb}
                />
              ) : (
                <AlertBootstrap variant="warning">
                  No storage locations to show. Please add by clicking on Add
                  Storage Location.
                </AlertBootstrap>
              )}
            </Tab>
          )}
          {common?.permissions?.['Item Categories']?.read && (
            <Tab
              eventKey="Item Categories"
              title={
                <div className="flex items-center title-icon gap-[7px]">
                  {key === 'Item Categories' ? (
                    <AddCategory />
                  ) : (
                    <ItemCategory />
                  )}
                  Item Categories
                </div>
              }
            >
              <ItemCategoriesComponent
                ref={itemCategoryRef}
                categories={categoriesData}
                addCategoryCTA={addCategoryCTA}
                addCustomFieldsCTA={addCustomFieldsCTA}
                removeCategoryCTA={removeCategoryCTA}
                itemCategoryFormat={common.itemCategoryFormat}
                deleteCustomFieldCTA={deleteCustomFieldCTA}
                updateItemCategoryFields={updateItemCategoryFields}
                categoryName={categoryName}
                setcategoryName={setcategoryName}
                setInitialState={setInitialState}
              />
            </Tab>
          )}
        </Tabs>
      </div>

      {uisettings?.openform && (
        <FormContainer
          cta={CTA}
          formType={updatedData}
          formName={formName}
          Icon={formIcon}
          okBtnText={okBtnText}
          okBtnIcon={okBtnIcon}
          onFormChange={onFormChange}
          onFormClose={onFormClose}
          subheadingComponent={subheadingComponent || undefined}
          formValidation={formValidation}
        />
      )}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="contents">
            <TextWithIcon
              text={locationModalTitleProps.title}
              icon={locationModalTitleProps.icon}
              verticalAlignClass="align-baseline"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {locationModalTitleProps.subTitle && (
            <h6 className="mb-4">
              {' '}
              {locationModalTitleProps.subTitle} <hr />{' '}
            </h6>
          )}
          <div className="mt-3 d-flex storage-location-container">
            <div className="flex-shrink-0 location-col">
              <ul className="p-0 m-0 list-unstyled">
                {popupTree.map((item) => {
                  return (
                    <li className="mb-2 parent-lst" key={item.id}>
                      <Explorer
                        explorer={item}
                        onItemClick={expandOrSelectItem}
                        showOptions={false}
                        selectedLocation={selectedLocation}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            text="Cancel"
            bg="bg-white"
            border="border-primary1"
            color="text-primary1"
            Icon={<Cancel />}
            cta={handleClose}
          />
          <Button
            disabled={selectedLocation === null}
            text={selectedLocation === null ? 'Select' : selectedLocation.name}
            Icon={<Save color="white" />}
            color="text-white"
            bg="bg-primary1"
            cta={chooseLocation}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Storage;
