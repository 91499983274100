import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, HemaLabel, HemaValue, FormText } from '../../../utils';
import { AddBlack, AddInKit, Cancel, EditBlue, BlueTick, Delete, CrossIcon } from '../../../HemeIconLibrary';


import ItemCategoryField from './categoryField';
import { getItemCategoryByIdAction } from '../../../Actions/itemCategory';
import Skeleton from 'react-loading-skeleton';
import { setItemCategoriesReducer } from '../../../Store/reducers/storageLocation';
import { Alert } from 'react-bootstrap';

const ItemCategoriesComponent = forwardRef(
  ({ setInitialState, addCategoryCTA, removeCategoryCTA, addCustomFieldsCTA, itemCategoryFormat, deleteCustomFieldCTA, updateItemCategoryFields, categoryName, setcategoryName }, ref) => {
    const dispatch = useDispatch();
    const { common, storageLocation } = useSelector((state) => state);
    const [showEditState, setShowEditState] = useState(false);

    useEffect(() => {
      setShowEditState(false);
      setcategoryName(storageLocation.itemCategories?.name);
    }, [storageLocation.itemCategories?.id]);

    useEffect(() => {
      if (common) {
        if (common.allCategories?.length) {
          getItemCategoryByIdAction(common.allCategories[0]?.id);
        }
      }
    }, [common?.allCategories]);

    const  handleItemCategoryClick = async (category) => {
      dispatch(setItemCategoriesReducer(null));
      const result = await getItemCategoryByIdAction(category.id)
      if(result.data) {
      setInitialState(result.data?.fields)
      }
    };

    useImperativeHandle(ref, () => ({
      toggleEditState() {
        setShowEditState((prevState) => !prevState);
      },
    }));

    return (
      <>
        <div className="flex mt-[26px] h-full">
          <div className="border-r-2 border-solid border-[#ccc] w-[250px] ">
            {common?.permissions?.['Item Categories']?.write && <Button text={'Add Item Category'} Icon={<AddBlack w="10" h="10" />} className="p-[16px]" cta={addCategoryCTA} />}
            <div className="">
              {common.allCategories ? (
                common.allCategories?.map((category) => {
                  return (
                    <div
                      className={
                        storageLocation.itemCategories?.id === category.id ? 'bg-[#F9F5FF] cursor-pointer min-h-10 px-[16px] py-2' : 'cursor-pointer t px-[16px] min-h-10 py-2'
                      }
                      onClick={() => handleItemCategoryClick(category)}
                    >
                      <HemaValue
                        text={category.name}
                        key={category.id}
                        className="leading-6 capitalize break-words cursor-pointer"
                        color={`text-[${storageLocation.itemCategories?.id === category.id ? '#605DAF' : '#353B4A'}]`}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="p-[16px]">
                  <Skeleton count={1} />
                  <Skeleton count={1} />
                  <Skeleton count={1} />
                </div>
              )}
              {common.allCategories?.length === 0 && <Alert variant="warning">No Categories Available.</Alert>}
            </div>
          </div>
          <div className="w-full mt-2 ml-[15px]">
            {storageLocation.itemCategories && (
              <>
                <div className="flex gap-[16px] items-center  ml-[15px] mb-[2px]">
                  <div className="relative flex">
                    <input
                      className={` text-xl font-extrabold pr-[20px] capitalize ${
                        showEditState && 'min-w-[289px] h-[30px] px-[6px] border border-solid border-[#DEE2E6] rounded-md flex items-center '
                      }`}
                      type="text"
                      name="category"
                      value={categoryName}
                      readOnly={showEditState ? false : true}
                      onChange={(e) => {
                        setcategoryName(e.target.value);
                      }}
                    />
                    {categoryName && showEditState && (
                      <span
                        className="absolute top-[10px] right-[8px]"
                        onClick={() => {
                          setcategoryName('');
                        }}
                      >
                        <CrossIcon />
                      </span>
                    )}
                  </div>
                  <div className="flex items-center gap-[16px]">
                    {showEditState ? (
                      <>
                        <div
                          className="cursor-pointer "
                          onClick={() => {
                            updateItemCategoryFields();
                          }}
                        >
                          <BlueTick color="#775FD5" />
                        </div>
                        <div
                          onClick={() => {
                            setShowEditState(false);
                            setcategoryName(storageLocation.itemCategories?.name);
                            getItemCategoryByIdAction(storageLocation.itemCategories?.id);
                          }}
                          className="cursor-pointer "
                        >
                          <Cancel color="#775FD5" />
                        </div>
                      </>
                    ) : (
                      <>
                        {common?.permissions?.['Item Categories']?.update && (
                          <div
                            className="cursor-pointer "
                            onClick={() => {
                              setShowEditState(true);
                            }}
                          >
                            <EditBlue color="#775FD5" />
                          </div>
                        )}
                        {common?.permissions?.['Item Categories']?.delete && (
                          <div
                            className="cursor-pointer "
                            onClick={() => {
                              removeCategoryCTA(storageLocation.itemCategories);
                            }}
                          >
                            <Delete color="#F87FB1" />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <HemaValue className="font-normal text-base pl-[15px] " text={`You have ${storageLocation.itemCategories?.numberOfItems} items in this category.`} />
              </>
            )}
            {!storageLocation.itemCategories ? (
              <div className="px-[16px]">
                <Skeleton count={4} />
                <br />
                <Skeleton count={4} />
                <br />
                <Skeleton count={4} />
              </div>
            ) : (
              <ItemCategoryField
                selectedCategory={storageLocation.itemCategories}
                addCustomFieldsCTA={addCustomFieldsCTA}
                showEditState={showEditState}
                itemCategoryFormat={itemCategoryFormat}
                deleteCustomFieldCTA={deleteCustomFieldCTA}
                dispatch={dispatch}
              />
            )}
            {storageLocation.itemCategories && !showEditState && (
              <div className="flex w-full border-b-[1px] border-solid border-b-[#DEE2E6]">
                {common?.permissions?.['Item Categories']?.write && (
                  <Button type="button" cta={addCustomFieldsCTA} Icon={<AddInKit />} text="Custom Fields " className="text-xs leading-[18px] text-[#232323]" />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  },
);

export default ItemCategoriesComponent;
