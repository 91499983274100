import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  getEmailTemplateTypeAction,
  createEmailTemplateAction,
  updateEmailTemplateAction,
  getMergefieldforEmailAction,
  getAllEmailTemplateAction,
} from "../../../Actions/emailTemplate";

import { HemaLabel, Button, FormTextarea, FormSelect, FormText, FormCheckbox, SingleSelect } from "../../../utils";
import { Cancel, CrossIcon, Add, LabelName, Email, ChangeReason, LabelQuantity, MergeField, Confirm } from "../../../HemeIconLibrary";

import { setForm, showSuccessReducer } from "../../../Store/reducers/uiSettings";

const CreateEmail = () => {
  const formikRef = useRef();
  const bodyRef = useRef();
  const subjectRef = useRef();
  const { uisettings, emailTemplate } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [activeType, setActiveType] = useState("");
  const [mergeFieldbyId, setMergeFieldbyId] = useState([]);
  const [activeInputType, setActiveInputType] = useState();
  //   const [to, setTo] = useState([]);
  //   const [cc, setCc] = useState([]);
  //   const [bcc, setBcc] = useState([]);
  useEffect(() => {
    getEmailTemplateTypeAction();
  }, []);

  useEffect(() => {
    if (activeType) {
      getMergefieldforEmailAction(activeType);
    }
  }, [activeType]);

  useEffect(() => {
    if (emailTemplate.mergeField?.length) {
      setMergeFieldbyId(
        emailTemplate.mergeField?.map((data) => {
          return {
            name: data.name,
            value: data.mergeFieldKey,
          };
        }),
      );
    }
  }, [emailTemplate.mergeField]);

  return (
    <>
      <div className={"block h-full"}>
        <Formik
          initialValues={{
            typeId: uisettings.editForm?.type?.id || "",
            name: uisettings.editForm?.name || "",
            subject: uisettings.editForm?.subject || "",
            body: uisettings.editForm?.body || "",
            isDefault: uisettings.editForm?.isDefault || false,
            changeReason: "",
          }}
          innerRef={formikRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.subject) {
              errors.subject = "Required";
            } else if (values.subject.length > 250) {
              errors.subject = "Max 250 characters allowed";
            }

            if (!values.body) {
              errors.body = "Required";
            } else if (values.body.length > 2500) {
              errors.body = "Max 2500 characters allowed";
            }

            if (!values.name) {
              errors.name = "Required";
            } else if (values.name.length > 100) {
              errors.name = "Max 100 characters allowed";
            }

            if (!values.typeId) {
              errors.typeId = "Required";
            }

            if (!values.changeReason && uisettings.editForm?.type?.id) {
              errors.changeReason = "Required";
            } else if (values.changeReason.length > 200) {
              errors.changeReason = "Max 200 characters allowed";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            console.log(values);
            if (uisettings.editForm?.id) {
              const result = await updateEmailTemplateAction(uisettings.editForm?.id, values);
              if (result.status === 200) {
                getAllEmailTemplateAction();
                dispatch(setForm({ state: false, type: "" }));
                dispatch(showSuccessReducer(` Email template updated`));
              }
            } else {
              const result = await createEmailTemplateAction(values);
              if (result.status === 200) {
                getAllEmailTemplateAction();
                dispatch(setForm({ state: false, type: "" }));
                dispatch(showSuccessReducer(`Email template added`));
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            initialValues,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-50px)]">
              <div>
                <div className={"w-full"}>
                  <div className="mb-[16px] ">
                    <HemaLabel text={"Email Template Name"} Icon={<LabelName />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormText
                        placeholder={"Enter Name"}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        className="pr-[40px]"
                        crossIcon={values.name ? <CrossIcon /> : ""}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {errors.name && touched.name && <div className="error text-[red] text-[12px] pt-[2px]">{errors.name}</div>}
                  </div>
                </div>

                <div className={"w-full"}>
                  <div className="mb-[16px] ">
                    <HemaLabel text={"Email Template Type"} Icon={<ChangeReason />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormSelect
                        formikRef={formikRef}
                        placeholder="Select"
                        name="typeId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.typeId}
                        options={emailTemplate?.allEmailTemplateTypes}
                        crossIcon={values?.typeId ? <CrossIcon /> : ""}
                        setFieldValue={(_, value) => {
                          setMergeFieldbyId([]);
                          setFieldValue("typeId", value);
                          setActiveType(value);
                        }}
                        dispatch={dispatch}
                      />
                    </div>
                    {errors.typeId && touched.typeId && <div className="error text-[red] text-[12px] pt-[2px]">{errors.typeId}</div>}
                  </div>
                </div>
                <div className={"w-full"}>
                  <div className="mb-[16px] ">
                    <HemaLabel text={"Merge Fields"} Icon={<MergeField />} className={`mb-[10px]`} />
                    <div className="relative">
                      <SingleSelect
                        placeholder={"select"}
                        name="Merge Fields"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.typeId}
                        options={mergeFieldbyId}
                        disabled={mergeFieldbyId.length ? false : true}
                        className="pr-[40px]"
                        crossIcon={values.typeId ? <CrossIcon /> : ""}
                        setFieldValue={(_, value) => {
                          console.log(activeInputType);
                          if (activeInputType === "body") {
                            setFieldValue(
                              "body",
                              `${bodyRef.current.defaultValue?.substring(0, bodyRef.current.selectionStart)} ${value} ${bodyRef.current.defaultValue?.substring(
                                bodyRef.current.selectionStart,
                                bodyRef.current.defaultValue.length,
                              )} `,
                            );
                          } else if (activeInputType === "subject") {
                            setFieldValue(
                              "subject",
                              `${subjectRef.current.defaultValue?.substring(0, subjectRef.current.selectionStart)} ${value} ${subjectRef.current.defaultValue?.substring(
                                subjectRef.current.selectionStart,
                                subjectRef.current.defaultValue.length,
                              )} `,
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={"w-full"}>
                  <div className="mb-[16px] ">
                    <HemaLabel text={"Email Subject"} Icon={<LabelQuantity />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormText
                        placeholder={"Enter Subject"}
                        name="subject"
                        onChange={handleChange}
                        value={values.subject}
                        className="pr-[40px]"
                        crossIcon={values.subject ? <CrossIcon /> : ""}
                        setFieldValue={setFieldValue}
                        onBlur={(e) => {
                          handleBlur(e);
                          setActiveInputType("subject");
                        }}
                        subjectref={subjectRef}
                      />
                    </div>
                    {errors.subject && touched.subject && <div className="error text-[red] text-[12px] pt-[2px]">{errors.subject}</div>}
                  </div>
                </div>
                <div className={"w-full"}>
                  <div className="mb-[16px] ">
                    <HemaLabel text={"Email Body"} Icon={<Email />} required={true} className={`mb-[10px]`} />
                    <div className="relative">
                      <FormTextarea
                        placeholder={"Type email"}
                        name="body"
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          setActiveInputType("body");
                        }}
                        value={values.body}
                        className="pr-[40px]"
                        crossIcon={values.body ? <CrossIcon /> : ""}
                        setFieldValue={setFieldValue}
                        rows="9"
                        bodyref={bodyRef}
                      />
                    </div>
                    {errors.body && touched.body && <div className="error text-[red] text-[12px] pt-[2px]">{errors.body}</div>}
                  </div>
                </div>

                <div className={"w-full"}>
                  <div className="mb-[16px] flex items-start gap-[10px]">
                    <HemaLabel text={"Default Email"} Icon={<LabelName />} />
                    <div className="relative parent-level-tree">
                      <FormCheckbox
                        type="checkbox"
                        name="isDefault"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.isDefault}
                        checked={values.isDefault}
                        className="pr-[40px] parent_checkbox"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>

                {uisettings.editForm?.id && (
                  <div className={"w-full"}>
                    <div className="mb-[16px] ">
                      <HemaLabel text={"change Reason"} Icon={<ChangeReason />} required={true} className={`mb-[10px]`} />
                      <div className="relative">
                        <FormTextarea
                          placeholder={"Enter Reason"}
                          name="changeReason"
                          onBlur={(e) => {
                            handleBlur(e);
                            setActiveInputType("changeReason");
                          }}
                          onChange={handleChange}
                          value={values.changeReason}
                          className="pr-[40px]"
                          crossIcon={values.changeReason ? <CrossIcon /> : ""}
                          setFieldValue={setFieldValue}
                          subjectref={subjectRef}
                        />
                      </div>
                      {errors.changeReason && touched.changeReason && <div className="error text-[red] text-[12px] pt-[2px]">{errors.changeReason}</div>}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex gap-[8px] justify-end my-[20px]">
                <Button
                  cta={() => {
                    dispatch(setForm({ state: false, type: "" }));
                  }}
                  type="button "
                  text="Cancel"
                  bg="bg-white"
                  border="border-primary1"
                  color="text-primary1"
                  Icon={<Cancel />}
                />

                {isSubmitting ? (
                  <Button
                    type="button"
                    bg="bg-primary1"
                    text={
                      <>
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                        <Spinner animation="grow" size="sm" variant="light" />
                      </>
                    }
                  />
                ) : (
                  <>
                    <Button
                      type="submit"
                      disabled={
                        uisettings.editForm?.id
                          ? values.name && values.subject && values.typeId && values.body && values.changeReason
                            ? false
                            : true
                          : values.name && values.subject && values.typeId && values.body
                          ? false
                          : true
                      }
                      text={uisettings.editForm?.id ? "Update" : "Create"}
                      bg="bg-primary1"
                      color="text-white"
                      Icon={uisettings.editForm?.id ? <Confirm /> : <Add color="#ffffff" />}
                    />
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CreateEmail;
