import { Button } from '../../utils';
import no from '../../assets/images/no.png';
import { useDispatch } from 'react-redux';

const NoDataComponent = ({ setActiveFilterTab, activeFilterOriginalData, resetRedux, text }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col items-center justify-center mt-[20px]">
      <img src={no} alt="" />
      {text ? text : "We couldn't find results."}
      {!text && (
        <Button
          cta={() => {
            if (setActiveFilterTab && activeFilterOriginalData && resetRedux) {
              setActiveFilterTab(activeFilterOriginalData);
              dispatch(resetRedux([]));
            } else {
              window.location.reload();
            }
          }}
          className="text-white bg-primary1 my-[40px]"
          text="Reset the filters"
        ></Button>
      )}
    </div>
  );
};

export default NoDataComponent;
