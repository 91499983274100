const addDepotValidation = (errors, values, store) => {
  if (values?.name?.length > 100) {
    errors.name = "Max 100 characters allowed";
  }
  if (values?.eori?.length > 100) {
    errors.eori = "Max 100 characters allowed";
  }
  if (values?.BrokerContactname?.length > 100) {
    errors.BrokerContactname = "Max 100 characters allowed";
  }
  if (values?.BrokerCompanyname?.length > 100) {
    errors.BrokerCompanyname = "Max 100 characters allowed";
  }
  if (values?.brokerAddress?.address1.length > 100) {
    errors.brokerAddress.address1 = "Max 100 characters allowed";
  }
  if (values?.brokerContact?.length > 100) {
    errors.brokerContact = "Max 100 characters allowed";
  }
  if (values?.brokerAddress1?.length > 100) {
    errors.brokerAddress1 = "Max 100 characters allowed";
  }
  if (values?.brokerAddress2?.length > 100) {
    errors.brokerAddress2 = "Max 100 characters allowed";
  }
  if (values?.IORAddress1?.length > 100) {
    errors.IORAddress1 = "Max 100 characters allowed";
  }
  if (values?.IORAddress2?.length > 100) {
    errors.IORAddress2 = "Max 100 characters allowed";
  }
  if (values?.brokercity?.length > 100) {
    errors.brokercity = "Max 100 characters allowed";
  }
  if (values?.IORcity?.length > 100) {
    errors.IORcity = "Max 100 characters allowed";
  }
  if (values?.borkerPostalcode?.length > 100) {
    errors.borkerPostalcode = "Max 100 characters allowed";
  }
  if (values?.IORContactname?.length > 100) {
    errors.IORContactname = "Max 100 characters allowed";
  }
  if (values?.IORCompanyname?.length > 100) {
    errors.IORCompanyname = "Max 100 characters allowed";
  }
  if (values?.IORstate?.length > 100) {
    errors.IORstate = "Max 100 characters allowed";
  }
  if (values?.brokerstate?.length > 100) {
    errors.brokerstate = "Max 100 characters allowed";
  }
  if (values?.IORPostalcode?.length > 100) {
    errors.IORPostalcode = "Max 100 characters allowed";
  }
  if (values?.brokerPhone?.length > 100) {
    errors.IORPostalcode = "Max 100 characters allowed";
  }
  if (values?.IORPhone?.length > 100) {
    errors.IORPostalcode = "Max 100 characters allowed";
  }

  if (!values?.brokerEmail) {
    errors.brokerEmail = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.brokerEmail)) {
    errors.brokerEmail = "Please enter correct email format";
  }
  if (!values?.IOREmail) {
    errors.IOREmail = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.IOREmail)) {
    errors.IOREmail = "Please enter correct email format";
  }
  return errors;
};

export default addDepotValidation;
