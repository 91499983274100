import { useEffect, useRef, useState } from 'react';
import { setDropdownValue } from '../../Store/reducers/common';
import Multiselect from 'multiselect-react-dropdown';

import { Cancel, SelectDownArrow, CrossIcon } from '../../HemeIconLibrary';

import './style.scss';

function FormMultiSelect(props) {
  const { options, setFieldValue, name, value, dispatch, placeholder, disabled, formikRef } = props;
  const [selectLabel, setSelectLabel] = useState([]);
  const multiRef = useRef();
  useEffect(() => {
    setFieldValue(name, selectLabel);
  }, [selectLabel?.length]);

  useEffect(() => {
    if (value) {
      setSelectLabel(value);
    }
  }, []);
  // useEffect(() => {
  //   if (name && disabled) {
  //     setFieldValue(name, '');
  //     setSelectLabel([]);
  //   }
  // }, [disabled]);

  return (
    <div className="relative">
      <Multiselect
        options={options}
        selectedValues={value}
        onSelect={(selectedList, selectedItem) => {
          setSelectLabel(selectedList);
          if (setFieldValue) {
            setFieldValue(name, selectedItem.id);
          }
          if (!!formikRef) {
            formikRef.current?.setFieldTouched(name);
          }
          if (dispatch) {
            dispatch(setDropdownValue(selectedItem));
          }
        }} // Function will trigger on select event
        onRemove={(selectedList) => {
          setSelectLabel(selectedList);
        }}
        className="hema-form-dropdown-custom dropdown-selected"
        displayValue="name"
        customCloseIcon={<Cancel />}
        placeholder={placeholder}
        disable={disabled}
        ref={multiRef}
      />

      {placeholder ? null : value?.length <= 0 && <p className=" w-fit m-0 font-medium text-xs text-[#989898] absolute  top-[50%] left-[10px] translate-y-[-50%]">Multi Select</p>}

      <div className={`absolute z-0 top-[50%] translate-y-[-50%] right-[1px] flex items-center gap-[7px] h-[32px] ${disabled ? 'bg-gray' : 'bg-white'}  pr-2  rounded-[5px]`}>
        {value?.length > 0 && (
          <div
            className="cursor-pointer "
            onClick={async () => {
              setFieldValue(name, '');
              setSelectLabel([]);
              multiRef?.current?.resetSelectedValues([]);
            }}
          >
            <div className="flex items-center justify-center w-[16px] h-[16px] ml-[6px]">
              <CrossIcon />
            </div>
          </div>
        )}
        {!disabled && (
          <div onClick={() => multiRef?.current?.onFocus()} className="cursor-pointer ">
            <div className="flex items-center justify-center w-[16px] h-[16px] ">
              <SelectDownArrow width={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormMultiSelect;
