const BuildValidator = (errors, values, store) => {
  if (values?.build_qty > store.editForm?.availableToBuild || values?.build_qty > store.editForm?.remainingItem) {
    errors.build_qty = `Don't have enough quantity available.`;
  }
  if (values?.build_qty && values?.build_qty < 0) {
    errors.build_qty = `Please enter a positive number.`;
  }
  return errors;
};

export default BuildValidator;
