import { HemaValue } from "../../utils";
import { LockOpen, CloseLock } from "../../HemeIconLibrary";
import { HemaDateFormat } from "../../helpers/commonHelper";
export const KitPackagingCol = [
  {
    name: (
      <HemaValue
        text={" Package Number"}
        className="font-normal text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => (
      <>
        <div className="flex gap-[10px]">
          {!row?.isLocked ? <LockOpen /> : <CloseLock />}
          <HemaValue text={row?.name} />
        </div>
      </>
    ),
    sortId: "Name",
  },
  {
    name: (
      <HemaValue
        text={"Items Per Package"}
        className="font-normal text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => <HemaValue text={row?.items?.length} />,
    sortId: "readToShipQuantity",
  },
  {
    name: (
      <HemaValue text={"Parcel ID"} className="font-normal text-[#000000]" />
    ),
    sortable: true,
    selector: (row) => <HemaValue text={row?.parcelId || "NA"} />,
    sortId: "readToShipQuantity",
  },
  {
    name: (
      <HemaValue
        text={"Depot Parcel ID"}
        className="font-normal text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => <HemaValue text={row?.depotParcelId || "NA"} />,
    sortId: "depotParcelId",
  },
  {
    name: (
      <HemaValue text={"Prepared By"} className="font-normal text-[#000000]" />
    ),
    sortable: true,
    selector: (row) => <HemaValue text={row?.lockedBy?.name || "N/A"} />,
    sortId: "readToShipQuantity",
  },
  {
    name: (
      <HemaValue
        text={"Prepared Date"}
        className="font-normal text-[#000000]"
      />
    ),
    sortable: true,
    selector: (row) => <HemaValue text={HemaDateFormat(row?.lockedUtc)} />,
    sortId: "readToShipQuantity",
  },
];
