import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { setRefreshTokenTime } from '../Store/reducers/common';
const ProtectedRoute = ({ children, count }) => {
  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRefreshTokenTime());
  }, [count]);
  if (!localStorage.getItem('hema-token')) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;
