import React from 'react';

export const UploadIcon = ({ purple }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 9.70667V13.3333C14 13.7067 13.693 14 13.3023 14H2.69767C2.30698 14 2 13.7067 2 13.3333V9.70667C2 9.33333 2.30698 9.04 2.69767 9.04C3.08837 9.04 3.39535 9.33333 3.39535 9.70667V12.6667H12.6047V9.70667C12.6047 9.33333 12.9116 9.04 13.3023 9.04C13.693 9.04 14 9.33333 14 9.70667ZM5.71163 5.85333L7.30233 4.29333V10.0667C7.30233 10.44 7.6093 10.7333 8 10.7333C8.3907 10.7333 8.69767 10.44 8.69767 10.0667V4.30667L10.2884 5.85333C10.4279 5.98667 10.6093 6.05333 10.7907 6.05333C10.9721 6.05333 11.1395 5.98667 11.2791 5.86667C11.5581 5.61333 11.5581 5.18667 11.293 4.92L8.50233 2.2C8.36279 2.06667 8.1814 2 8 2C7.8186 2 7.63721 2.06667 7.49767 2.2L4.72093 4.92C4.45581 5.18667 4.45581 5.6 4.73488 5.86667C5 6.12 5.44651 6.10667 5.71163 5.85333Z"
        fill={purple ? 'url(#paint0_linear_5230_103047)' : 'white'}
        stroke={purple ? 'url(#paint1_linear_5230_103047)' : 'white'}
        stroke-width="0.25"
      />
      {purple && (
        <defs>
          <linearGradient
            id="paint0_linear_5230_103047"
            x1="2"
            y1="7.99999"
            x2="14"
            y2="7.99999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#775FD5" />
            <stop offset="1" stop-color="#F03A85" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5230_103047"
            x1="2"
            y1="7.99999"
            x2="14"
            y2="7.99999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#775FD5" />
            <stop offset="1" stop-color="#F03A85" />
          </linearGradient>
        </defs>
      )}
    </svg>
  );
};
