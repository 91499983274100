import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { filter } from 'smart-array-filter';
import { setSystemUsersFilter } from '../../../Store/reducers/settings';
import { Button, FormSearch, HemaValue, Pagination, FilterColumn, NoDataComponent } from '../../../utils';
import { SystemUserValidation } from '../../../components/Formik/allFormValidation';
import { DeletePurple, EditColor, ExpiryAlertPurple, TableSortIcon } from '../../../HemeIconLibrary';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import { createUserField } from '../../../components/Formik/AllForms/addUserFields';
import { Remove } from '../../../components/Formik/AllForms/remove';
import { sortedData } from '../../../helpers/sort';
import { setForm, editFormReducer, setFormCloseReducer, showSuccessReducer, setFormLoaderReducer } from '../../../Store/reducers/uiSettings';

// Actions
import { getSystemUserAction, updateSystemUserAction, deleteSystemUserAction } from '../../../Actions/settings';

// Utils
import { getFullName } from '../Utils';

// assets

const SystemUserList = ({ setCTA, setformName, setFormIcon, setUpdatedData, setokBtnText, setokBtnIcon, setFormValidation }) => {
  const dispatch = useDispatch();
  const { settings, common } = useSelector((state) => state);
  const { systemUsers, systemUsersFilter } = settings;

  useEffect(() => {
    getSystemUserAction();
  }, []);

  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);

  //search for location
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const filteredDataResult = filter(systemUsers, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setDataList(filteredDataResult);
      } else {
        setDataList(systemUsers);
      }
    })();
  }, [searchQuery, systemUsers]);

  const editSystemUser = (row) => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await updateSystemUserAction(row.id, payload);
      dispatch(setFormLoaderReducer(false));
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`User updated.`));
        getSystemUserAction();
      }
    });
    setFormValidation((error, values) => (error, values) => {
      SystemUserValidation(error, values);
    });
  };

  const deleteSystemUser = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true));
      const resp = await deleteSystemUserAction(row.id, data.change_reason);
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`User deleted.`));
        dispatch(setFormCloseReducer());
      }
      dispatch(setFormLoaderReducer(false));
    });
  };

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  useEffect(() => {
    if (Object.keys(systemUsersFilter)?.length) {
      const filterResult = systemUsers?.filter((port) => {
        if (
          (systemUsersFilter.email?.length ? systemUsersFilter.email.includes(port.email) : true) &&
          (systemUsersFilter.isActive?.length ? systemUsersFilter.isActive?.includes(port.isActive) : true) &&
          (systemUsersFilter.firstName?.length ? systemUsersFilter.firstName?.includes(getFullName(port)) : true) &&
          (systemUsersFilter.role?.length ? systemUsersFilter.role?.includes(port.role.name) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setDataList(filterResult);
    } else {
      setDataList(systemUsers);
    }
  }, [JSON.stringify(systemUsersFilter)]);
  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>

        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 || Object.keys(systemUsersFilter)?.length ? (
          <DataTable
            noDataComponent={<NoDataComponent setActiveFilterTab={setDataList} activeFilterOriginalData={systemUsers} resetRedux={setSystemUsersFilter} />}
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: 'visible !important' },
                },
              },

              cells: {
                style: { overflow: 'visible !important' },
              },

              responsiveWrapper: {
                style: { overflow: 'visible !important' },
              },
            }}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Name'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="firstName"
                          secondColumnName="lastName"
                          setRedux={setSystemUsersFilter}
                          reduxValues={systemUsersFilter || []}
                          options={Array.from(new Set(systemUsers.map((filter) => getFullName(filter))))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={getFullName(row)} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'firstName',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Email'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="email"
                          setRedux={setSystemUsersFilter}
                          reduxValues={systemUsersFilter || []}
                          options={Array.from(new Set(systemUsers.map((filter) => filter.email)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.email} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'email',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Role'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="role"
                          setRedux={setSystemUsersFilter}
                          reduxValues={systemUsersFilter || []}
                          options={Array.from(new Set(systemUsers.map((filter) => filter.role?.name)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue text={row.role?.name} color="text-textColor" className="text-xs font-medium" />,
                sortId: 'role.name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Status'} color="text-[#000000]" className="text-xs font-normal" />{' '}
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="isActive"
                          type="boolean"
                          boolTrueText="Active"
                          boolFalseText="In-Active"
                          setRedux={setSystemUsersFilter}
                          reduxValues={systemUsersFilter || []}
                          options={Array.from(new Set(systemUsers.map((filter) => filter.isActive)))}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => (
                  <div className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${row?.isActive ? 'bg-[#E6F6EB]' : 'bg-[#EFEFEF]'}`}>
                    <div className={`w-2 h-2 rounded-full ${row?.isActive ? 'bg-[#138846]' : 'bg-[#454545]'}`} />
                    <HemaValue className="text-xs" color={row?.isActive ? 'text-[#138846]' : 'text-[#454545]'} text={row.isActive ? 'Active' : 'Inactive'} />
                  </div>
                ),
                sortId: 'isActive',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <HemaValue text={'Actions'} color="text-[#000000]" className="text-xs font-normal pr-7" />{' '}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[16px] meta">
                        {common?.permissions?.['System User Management']?.update && (
                          <Button
                            Icon={<EditColor color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              setformName('Edit user');
                              setFormIcon(<ExpiryAlertPurple />);
                              setokBtnIcon();
                              setokBtnText('Update');
                              createUserField[0].initialValue = row.firstName;
                              createUserField[1].initialValue = row.lastName;
                              createUserField[2].initialValue = row.email;
                              createUserField[2].disabled = row.email;
                              createUserField[2].disabled = true;
                              createUserField[3].initialValue = row.role?.id;
                              // createUserField[3].placeholder = row.role?.name;
                              createUserField[3].options = settings?.userRole;
                              createUserField[4].initialValue = row.isActive;
                              setUpdatedData(createUserField);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'create-document-language',
                                }),
                              );
                              editSystemUser(row);
                            }}
                          />
                        )}
                        {/* <Button
                            Icon={<SearchColor color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                          /> */}
                        {common?.permissions?.['System User Management']?.delete && (
                          <Button
                            Icon={<img src={deleteIcon} alt="" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row));
                              Remove[0].label = 'User Name';
                              Remove[0].initialValue = row?.firstName + ' ' + row?.lastName;
                              setUpdatedData(Remove);
                              setformName('Delete user');
                              setokBtnIcon();
                              setokBtnText('Confirm');
                              setFormIcon(<DeletePurple />);
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'deleteItem',
                                }),
                              );
                              deleteSystemUser(row);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <BootstrapAlert variant="warning" className="mt-3 text-center">
            There is no data found.
          </BootstrapAlert>
        )}
      </>
    </div>
  );
};

export default SystemUserList;
