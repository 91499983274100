import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { registerLicense } from '@syncfusion/ej2-base';
import { showSpinner, hideSpinner, createSpinner } from '@syncfusion/ej2-popups';
import { useEffect, useRef } from 'react';

DocumentEditorContainerComponent.Inject(Toolbar);
const LICENSE_KEY = process.env.REACT_APP_SYNCFUSION_LICENSE_KEY;

// Registering Syncfusion license key
registerLicense(LICENSE_KEY);

const DocumentEditorDialog = ({ docFile, setisdocLoaded, docRef, uisettings, isPrintAble, uploadFile }) => {
  useEffect(() => {
    if (!docFile) {
      return;
    }
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById('container'),
    });
    initialize();
  }, [docFile]);
  const handleCreated = () => {
    if (!docFile) {
      return;
    }
    initialize();
  };
  console.log('docFile', docFile);

  const initialize = () => {
    showSpinner(document.getElementById('container'));
    fetch(docFile)
      .then((res) => res.blob())
      .then((myBlob) => {
        function convertBlobToFile(blob, fileName) {
          if (blob) {
            blob.lastModifiedDate = new Date();
            blob.name = fileName;
          }
          return blob;
        }
        const myFile = convertBlobToFile(myBlob, 'HEMA-LAB-DOC');
        loadFile(myFile);
      });

    // convert docx file to sdft format and load it in editor
    const loadFile = (file) => {
      const ajax = new XMLHttpRequest();
      ajax.open('POST', 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/Import', true);
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4) {
          if (ajax.status === 200 || ajax.status === 304) {
            // hideSpinner() method used hide spinner
            hideSpinner(document.getElementById('container'));
            // open SFDT text in document editor
            docRef.current?.documentEditor.open(ajax.responseText);
          }
        }
      };
      const formData = new FormData();
      formData.append('files', file);
      ajax.send(formData);
    };
  };
  useEffect(() => {
    if (uploadFile) {
      console.log(uploadFile);
      const ajax = new XMLHttpRequest();
      ajax.open('POST', 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/Import', true);
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4) {
          if (ajax.status === 200 || ajax.status === 304) {
            // hideSpinner() method used hide spinner
            hideSpinner(document.getElementById('container'));
            // open SFDT text in document editor
            docRef.current?.documentEditor.open(ajax.responseText);
          }
        }
      };
      const formData = new FormData();
      formData.append('files', uploadFile[0]);
      ajax.send(formData);
    }
  }, [uploadFile]);
  return (
    <DocumentEditorContainerComponent
      id="container"
      height={'740px'}
      ref={docRef}
      created={handleCreated}
      serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
      enableToolbar={true}
      // enablePrint={isPrintAble ? true : false}
      restrictEditing={uisettings?.formName === 'view-doc-template' ? true : false}
      showPropertiesPane={false}
    />
  );
};
export default DocumentEditorDialog;
