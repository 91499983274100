import React from "react";
import { useEffect } from "react";

const FormText = (props) => {
  const { setpackageName, setpackageWeight, subjectref, value } = props;
  console.log("value", value);
  useEffect(() => {
    if (
      props.disabled &&
      (props.name === "options" ||
        // props.name === 'lowInventoryThreshold' ||
        props.name === "closeToExpiryNumberOfDays")
    ) {
      props.setFieldValue(props.name, "");
    }
  }, [props.disabled]);
  const posRight =
    props.type === "number" || props.type === "date"
      ? "right-[30px]"
      : "right-[12px]";
  return (
    <div className="relative flex items-center justify-center w-full">
      {props.type === "number" ? (
        <>
          <input
            value={value}
            ref={subjectref}
            {...props}
            className={` w-full  border-solid p-[9px] pr-[30px] font-medium text-[12px] leading-[15px] border rounded-[5px]`}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
          />
          {props.crossIcon && !props?.disabled && (
            <span
              onClick={() => {
                props?.setFieldValue(props.name, "");
                if (setpackageName) {
                  setpackageName("");
                }
                if (setpackageWeight) {
                  setpackageWeight("");
                }
              }}
              className={`absolute   top-[13px] ${posRight} cursor-pointer`}
            >
              {props.crossIcon}
            </span>
          )}
        </>
      ) : (
        <>
          <input
            ref={subjectref}
            {...props}
            className={` w-full  border-solid p-[9px] pr-[30px] font-medium text-[12px] leading-[15px] border rounded-[5px]`}
          />
          {props.crossIcon && !props?.disabled && (
            <span
              onClick={() => {
                props?.setFieldValue(props.name, "");
                if (setpackageName) {
                  setpackageName("");
                }
                if (setpackageWeight) {
                  setpackageWeight("");
                }
              }}
              className={`absolute   top-[13px] ${posRight} cursor-pointer`}
            >
              {props.crossIcon}
            </span>
          )}
        </>
      )}
    </div>
  );
};
export default FormText;
