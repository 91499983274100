import React from "react";

export const LockedPurple = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9974 5.66667H11.3307V4.33333C11.3307 2.49333 9.8374 1 7.9974 1C6.1574 1 4.66406 2.49333 4.66406 4.33333V5.66667H3.9974C3.26406 5.66667 2.66406 6.26667 2.66406 7V13.6667C2.66406 14.4 3.26406 15 3.9974 15H11.9974C12.7307 15 13.3307 14.4 13.3307 13.6667V7C13.3307 6.26667 12.7307 5.66667 11.9974 5.66667ZM5.9974 4.33333C5.9974 3.22667 6.89073 2.33333 7.9974 2.33333C9.10406 2.33333 9.9974 3.22667 9.9974 4.33333V5.66667H5.9974V4.33333ZM11.9974 13.6667H3.9974V7H11.9974V13.6667ZM7.9974 11.6667C8.73073 11.6667 9.33073 11.0667 9.33073 10.3333C9.33073 9.6 8.73073 9 7.9974 9C7.26406 9 6.66406 9.6 6.66406 10.3333C6.66406 11.0667 7.26406 11.6667 7.9974 11.6667Z"
        fill="url(#paint0_linear_5508_486681)"
      />
      <defs>
        <linearGradient id="paint0_linear_5508_486681" x1="2.66406" y1="7.99999" x2="13.3307" y2="7.99999" gradientUnits="userSpaceOnUse">
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
