import React from "react";

export const CreateSite = () => {
  return (
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 12.7031V13.2031H16H23.5V25.5365H0.5V7.86979H8H8.5V7.36979V4.91023L12 1.41023L15.5 4.91023V12.7031ZM5.33333 23.8698H5.83333V23.3698V20.7031V20.2031H5.33333H2.66667H2.16667V20.7031V23.3698V23.8698H2.66667H5.33333ZM5.33333 18.5365H5.83333V18.0365V15.3698V14.8698H5.33333H2.66667H2.16667V15.3698V18.0365V18.5365H2.66667H5.33333ZM5.33333 13.2031H5.83333V12.7031V10.0365V9.53646H5.33333H2.66667H2.16667V10.0365V12.7031V13.2031H2.66667H5.33333ZM13.3333 23.8698H13.8333V23.3698V20.7031V20.2031H13.3333H10.6667H10.1667V20.7031V23.3698V23.8698H10.6667H13.3333ZM13.3333 18.5365H13.8333V18.0365V15.3698V14.8698H13.3333H10.6667H10.1667V15.3698V18.0365V18.5365H10.6667H13.3333ZM13.3333 13.2031H13.8333V12.7031V10.0365V9.53646H13.3333H10.6667H10.1667V10.0365V12.7031V13.2031H10.6667H13.3333ZM13.3333 7.86979H13.8333V7.36979V4.70312V4.20312H13.3333H10.6667H10.1667V4.70312V7.36979V7.86979H10.6667H13.3333ZM21.3333 23.8698H21.8333V23.3698V20.7031V20.2031H21.3333H18.6667H18.1667V20.7031V23.3698V23.8698H18.6667H21.3333ZM21.3333 18.5365H21.8333V18.0365V15.3698V14.8698H21.3333H18.6667H18.1667V15.3698V18.0365V18.5365H18.6667H21.3333Z"
        fill="url(#paint0_linear_3315_78531)"
        stroke="url(#paint1_linear_3315_78531)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3315_78531"
          x1="1.08965e-07"
          y1="13.3698"
          x2="24"
          y2="13.3698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3315_78531"
          x1="1.08965e-07"
          y1="13.3698"
          x2="24"
          y2="13.3698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
