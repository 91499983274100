import React from "react";

export const CreateTranslation = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69444 0.90625C2.05617 0.90625 1.40548 1.1401 0.944661 1.60091C0.483846 2.06173 0.25 2.71242 0.25 3.35069V14.3507C0.25 14.989 0.483846 15.6397 0.944661 16.1005C1.40548 16.5613 2.05617 16.7951 2.69444 16.7951H6.36111V20.4618C6.36111 21.8062 7.46111 22.9062 8.80556 22.9062H19.8056C21.15 22.9062 22.25 21.8062 22.25 20.4618V9.46181C22.25 8.11736 21.15 7.01736 19.8056 7.01736H16.1389V3.35069C16.1389 2.71242 15.905 2.06173 15.4442 1.60091C14.9834 1.1401 14.3327 0.90625 13.6944 0.90625H2.69444ZM21.0278 0.90625L18.5833 3.35069L21.0278 5.79514V0.90625ZM2.69444 3.35069H13.6944V8.23958L10.0278 11.9062L7.58333 14.3507H2.69444V3.35069ZM10.0278 11.9062V10.684C9.69119 10.684 9.38119 10.6524 9.08724 10.6029C9.09946 10.5939 9.11327 10.5881 9.12543 10.579C10.1949 9.78067 11.0343 8.56833 11.2118 7.01736H11.25V6.40625V5.79514H8.80556V4.57292H7.58333V5.79514H5.13889V7.01736H9.98004C9.81884 8.15045 9.2163 8.98477 8.39497 9.59787C8.15134 9.77973 7.88839 9.93871 7.61437 10.0753C6.80755 9.59238 6.36111 8.89609 6.36111 8.23958H5.13889C5.13889 9.1189 5.54925 9.9144 6.22266 10.5456C5.848 10.6263 5.47884 10.684 5.13889 10.684V11.9062C5.92085 11.9062 6.763 11.7309 7.57617 11.4121C8.29032 11.7174 9.1147 11.9062 10.0278 11.9062ZM13.8162 10.8058H15.406L18.4616 18.9961H16.6283L16.1389 17.2845H13.2051L12.594 18.9961H10.7606L13.8162 10.8058ZM14.6732 12.885L13.5727 15.9405H15.6495L14.6732 12.885ZM1.47222 18.0174V22.9062L3.91667 20.4618L1.47222 18.0174Z"
        fill="url(#paint0_linear_3315_60747)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3315_60747"
          x1="0.25"
          y1="11.9062"
          x2="22.25"
          y2="11.9062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
