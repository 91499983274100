import React from 'react';
export const DaysCalender = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23077 2C4.95313 2 4.76923 2.18389 4.76923 2.46154V3.84615C4.76923 4.1238 4.95313 4.30769 5.23077 4.30769C5.50841 4.30769 5.69231 4.1238 5.69231 3.84615V2.46154C5.69231 2.18389 5.50841 2 5.23077 2ZM10.7692 2C10.4916 2 10.3077 2.18389 10.3077 2.46154V3.84615C10.3077 4.1238 10.4916 4.30769 10.7692 4.30769C11.0469 4.30769 11.2308 4.1238 11.2308 3.84615V2.46154C11.2308 2.18389 11.0469 2 10.7692 2ZM3.38462 2.92308C2.60036 2.92308 2 3.52344 2 4.30769V12.6154C2 13.3996 2.60036 14 3.38462 14H12.6154C13.3996 14 14 13.3996 14 12.6154V4.30769C14 3.52344 13.3996 2.92308 12.6154 2.92308H11.6923V3.84615C11.6923 4.35457 11.2776 4.76923 10.7692 4.76923C10.2608 4.76923 9.84615 4.35457 9.84615 3.84615V2.92308H6.15385V3.84615C6.15385 4.35457 5.73918 4.76923 5.23077 4.76923C4.72236 4.76923 4.30769 4.35457 4.30769 3.84615V2.92308H3.38462ZM2.92308 6.15385H13.0769V12.6154C13.0769 12.893 12.893 13.0769 12.6154 13.0769H3.38462C3.10697 13.0769 2.92308 12.893 2.92308 12.6154V6.15385ZM6.24038 7.68269C5.73197 7.68269 5.27404 7.81791 5.04327 7.95673L5.23077 8.60577C5.36959 8.46695 5.69772 8.36418 6.06731 8.31731C6.4369 8.31731 6.61538 8.50481 6.61538 8.73558C6.61538 9.05829 6.24579 9.19712 5.92308 9.19712H5.54808V9.84615H5.92308C6.33774 9.84615 6.70192 10.0246 6.70192 10.3942C6.70192 10.6719 6.47115 10.9135 6.00962 10.9135C5.64002 10.9135 5.27043 10.7746 5.08654 10.6827L4.91346 11.4615C5.14423 11.6004 5.55889 11.7356 6.06731 11.7356C7.08233 11.7356 7.68269 11.2308 7.68269 10.5385C7.68269 9.98498 7.30769 9.66406 6.84615 9.57212C7.30769 9.38822 7.53846 9.0637 7.53846 8.64904C7.53846 8.09555 7.07151 7.68269 6.24038 7.68269ZM9.57212 7.72596L8.54808 8.1875L8.69231 8.87981L9.42788 8.50481V11.649H10.3077V7.72596H9.57212Z"
        fill="url(#paint0_linear_1188_63985)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_63985"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
