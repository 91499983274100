import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './navbar';
import Sidebar from './sidebar';
import { useSelector } from 'react-redux';
import './style.scss';

const Layout = ({ setShowDashboard }) => {
  const { uisettings } = useSelector((state) => state);
   const [isCollapse, setisCollapse] =  useState()
  const [sidebar, setSidebar] = useState();
  useEffect(() => {
    if (uisettings.sidebarOpen === null) {
      if (localStorage.getItem('sidebarOpen') === 'true') {
        setSidebar(true);
      } else {
        setSidebar(false);
      }
    } else {
      setSidebar(uisettings.sidebarOpen);
    }
  }, [uisettings.sidebarOpen]);

  useEffect(() => {
    console.log(uisettings)
    if (uisettings.sidebarOpen === null) {
      if (localStorage.getItem("sidebarOpen") === "true") {
        setisCollapse(true);
      } else {
        setisCollapse(false);
      }
    } else {
      setisCollapse(!!uisettings.sidebarOpen);
    }
  }, [uisettings.sidebarOpen]);

  return (
    <div className="">
      <Navbar />
      <div className="flex w-full h-full bg-hemaSecondary ">
        <Sidebar setShowDashboard={setShowDashboard } isCollapse={isCollapse} setisCollapse={setisCollapse} />
        <div
          className={`hemergy-container pt-[107px] pb-[25px] ${
            sidebar ? 'pl-[245px]' : 'pl-[110px]'
          } pl-[110px] px-[20px] w-full min-h-[100vh] max-h-full `}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
export default Layout;
