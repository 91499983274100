import React from "react";

export const DeleteColor = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3721 5.85156H2.62791C2.14521 5.85156 1.75391 6.24287 1.75391 6.72556V13.7207C1.75391 15.1694 2.92828 16.3438 4.37695 16.3438H9.62305C10.3187 16.3438 10.9859 16.0674 11.4778 15.5755C11.9697 15.0836 12.2461 14.4164 12.2461 13.7207V6.72556C12.2461 6.24287 11.8548 5.85156 11.3721 5.85156Z"
        stroke="url(#paint0_linear_3263_76361)"
        stroke-width="1.7"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.09727 9.35039C6.09727 8.88095 5.71671 8.50039 5.24727 8.50039C4.77782 8.50039 4.39727 8.88095 4.39727 9.35039H6.09727ZM4.39727 12.8474C4.39727 13.3169 4.77782 13.6974 5.24727 13.6974C5.71671 13.6974 6.09727 13.3169 6.09727 12.8474H4.39727ZM9.60117 9.35039C9.60117 8.88095 9.22061 8.50039 8.75117 8.50039C8.28173 8.50039 7.90117 8.88095 7.90117 9.35039H9.60117ZM7.90117 12.8474C7.90117 13.3169 8.28173 13.6974 8.75117 13.6974C9.22061 13.6974 9.60117 13.3169 9.60117 12.8474H7.90117ZM8.8447 2.50625C9.31414 2.50625 9.6947 2.12569 9.6947 1.65625C9.6947 1.18681 9.31414 0.80625 8.8447 0.80625V2.50625ZM5.34766 0.80625C4.87821 0.80625 4.49766 1.18681 4.49766 1.65625C4.49766 2.12569 4.87821 2.50625 5.34766 2.50625V0.80625ZM12.2461 4.60488C12.7155 4.60488 13.0961 4.22432 13.0961 3.75488C13.0961 3.28544 12.7155 2.90488 12.2461 2.90488V4.60488ZM1.75391 2.90488C1.28446 2.90488 0.903906 3.28544 0.903906 3.75488C0.903906 4.22432 1.28446 4.60488 1.75391 4.60488V2.90488ZM4.39727 9.35039V12.8474H6.09727V9.35039H4.39727ZM7.90117 9.35039V12.8474H9.60117V9.35039H7.90117ZM8.8447 0.80625H5.34766V2.50625H8.8447V0.80625ZM12.2461 2.90488H1.75391V4.60488H12.2461V2.90488Z"
        fill="url(#paint1_linear_3263_76361)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_76361"
          x1="1.75391"
          y1="11.0977"
          x2="12.2461"
          y2="11.0977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EE318A" />
          <stop offset="1" stop-color="#EC6551" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3263_76361"
          x1="1.75391"
          y1="7.25173"
          x2="12.2461"
          y2="7.25173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EE318A" />
          <stop offset="1" stop-color="#EC6551" />
        </linearGradient>
      </defs>
    </svg>
  );
};
