import React from "react";
export const StorageLocation = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6984 9.71816V10.2182H13.1984H19.0984V19.8848H0.898438V5.9515H6.79844H7.29844V5.4515V3.52527L9.99844 0.825271L12.6984 3.52527V9.71816ZM4.6651 18.7515H5.1651V18.2515V16.1182V15.6182H4.6651H2.53177H2.03177V16.1182V18.2515V18.7515H2.53177H4.6651ZM4.6651 14.4848H5.1651V13.9848V11.8515V11.3515H4.6651H2.53177H2.03177V11.8515V13.9848V14.4848H2.53177H4.6651ZM4.6651 10.2182H5.1651V9.71816V7.58483V7.08483H4.6651H2.53177H2.03177V7.58483V9.71816V10.2182H2.53177H4.6651ZM11.0651 18.7515H11.5651V18.2515V16.1182V15.6182H11.0651H8.93177H8.43177V16.1182V18.2515V18.7515H8.93177H11.0651ZM11.0651 14.4848H11.5651V13.9848V11.8515V11.3515H11.0651H8.93177H8.43177V11.8515V13.9848V14.4848H8.93177H11.0651ZM11.0651 10.2182H11.5651V9.71816V7.58483V7.08483H11.0651H8.93177H8.43177V7.58483V9.71816V10.2182H8.93177H11.0651ZM11.0651 5.9515H11.5651V5.4515V3.31816V2.81816H11.0651H8.93177H8.43177V3.31816V5.4515V5.9515H8.93177H11.0651ZM17.4651 18.7515H17.9651V18.2515V16.1182V15.6182H17.4651H15.3318H14.8318V16.1182V18.2515V18.7515H15.3318H17.4651ZM17.4651 14.4848H17.9651V13.9848V11.8515V11.3515H17.4651H15.3318H14.8318V11.8515V13.9848V14.4848H15.3318H17.4651Z"
        fill="#414753"
        stroke="#414753"
      />
    </svg>
  );
};