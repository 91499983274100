import React from 'react';

export const Broker = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.875"
        y="3.125"
        width="12.25"
        height="9.75"
        rx="2"
        stroke="url(#paint0_linear_2850_108102)"
        stroke-width="1.5"
      />
      <path
        d="M7.28125 6.48438H11.5625"
        stroke="url(#paint1_linear_2850_108102)"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7.28125 9.51562H11.5625"
        stroke="url(#paint2_linear_2850_108102)"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <rect
        x="3.3125"
        y="5.87695"
        width="2.40625"
        height="4.34375"
        rx="0.5"
        fill="url(#paint3_linear_2850_108102)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2850_108102"
          x1="1.875"
          y1="7.99999"
          x2="14.125"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2850_108102"
          x1="7.28125"
          y1="6.98437"
          x2="11.5625"
          y2="6.98437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2850_108102"
          x1="7.28125"
          y1="10.0156"
          x2="11.5625"
          y2="10.0156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2850_108102"
          x1="3.3125"
          y1="8.04883"
          x2="5.71875"
          y2="8.04883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
