import {
  FileIcon,
  LabelName,
  LabelCategory,
  LabelDescription,
  Upload,
  LabelUnitType,
  LabelUnitSize,
  ExpiryNotification,
  Threshold,
  ChangeReason,
  UserGroup,
} from '../../../HemeIconLibrary';

export const createNewItem = [
  {
    label: 'Item name',
    Icon: <LabelName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter Name',
    required: true,
    name: 'name',
  },
  {
    name: 'itemCategoryId',
    label: 'Category',
    Icon: <LabelCategory />,
    type: 'select',
    required: true,
    initialValue: '',
    placeholder: 'Select Item Type',
    options: [],
  },
  {
    name: 'description',
    label: 'description',
    Icon: <LabelDescription />,
    type: 'textarea',
    initialValue: '',
    placeholder: 'Enter Description',
    fluid: true,
    required: true,
  },
  {
    name: 'fileId',
    label: 'Upload Image',
    Icon: <Upload />,
    type: 'upload-image',
    initialValue: undefined,
    placeholder: '',
    fluid: true,
    accept: 'image/png, image/gif, image/jpeg, image/svg, image/gif',
  },
  {
    name: 'unitTypeId',
    label: 'Unit type',
    Icon: <LabelUnitType />,
    type: 'select',
    initialValue: '',
    placeholder: 'Select Unit Type',
    options: [],
  },
  {
    name: 'unitSize',
    label: 'Unit size',
    Icon: <LabelUnitSize />,
    type: 'text',
    initialValue: '',
    placeholder: 'Enter the Unit size',
    min: '1',
    step: '1',
  },

  {
    name: 'lowInventoryAlert',
    label: 'low Inventory alert',
    Icon: <ExpiryNotification />,
    type: 'switch',
    initialValue: false,
    placeholder: '',
    fluid: true,
  },
  {
    name: 'lowInventoryThreshold',
    label: 'low Inventory threshold',
    Icon: <Threshold />,
    type: 'number',
    initialValue: undefined,
    placeholder: 'Enter low inventory threshold',
    min: '1',
    step: '1',
    disabled: true,
    required: false,
    fluid: true,
  },
  {
    name: 'userIds',
    label: 'Send Alert To',
    Icon: <UserGroup />,
    type: 'multiSelect',
    initialValue: undefined,
    placeholder: 'Select user(s)',
    disabled: true,
    required: false,
    fluid: true,
  },

  {
    name: 'change_reason',
    label: 'Change Reason',
    Icon: <ChangeReason />,
    type: 'textarea',
    fluid: true,
    initialValue: '',
    required: true,
  },
];
