import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer'
import { forwardRef, useEffect } from 'react'

const PDF = forwardRef(({ uploadFile }, ref) => {
  const initialize = async (filePath) => {
    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', filePath, true)
      xhr.responseType = 'blob'
      xhr.onload = function (e) {
        const myBlob = this.response
        const reader = new window.FileReader()
        // read the blob data
        reader.readAsDataURL(myBlob)
        reader.onloadend = function () {
          const base64data = reader.result
          // Typecast the HTMLElement to avoid Typescript lint issue
          const pdfviewer =
            document.getElementById('pdfviewerspecial').ej2_instances[0]
          //  load the base64 string
          pdfviewer.load(base64data, null)
        }
      }
      xhr.send()
    })
  }

  useEffect(() => {
    if (uploadFile) {
      initialize(uploadFile)
    }
  }, [uploadFile])

  return (
    <PdfViewerComponent
      id="pdfviewerspecial"
      // documentPath={
      //   documentdetail?.preGeneratedFile
      //     ? documentdetail?.preGeneratedFile?.storageUrl
      //     : documentdetail?.storageUrl
      // }
      serviceUrl={`${process.env.REACT_APP_PDF_APP_API_URL}/pdfviewer`}
      // serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
      style={{ height: '100%' }}
      ref={ref}
    >
      <Inject
        services={[
          Toolbar,
          Magnification,
          Navigation,
          LinkAnnotation,
          BookmarkView,
          ThumbnailView,
          Print,
          TextSelection,
          TextSearch,
          Annotation,
          FormFields,
          FormDesigner,
        ]}
      />
    </PdfViewerComponent>
  )
})
export default PDF
