import React, { forwardRef } from 'react';

const FormTextarea = (props) => {
  const posRight =
    props.type === 'number' || props.type === 'date'
      ? 'right-[30px]'
      : 'right-[12px]';

  return (
    <div className="relative flex items-center justify-center w-full">
      <textarea
        {...props}
        ref={props.bodyref}
        className={`w-full h-[auto] min-h-[35px] border-solid p-[9px] font-medium text-[12px] leading-[15px] border rounded-[5px] ${props?.className}`}
      />

      {props.crossIcon && !props?.disabled && (
        <span
          onClick={() => {
            props?.setFieldValue(props.name, '');
          }}
          className={`absolute   top-[13px] ${posRight} cursor-pointer`}
        >
          {props.crossIcon}
        </span>
      )}
    </div>
  );
};
export default FormTextarea;
