import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import { HemaLabel, Button, HemaValue } from '../../../utils';
import { getQualityControlItemAction, getAllShippingStatsAction, getShipmentDetailAction, updateQualityStatusAction } from '../../../Actions/order';
import { Cancel, Confirm } from '../../../HemeIconLibrary';
import { HemaDateFormat } from '../../../helpers/commonHelper';
import { setForm, showSuccessReducer } from '../../../Store/reducers/uiSettings';
import Heading from '../../../pages/logistOrchestra/logisticComponents/heading';
import DataTable from 'react-data-table-component';

const ShippingUpload = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const { uisettings, orders } = useSelector((state) => state);
  return (
    <div className="flex flex-col justify-between h-[inherit]">
      <div>
        <div className={'w-full'}>
          <Heading heading={'Item Details'} />
        </div>

        <div className="flex gap-[20px] pt-[20px]">
          <HemaLabel text="Item Name:" />
          <HemaValue text={uisettings?.editForm?.name} />
        </div>
        <div className="flex gap-[20px] pt-[15px] pb-[20px]">
          <HemaLabel text="Total Quantity:" />
          <HemaValue text={uisettings?.editForm?.quantity} />
        </div>
        {uisettings?.editForm?.kitTemplateId && (
          <>
            <Heading heading={'Kit Template Details'} />
            <div className="flex gap-[20px] pt-[15px] pb-[20px]">
              <HemaLabel text="Outbound Condition:" />
              <HemaValue text={uisettings?.editForm?.kitTemplateContents?.outboundCondition?.name} />
            </div>
            <div className=" mb-[20px] w-full flex items-start gap-[16px] border solid">
              <DataTable
                data={uisettings?.editForm?.kitTemplateContents?.contents}
                columns={[
                  {
                    name: 'Item Name',
                    selector: (row) => <HemaValue className="whitespace-pre-line" text={row?.name}  />,
                  },
                  {
                    name: 'Quantity Per Kit',
                    selector: (row) => <HemaValue className="whitespace-pre-line" text={row?.quantity} />,
                    grow: 2,
                  },
                  {
                    name: 'Lot Number',
                    selector: (row) => <HemaValue className="whitespace-pre-line" text={row?.lotNumber || 'N/A'} />,
                  },
                  {
                    name: 'Expiration Date',
                    grow: 2,
                    selector: (row) => <HemaValue className="whitespace-pre-line" text={HemaDateFormat(row?.expirationDate)} />,
                  },
                ]}
              />
            </div>
            {orders.activeOrder?.type?.id === 1 && (
              <>
                <Heading heading={'Return Kit Assembly Details'} />
                {uisettings?.editForm?.returnKitContents?.map((data) => {
                  return (
                    <>
                      <div className="flex gap-[20px] pt-[15px] ">
                        <HemaLabel text="Assembly Name:" />
                        <HemaValue text={data.assembly?.name} />
                      </div>
                      <div className="flex gap-[20px] pt-[15px] ">
                        <HemaLabel text="Inbound Condition:" />
                        <HemaValue text={data.inboundCondition?.name} />
                      </div>
                      <div className="flex gap-[20px] pt-[15px] pb-[20px]">
                        <HemaLabel text="Testing Lab:" />
                        <HemaValue text={data.testingLab?.name} />
                      </div>
                      <div className="w-full flex items-start gap-[16px] border solid">
                        <DataTable
                          data={data?.contents}
                          columns={[
                            {
                              name: 'Item Name',
                              selector: (row) => <HemaValue text={row?.name} className="whitespace-pre-line" />,
                            },
                            {
                              name: 'Quantity Per Kit',
                              selector: (row) => <HemaValue text={row?.quantity} className="whitespace-pre-line" />,
                              grow: 2,
                            },
                            {
                              name: 'Lot Number',
                              selector: (row) => <HemaValue text={row?.lotNumber || 'N/A'} className="whitespace-pre-line" />,
                            },
                            {
                              name: 'Expiration Date',
                              grow: 2,
                              selector: (row) => <HemaValue text={HemaDateFormat(row?.expirationDate)} className="whitespace-pre-line" />,
                            },
                          ]}
                        />
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>

      <div className="flex gap-[8px] justify-end my-[20px]">
        <Button
          cta={() => {
            dispatch(
              setForm({
                state: false,
                type: '',
              }),
            );
          }}
          type="button"
          text="Cancel"
          bg="bg-white"
          border="border-primary1"
          color="text-primary1"
          Icon={<Cancel />}
        />

        <Button
          type="button"
          text={
            loader ? (
              <>
                <Spinner animation="grow" size="sm" variant="light" />
                <Spinner animation="grow" size="sm" variant="light" />
                <Spinner animation="grow" size="sm" variant="light" />
              </>
            ) : (
              'Mark QC'
            )
          }
          bg="bg-primary1"
          color="text-white"
          Icon={!loader && <Confirm />}
          cta={async (e) => {
            setLoader(true);
            const result = await updateQualityStatusAction(orders.activeOrder?.id, orders.activeShipment?.id, {
              items: [
                {
                  id: uisettings.editForm?.id,
                  check: true,
                },
              ],
            });
            setLoader(false);
            if (result.status === 200) {
              getAllShippingStatsAction(orders.activeOrder?.id);
              getQualityControlItemAction(orders.activeOrder?.id, orders.activeShipment?.id);
              getShipmentDetailAction(orders.activeShipment?.id);

              dispatch(showSuccessReducer(`Item has been successfully QC'ed`));
              dispatch(
                setForm({
                  state: false,
                  type: '',
                }),
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default ShippingUpload;
