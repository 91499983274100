export const LabelName = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 9.5H6.5C6.30109 9.5 6.11032 9.57902 5.96967 9.71967C5.82902 9.86032 5.75 10.0511 5.75 10.25C5.75 10.4489 5.82902 10.6397 5.96967 10.7803C6.11032 10.921 6.30109 11 6.5 11H9.5C9.69891 11 9.88968 10.921 10.0303 10.7803C10.171 10.6397 10.25 10.4489 10.25 10.25C10.25 10.0511 10.171 9.86032 10.0303 9.71967C9.88968 9.57902 9.69891 9.5 9.5 9.5ZM14 5.705C13.9922 5.6361 13.9771 5.56822 13.955 5.5025V5.435C13.9189 5.35788 13.8708 5.287 13.8125 5.225L9.3125 0.725C9.2505 0.666662 9.17962 0.618561 9.1025 0.5825H9.035C8.95881 0.538806 8.87467 0.510758 8.7875 0.5H4.25C3.65326 0.5 3.08097 0.737053 2.65901 1.15901C2.23705 1.58097 2 2.15326 2 2.75V13.25C2 13.8467 2.23705 14.419 2.65901 14.841C3.08097 15.2629 3.65326 15.5 4.25 15.5H11.75C12.3467 15.5 12.919 15.2629 13.341 14.841C13.7629 14.419 14 13.8467 14 13.25V5.75C14 5.75 14 5.75 14 5.705ZM9.5 3.0575L11.4425 5H10.25C10.0511 5 9.86032 4.92098 9.71967 4.78033C9.57902 4.63968 9.5 4.44891 9.5 4.25V3.0575ZM12.5 13.25C12.5 13.4489 12.421 13.6397 12.2803 13.7803C12.1397 13.921 11.9489 14 11.75 14H4.25C4.05109 14 3.86032 13.921 3.71967 13.7803C3.57902 13.6397 3.5 13.4489 3.5 13.25V2.75C3.5 2.55109 3.57902 2.36032 3.71967 2.21967C3.86032 2.07902 4.05109 2 4.25 2H8V4.25C8 4.84674 8.23705 5.41903 8.65901 5.84099C9.08097 6.26295 9.65326 6.5 10.25 6.5H12.5V13.25Z"
        fill="url(#paint0_linear_1188_38709)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_38709"
          x1="2"
          y1="7.99999"
          x2="14"
          y2="7.99999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
