import React from 'react';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

import Heading from '../../logistOrchestra/logisticComponents/heading';
import { HemaValue, FormCheckbox, Button } from '../../../utils';
import { QualityControlTab } from '../../../HemeIconLibrary';

const QualityControl = ({ setPayloadQc, payloadQc, ctaDetail }) => {
  const { orders, common } = useSelector((state) => state);

  return (
    <div>
      <div className="px-[16px] w-full mb-[9px] mt-[34px]">
        <Heading heading="Checklist" border buttonData={undefined} />
      </div>

      <div className="border-t border-b border-[#DEE2E6]">
        <DataTable
          data={orders?.qualityControlItems?.items || []}
          columns={[
            {
              name: <HemaValue text={'Name'} className="text-xs font-normal" color="text-[#000000]" />,
              sortable: true,
              sortId: 'name',
              selector: (row, index) => {
                return (
                  <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                    <FormCheckbox
                      onChange={(e) => {
                        if (payloadQc?.filter((data) => data.id === row?.id)?.length) {
                          console.log('need to remove');
                          setPayloadQc(payloadQc.filter((data) => data.id !== row?.id));
                        } else {
                          setPayloadQc([{ id: row?.id, check: true }, ...payloadQc]);
                        }
                      }}
                      checked={payloadQc?.filter((data) => data.id === row?.id)?.length ? true : false}
                      disabled
                      type="checkbox"
                    />
                    <HemaValue text={row?.name} className="text-xs" />
                  </div>
                );
              },
            },
            {
              name: <HemaValue text={'Total Quantity '} className="text-xs font-normal" color="text-[#000000]" />,
              sortable: true,
              selector: (row) => <HemaValue className="text-xs" text={row?.quantity} />,
              sortId: 'quantity',
            },
            {
              name: <HemaValue text={'Lot Number'} className="text-xs font-normal" color="text-[#000000]" />,
              sortable: true,
              selector: (row) => <HemaValue className="text-xs" text={row?.lotNumber || ' N/A'} />,
              sortId: 'lotNumber',
            },
            {
              name: <HemaValue text={'Expiration Date'} className="text-xs font-normal" color="text-[#000000]" />,
              sortable: true,
              selector: (row) => <HemaValue className="text-xs" text={row?.expirationDate || ' N/A'} />,
              sortId: 'expirationDate',
            },

            {
              name: <HemaValue text={'QC By'} className="text-xs font-normal" color="text-[#000000]" />,
              sortable: true,
              selector: (row) => <HemaValue className="text-xs" text={row?.qualityControlledBy?.name || ' N/A'} />,
              sortId: 'qualityControlledBy.name ',
            },
            {
              name: <HemaValue text={'QC Date'} className="text-xs font-normal" color="text-[#000000]" />,
              sortable: true,
              selector: (row) => <HemaValue className="text-xs" text={row?.qualityControlledDate || ' N/A'} />,
              sortId: 'qualityControlledDate',
            },
            {
              name: <HemaValue text={'Actions'} className="text-xs font-normal" color="text-[#000000]" />,

              selector: (row) =>
                common?.permissions?.['Kit Order Process']?.update && (
                  <button
                    className="cursor-pointer flex items-center justify-center w-[28px] h-[28px] bg-[#F1F5F7] rounded-[5px]"
                    onClick={() => {
                      if (ctaDetail) {
                        ctaDetail(row);
                      }
                    }}
                    disabled={payloadQc?.filter((data) => data.id === row?.id)?.length ? true : false}
                    style={{
                      opacity: payloadQc?.filter((data) => data.id === row?.id)?.length ? '.5' : '1',
                    }}
                  >
                    <QualityControlTab purple />
                  </button>
                ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default QualityControl;
