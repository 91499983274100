import { store } from '../Store';
import { showErrorReducer, setFormLoaderReducer } from '../Store/reducers/uiSettings';

export const errorHandling = (error) => {
  console.log(error)
  if(error?.status===401) {
    window.location.reload();
  }
  store.dispatch(setFormLoaderReducer(false));
  try {
    if (error.details || error.Details) {
      if (error.details) {
        store.dispatch(showErrorReducer(error.details[error?.details?.length - 1]?.errors[0] || error.details[0]?.errors[0]));
      } else {
        try {
          store.dispatch(showErrorReducer(error.Details[error?.Details?.length - 1]?.Errors[0] || error.Details[0]?.Errors[0]));
        } catch (e) {
          store.dispatch(showErrorReducer(error.Details[error?.Details?.length - 1]?.Error || error.Details[0]?.Error));
        }
      }
    } else {
      store.dispatch(showErrorReducer(error?.message || 'Something Went Wrong'));
    }
  } catch (e) {
    store.dispatch(showErrorReducer('Something Went Wrong'));
  }
  window.scrollTo(0, 0);
};
