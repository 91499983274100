import React from 'react';
export const TestingLab = ({ color }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.57939 13.6143H0.398438V15.4095H3.15413C3.54341 16.0844 4.0407 16.6908 4.62622 17.2048H2.19367V19H16.5556V17.2048H14.1051C14.824 16.5714 15.409 15.8005 15.8254 14.9376C16.2419 14.0746 16.4814 13.1371 16.5299 12.1801C16.5784 11.2232 16.435 10.2662 16.1079 9.36557C15.7809 8.46494 15.2769 7.63886 14.6257 6.93601L15.8465 5.71525C16.3434 5.18473 16.6147 4.48175 16.6028 3.7549C16.591 3.02806 16.297 2.3343 15.7829 1.82027C15.2689 1.30624 14.5751 1.01223 13.8483 1.00037C13.1214 0.988519 12.4185 1.25976 11.888 1.75675L6.31374 7.33096L10.2722 11.2895L13.36 8.22858C14.2581 9.20895 14.7576 10.4895 14.7603 11.8191C14.7579 12.9074 14.4258 13.9694 13.8078 14.8652C13.1898 15.7611 12.3148 16.4486 11.2983 16.8374C10.2818 17.2261 9.17135 17.2977 8.1133 17.0428C7.05525 16.788 6.09924 16.2185 5.37124 15.4095H7.57939V13.6143ZM8.84503 7.33096L13.1446 3.03137C13.2383 2.93766 13.3496 2.86332 13.472 2.8126C13.5945 2.76189 13.7257 2.73578 13.8582 2.73578C13.9908 2.73578 14.122 2.76189 14.2444 2.8126C14.3669 2.86332 14.4781 2.93766 14.5718 3.03137C14.6655 3.12508 14.7399 3.23633 14.7906 3.35877C14.8413 3.48121 14.8674 3.61245 14.8674 3.74497C14.8674 3.8775 14.8413 4.00873 14.7906 4.13118C14.7399 4.25362 14.6655 4.36487 14.5718 4.45858L10.2722 8.75817L8.84503 7.33096Z"
        fill={color || "url(#paint0_linear_5200_305552)"}
        stroke={color || "url(#paint1_linear_5200_305552)"}
        stroke-width="0.5" />
      <defs>
        <linearGradient id="paint0_linear_5200_305552" x1="2.28665" y1="12.3272" x2="14.8327" y2="12.5072" gradientUnits="userSpaceOnUse">
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient id="paint1_linear_5200_305552" x1="2.28665" y1="12.3272" x2="14.8327" y2="12.5072" gradientUnits="userSpaceOnUse">
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
