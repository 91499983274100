import React from 'react';

function SystemUser({ color }) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33203 20.7453C9.39736 22.8282 11.2335 24.4674 13.4351 24.4082H18.5623C20.7639 24.4674 22.6 22.8282 22.6654 20.7453M22.6654 20.5778C22.6 18.4949 20.7639 16.8557 18.5623 16.9148H13.4351C11.2335 16.8557 9.39736 18.4949 9.33203 20.5778M19.3362 10.7631C19.3362 12.5049 17.8438 13.9169 16.0029 13.9169C14.1619 13.9169 12.6695 12.5049 12.6695 10.7631C12.6695 9.02136 14.1619 7.60938 16.0029 7.60938C17.8438 7.60938 19.3362 9.02136 19.3362 10.7631Z"
        stroke={color || 'url(#paint0_linear_4637_133281)'}
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4637_133281"
          x1="9.33203"
          y1="16.0096"
          x2="22.6654"
          y2="16.0096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SystemUser;
