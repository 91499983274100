import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allTasks: null,
  statuses : null
};

export const Tasks = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setAllTasks: (state, action) => {
      state.allTasks = action.payload;
    },

    addStatus: (state, action) => {
      state.statuses = action.payload;
    },


    addNewTasks: (state, action) => {
        if(state.allTasks) {
            state.allTasks = [action.payload,...state.allTasks];
        } else {
            state.allTasks = [action.payload];
        }

      },
  },
});

export const { setAllTasks, addNewTasks, addStatus } = Tasks.actions;

export default Tasks.reducer;
