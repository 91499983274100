const SystemUserValidation = (errors, values) => {
  var letters = /^[ A-Za-z_@./#&+-]*$/;
  if (values.firstName && !values?.firstName?.match(letters)) {
    errors.firstName = 'Name can be only alphabets & special characters.';
  }
  if (values.firstName && values?.firstName?.length > 100) {
    errors.firstName = 'Max 100 characters allowed.';
  }
  if (values.lastName && values?.lastName?.length > 100) {
    errors.lastName = 'Max 100 characters allowed.';
  }
  if (values?.lastName && !values?.lastName?.match(letters)) {
    errors.lastName = 'Name can be only alphabets & special characters.';
  }
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Please enter correct email format';
  }
  return errors;
};

export default SystemUserValidation;
