import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Button, HemaValue, SingleSelect } from "../../../utils";
import { BellIcon, Save } from "../../../HemeIconLibrary";
import {
  getSettingListAction,
  updateSettingListAction,
} from "../../../Actions/order";
import { showSuccessReducer } from "../../../Store/reducers/uiSettings";

const ApplicationSetting = () => {
  const [selectedProcess, setSelectedProcess] = useState();
  const [loader, setLoader] = useState(false);
  const { orders } = useSelector((state) => state);
  const dispatch = useDispatch();
  const notificationOptions = [
    { label: "Default Notification Process", value: "default" },
    { label: "Custom Notification Process", value: "custom" },
  ];

  useEffect(() => {
    getSettingListAction();
  }, []);

  useEffect(() => {
    if (orders?.allSettingList?.length) {
      setSelectedProcess(orders?.allSettingList?.[0]);
    }
  }, [orders?.allSettingList]);

  return (
    <div className="bg-hemaSecondary">
      <div className="p-[25px] bg-white">
        <HemaValue
          text="Client Ordering Portal"
          color="text-[#454545]"
          className="text-xl font-bold"
        />

        <div className="flex flex-col gap-3 mt-3">
          <div className="flex items-center gap-[10px]">
            <BellIcon width={18} height={18} />
            <HemaValue
              text="Kit Request Notification Process"
              color="text-[#454545]"
              className="text-sm font-bold"
            />
          </div>
          <SingleSelect
            options={notificationOptions}
            setFieldValue={(e, value) => {
              setSelectedProcess({ ...selectedProcess, value: value });
            }}
            name={"country"}
            value={selectedProcess?.value}
            placeholder={
              selectedProcess?.value === "custom"
                ? "Custom Notification Process"
                : "Default Notification Process"
            }
          />
          <HemaValue
            text="The Default process sends all kit request notifications to the portal user who submitted the request whereas the Modified process sends notifications to the contact information associated with custom addresses only with the exception of the Request More Information notification."
            color="text-[#707070]"
            className="text-sm font-bold"
          />
        </div>
      </div>

      <div className="flex-row flex gap-4 justify-end pt-3 pr-4 pb-[6px] mt-5 bg-white">
        <Button
          type="button"
          text={
            loader ? (
              <>
                <Spinner animation="grow" size="sm" variant="light" />
                <Spinner animation="grow" size="sm" variant="light" />
                <Spinner animation="grow" size="sm" variant="light" />
              </>
            ) : (
              "Save Changes"
            )
          }
          bg="bg-[#605DAF]"
          Icon={loader ? "" : <Save color="white" />}
          color="text-white"
          disabled={loader ? true : false}
          cta={async () => {
            setLoader(true);
            const result = await updateSettingListAction(
              orders?.allSettingList?.[0]?.id,
              {
                value: selectedProcess?.value,
              }
            );
            setLoader(false);
            if (result.status === 200) {
              dispatch(showSuccessReducer("Setting List updated"));
            }
          }}
        />
      </div>
    </div>
  );
};

export default ApplicationSetting;
