import { get } from './http';
import { errorHandling } from './error';

const getAudit = async (PageNumber, PageSize, filters) => {
  try {
    return await get(
      `audit/audits?PageNumber=${PageNumber}&EnableBackendPagination=true&PageSize=${PageSize}&${filters}`
    );
  } catch (err) {
    errorHandling(err?.response);
    return err?.response?.data;
  }
};

const getAuditFilters = async () => {
  try {
    return await get(`audit/audits/filters`);
  } catch (err) {
    return err?.response?.data;
  }
};

export { getAudit, getAuditFilters };
