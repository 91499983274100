import React from 'react';

export const CompanyLocation = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4402_209715)">
        <path
          d="M8.8136 11.2836H4.35603L3.71317 17.2082M3.71317 17.2082L3.07031 23.1224H10.7846M3.71317 17.2082L7.07788 14.9311M15.3233 11.2937L15.5521 11.1021C16.7687 10.0519 17.4681 8.52426 17.4681 6.91708C17.4681 5.30991 16.7687 3.78228 15.5521 2.73209C13.3908 0.998942 11.1858 0.591371 8.58227 2.73209C7.36567 3.78228 6.66629 5.30991 6.66629 6.91708C6.66629 8.52426 7.36567 10.0519 8.58227 11.1021L8.81112 11.2937L10.4398 12.6488M15.3248 11.2824H19.7823L20.4252 17.1967M15.3288 11.2824L12.0721 14.0158L10.4431 12.6491M10.7846 23.1224L7.07812 14.9261L10.4416 12.6491M10.7846 23.1224L14.6417 23.1225M14.6417 23.1225L21.0703 23.1224L20.4275 17.2081L14.6417 23.1225ZM13.8722 6.72964C13.8602 7.72082 13.049 8.51573 12.0578 8.50773C11.0666 8.49973 10.2684 7.69183 10.2723 6.70059C10.2763 5.70934 11.0809 4.90786 12.0722 4.90778C12.5525 4.9105 13.0121 5.10402 13.3497 5.44572C13.6873 5.78743 13.8753 6.2493 13.8722 6.72964Z"
          stroke="url(#paint0_linear_4402_209715)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4402_209715"
          x1="3.07031"
          y1="12.1989"
          x2="21.0703"
          y2="12.1989"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <clipPath id="clip0_4402_209715">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0703125 0.199219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
