import { HemaValue } from '../../utils';
export const KitRequestBuilding = [
  {
    name: (
      <HemaValue
        text={' Name'}
        className="text-xs font-normal"
        color="text-[#000000]"
      />
    ),

    selector: (row) => <HemaValue className="text-xs" text={row?.item?.name} />,
    sortId: 'item.name',
  },
  {
    name: (
      <HemaValue
        text={'Remaining Quantity'}
        className="text-xs font-normal"
        color="text-[#000000]"
      />
    ),
    center: true,

    selector: (row) => (
      <HemaValue className="text-xs " text={row?.remainingQuantity || 0} />
    ),
    sortId: 'remainingQuantity',
  },
  {
    name: (
      <HemaValue
        text={'Built Quantity'}
        className="text-xs font-normal"
        color="text-[#000000]"
      />
    ),
    center: true,
    selector: (row) => (
      <HemaValue className="text-xs" text={row?.builtQuantity || 0} />
    ),
    sortId: 'builtQuantity',
  },
  {
    name: (
      <HemaValue
        text={'Ready to Ship Quantity'}
        className="text-xs font-normal"
        color="text-[#000000]"
      />
    ),
    center: true,
    selector: (row) => (
      <HemaValue className="text-xs" text={row?.readyToShipQuantity || 0} />
    ),
    sortId: 'readToShipQuantity',
  },
  {
    name: (
      <HemaValue
        text={'Shipped Quantity'}
        className="text-xs font-normal"
        color="text-[#000000]"
      />
    ),
    center: true,
    selector: (row) => (
      <HemaValue className="text-xs" text={row?.shippedQuantity || 0} />
    ),
    sortId: 'readToShipQuantity',
  },
  {
    name: (
      <HemaValue
        text={'Delivered Quantity'}
        className="text-xs font-normal"
        color="text-[#000000]"
      />
    ),
    center: true,
    selector: (row) => (
      <HemaValue className="text-xs" text={row?.deliveredQuantity || 0} />
    ),
    sortId: 'readToShipQuantity',
  },
];
