import React from "react";
export const DeleteIcon = ({ color }) => {
  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3721 5.35229H1.62791C1.14521 5.35229 0.753906 5.7436 0.753906 6.22629V13.2214C0.753906 14.6701 1.92828 15.8445 3.37695 15.8445H8.62305C9.31872 15.8445 9.9859 15.5681 10.4778 15.0762C10.9697 14.5843 11.2461 13.9171 11.2461 13.2214V6.22629C11.2461 5.7436 10.8548 5.35229 10.3721 5.35229Z"
        stroke="#775FD5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.99704 8.84967C4.99704 8.43546 4.66125 8.09967 4.24704 8.09967C3.83282 8.09967 3.49704 8.43546 3.49704 8.84967H4.99704ZM3.49704 12.3467C3.49704 12.7609 3.83282 13.0967 4.24704 13.0967C4.66125 13.0967 4.99704 12.7609 4.99704 12.3467H3.49704ZM8.49976 8.84967C8.49976 8.43546 8.16398 8.09967 7.74976 8.09967C7.33555 8.09967 6.99976 8.43546 6.99976 8.84967H8.49976ZM6.99976 12.3467C6.99976 12.7609 7.33555 13.0967 7.74976 13.0967C8.16398 13.0967 8.49976 12.7609 8.49976 12.3467H6.99976ZM7.84645 1.90567C8.26066 1.90567 8.59645 1.56988 8.59645 1.15567C8.59645 0.741453 8.26066 0.405666 7.84645 0.405666V1.90567ZM4.3494 0.405666C3.93519 0.405666 3.5994 0.741453 3.5994 1.15567C3.5994 1.56988 3.93519 1.90567 4.3494 1.90567V0.405666ZM11.2461 4.0042C11.6603 4.0042 11.9961 3.66842 11.9961 3.2542C11.9961 2.83999 11.6603 2.5042 11.2461 2.5042V4.0042ZM0.753906 2.5042C0.339693 2.5042 0.00390625 2.83999 0.00390625 3.2542C0.00390625 3.66842 0.339693 4.0042 0.753906 4.0042V2.5042ZM3.49704 8.84967V12.3467H4.99704V8.84967H3.49704ZM6.99976 8.84967V12.3467H8.49976V8.84967H6.99976ZM7.84645 0.405666H4.3494V1.90567H7.84645V0.405666ZM11.2461 2.5042H0.753906V4.0042H11.2461V2.5042Z"
        fill="#775FD5"
      />
    </svg>
  );
};
