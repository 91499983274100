import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import AppRouter from './appRouter'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from '../msConfig'
import { setShowRefreshToken } from '../Store/reducers/common'

const App = () => {
  const { common } = useSelector((state) => state)
  const [tenantIsActive, setTenantIsACtive] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (common.tenant) {
      setTenantIsACtive(common.tenant)
    }
  }, [common])

  // useEffect(() => {
  //   let timer
  //   if (common.refreshTokenTime) {
  //     clearInterval(timer)
  //     timer = setInterval(() => {
  //       const currentTime = Date.now()

  //       if (currentTime - common.refreshTokenTime >= 720000) {
  //         // No route has been called within 12 minutes
  //         // You can perform your desired action here, such as showing a message or redirecting

  //         dispatch(setShowRefreshToken(true))
  //         clearInterval(timer)
  //       }
  //     }, 2000)
  //   }
  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [common.refreshTokenTime])

  useEffect(() => {
    let timer
    let lastUserActionTime = Date.now()

    const handleUserAction = () => {
      lastUserActionTime = Date.now()
    }

    const checkUserInactivity = () => {
      const currentTime = Date.now()
      const timeSinceLastAction = currentTime - lastUserActionTime
   
      if (timeSinceLastAction >= 540000) {
        // 9 minutes in milliseconds
        // No mouse or keyboard activity for the last 9 minutes
        dispatch(setShowRefreshToken(true))
        clearInterval(timer)
      }
    }

    if (common.refreshTokenTime) {
      clearInterval(timer)
      timer = setInterval(checkUserInactivity, 2000)

      // Add event listeners for mouse movement and keyboard presses
      window.addEventListener('mousemove', handleUserAction)
      window.addEventListener('keydown', handleUserAction)
    }

    return () => {
      clearInterval(timer)

      // Remove event listeners on cleanup
      window.removeEventListener('mousemove', handleUserAction)
      window.removeEventListener('keydown', handleUserAction)
    }
  }, [common.refreshTokenTime])

  return tenantIsActive ? (
    <MsalProvider
      instance={
        new PublicClientApplication(
          msalConfig(
            tenantIsActive.azureClientId,
            tenantIsActive.azureTenantId,
            window.location.origin + '/login',
          ),
        )
      }
    >
      <AppRouter />
    </MsalProvider>
  ) : (
    <AppRouter />
  )
}

export default App
