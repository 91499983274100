import React from 'react';

export const WhoIcon = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8346 10.7534C19.8346 12.4951 18.3423 13.9071 16.5013 13.9071C14.6604 13.9071 13.168 12.4951 13.168 10.7534C13.168 9.01159 14.6604 7.59961 16.5013 7.59961C18.3423 7.59961 19.8346 9.01159 19.8346 10.7534Z"
        stroke="#775FD5"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.8315 20.704C10.8142 20.152 10.3527 19.7185 9.80068 19.7358C9.24867 19.7532 8.81521 20.2147 8.83252 20.7667L10.8315 20.704ZM13.9351 24.3983V23.3983C13.9261 23.3983 13.9172 23.3984 13.9082 23.3987L13.9351 24.3983ZM19.0623 24.3983L19.0892 23.3987C19.0802 23.3984 19.0713 23.3983 19.0623 23.3983V24.3983ZM24.1649 20.7667C24.1822 20.2147 23.7487 19.7532 23.1967 19.7358C22.6447 19.7185 22.1832 20.152 22.1659 20.704L24.1649 20.7667ZM22.1659 20.5992C22.1832 21.1512 22.6447 21.5846 23.1967 21.5673C23.7487 21.55 24.1822 21.0885 24.1649 20.5365L22.1659 20.5992ZM19.0623 16.9049V17.9049C19.0713 17.9049 19.0802 17.9048 19.0892 17.9045L19.0623 16.9049ZM13.9351 16.9049L13.9082 17.9045C13.9172 17.9048 13.9261 17.9049 13.9351 17.9049V16.9049ZM8.83252 20.5365C8.81521 21.0885 9.24867 21.55 9.80068 21.5673C10.3527 21.5846 10.8142 21.1512 10.8315 20.5992L8.83252 20.5365ZM8.83252 20.7667C8.91671 23.4508 11.2625 25.4704 13.9619 25.3979L13.9082 23.3987C12.2045 23.4444 10.878 22.1856 10.8315 20.704L8.83252 20.7667ZM13.9351 25.3983H19.0623V23.3983H13.9351V25.3983ZM19.0355 25.3979C21.7349 25.4704 24.0807 23.4508 24.1649 20.7667L22.1659 20.704C22.1194 22.1856 20.7929 23.4444 19.0892 23.3987L19.0355 25.3979ZM24.1649 20.5365C24.0807 17.8523 21.7349 15.8327 19.0355 15.9052L19.0892 17.9045C20.7929 17.8588 22.1194 19.1175 22.1659 20.5992L24.1649 20.5365ZM19.0623 15.9049H13.9351V17.9049H19.0623V15.9049ZM13.9619 15.9052C11.2625 15.8327 8.91671 17.8523 8.83252 20.5365L10.8315 20.5992C10.878 19.1175 12.2045 17.8588 13.9082 17.9045L13.9619 15.9052Z"
        fill="#775FD5"
      />
    </svg>
  );
};
