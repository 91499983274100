import { post, get, remove, put } from './http';
import { errorHandling } from './error';

const getAllEmailTemplates = async () => {
  try {
    return await get(`settings/emailtemplates`);
  } catch (err) {
    return err?.response?.data;
  }
};

const getMergefieldforEmail = async (id) => {
  try {
    return await get(`settings/emailtemplates/${id}/merge-fields`);
  } catch (err) {
    return err?.response?.data;
  }
};

const removeEmailTemplatesItem = async (id, data) => {
  try {
    return await remove(`settings/emailtemplates/${id}`);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const createEmailTemplates = async (data) => {
  try {
    return await post(`settings/emailtemplates`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const updateEmailTemplates = async (id, data) => {
  try {
    return await put(`settings/emailtemplates/${id}`, data);
  } catch (err) {
    errorHandling(err?.response?.data);
    return err?.response?.data;
  }
};

const getEmailTemplatesTypes = async (id, data) => {
  try {
    return await get(`settings/emailtemplates/template-types`);
  } catch (err) {
    // errorHandling(err?.response?.data?.message || 'Something Went Wrong')
    return err?.response?.data;
  }
};

export {
  getAllEmailTemplates,
  getMergefieldforEmail,
  createEmailTemplates,
  updateEmailTemplates,
  getEmailTemplatesTypes,
  removeEmailTemplatesItem,
};
