export const viewPackageItems = [
  {
    type: "heading-only",
    initialValue: "Package Details",
    fluid: true,
  },
  {
    label: "Package Number",
    initialValue: "",
    name: "shipment-name",
    fluid: true,
    type: "table",
  },
  {
    label: "Package Weight",
    initialValue: "",
    name: "Package-Weight",
    fluid: true,
    type: "table",
  },
  {
    label: "Package Dimensions",
    initialValue: "",
    name: "Package-Dimensions",
    fluid: true,
    type: "table",
  },
  {
    name: "recordItemId",
    label: "",
    initialValue: "",
    fluid: true,
    type: "viewItems",
  },
];

export const UnloackPackage = [
  {
    type: "heading-only",
    initialValue: "Package Details",
    fluid: true,
  },
  {
    label: "Shipment Name",
    initialValue: "",
    name: "shipment-name",
    fluid: true,
    type: "table",
  },

  {
    label: "Package Number",
    initialValue: "",
    name: "shipment-name",
    fluid: true,
    type: "table",
  },
  {
    type: "heading-only",
    initialValue: "Confirmation Message",
    fluid: true,
  },
  {
    name: "recordItemId",
    label: "Item Details",
    initialValue: "",
    fluid: true,
    type: "table",
  },
];
