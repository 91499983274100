import {
  login,
  checkDomain,
  verifyAzureToken,
  refreshToken,
} from '../Services/auth';
import {
  setDomainTenant,
  setPermissions,
  setUserDetails,
} from '../Store/reducers/common';
import { store } from '../Store';

const LoginAction = async (data) => {
  try {
    const result = await login(data);
    if (result.status === 200) {
      store.dispatch(setPermissions(result.data?.token?.permissions));
      store.dispatch(setUserDetails(result.data?.token?.user));
    }
    return result;
  } catch (err) {
    return err;
  }
};

const checkDomainAction = async (domain) => {
  const result = await checkDomain(domain);
  if (result.status === 200) {
    store.dispatch(setDomainTenant(result.data));
  }
  return result;
};

const verifyAzureTokenAction = async (domain) => {
  const result = await verifyAzureToken(domain);
  if (result.status === 200) {
    store.dispatch(setPermissions(result.data?.token?.permissions));
    store.dispatch(setUserDetails(result.data?.token?.user));
  }
  return result;
};
const refreshTokenAction = async () => {
  const result = await refreshToken({
    token: localStorage.getItem('hema-token'),
    refreshToken: localStorage.getItem('hema-token-refresh'),
  });
  if (result.status === 200) {
    store.dispatch(setPermissions(result.data?.permissions));
    store.dispatch(setUserDetails(result.data?.user));
  }
  return result;
};

export {
  LoginAction,
  checkDomainAction,
  verifyAzureTokenAction,
  refreshTokenAction,
};
