import React, { forwardRef, useEffect, useState } from 'react';

import DataTable from 'react-data-table-component';

import { useSelector, useDispatch } from 'react-redux';

import { Button, HemaValue, Pagination } from '../../utils';
import { HemaDateFormat } from '../../helpers/commonHelper';
import { Add, AddInventoryPurple, UploadIcon } from '../../HemeIconLibrary';

import { createNewDoumentAction, createOrderTypeAction, getAllDocumentsForOrderAction } from '../../Actions/order';
import { showSuccessReducer, setForm, setFormLoaderReducer, setUploadProgress } from '../../Store/reducers/uiSettings';
import FileIcon from '../../assets/images/file-item.svg';
import attachIcon from '../../assets/images/attach-icon.svg';
const Notebook = forwardRef((props, ref) => {
  const { orders, common } = useSelector((state) => state);

  const { setformName, setCTA, setFormIcon, setUpdatedData, setokBtnText, setCancelBtnText, setokBtnIcon } = props;
  const dispatch = useDispatch();
  const submitform = () => {
    setCTA(() => async (data) => {
      const formData = new FormData();
      formData.append('file', data.notebookdocs);
      dispatch(setFormLoaderReducer(true));
      const result = await createNewDoumentAction(orders.activeOrder?.id, data?.allTypes, formData);
      dispatch(setFormLoaderReducer(false));
      if (result.status === 200) {
        getAllDocumentsForOrderAction(orders.activeOrder?.id);
        dispatch(showSuccessReducer('New document added'));
        dispatch(setUploadProgress());
        dispatch(
          setForm({
            state: false,
            type: '',
          }),
        );
      }
    });
  };
  useEffect(() => {
    if (common?.activeDropdownValue?.id === 'new-type') {
      setformName('Create New File Type');
      setFormIcon(<AddInventoryPurple />);
      setokBtnText('Save');
      setUpdatedData([
        {
          label: 'File Type Name',
          Icon: <img src={FileIcon} />,
          type: 'text',
          placeholder: 'Enter file type name',
          initialValue: undefined,

          fluid: true,
          required: true,
          name: 'name',
        },
      ]);
      setCTA(() => async (data) => {
        dispatch(setFormLoaderReducer(true));
        const result = await createOrderTypeAction(data);
        dispatch(setFormLoaderReducer(false));
        if (result.status === 200) {
          dispatch(showSuccessReducer('New type added to dropdown list'));
          setformName('Add New File');
          setFormIcon(<AddInventoryPurple />);
          setokBtnIcon(<img src={attachIcon} alt="logo" />);
          setokBtnText('Attach');
          setUpdatedData([
            {
              label: 'File Type',
              Icon: <img src={FileIcon} />,
              type: 'select',
              initialValue: undefined,
              placeholder: 'Select file Type',
              fluid: true,
              required: true,
              name: 'allTypes',
              options:
                [
                  result.data,
                  ...orders?.documentTypes,
                  {
                    icon: <Add color="#000" />,
                    id: 'new-type',
                    name: 'Create New File Type',
                  },
                ] || [],
            },
            {
              label: 'Upload File',
              Icon: <UploadIcon purple />,
              type: 'upload-image',
              initialValue: undefined,
              placeholder: 'Excel',
              fluid: true,
              required: true,
              name: 'notebookdocs',
            },
          ]);

          submitform();
        }
      });
    }
  }, [common.activeDropdownValue]);

  return (
    <div className="w-full bg-white rounded-[5px] mb-[13px] inventory-tabs pb-[16px]">
      <div className="w-full flex items-center justify-between mb-[16px] px-[16px]">
        <h2 className="m-0 text-base font-bold text-primary1">Notebook Information</h2>
        {common?.permissions?.['Kit Order Process']?.update && (
          <Button
            cta={() => {
              setformName('Add New File');
              setFormIcon(<AddInventoryPurple />);
              setokBtnIcon(<img src={attachIcon} alt="logo" />);
              setokBtnText('Attach');
              setUpdatedData([
                {
                  label: 'File Type',
                  Icon: <img src={FileIcon} />,
                  type: 'select',
                  initialValue: undefined,
                  placeholder: 'Select file Type',
                  fluid: true,
                  required: true,
                  name: 'allTypes',
                  options:
                    [
                      ...orders?.documentTypes,
                      {
                        icon: <Add color="#000" />,
                        id: 'new-type',
                        name: 'Create New File Type',
                      },
                    ] || [],
                },
                {
                  label: 'Upload File',
                  Icon: <UploadIcon purple />,
                  type: 'upload-image',
                  initialValue: undefined,
                  placeholder: 'Excel',
                  fluid: true,
                  required: true,
                  name: 'notebookdocs',
                },
              ]);

              submitform();
              dispatch(
                setForm({
                  state: true,
                  type: 'create-new-document',
                }),
              );
            }}
            type="button"
            text="Add New File"
            bg="bg-primary1"
            color="text-white"
            Icon={<Add color="#fff" />}
          />
        )}
      </div>

      <DataTable
        data={orders?.notebookDocument || []}
        className=" border-t border-b border-[#DEE2E6] notebook_table"
        columns={[
          {
            name: <HemaValue text={'File Type'} className="text-xs font-normal " color="text-[#000000]" />,

            selector: (row) => (
              <div className="flex gap-[10px]">
                <HemaValue className="text-xs" text={row?.documentType?.name} />
              </div>
            ),
            sortId: 'Name',
          },
          {
            style: {
              maxWidth: 'clamp(330px, 48vw, 850px)',
              minWidth: 'clamp(330px, 48vw, 850px)',
            },
            name: <HemaValue text="File" className="text-xs font-normal " color="text-[#000000]" />,

            selector: (row) => (
              <a href={row?.pdfFile?.storageUrl} target="_blank" rel="noreferrer">
                <HemaValue className="font-normal text-xs decoration-[#605DAF] " color="text-[#605DAF]" text={row?.name} />
              </a>
            ),
            sortId: 'readToShipQuantity',
          },
          {
            name: <HemaValue text={'Date Created'} className="text-xs font-normal " color="text-[#000000]" />,

            selector: (row) => <HemaValue className="text-xs" text={HemaDateFormat(row?.createdUtc)} />,
            sortId: 'readToShipQuantity',
          },
          {
            name: <HemaValue text={'Record Owner'} className="text-xs font-normal " color="text-[#000000]" />,

            selector: (row) => <HemaValue className="text-xs" text={row?.owner?.name || 'NA'} />,
          },
        ]}
        pagination={true}
        customStyles={{
          responsiveWrapper: {
            style: { overflow: 'visible !important' },
          },
        }}
        paginationComponent={(e) => {
          return <Pagination e={e} />;
        }}
      />
    </div>
  );
});

export default Notebook;
