import React from "react";
export const Library = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11.0001L10.6422 15.8212C10.7734 15.8868 10.839 15.9196 10.9078 15.9325C10.9687 15.9439 11.0313 15.9439 11.0922 15.9325C11.161 15.9196 11.2266 15.8868 11.3578 15.8212L21 11.0001M1 16.0001L10.6422 20.8212C10.7734 20.8868 10.839 20.9196 10.9078 20.9325C10.9687 20.9439 11.0313 20.9439 11.0922 20.9325C11.161 20.9196 11.2266 20.8868 11.3578 20.8212L21 16.0001M1 6.00006L10.6422 1.17895C10.7734 1.11336 10.839 1.08056 10.9078 1.06766C10.9687 1.05622 11.0313 1.05622 11.0922 1.06766C11.161 1.08056 11.2266 1.11336 11.3578 1.17895L21 6.00006L11.3578 10.8212C11.2266 10.8868 11.161 10.9196 11.0922 10.9325C11.0313 10.9439 10.9687 10.9439 10.9078 10.9325C10.839 10.9196 10.7734 10.8868 10.6422 10.8212L1 6.00006Z"
        stroke="url(#paint0_linear_3181_93546)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3181_93546"
          x1="1"
          y1="11.0001"
          x2="21"
          y2="11.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
