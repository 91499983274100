import { getAudit, getAuditFilters } from '../Services/audit';
import { setAudit, setAuditFilters } from '../Store/reducers/audit';
import { store } from '../Store';

const getAuditAction = async (PageNumber, PageSize, filters) => {
  try {
    const result = await getAudit(PageNumber, PageSize, filters);
    if (result.status === 200) {
      store.dispatch(setAudit(result.data));
    }
    return result;
  } catch (err) {
    return err;
  }
};

const getAuditFiltersAction = async () => {

    const result = await getAuditFilters();
    console.log(result)
    if (result.status === 200) {
      store.dispatch(setAuditFilters(result.data));
    }
    return result;

};

export { getAuditAction, getAuditFiltersAction };
