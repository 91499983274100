const PackageValidation = (errors, values, store) => {
  if (values.name?.length > 100) {
    errors.name = 'Max 100 characters allowed';
  }
  if (values.unit?.length > 20) {
    errors.unit = 'Max 20 characters allowed';
  }
  if (values.length?.toString()?.length > 20) {
    errors.length = 'Max 20 characters allowed';
  }
  if (values.height?.toString()?.length > 20) {
    errors.height = 'Max 20 characters allowed';
  }
  if (values.width?.toString()?.length > 20) {
    errors.width = 'Max 20 characters allowed';
  }
  return errors;
};

export default PackageValidation;
