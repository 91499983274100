import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allOrders: null,
  allSettingList: null,
  dashboard: null,
  defaultValues: null,
  itemAvailable: null,
  type: null,
  activesite: null,
  alladdress: null,
  acknowledgedetails: null,
  activeShipment: null,
  allBuildPreparation: null,
  allStagingPreparation: null,
  allShipping: null,
  activeOrder: null,
  allShipments: null,
  allGreenLights: null,
  qualityControlItems: null,
  postShippingTemplate: null,
  kmcTemplate: null,
  activeBuildStaging: null,
  dashboardFilter: null,
  builtAssembly: null,
  notebookDocument: null,
  documentTypes: null,
  couriertype: null,
  dynamicdocuments: null,
  activeDynamicDoc:null,
  packageDimensions: null,
};

export const Orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setAllDocument: (state, action) => {
      state.notebookDocument = action.payload;
    },
    setActiveDynamicDoc: (state, action) => {
      state.activeDynamicDoc = action.payload;
    },

    setdynamicdocuments: (state, action) => {
      state.dynamicdocuments = action.payload;
    },
    setPackageDimension: (state, action) => {
      state.packageDimensions = action.payload;
    },

    addtypeOrderNew: (state, action) => {
      if (state.documentTypes) {
        state.documentTypes = [action.payload, ...state.documentTypes];
      } else {
        state.documentTypes = [action.payload];
      }
    },
    setAllTypes: (state, action) => {
      state.documentTypes = action.payload;
    },
    setDashbaordFilter: (state, action) => {
      state.dashboardFilter = action.payload;
    },
    setDashbaord: (state, action) => {
      state.dashboard = action.payload;
    },
    setAllOrder: (state, action) => {
      state.allOrders = action.payload;
    },
    setAllSettingList: (state, action) => {
      state.allSettingList = action.payload;
    },
    updateSettingListReducer: (state, action) => {
      state.allSettingList = state.allSettingList?.map((data) => {
        if (data.id === action.payload?.id) {
          return action.payload
        } else {
          return data
        }
      })
    },
    setQualityControlItems: (state, action) => {
      state.qualityControlItems = action.payload;
    },
    setActiveOrder: (state, action) => {
      state.activeOrder = action.payload;
    },
    setGreenLightdetails: (state, action) => {
      state.allGreenLights = action.payload;
    },

    addNewOrder: (state, action) => {
      if (state.allOrders) {
        state.allOrders = [action.payload, ...state.allOrders];
      } else {
        state.allOrders = [action.payload];
      }
    },
    deleteShimentReducer: (state, action) => {
      state.allShipping = state.allShipping?.filter(
        (data) => data.id !== action.payload
      );
    },
    setPostShippingTemplate: (state, action) => {
      state.postShippingTemplate = action.payload;
    },
    setDefaultValues: (state, action) => {
      state.defaultValues = action.payload;
    },

    setItemAvailable: (state, action) => {
      state.itemAvailable = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setActiveSite: (state, action) => {
      state.activesite = action.payload;
    },
    setAlladdresses: (state, action) => {
      state.alladdress = action.payload;
    },
    setacknowledgedetails: (state, action) => {
      state.acknowledgedetails = action.payload;
    },
    setAllBuildPreparation: (state, action) => {
      state.allBuildPreparation = action.payload;
    },
    setAllStagingPreparation: (state, action) => {
      state.allStagingPreparation = action.payload;
    },

    setAllShipping: (state, action) => {
      state.allShipping = action.payload;
    },
    setAllAvailableShipment: (state, action) => {
      //  state.allShipments = action.payload;
    },
    setActiveShipment: (state, action) => {
      state.activeShipment = action.payload;
    },
    setActivestagingForbuild: (state, action) => {
      state.activeBuildStaging = action.payload;
    },
    setAssemblyForStagingBuild: (state, action) => {
      state.builtAssembly = action.payload;
    },
    setKMCTemplate: (state, action) => {
      state.kmcTemplate = action.payload;
    },
    setCouriertype: (state, action) => {
      state.couriertype = action.payload;
    },
    setPostShippingTemplateSubmission: (state, action) => {
      state.postShippingTemplate = {
        ...state.postShippingTemplate,
        submission: action.payload,
      };
    },

    setkmcTemplateSubmission: (state, action) => {
      state.kmcTemplate = {
        ...state.kmcTemplate,
        submission: action.payload,
      };
    },
  },
});

export const {
  setActiveDynamicDoc,
  setPackageDimension,
  setkmcTemplateSubmission,
  setdynamicdocuments,
  setCouriertype,
  setKMCTemplate,
  addtypeOrderNew,
  deleteShimentReducer,
  setDashbaordFilter,
  setPostShippingTemplateSubmission,
  setPostShippingTemplate,
  setQualityControlItems,
  setActiveShipment,
  setAllAvailableShipment,
  setActiveOrder,
  setacknowledgedetails,
  addNewOrder,
  setAlladdresses,
  setDashbaord,
  setAllOrder,
  setAllSettingList,
  updateSettingListReducer,
  setDefaultValues,
  setItemAvailable,
  setType,
  setActiveSite,
  setAllBuildPreparation,
  setAllStagingPreparation,
  setAllShipping,
  setGreenLightdetails,
  setActivestagingForbuild,
  setAssemblyForStagingBuild,
  setAllDocument,
  setAllTypes,
} = Orders.actions;

export default Orders.reducer;
