import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Alert as BootStrapAlert } from 'react-bootstrap';
import { HemaLabel, Button, FormTextarea, FormCheckbox, Alert, FormMultiSelect } from '../../../utils';
import { NameKit, Cancel, Confirm, ChangeReason, BlackSearch, Add, Unassign } from '../../../HemeIconLibrary';
import Heading from '../../../pages/logistOrchestra/logisticComponents/heading';
import {
  assignShippingRuleAction,
  assignKitsAction,
  assignGeneralAssemblyAction,
  unAssignKitsAction,
  regionDetailIdAction,
  getAllCountryForRegionDetailAction,
  getAllSitesForCountryDetailAction,
  getAllLogisticLevelNode,
} from '../../../Actions/logistic';
import { setForm, showSuccessReducer } from '../../../Store/reducers/uiSettings';
import { compareArrays } from '../../../helpers/commonHelper';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const FormikForm = () => {
  const { common, assembly, logistic, uisettings } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [checked, setchecked] = useState([]);
  const [expanded, setexpanded] = useState([]);
  const [selectAll, setselectAll] = useState([]);
  const [allregionstree, setallregionstree] = useState([]);
  const [searchableTrees, setsearchableTrees] = useState([]);
  const [searchquery, setSearchquery] = useState('');
  const [msgLabel, setmsgLabel] = useState('region');
  const formikRef = useRef();

  useEffect(() => {
    (async () => {
      console.log(await formikRef.current?.validateForm());
    })();
  }, [JSON.stringify(formikRef?.current), uisettings?.editForm, checked]);
  useEffect(() => {
    if (allregionstree) {
      const regionCollapseIcon = document.querySelectorAll('.rct-collapse-btn');
      const countryCollapseIcon = document.querySelectorAll('.rct-node-parent .rct-collapse-btn');
      const allCollapseIcon = [...regionCollapseIcon, ...countryCollapseIcon];
      allCollapseIcon?.forEach(function (button) {
        button.addEventListener('mouseover', function () {
          button?.removeAttribute('title');
          button?.removeAttribute('aria-label');
        });
      });
    }
  }, [allregionstree, expanded]);
  useEffect(() => {
    ((async) => {
      var filtertree;
      if (logistic?.allLowerLeveltree) {
        filtertree =
          logistic?.allLowerLeveltree?.map((data) => {
            return {
              ...data,
              value: data.id + 'a' + data.name,
              label: data.name,
              id: data.id,
              children: data?.children?.map((child) => {
                return {
                  ...child,
                  value: child.id + 'a' + child.name,
                  label: child.name,
                  children: [],
                  id: child.id,
                  children: child?.children?.map((child) => {
                    return {
                      ...child,
                      value: child.id + 'a' + child.name,
                      label: child.name,
                      id: child.id,
                    };
                  }),
                };
              }),
            };
          }) || [];
        function separateValues(array) {
          const result = [];

          for (const item of array) {
            result.push(item.value); // add the current item's value to the result array

            if (item.children) {
              const childrenValues = separateValues(item.children); // recursively call the function for children
              result.push(...childrenValues); // add the children values to the result array
            }
          }

          return result;
        }

        if (filtertree) {
          if (
            uisettings.formName === 'assign-shipping-region-level' ||
            uisettings?.formName === 'assign-kit-region-level' ||
            uisettings?.formName === 'assign-initial-kit-region-level'
          ) {
            filtertree = filtertree?.filter((f) => f.id === logistic.selectedRegion.id).reduce((acc, cur) => acc.concat(cur.children), []);
            setallregionstree(filtertree);
            setsearchableTrees(filtertree);
            const valuesArray = separateValues(filtertree);
            setselectAll(valuesArray);
            setmsgLabel('country');
          } else if (
            uisettings?.formName === 'assign-shipping-country-level' ||
            uisettings?.formName === 'assign-initial-kit-country-level' ||
            uisettings?.formName === 'assign-kit-country-level'
          ) {
            filtertree = filtertree?.reduce((acc, cur) => acc.concat(cur.children), []);
            filtertree = filtertree?.filter((f) => f.id === logistic?.selectedCountry.id).reduce((acc, cur) => acc.concat(cur.children), []);
            setallregionstree(filtertree);
            setsearchableTrees(filtertree);
            const valuesArray = separateValues(filtertree);
            setselectAll(valuesArray);
            setmsgLabel('site');
          } else {
            setallregionstree(filtertree);
            setsearchableTrees(filtertree);
            const valuesArray = separateValues(filtertree);
            setselectAll(valuesArray);
            setmsgLabel('region');
          }
        }
      }
    })();
  }, [logistic]);

  useEffect(() => {
    ((async) => {
      if (allregionstree && searchquery) {
        const filterFileSystemByLabel = (data, label) => {
          return data.reduce((acc, curr) => {
            if (curr.label?.toLowerCase().includes(label?.toLowerCase())) {
              acc.push(curr);
            } else if (curr.children) {
              const filteredChildren = filterFileSystemByLabel(curr.children, label);
              if (filteredChildren.length > 0) {
                acc.push({ ...curr, children: filteredChildren });
              }
            }
            return acc;
          }, []);
        };

        // Example usage
        const filtertree = filterFileSystemByLabel(searchableTrees, searchquery);

        if (filtertree) {
          setallregionstree(filtertree);
        }
      } else {
        getAllLogisticLevelNode(logistic?.selectedProtocol?.id);
      }
    })();
  }, [searchquery]);

  return (
    <Formik
      initialValues={{
        regionId: [],
        action: '',
        changeReason: '',
      }}
      enableReinitialize
      innerRef={formikRef}
      validate={(values) => {
        const errors = {};
        if (!checked.length > 0) {
          errors.regionId = 'Required';
        }
        if (!values.changeReason && logistic.selectedProtocolDetail?.qc?.status?.id === 3) {
          errors.changeReason = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values) => {
        var countryIds = [];
        var result;
        var kitAssembliesIds = [];
        // extract  checked and partial checked country ids
        allregionstree?.map((region) => {
          if (checked.includes(region.value)) {
            const regionfound = countryIds?.some((el) => el.value === region.value);
            if (!regionfound) {
              countryIds.push({ id: region.id, levelId: region.level, value: region.value });
            }
          }
          if (region?.children?.length > 0) {
            region.children?.map((country) => {
              if (checked.includes(country.value)) {
                const countryfound = countryIds?.some((el) => el.value === country.value);
                if (!countryfound) {
                  countryIds.push({ id: country.id, levelId: country.level, value: country.value });
                  const regionfound = countryIds?.some((el) => el.value === region.value);
                  if (!regionfound) {
                    countryIds.push({ id: region.id, levelId: region.level, value: region.value });
                  }
                }
              }
              if (country?.children?.length > 0) {
                country.children?.map((site) => {
                  if (checked.includes(site.value)) {
                    countryIds.push({ id: site.id, levelId: site.level, value: site.value });
                    const countryfound = countryIds?.some((el) => el.value === country.value);
                    if (!countryfound) {
                      countryIds.push({ id: country.id, levelId: country.level, value: country.value });
                      const regionfound = countryIds?.some((el) => el.value === region.value);
                      if (!regionfound) {
                        countryIds.push({ id: region.id, levelId: region.level, value: region.value });
                      }
                    }
                  }
                });
              }
            });
          }
        });
        if (countryIds) {
          countryIds = countryIds.map((country) => {
            return {
              id: country.id,
              levelId: country.levelId,
            };
          });
        }

        console.log('countryIds', countryIds);
        if (values.action === 'assign') {
          // shipping ids

          if (uisettings.formName === 'assign-shipping-study-level') {
            const shippingIds = logistic?.selectedProtocolDetail?.shippingRules?.map((rule) => {
              return rule.shippingCondition.id;
            });
            result = await assignShippingRuleAction('protocols', logistic?.selectedProtocol?.id, {
              shippingConditionIds: shippingIds,
              assignTo: countryIds,
              changeReason: values.changeReason,
            });
          } else if (uisettings.formName === 'assign-shipping-region-level') {
            const shippingIds = logistic?.selectedRegionDetail?.shippingRules?.map((rule) => {
              return rule.shippingCondition.id;
            });
            result = await assignShippingRuleAction('regions', logistic?.selectedRegion?.id, {
              shippingConditionIds: shippingIds,
              assignTo: countryIds,
              changeReason: values.changeReason,
            });
          } else if (uisettings?.formName === 'assign-shipping-country-level') {
            const shippingIds = logistic?.selectedCountryDetail?.shippingRules?.map((rule) => {
              return rule.shippingCondition.id;
            });
            result = await assignShippingRuleAction('countries', logistic?.selectedCountry?.id, {
              shippingConditionIds: shippingIds,
              assignTo: countryIds,
              changeReason: values.changeReason,
            });
          } else if (uisettings?.formName === 'assign-kit-study-level') {
            logistic?.selectedProtocolDetail?.logisticBuilderKits?.map(async (kit) => {
              if (kit.checked) {
                kitAssembliesIds = [];
                kit?.assemblies?.map((ass) => {
                  if (ass.checked) {
                    kitAssembliesIds.push(ass.id);
                  }
                });
                result = await assignKitsAction({
                  logisticBuilderKitId: kit?.id,
                  logisticBuilderKitAssemblyIds: kitAssembliesIds,
                  children: countryIds,
                  changeReason: values.changeReason,
                });
              }
            });
          } else if (uisettings?.formName === 'assign-kit-region-level') {
            logistic?.selectedRegionDetail?.logisticBuilderKits?.map(async (kit) => {
              if (kit.checked) {
                kitAssembliesIds = [];
                kit?.assemblies?.map((ass) => {
                  if (ass.checked) {
                    kitAssembliesIds.push(ass.id);
                  }
                });
                result = await assignKitsAction({
                  logisticBuilderKitId: kit?.id,
                  logisticBuilderKitAssemblyIds: kitAssembliesIds,
                  children: countryIds,
                  changeReason: values.changeReason,
                });
              }
            });
          } else if (uisettings?.formName === 'assign-kit-country-level') {
            logistic?.selectedCountryDetail?.logisticBuilderKits?.map(async (kit) => {
              if (kit.checked) {
                kitAssembliesIds = [];
                kit?.assemblies?.map((ass) => {
                  if (ass.checked) {
                    kitAssembliesIds.push(ass.id);
                  }
                });
                result = await assignKitsAction({
                  logisticBuilderKitId: kit?.id,
                  logisticBuilderKitAssemblyIds: kitAssembliesIds,
                  children: countryIds,
                  changeReason: values.changeReason,
                });
              }
            });
          } else if (uisettings?.formName === 'assign-initial-kit-study-level') {
            result = await assignGeneralAssemblyAction('protocols', logistic?.selectedProtocol?.id, {
              initRequestAssembly: {
                id: logistic?.selectedProtocolDetail?.initialRequestAssembly?.id,
              },
              assignTo: countryIds,
              changeReason: values.changeReason,
            });
          } else if (uisettings?.formName === 'assign-initial-kit-region-level') {
            result = await assignGeneralAssemblyAction('regions', logistic?.selectedRegion?.id, {
              initRequestAssembly: {
                id: logistic?.selectedRegionDetail?.initialRequestAssembly?.id,
              },
              assignTo: countryIds,
              changeReason: values.changeReason,
            });
          } else if (uisettings?.formName === 'assign-initial-kit-country-level') {
            result = await assignGeneralAssemblyAction('countries', logistic?.selectedCountry?.id, {
              initRequestAssembly: {
                id: logistic?.selectedCountryDetail?.initialRequestAssembly?.id,
              },
              assignTo: countryIds,
              changeReason: values.changeReason,
            });
          }
        } else {
          if (uisettings?.formName === 'assign-kit-study-level') {
            logistic?.selectedProtocolDetail?.logisticBuilderKits?.map(async (kit) => {
              if (kit.checked) {
                kitAssembliesIds = [];
                kit.assemblies.map((ass) => {
                  if (ass.checked) {
                    kitAssembliesIds.push(ass.id);
                  }
                });
                result = await unAssignKitsAction({
                  logisticBuilderKitId: kit?.id,
                  logisticBuilderKitAssemblyIds: kitAssembliesIds,
                  children: countryIds,
                  changeReason: values.changeReason,
                });
              }
            });
          }
        }

        // if (result?.status === 200) {
        dispatch(setForm({ state: false, type: '' }));
        dispatch(showSuccessReducer(`Configuration are applied to lower level.`));
        if (logistic?.selectedRegion?.id) {
          await regionDetailIdAction(logistic?.selectedRegion?.id);
        }
        if (logistic?.selectedCountry?.id) {
          await getAllCountryForRegionDetailAction(logistic?.selectedCountry?.id);
        }
        if (logistic?.selectedSite?.id) {
          await getAllSitesForCountryDetailAction(logistic?.selectedSite?.id);
        }

        // }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        initialValues,

        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="max-w-[600px]  overflow-auto m-auto flex flex-col justify-between h-[calc(100%-66px)]">
          <div>
            <Heading heading="Details" mb />

            <div className="relative mb-[16px]">
              <span className="absolute top-[12px] left-[10px] ">
                <BlackSearch />
              </span>
              <input
                type="text"
                name="Search"
                value={searchquery}
                placeholder="Search..."
                onChange={(e) => setSearchquery(e.target.value)}
                className="w-full rounded border-2 border-[#DEE2E6] pr-[10px] py-[5px] pl-[30px]"
              />
            </div>

            {allregionstree?.length > 0 ? (
              <>
                <div className={`flex ml-[36px] mb-[10px] ${'parent-level-tree'}`}>
                  <FormCheckbox
                    onClick={(e) => {
                      if (e.target.checked) {
                        setchecked(selectAll);
                        setFieldValue('regionId', selectAll);
                      } else {
                        setchecked([]);
                        setFieldValue('regionId', []);
                      }
                    }}
                    checked={checked?.length === selectAll?.length ? true : false}
                    type="checkbox"
                    className="parent_checkbox"
                  />
                  <label className="ml-[6px]">Select All</label>
                </div>

                <div className={'w-full lower-level-tree'}>
                  <CheckboxTree
                    nodes={allregionstree}
                    checked={checked}
                    expanded={expanded}
                    checkModel="all"
                    nameAsArray={true}
                    onCheck={(checked, data) => {
                      setchecked(checked);
                      setFieldValue('regionId', checked);
                    }}
                    onClick={(data) => {
                      console.log(data);
                    }}
                    onExpand={(expanded) => setexpanded(expanded)}
                  />
                  {errors.regionId && touched.regionId && <div className="error text-[red] text-[12px] pt-[2px]">{errors.regionId}</div>}
                </div>
                {logistic.selectedProtocolDetail?.qc?.status?.id === 3 && (
                  <div className=" gap-[15px] items-center w-full block">
                    <HemaLabel text={'Change Reason'} Icon={<ChangeReason />} required={true} className={`mb-[10px]`} />

                    <FormTextarea placeholder="write edit reason" onChange={handleChange} onBlur={handleBlur} name={'changeReason'} value={values?.changeReason} />

                    {errors.changeReason && touched.changeReason && <div className="error text-[red] text-[12px] pt-[2px]">{errors.changeReason}</div>}
                  </div>
                )}
              </>
            ) : !!searchquery ? (
              <BootStrapAlert variant="warning">No result found</BootStrapAlert>
            ) : (
              <BootStrapAlert variant="warning">{`Please add ${msgLabel} to proceed further`}</BootStrapAlert>
            )}
          </div>
          <div>
            <Alert type="error" />
            <div className="flex gap-[8px] justify-end">
              <Button
                cta={() => {
                  dispatch(setForm({ state: false, type: '' }));
                }}
                type="button"
                text="Cancel"
                bg="bg-white"
                border="border-primary1"
                color="text-primary1"
                Icon={<Cancel />}
              />

              {isSubmitting ? (
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={
                    <>
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                      <Spinner animation="grow" size="sm" variant="light" />
                    </>
                  }
                />
              ) : (
                <>
                  {uisettings?.formName === 'assign-kit-study-level' && (
                    <Button type="submit" cta={() => setFieldValue('action', 'Unassign')} text="Unassign" bg="bg-primary1" color="text-white" Icon={<Unassign color=" #fff" />} />
                  )}
                  <Button
                    type="submit"
                    cta={() => setFieldValue('action', 'assign')}
                    text="Assign"
                    bg="bg-primary1"
                    color="text-white"
                    Icon={<Add color=" #fff" />}
                    disabled={compareArrays(initialValues, values) ? true : Object.keys(errors)?.length ? true : false}
                  />
                </>
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FormikForm;
