import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import {
  Add,
  Confirm,
  CourierIcon,
  DepotIcon,
  PackageIcon,
  PaginationRightArrow,
  SliderRightIcon,
  TestingLab,
  Setting
} from '../../HemeIconLibrary'
import FormContainer from '../../components/Formik/formContainer'
import { companyLocationField } from '../../components/Formik/AllForms/companyLocationField'
import {
  createUserField,
  bulkFileImport,
} from '../../components/Formik/AllForms/addUserFields'
import { AddCourier } from '../../components/Formik/AllForms/courierSettings'
import { AddTestingLabSettings } from '../../components/Formik/AllForms/addTestingLabSettings'
import { addPortalUserFields } from '../../components/Formik/AllForms/addPortalUserFields'
import {
  SystemUserValidation,
  PackageValidation,
  TestingLabValidation,
} from '../../components/Formik/allFormValidation'
import { AddDepotSettings } from '../../components/Formik/AllForms/addDepotSettings'
import { AddPackageLabSettings } from '../../components/Formik/AllForms/addPackageLabSettings'
import { AddDepotValidation } from '../../components/Formik/allFormValidation'
import CompanyLocation from '../../HemeIconLibrary/settingsIcons/companyLocation'
import PortalUser from '../../HemeIconLibrary/settingsIcons/portalUser'
import { RoleManagement } from '../../HemeIconLibrary'
import SystemUser from '../../HemeIconLibrary/settingsIcons/systemUser'
import LocationPurple from '../../HemeIconLibrary/settingsIcons/locationPurple'
import ImportIcon from '../../HemeIconLibrary/settingsIcons/import'
import UserPurple from '../../HemeIconLibrary/settingsIcons/userPurple'
import { Import } from '../../HemeIconLibrary'
import AddRole from './RoleManagmentList/addRole'
import CourierSettings from './CourierSettings'
// Utils
import HemaHeadingWithSubText from '../../utils/HemaHeadingWithSubText'
import { Button, Alert, NoPermissionAlert } from '../../utils'
import { getFullName } from './Utils'

// Actions
import {
  getCompanyLocationAction,
  createCompanyLocationAction,
  getSystemUserAction,
  createSystemUserAction,
  createSystemUserUploadAction,
  getSystemUserDownloadAction,
  getPortalUserAction,
  createPortalUserAction,
  createPortalUserUploadAction,
  getPortalUserDownloadAction,
  fetchUserRoles,
  getSitesAction,
  getPerimssionAction,
  createCourierAction,
  AddTestingLabSettingAction,
  addDepotSettingsAction,
  addPackageSettingsAction,
  getCourierSettingsAction,
} from '../../Actions/settings'
import { getAllInventoryLanguages } from '../../Actions/common'
import {
  setForm,
  setFormCloseReducer,
  showSuccessReducer,
  showErrorReducer,
  editFormReducer,
  setFormLoaderReducer,
} from '../../Store/reducers/uiSettings'
import { setShowOrderConfig } from '../../Store/reducers/settings'
import { getCountriesLogisticBuilder } from '../../Actions/logistic'
import { getcourierServiceType } from '../../Actions/order'
// Components
import CompanyLocationList from './CompanyLocationList'
import SystemUserList from './SystemUserList'
import PortalUserList from './PortalUserList'
import RoleManagmentList from './RoleManagmentList'
import { COMPANY_LOCATION_NAME_MAXSIZE } from '../../config'
import DepotSettings from './DepotSettings'
import TestingLabSettings from './TestingLabSettings'
import PackageSettings from './PackageSettings'
import ApplicationSetting from './ApplicationSetting'

const Settings = () => {
  const dispatch = useDispatch()
  const { uisettings, settings, common, orders } = useSelector((state) => state)

  /* form states */
  const [CTA, setCTA] = useState()
  const [formName, setformName] = useState()
  const [formIcon, setFormIcon] = useState()
  const [formValidation, setFormValidation] = useState()
  const [updatedData, setUpdatedData] = useState()
  const [activeTab, setactiveTab] = useState('CompanyLocation')
  const [pageSub, setPageSub] = useState('Manage your company locations here.')
  const [allItemsInTemplate, setAllItemsInTemplate] = useState()
  const [dropdownItemList, setDropDownItemList] = useState()
  const [okBtnText, setokBtnText] = useState()
  const [okBtnIcon, setokBtnIcon] = useState()
  const [cancelBtnText, setCancelBtnText] = useState()
  const [cancelBtnIcon, setSancelBtnIcon] = useState()
  const [searchParams] = useSearchParams()
  const [tabSlider, setTabSlider] = useState(false)

  const navigate = useNavigate()
  const pageLocation = useLocation()

  useEffect(() => {
    const tabToOpen = searchParams.get('type')
    if (
      [
        'CompanyLocation',
        'SystemUser',
        'PortalUser',
        'RoleManagment',
        'CourierSettings',
        'DepotSettings',
        'testingLabSetting',
        'packageSettings',
        'applicationSettings'
      ].includes(tabToOpen)
    ) {
      setactiveTab(tabToOpen)
    }
    if (tabToOpen === 'CompanyLocation') {
      setPageSub('Manage your company locations here.')
    } else if (tabToOpen === 'SystemUser') {
      setPageSub('Manage your system’s users here.')
    } else if (tabToOpen === 'PortalUser') {
      setPageSub('Manage your portal’s users here.')
    } else if (tabToOpen === 'RoleManagment') {
      setPageSub('Manage your roles here.')
    } else if (tabToOpen === 'CourierSettings') {
      setPageSub('Manage your courier settings here.')
    } else if (tabToOpen === 'DepotSettings') {
      setPageSub('Manage your depot settings here.')
    } else if (tabToOpen === 'testingLabSetting') {
      setPageSub('Manage your testing lab settings here.')
    } else if (tabToOpen === 'packageSettings') {
      setPageSub('Manage your package settings here.')
    } else if (tabToOpen === 'applicationSettings') {
      setPageSub('Manage your application settings here.')
    } else {
      setPageSub('')
    }
  }, [searchParams])

  useEffect(() => {
    if (
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=CompanyLocation' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=SystemUser' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=PortalUser' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=PortalUser' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=RoleManagment' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=CourierSettings'
    ) {
      setTabSlider(true)
    }

    if (
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=testingLabSetting' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=DepotSettings' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=packageSettings' ||
      pageLocation.pathname.concat(pageLocation.search) ===
        '/settings?type=applicationSettings'
    ) {
      setTabSlider(false)
    }
  }, [navigate, pageLocation.pathname, pageLocation.search, tabSlider])

  const addCompanyLocation = () => {
    setCTA(() => async (data) => {
      if (data.name && data.name.length > COMPANY_LOCATION_NAME_MAXSIZE) {
        dispatch(
          showErrorReducer(
            `add company name must not be empty or more than ${COMPANY_LOCATION_NAME_MAXSIZE} character`,
          ),
        )
      } else {
        dispatch(setFormLoaderReducer(true))
        const payload = {
          name: data.name,
          iconName: data.itemCategoryId,
        }
        const resp = await createCompanyLocationAction(payload)
        dispatch(setFormLoaderReducer(false))
        if (resp?.status === 200) {
          dispatch(setFormCloseReducer())
          dispatch(showSuccessReducer(`${resp.data?.name} added.`))
          getCompanyLocationAction()
        }
      }
    })
    setFormValidation((error, values) => (error, values) => {
      if (values.name?.length > 100) {
        return (error.name = 'Max 100 characters allowed')
      }
      if (values.itemCategoryId?.length === 0) {
        return (error.itemCategoryId = 'Required')
      }
    })
  }

  const addSystemUser = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const resp = await createSystemUserAction(payload)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`New user added.`))
        getSystemUserAction()
      }
    })
    setFormValidation((error, values) => (error, values) => {
      SystemUserValidation(error, values)
    })
  }

  const bulkImportSystemUser = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const formData = new FormData()
      formData.append('userFile', payload.userFile)
      const resp = await createSystemUserUploadAction(formData)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(
          showSuccessReducer(`${getFullName(resp.data)} system user added.`),
        )
        getSystemUserAction()
      }
    })
  }

  const addPortalUser = () => {
    setCTA(() => async (payload) => {
      const filterpayload = {
        ...payload,
        siteIds: payload.siteIds
          ?.filter((site) => site.checked === true)
          ?.map((site) => {
            return site.id
          }),
      }

      dispatch(setFormLoaderReducer(true))
      const resp = await createPortalUserAction(filterpayload)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`New user added.`))
        getPortalUserAction()
      }
    })
    setFormValidation((error, values) => (error, values) => {
      SystemUserValidation(error, values)
    })
  }

  const bulkImportPortalUser = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const formData = new FormData()
      formData.append('userFile', payload.userFile)
      const resp = await createPortalUserUploadAction(formData)
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(setFormLoaderReducer(false))
        dispatch(
          showSuccessReducer(`${getFullName(resp.data)} portal user added.`),
        )
        getPortalUserAction()
      }
    })
  }

  const handleDownload = async (downloadType) => {
    if (downloadType === 'SystemUser') {
      dispatch(setFormLoaderReducer(true))
      const resp = await getSystemUserDownloadAction()
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        //dispatch(setFormCloseReducer());
        dispatch(showSuccessReducer(`File downloaded.`))
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'SystemUsers.xlsx')
        document.body.appendChild(link)
        link.click()
      }
    }
    if (downloadType === 'PortalUser') {
      dispatch(setFormLoaderReducer(true))
      const resp = await getPortalUserDownloadAction()
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`File downloaded.`))

        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'PortalUsers.xlsx')
        document.body.appendChild(link)
        link.click()
      }
    }
  }

  const AddTestingLabSetting = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const filterPayload = {
        name: payload.name,
        contactName: payload?.contactName,
        address: {
          address1: payload?.address1,
          address2: payload?.address2,
          city: payload?.city,
          stateProvince: payload?.stateAddress,
          postalCode: payload?.postalCode,
          email: payload?.email,
          phone: payload?.phoneNo.toString(),
          countryId: payload?.countryId,
          // specialInstruction: null,
        },
        isFulfillmentFacility: payload?.Facility === 1 ? true : false,
      }
      const resp = await AddTestingLabSettingAction(filterPayload)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`Testing Lab successfully added.`))
        // getSystemUserAction();
      }
    })
    setFormValidation((error, values) => (error, values) => {
      TestingLabValidation(error, values)
      if (
        settings?.alltestingLab?.filter((ass) => ass?.name === values.name)
          ?.length > 0
      ) {
        error.name = 'Duplicate name'
      }
    })
  }
  const addCourierSettings = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const resp = await createCourierAction(payload)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`Courier successfully added.`))
        // getSystemUserAction();
      }
    })
    setFormValidation((error, values) => (error, values) => {
      if (values.name?.length > 100) {
        error.name = 'Max 100 characters allowed'
      }
      if (
        settings?.allCouriers?.filter((ass) => ass?.name === values.name)
          ?.length > 0
      ) {
        error.name = 'Duplicate name'
      }
      if (values.accountNumber?.length > 100) {
        error.accountNumber = 'Max 100 characters allowed'
      } else if (/[^a-zA-Z0-9]/.test(values.accountNumber)) {
        error.accountNumber = 'Input is not alphanumeric'
      }
      return error
    })
  }
  const addDepotSettings = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const payloadData = {
        name: payload?.name,
        eorI: payload?.eori,
        languageId: payload?.languageId,
        brokerContact: payload?.BrokerContactname,
        brokerCompanyName: payload?.BrokerCompanyname,
        brokerEORI: payload?.brokerEORI,
        brokerTaxID: payload?.brokerTaxID,
        brokerAddress: {
          address1: payload?.brokerAddress1,
          address2: payload?.brokerAddress2,
          city: payload?.brokercity,
          stateProvince: payload?.brokerstate,
          postalCode: payload?.borkerPostalcode,
          email: payload?.brokerEmail,
          phone: String(payload?.brokerPhone),
          countryId: payload?.brokercountry,
        },
        ioRContact: payload?.IORContactname,
        ioRCompanyName: payload?.IORCompanyname,
        ioreori: payload?.ioreori,
        iorTaxID: payload?.iorTaxID,
        ioRAddress: {
          address1: payload?.IORAddress1,
          address2: payload?.IORAddress2,
          city: payload?.IORcity,
          stateProvince: payload?.IORstate,
          postalCode: payload?.IORPostalcode,
          email: payload?.IOREmail,
          phone: String(payload?.IORPhone),
          countryId: payload?.IORcountry,
        },
        depotCourierServiceTypeId: payload?.Servicetype || null,
        courierId: payload?.courierId,
      }
      const resp = await addDepotSettingsAction(payloadData)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`Depot successfully added.`))
        // getSystemUserAction();
      }
    })
    setFormValidation((error, values) => (error, values) => {
      AddDepotValidation(error, values)
      if (
        settings?.allDepotSettings?.filter((ass) => ass?.name === values.name)
          ?.length > 0
      ) {
        error.name = 'Duplicate name'
      }
    })
    // setFormValidation((error, values) => (error, values) => {
    //   SystemUserValidation(error, values);
    // });
  }
  const addPackageSettings = () => {
    setCTA(() => async (payload) => {
      dispatch(setFormLoaderReducer(true))
      const resp = await addPackageSettingsAction(payload)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`Package successfully added.`))
        // getSystemUserAction();
      }
    })
    setFormValidation((error, values) => (error, values) => {
      PackageValidation(error, values)
      if (
        settings?.allpackageLab?.filter((ass) => ass?.name === values.name)
          ?.length > 0
      ) {
        error.name = 'Duplicate name'
      }
    })
  }

  useEffect(() => {
    ;(async () => {
      if (common?.activeDropdownValue?.name === 'courierId') {
        const res = await getcourierServiceType(common?.activeDropdownValue?.id)
        if (res.status === 200) {
          AddDepotSettings[5].options = res?.data
        } else {
          AddDepotSettings[5].options = []
        }
      }
    })()
  }, [common?.activeDropdownValue])
  useEffect(() => {
    if (orders?.couriertype) {
      AddDepotSettings[5].options = orders?.couriertype
    } else {
      AddDepotSettings[5].options = []
    }
  }, [orders?.couriertype])

  useEffect(() => {
    fetchUserRoles()
    getSitesAction()
    getPerimssionAction()
    getCountriesLogisticBuilder()
    getAllInventoryLanguages()
    getCourierSettingsAction()
  }, [])

  if (
    !(
      common?.permissions?.['Company Locations']?.read ||
      common?.permissions?.['Portal User Management']?.read ||
      common?.permissions?.['Role Management']?.read ||
      common?.permissions?.['System User Management']?.read
    )
  ) {
    return <NoPermissionAlert />
  }

  return (
    <>
      <div className={`${settings?.showOrderConfig ? 'hidden' : 'block'}`}>
        <div className="flex gap-[10px] justify-between items-end">
          <HemaHeadingWithSubText heading="Settings" sub={pageSub} />

          {activeTab === 'CompanyLocation' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Company Locations']?.write && (
                <Button
                  text="Add Company Location"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={() => {
                    setformName('Add Company Location')
                    setokBtnText('Create')
                    setokBtnIcon(<Add color="white" />)
                    setFormIcon(<LocationPurple />)
                    companyLocationField[0].initialValue = ''
                    companyLocationField[1].initialValue = ''
                    setUpdatedData(companyLocationField.slice(0, 2))
                    addCompanyLocation()
                    dispatch(
                      setForm({ state: true, type: 'add-company-location' }),
                    )
                  }}
                />
              )}
            </div>
          ) : activeTab === 'SystemUser' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['System User Management']?.special && (
                <Button
                  text="Bulk Import"
                  Icon={<ImportIcon color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={() => {
                    setformName('Bulk Import')
                    setFormIcon(<Import />)
                    setokBtnText('Import')
                    setokBtnIcon(<ImportIcon color="white" />)
                    bulkFileImport[0].onClick = () => {
                      handleDownload('SystemUser')
                    }
                    setUpdatedData(bulkFileImport)
                    bulkImportSystemUser()
                    dispatch(setForm({ state: true, type: 'additem' }))
                  }}
                />
              )}
              {common?.permissions?.['System User Management']?.write && (
                <Button
                  text="Add New User"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={() => {
                    setformName('Add New User')
                    setFormIcon(<UserPurple />)
                    setokBtnText('Create')
                    setokBtnIcon(<Add color="white" />)
                    createUserField[0].initialValue = ''
                    createUserField[1].initialValue = ''
                    createUserField[2].initialValue = ''
                    createUserField[2].disabled = false
                    createUserField[3].initialValue = ''
                    createUserField[3].options = settings?.userRole
                    createUserField[3].placeholder = 'Select Role'
                    createUserField[4].initialValue = true
                    setUpdatedData(createUserField.slice(0, 5))

                    addSystemUser()
                    dispatch(setForm({ state: true, type: 'additem' }))
                  }}
                />
              )}
            </div>
          ) : activeTab === 'PortalUser' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Portal User Management']?.special && (
                <Button
                  text="Bulk Import"
                  Icon={<ImportIcon color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={() => {
                    setformName('Bulk Import')
                    setFormIcon(<Import />)
                    setokBtnText('Import')
                    setokBtnIcon(<ImportIcon color="white" />)
                    bulkFileImport[0].onClick = () => {
                      handleDownload('PortalUser')
                    }
                    setUpdatedData(bulkFileImport)
                    bulkImportPortalUser()
                    dispatch(setForm({ state: true, type: 'additem' }))
                  }}
                />
              )}
              {common?.permissions?.['Portal User Management']?.write && (
                <Button
                  text="Add New User"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={() => {
                    setformName('Add New User')
                    setFormIcon(<UserPurple />)
                    setokBtnText('Create')
                    setokBtnIcon(<Add color="white" />)
                    addPortalUserFields[0].initialValue = ''
                    addPortalUserFields[1].initialValue = ''
                    addPortalUserFields[2].initialValue = ''
                    addPortalUserFields[2].disabled = false
                    // addPortalUserFields[4].options = settings?.sites;
                    addPortalUserFields[4].initialValue = false
                    addPortalUserFields[5].initialValue = settings?.sites?.map(
                      (site) => {
                        return { ...site, checked: false }
                      },
                    )
                    setUpdatedData(addPortalUserFields.slice(0, 6))
                    addPortalUser()
                    dispatch(setForm({ state: true, type: 'additem' }))
                  }}
                />
              )}
            </div>
          ) : activeTab === 'CourierSettings' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Role Management']?.write && (
                <Button
                  text="Add Courier"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={async () => {
                    setformName('Add Courier')
                    setokBtnText('Create')
                    AddCourier[0].initialValue = ''
                    AddCourier[1].initialValue = ''
                    setokBtnIcon(<Add color="white" />)
                    setUpdatedData(AddCourier.slice(0, 2))
                    addCourierSettings()
                    dispatch(setForm({ state: true, type: 'additem' }))
                  }}
                />
              )}
            </div>
          ) : activeTab === 'DepotSettings' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Role Management']?.write && (
                <Button
                  text="Add Depot"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={async () => {
                    setformName('Add Depot')
                    setokBtnText('Save')
                    setokBtnIcon(<Confirm color="white" />)
                    AddDepotSettings[1].initialValue = ''
                    AddDepotSettings[2].initialValue = ''
                    AddDepotSettings[3].options = common?.allInventorylanguages
                    AddDepotSettings[4].options = settings?.allCouriers
                    AddDepotSettings[4].placeholder = 'Select'
                    AddDepotSettings[5].options = orders?.couriertype
                    AddDepotSettings[5].placeholder = 'Select'
                    AddDepotSettings[3].initialValue = ''
                    AddDepotSettings[4].initialValue = ''
                    AddDepotSettings[5].initialValue = ''
                    AddDepotSettings[7].initialValue = ''
                    AddDepotSettings[8].initialValue = ''
                    AddDepotSettings[9].initialValue = ''
                    AddDepotSettings[10].initialValue = ''
                    AddDepotSettings[11].initialValue = ''
                    AddDepotSettings[12].initialValue = ''
                    AddDepotSettings[13].initialValue = ''
                    AddDepotSettings[14].initialValue = ''

                    AddDepotSettings[15].initialValue = ''
                    AddDepotSettings[15].options = common?.allCountries || []
                    AddDepotSettings[16].initialValue = ''
                    AddDepotSettings[17].initialValue = ''
                    AddDepotSettings[18].initialValue = ''

                    AddDepotSettings[20].initialValue = ''
                    AddDepotSettings[21].initialValue = ''
                    AddDepotSettings[22].initialValue = ''
                    AddDepotSettings[23].initialValue = ''
                    AddDepotSettings[24].initialValue = ''
                    AddDepotSettings[25].initialValue = ''
                    AddDepotSettings[26].initialValue = ''
                    AddDepotSettings[27].initialValue = ''
                    AddDepotSettings[28].initialValue = ''
                    AddDepotSettings[28].options = common?.allCountries || []
                    AddDepotSettings[29].initialValue = ''
                    AddDepotSettings[30].initialValue = ''
                    AddDepotSettings[31].initialValue = ''

                    setUpdatedData(AddDepotSettings)
                    dispatch(setForm({ state: true, type: 'additem' }))
                    addDepotSettings()
                  }}
                />
              )}
            </div>
          ) : activeTab === 'testingLabSetting' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Role Management']?.write && (
                <Button
                  text="Add Testing Lab"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={async () => {
                    setformName('Add Testing Lab')
                    setokBtnText('Create')
                    setokBtnIcon(<Add color="white" />)
                    setokBtnIcon(<Confirm color="white" />)
                    AddTestingLabSettings[0].initialValue = ''
                    AddTestingLabSettings[1].initialValue = ''
                    AddTestingLabSettings[2].initialValue = ''
                    AddTestingLabSettings[3].initialValue = ''
                    AddTestingLabSettings[4].initialValue = ''
                    AddTestingLabSettings[5].initialValue = ''
                    AddTestingLabSettings[6].initialValue = ''
                    AddTestingLabSettings[7].initialValue = ''
                    AddTestingLabSettings[8].initialValue = ''
                    AddTestingLabSettings[9].initialValue = ''
                    AddTestingLabSettings[10].initialValue = ''
                    AddTestingLabSettings[5].options = common?.allCountries
                    AddTestingLabSettings[10].options = [
                      { id: 1, name: 'Yes' },
                      { id: 2, name: 'No' },
                    ]
                    setUpdatedData(AddTestingLabSettings.slice(0, 11))
                    AddTestingLabSetting()
                    dispatch(setForm({ state: true, type: 'additem' }))
                  }}
                />
              )}
            </div>
          ) : activeTab === 'packageSettings' ? (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Role Management']?.write && (
                <Button
                  text="Add New Package"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={async () => {
                    setformName('Add New Package')
                    setokBtnText('Create')
                    AddPackageLabSettings[0].initialValue = ''
                    AddPackageLabSettings[2].initialValue = ''
                    AddPackageLabSettings[3].initialValue = ''
                    AddPackageLabSettings[4].initialValue = ''
                    AddPackageLabSettings[5].initialValue = ''
                    setokBtnIcon(<Add color="white" />)
                    setUpdatedData(AddPackageLabSettings.slice(0, 6))
                    dispatch(setForm({ state: true, type: 'additem' }))
                    addPackageSettings()
                  }}
                />
              )}
            </div>
          ) : activeTab === 'applicationSettings' ? (
            <div className="flex gap-[10px] ">
            </div>
          ) : (
            <div className="flex gap-[10px] ">
              {common?.permissions?.['Role Management']?.write && (
                <Button
                  text="Add New Role"
                  Icon={<Add color="white" />}
                  color="text-white"
                  bg="bg-primary1"
                  cta={async () => {
                    dispatch(editFormReducer(null))
                    dispatch(setShowOrderConfig(true))
                    dispatch(setForm({ state: false, type: 'add-new-role' }))
                  }}
                />
              )}
            </div>
          )}
        </div>
        <Alert />
        <div
          className={`bg-white rounded-[5px] pt-[15px] pb-[10px] mt-[27px] inventory-tabs relative ${
            !tabSlider && 'tab-space-left'
          }`}
        >
          <div
            onClick={() => {
              setTabSlider(!tabSlider)
              if (tabSlider) {
                setactiveTab('DepotSettings')
                navigate('/settings?type=DepotSettings')
              } else {
                setactiveTab('CompanyLocation')
                navigate('/settings?type=CompanyLocation')
              }
            }}
            className={`absolute  cursor-pointer ${
              tabSlider ? 'top-6 right-6' : 'top-6 left-6 rotate-180'
            }`}
          >
            <SliderRightIcon />
          </div>
          <Tabs
            activeKey={activeTab}
            id="controlled-tab-example"
            className="mb-3 mr-4 ml-4 gap-[20px]"
            onSelect={(key) => {
              setactiveTab(key)
              if (key === 'CompanyLocation') {
                setPageSub('Manage your company locations here.')
                navigate('/settings?type=CompanyLocation')
              } else if (key === 'SystemUser') {
                setPageSub('Manage your system’s users here.')
                navigate('/settings?type=SystemUser')
              } else if (key === 'PortalUser') {
                setPageSub('Manage your portal’s users here.')
                navigate('/settings?type=PortalUser')
              } else if (key === 'RoleManagment') {
                setPageSub('Manage your roles here.')
                navigate('/settings?type=RoleManagment')
              } else if (key === 'CourierSettings') {
                setPageSub('Manage your courier settings here.')
                navigate('/settings?type=CourierSettings')
              } else if (key === 'DepotSettings') {
                setPageSub('Manage your depot settings here.')
                navigate('/settings?type=DepotSettings')
              } else if (key === 'testingLabSetting') {
                setPageSub('Manage your testing lab settings here.')
                navigate('/settings?type=testingLabSetting')
              } else if (key === 'packageSettings') {
                setPageSub('Manage your package settings here.')
                navigate('/settings?type=packageSettings')
              } else if (key === 'applicationSettings') {
                setPageSub('Manage your application settings here.')
                navigate('/settings?type=applicationSettings')
              } else {
                setPageSub('')
              }
            }}
          >
            {tabSlider
              ? common?.permissions?.['Company Locations']?.read && (
                  <Tab
                    eventKey="CompanyLocation"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'CompanyLocation' ? (
                          <CompanyLocation />
                        ) : (
                          <CompanyLocation color="#414753" />
                        )}
                        Company Locations
                      </div>
                    }
                  >
                    <CompanyLocationList
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setCancelBtnText={setCancelBtnText}
                    />
                  </Tab>
                )
              : ''}
            {tabSlider
              ? common?.permissions?.['System User Management']?.read && (
                  <Tab
                    eventKey="SystemUser"
                    title={
                      <div className="flex items-center title-icon gap-[10px]">
                        {activeTab === 'SystemUser' ? (
                          <SystemUser />
                        ) : (
                          <SystemUser color="#414753" />
                        )}
                        System User Management
                      </div>
                    }
                  >
                    <SystemUserList
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setFormValidation={setFormValidation}
                      setCancelBtnText={setCancelBtnText}
                    />
                  </Tab>
                )
              : ''}
            {tabSlider
              ? common?.permissions?.['Portal User Management']?.read && (
                  <Tab
                    eventKey="PortalUser"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'PortalUser' ? (
                          <PortalUser />
                        ) : (
                          <PortalUser color="#414753" />
                        )}
                        Portal User Management
                      </div>
                    }
                  >
                    <PortalUserList
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      sites={settings?.sites}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setCancelBtnText={setCancelBtnText}
                    />
                  </Tab>
                )
              : ''}
            {tabSlider
              ? common?.permissions?.['Role Management']?.read && (
                  <Tab
                    eventKey="RoleManagment"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'RoleManagment' ? (
                          <RoleManagement />
                        ) : (
                          <RoleManagement color="#414753" />
                        )}
                        Role Management
                      </div>
                    }
                  >
                    <RoleManagmentList
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setCancelBtnText={setCancelBtnText}
                    />
                  </Tab>
                )
              : ''}
            {tabSlider
              ? common?.permissions?.['Courier Settings']?.read && (
                  <Tab
                    eventKey="CourierSettings"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'CourierSettings' ? (
                          <CourierIcon />
                        ) : (
                          <CourierIcon color="#414753" />
                        )}
                        Courier Settings
                      </div>
                    }
                  >
                    <CourierSettings
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setCancelBtnText={setCancelBtnText}
                    />
                  </Tab>
                )
              : ''}

            {!tabSlider
              ? common?.permissions?.['Depot Settings']?.read && (
                  <Tab
                    eventKey="DepotSettings"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'DepotSettings' ? (
                          <DepotIcon />
                        ) : (
                          <DepotIcon color="#414753" />
                        )}
                        Depot Settings
                      </div>
                    }
                  >
                    <DepotSettings
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setCancelBtnText={setCancelBtnText}
                    />
                  </Tab>
                )
              : ''}
            {!tabSlider
              ? common?.permissions?.['Testing Lab Settings']?.read && (
                  <Tab
                    eventKey="testingLabSetting"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'testingLabSetting' ? (
                          <TestingLab />
                        ) : (
                          <TestingLab color="#414753" />
                        )}
                        Testing Lab Settings
                      </div>
                    }
                  >
                    <TestingLabSettings
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setFormValidation={setFormValidation}
                    />
                  </Tab>
                )
              : ''}
            {!tabSlider
              ? common?.permissions?.['Package Settings']?.read && (
                  <Tab
                    eventKey="packageSettings"
                    title={
                      <div className="flex items-center title-icon gap-[10px] ">
                        {activeTab === 'packageSettings' ? (
                          <PackageIcon />
                        ) : (
                          <PackageIcon color="#414753" />
                        )}
                        Package Settings
                      </div>
                    }
                  >
                    <PackageSettings
                      setokBtnIcon={setokBtnIcon}
                      setokBtnText={setokBtnText}
                      setCTA={setCTA}
                      setformName={setformName}
                      setFormIcon={setFormIcon}
                      setUpdatedData={setUpdatedData}
                      setFormValidation={setFormValidation}
                    />
                  </Tab>
                )
              : ''}
              {!tabSlider
                ? common?.permissions?.['Kit Order Process']?.special && (
                    <Tab
                      eventKey="applicationSettings"
                      title={
                        <div className="flex items-center title-icon gap-[10px] ">
                          {activeTab === 'applicationSettings' ? (
                            <Setting />
                          ) : (
                            <Setting color="#414753" />
                          )}
                          Application Settings
                        </div>
                      }
                    >
                    <ApplicationSetting />
                    </Tab>
                  )
                : ''}
          </Tabs>
        </div>

        {uisettings?.openform && (
          <FormContainer
            cta={CTA}
            formType={updatedData}
            formName={formName}
            Icon={formIcon}
            formValidation={formValidation}
            setUpdatedData={setUpdatedData}
            setformName={setformName}
            setFormIcon={setFormIcon}
            allItemsInTemplate={allItemsInTemplate}
            dropDownItemList={dropdownItemList}
            cancelBtnIcon={cancelBtnIcon}
            cancelBtnText={cancelBtnText}
            okBtnIcon={okBtnIcon}
            okBtnText={okBtnText}
            setokBtnIcon={setokBtnIcon}
            setokBtnText={setokBtnText}
            setCancelBtnText={setCancelBtnText}
          />
        )}
      </div>

      <div className={`${!settings.showOrderConfig ? 'hidden' : 'block'}`}>
        <AddRole />
      </div>
    </>
  )
}

export default Settings
