export const formats = [
    {
        "id": 1,
        "name": "Numeric"
    },
    {
        "id": 2,
        "name": "Date"
    },
    {
        "id": 3,
        "name": "Time"
    },
    {
        "id": 4,
        "name": "Date & Time"
    },
    {
        "id": 5,
        "name": "Check box"
    },
    {
        "id": 6,
        "name": "Single line text"
    },
    {
        "id": 7,
        "name": "Multi line text"
    },
    {
        "id": 8,
        "name": "Email"
    },
    {
        "id": 9,
        "name": "Phone"
    },
    {
        "id": 10,
        "name": "Single Select"
    },
    {
        "id": 11,
        "name": "Multiple Select"
    }
]