import {
  getAllTasks,
  createNewTask,
  updateTask,
  deleteTask,
  getTaskStatus
} from '../Services/task';
import { store } from '../Store';
import { setAllTasks, addNewTasks, addStatus } from '../Store/reducers/task';

const createNewTaskAction = async (data) => {
  console.log(data)
  const result = await createNewTask(data);
  if (result?.data) {
    store.dispatch(addNewTasks(result?.data));
  }
  return result
};
const getAllTasksAction = async (id) => {
    const result = await getAllTasks(id);
    if (result?.data) {
      store.dispatch(setAllTasks(result?.data));
    }
  };

  const getTaskStatusAction = async () => {
    const result = await getTaskStatus();
    if (result?.data) {
      store.dispatch(addStatus(result?.data));
    }
  };



export {
  getTaskStatusAction,
    createNewTaskAction,
    getAllTasksAction
};
