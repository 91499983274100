import React from 'react';

export const PortalUser = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4402_209725)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.5703 6.19922C16.5703 8.6845 14.5556 10.6992 12.0703 10.6992C9.58503 10.6992 7.57031 8.6845 7.57031 6.19922C7.57031 3.71394 9.58503 1.69922 12.0703 1.69922C13.2638 1.69922 14.4084 2.17332 15.2523 3.01724C16.0962 3.86115 16.5703 5.00574 16.5703 6.19922Z"
          stroke="url(#paint0_linear_4402_209725)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.3203 15.1992H6.82031C4.74924 15.1992 3.07031 16.8782 3.07031 18.9492C3.07031 21.0203 4.74924 22.6992 6.82031 22.6992H17.3203C19.3914 22.6992 21.0703 21.0203 21.0703 18.9492C21.0703 16.8782 19.3914 15.1992 17.3203 15.1992Z"
          stroke="url(#paint1_linear_4402_209725)"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4402_209725"
          x1="3.07031"
          y1="12.1992"
          x2="21.0703"
          y2="12.1992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4402_209725"
          x1="3.07031"
          y1="12.1992"
          x2="21.0703"
          y2="12.1992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <clipPath id="clip0_4402_209725">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.0703125 0.199219)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
