import React from "react";

export const RejectedIcon = () => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 2040">
        <rect x="0.601562" width="44" height="44" rx="22" fill="#FFFFFF" />
        <g id="Vector">
          <path
            d="M32.0016 20.2C32.0016 19.97 32.0016 19.97 31.8866 19.855V19.74C31.8866 19.625 31.7716 19.51 31.6566 19.395L23.6066 11.345C23.4916 11.23 23.3766 11.23 23.2616 11.115H23.1466C23.0316 11.115 22.9166 11.115 22.8016 11H18.2016C15.6716 11 13.6016 13.07 13.6016 15.6V29.4C13.6016 31.93 15.6716 34 18.2016 34H27.4016C29.9316 34 32.0016 31.93 32.0016 29.4V20.2ZM28.0916 19.05H26.2516C24.9866 19.05 23.9516 18.015 23.9516 16.75V14.91L28.0916 19.05ZM27.4016 31.7H18.2016C16.9366 31.7 15.9016 30.665 15.9016 29.4V15.6C15.9016 14.335 16.9366 13.3 18.2016 13.3H21.6516V16.75C21.6516 19.28 23.7216 21.35 26.2516 21.35H29.7016V29.4C29.7016 30.665 28.6666 31.7 27.4016 31.7Z"
            fill="#F87FB1"
          />
          <path
            d="M25.3335 22.845C24.8735 22.385 24.1835 22.385 23.7235 22.845L22.8035 23.765L21.8835 22.845C21.4235 22.385 20.7335 22.385 20.2735 22.845C19.8135 23.305 19.8135 23.995 20.2735 24.455L21.1935 25.375L20.2735 26.295C19.8135 26.755 19.8135 27.445 20.2735 27.905C20.5035 28.135 20.8485 28.25 21.0785 28.25C21.3085 28.25 21.6535 28.135 21.8835 27.905L22.8035 26.985L23.7235 27.905C23.9535 28.135 24.2985 28.25 24.5285 28.25C24.7585 28.25 25.1035 28.135 25.3335 27.905C25.7935 27.445 25.7935 26.755 25.3335 26.295L24.4135 25.375L25.3335 24.455C25.7935 23.995 25.7935 23.305 25.3335 22.845Z"
            fill="#F87FB1"
          />
        </g>
      </g>
    </svg>
  );
};
