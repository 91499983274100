import React from "react";
export const DisposeInventory = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0671 6.04326L18.5271 2.65326C17.8271 1.53326 16.6371 0.823258 15.3071 0.753258C13.8471 0.673258 12.4371 1.37326 11.5571 2.60326C11.5171 2.66326 11.4771 2.71326 11.4471 2.77326L9.12713 6.84326C8.57713 7.80326 8.91713 9.02326 9.86713 9.57326C10.1771 9.75326 10.5171 9.83326 10.8571 9.83326C11.5471 9.83326 12.2271 9.47326 12.5971 8.82326L14.8571 4.87326C14.9771 4.73326 15.1071 4.74326 15.1271 4.75326C15.1671 4.82326 15.2171 4.89326 15.2671 4.96326L17.5871 8.05326L16.1987 8.85409C15.601 9.19883 15.6908 10.0878 16.3454 10.306L21.6088 12.0605C22.0613 12.2113 22.5444 11.9352 22.6441 11.4688L23.8114 6.0101C23.9563 5.33276 23.2304 4.80393 22.6301 5.14945L21.0771 6.04326H21.0671Z"
        fill="url(#paint0_linear_3263_64486)"
      />
      <path
        d="M29.1995 19.7531L26.6795 15.6831C26.0995 14.7431 24.8695 14.4531 23.9295 15.0331C22.9895 15.6131 22.6995 16.8431 23.2795 17.7831L25.6995 21.7031H19.3295V20.0773C19.3295 19.3835 18.5066 19.0186 17.9924 19.4844L13.924 23.1702C13.5735 23.4878 13.5735 24.0384 13.924 24.356L17.9924 28.0417C18.5066 28.5076 19.3295 28.1427 19.3295 27.4489V25.7031H25.7495C27.2195 25.7031 28.5195 24.9631 29.2395 23.7331C29.9395 22.5231 29.9395 21.0531 29.1895 19.7531H29.1995Z"
        fill="url(#paint1_linear_3263_64486)"
      />
      <path
        d="M9.09971 21.7131H4.30971L6.68971 17.9031L8.03513 18.8127C8.60994 19.2013 9.37308 18.724 9.27519 18.0371L8.49607 12.5698C8.42871 12.0971 7.96497 11.7883 7.50286 11.9084L2.23536 13.2773C1.56864 13.4505 1.41806 14.3293 1.98908 14.7147L3.37971 15.6531L0.779714 19.8131C0.069714 21.0631 0.0597139 22.5231 0.769714 23.7331C1.48971 24.9731 2.78971 25.7031 4.25971 25.7031H9.09971C10.1997 25.7031 11.0997 24.8031 11.0997 23.7031C11.0997 22.6031 10.1997 21.7031 9.09971 21.7031V21.7131Z"
        fill="url(#paint2_linear_3263_64486)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3263_64486"
          x1="8.86328"
          y1="6.54516"
          x2="24.1871"
          y2="6.54516"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3263_64486"
          x1="13.2695"
          y1="21.9937"
          x2="29.7584"
          y2="21.9937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3263_64486"
          x1="0.242188"
          y1="18.6931"
          x2="11.0997"
          y2="18.6931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
