import React, { useEffect, useState, useRef } from "react";
import { CrossIcon, SelectDownArrow } from '../../HemeIconLibrary'
const TagsSelect = ({
  placeholder,
  name,
  setFieldValue,
  value,
  classNames,
  options,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [multiselectData, setMultiSelectData] = useState(value || []);
  useEffect(() => {
    if (setFieldValue && multiselectData?.length) {
      setFieldValue(name, multiselectData);
    }
  }, [multiselectData]);
  const dropdownRef = useRef()
  useEffect(() => {
    // Function to handle clicks outside of dropdown
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    // Add event listener when component mounts
    document.addEventListener("click", handleClickOutside);
    
    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div  className={`hema-tagselect-input ${classNames}`}>
      <div
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        ref={dropdownRef}
        className='flex min-h-[20px] relative rounded-md p-[4px] flex-wrap gap-[5px]'
        style={{
          border: "1px solid #ccc",
          paddingLeft: "10px",
        }}
      >
        {multiselectData?.length
          ? multiselectData.map((tag) => {
            return (
              <div
                className='flex w-fit bg-[#ebebeb] text-[#232323] gap-[10px] cursor-pointer px-[7px] py-[4px]  font-medium rounded-md text-[12px]'
                // style={{
                //   background: "#ccc",
                //   borderRadius: "10px",
                //   display: "flex",
                //   padding: "5px  10px",
                //   gap: "10px",
                //   cursor: "pointer",
                // }}
                key={tag?.id_frontend}
              >
                <div>{tag.name}</div>
                <div
                  onClick={() => {
                    setMultiSelectData(
                      multiselectData.filter(
                        (data) => data.id_frontend !== tag.id_frontend
                      )
                    );
                  }}
                >
                  x
                </div>
              </div>
            );
          })
          : placeholder}
          <div className={`absolute z-0 top-[50%] translate-y-[-50%] right-[1px] flex items-center gap-[7px] h-[32px]   pr-2  rounded-[5px]`}>
        {multiselectData?.length > 0 && (
        
          <div
            className="cursor-pointer "
            onClick={async () => {
              setFieldValue(name, []);
              setMultiSelectData([])
              // multiRef?.current?.resetSelectedValues([]);
            }}
          >
            <div className="flex items-center justify-center w-[16px] h-[16px] ml-[6px]">
              <CrossIcon />
            </div>
          </div>
          )}
          <div  className="cursor-pointer ">
            <div className="flex items-center justify-center w-[16px] h-[16px] ">
              <SelectDownArrow width={20} />
            </div>
          </div>
            
          
     
       
      </div>
      </div>

      {showDropdown && (
        <div
     
          style={{
            backgroundColor: "#fff",
            maxHeight: "250px",
            overflow: "auto",
            border: "1px solid #ccc",
          }}
        >
          {options?.map((data) => {
            return (
              <div
                key={data?.name}
                onClick={() => {
                  const rand = Math.random()
                  setShowDropdown(false);
                  setMultiSelectData([
                    ...multiselectData,
                    { ...data, id_frontend: rand, },
                  ]);
                }}
                style={{ padding: "5px 15px", cursor: "pointer" }}
              >
                {data?.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TagsSelect;
