import React from "react";
export const LabelDate = ({ color, purple }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2 3.2H11V2.6C11 2.44087 10.9368 2.28826 10.8243 2.17574C10.7117 2.06321 10.5591 2 10.4 2C10.2409 2 10.0883 2.06321 9.97574 2.17574C9.86321 2.28826 9.8 2.44087 9.8 2.6V3.2H6.2V2.6C6.2 2.44087 6.13679 2.28826 6.02426 2.17574C5.91174 2.06321 5.75913 2 5.6 2C5.44087 2 5.28826 2.06321 5.17574 2.17574C5.06321 2.28826 5 2.44087 5 2.6V3.2H3.8C3.32261 3.2 2.86477 3.38964 2.52721 3.72721C2.18964 4.06477 2 4.52261 2 5V12.2C2 12.6774 2.18964 13.1352 2.52721 13.4728C2.86477 13.8104 3.32261 14 3.8 14H12.2C12.6774 14 13.1352 13.8104 13.4728 13.4728C13.8104 13.1352 14 12.6774 14 12.2V5C14 4.52261 13.8104 4.06477 13.4728 3.72721C13.1352 3.38964 12.6774 3.2 12.2 3.2ZM12.8 12.2C12.8 12.3591 12.7368 12.5117 12.6243 12.6243C12.5117 12.7368 12.3591 12.8 12.2 12.8H3.8C3.64087 12.8 3.48826 12.7368 3.37574 12.6243C3.26321 12.5117 3.2 12.3591 3.2 12.2V8H12.8V12.2ZM12.8 6.8H3.2V5C3.2 4.84087 3.26321 4.68826 3.37574 4.57574C3.48826 4.46321 3.64087 4.4 3.8 4.4H5V5C5 5.15913 5.06321 5.31174 5.17574 5.42426C5.28826 5.53679 5.44087 5.6 5.6 5.6C5.75913 5.6 5.91174 5.53679 6.02426 5.42426C6.13679 5.31174 6.2 5.15913 6.2 5V4.4H9.8V5C9.8 5.15913 9.86321 5.31174 9.97574 5.42426C10.0883 5.53679 10.2409 5.6 10.4 5.6C10.5591 5.6 10.7117 5.53679 10.8243 5.42426C10.9368 5.31174 11 5.15913 11 5V4.4H12.2C12.3591 4.4 12.5117 4.46321 12.6243 4.57574C12.7368 4.68826 12.8 4.84087 12.8 5V6.8Z"
        fill={color || "url(#paint0_linear_4432_143157)"}
      />
      <defs>
        {purple ? (
          <linearGradient id="paint0_linear_4432_143157" x1="2" y1="7.99999" x2="14" y2="7.99999" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFCB57" />
            <stop offset="1" stopColor="#FF7443" />
          </linearGradient>
        ) : (
          <linearGradient id="paint0_linear_4432_143157" x1="2" y1="7.99999" x2="14" y2="7.99999" gradientUnits="userSpaceOnUse">
            <stop stop-color="#775FD5" />
            <stop offset="1" stop-color="#F03A85" />
          </linearGradient>
        )}
      </defs>
    </svg>
  );
};
