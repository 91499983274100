import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert as BootstrapAlert } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import Skeleton from 'react-loading-skeleton'
import { filter } from 'smart-array-filter'
import {
  Button,
  FormSearch,
  HemaValue,
  Pagination,
  FilterColumn,
  NoDataComponent,
} from '../../../utils'
import {
  ExpiryAlertPurple,
  DeletePurple,
  Edit,
  TableSortIcon,
  Binocular,
  ChangeReason,
} from '../../../HemeIconLibrary'
import deleteIcon from '../../../assets/images/deleteIcon.svg'
import {
  AddDepotSettings,
  ViewDepotSettings,
} from '../../../components/Formik/AllForms/addDepotSettings'
import { sortedTableData } from '../../../helpers/sortedData'
import { sortedData } from '../../../helpers/sort'
import { addPortalUserFields } from '../../../components/Formik/AllForms/addPortalUserFields'
import { AddDepotValidation } from '../../../components/Formik/allFormValidation'
import { Remove } from '../../../components/Formik/AllForms/remove'
import { setallDepotSettingsFilter } from '../../../Store/reducers/settings'
import {
  setForm,
  editFormReducer,
  setFormCloseReducer,
  showSuccessReducer,
  setFormLoaderReducer,
} from '../../../Store/reducers/uiSettings'

// Actions
import {
  getDepotSettingsAction,
  updateDepotSettingsAction,
  deleteDepotSettingsAction,
} from '../../../Actions/settings'

// Utils
import { getFullName } from '../Utils'

const DepotSettings = ({
  sites,
  setCTA,
  setformName,
  setFormIcon,
  setUpdatedData,
  setokBtnIcon,
  setokBtnText,
  setCancelBtnText,
}) => {
  const dispatch = useDispatch()
  const { settings, common, orders, uisettings } = useSelector((state) => state)
  const { isLoading, allDepotSettingsFilter, allDepotSettings } = settings

  useEffect(() => {
    getDepotSettingsAction()
  }, [])

  const [searchQuery, setsearchQuery] = useState('')
  const [dataList, setDataList] = useState(null)

  const [formValidation, setFormValidation] = useState()

  useEffect(() => {
    ;(async () => {
      if (searchQuery) {
        const filteredDataResult = filter(allDepotSettings, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        })
        setDataList(filteredDataResult)
      } else {
        setDataList(allDepotSettings)
      }
    })()
  }, [searchQuery, allDepotSettings])

  const editdepotSettings = (row) => {
    setCTA(() => async (payload) => {
      console.log('payload', payload)
      const payloadData = {
        name: payload?.name,
        eorI: payload?.eori,
        languageId: payload?.languageId,
        brokerContact: payload?.BrokerContactname,
        brokerCompanyName: payload?.BrokerCompanyname,
        brokerEORI: payload?.brokerEORI,
        brokerTaxID: payload?.brokerTaxID,
        brokerAddress: {
          address1: payload?.brokerAddress1,
          address2: payload?.brokerAddress2,
          city: payload?.brokercity,
          stateProvince: payload?.brokerstate,
          postalCode: payload?.borkerPostalcode,
          email: payload?.brokerEmail,
          phone: String(payload?.brokerPhone),
          countryId: payload?.brokercountry,
        },
        ioRContact: payload?.IORContactname,
        ioRCompanyName: payload?.IORCompanyname,
        ioreori: payload?.ioreori,
        iorTaxID: payload?.iorTaxID,
        ioRAddress: {
          address1: payload?.IORAddress1,
          address2: payload?.IORAddress2,
          city: payload?.IORcity,
          stateProvince: payload?.IORstate,
          postalCode: payload?.IORPostalcode,
          email: payload?.IOREmail,
          phone: String(payload?.IORPhone),
          countryId: payload?.IORcountry,
        },
        depotCourierServiceTypeId: payload?.Servicetype || null,
        courierId: payload?.courierId,
      }

      dispatch(setFormLoaderReducer(true))
      const resp = await updateDepotSettingsAction(row.id, payloadData)
      dispatch(setFormLoaderReducer(false))
      if (resp?.status === 200) {
        dispatch(setFormCloseReducer())
        dispatch(showSuccessReducer(`Depot successfully edited.`))
      }
    })
    setFormValidation((error, values) => (error, values) => {
      AddDepotValidation(error, values)
    })
  }

  const deleteDepotSettings = (row) => {
    setCTA(() => async (data) => {
      dispatch(setFormLoaderReducer(true))
      const resp = await deleteDepotSettingsAction(row.id, data.change_reason)
      if (resp?.status === 200) {
        dispatch(showSuccessReducer(`Depot deleted.`))
        dispatch(setFormCloseReducer())
      }
      dispatch(setFormLoaderReducer(false))
    })
  }

  useEffect(() => {
    if (Object.keys(allDepotSettingsFilter)?.length) {
      const filterResult = allDepotSettings?.filter((port) => {
        if (
          (allDepotSettingsFilter.email?.length
            ? allDepotSettingsFilter.email.includes(port.email)
            : true) &&
          (allDepotSettingsFilter.brokerAddress1?.length
            ? allDepotSettingsFilter.brokerAddress1?.includes(
                port.brokerAddress?.address1,
              )
            : true) &&
          (allDepotSettingsFilter.iorAddress1?.length
            ? allDepotSettingsFilter.iorAddress1?.includes(
                port.ioRAddress?.address1,
              )
            : true)
        ) {
          return true
        } else {
          return false
        }
      })
      setDataList(filterResult)
    } else {
      setDataList(allDepotSettings)
    }
  }, [JSON.stringify(allDepotSettingsFilter)])

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    )
  }

  return (
    <div className="bg-white rounded-[5px] mt-[36px] inventory-tabs">
      <>
        <div className="mb-[16px] px-[16px]">
          <FormSearch
            w="w-[400px]"
            searchQuery={searchQuery}
            setsearchQuery={setsearchQuery}
          />
        </div>

        {!dataList ? (
          <SkelatonCoponent />
        ) : dataList?.length > 0 ||
          Object.keys(allDepotSettingsFilter)?.length ? (
          <DataTable
            data={dataList}
            className="hdxa-rdt-filter border-t-[1px] border-solid border-[#DEE2E6] managment-table-st"
            noDataComponent={
              <NoDataComponent
                setActiveFilterTab={setDataList}
                activeFilterOriginalData={allDepotSettings}
                resetRedux={setallDepotSettingsFilter}
              />
            }
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue
                        text={'Name'}
                        color="text-[#000000]"
                        className="text-xs font-normal"
                      />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="name"
                          setRedux={setallDepotSettingsFilter}
                          reduxValues={allDepotSettingsFilter || []}
                          options={Array.from(
                            new Set(
                              allDepotSettings?.map((filter) => filter.name),
                            ),
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => (
                  <HemaValue
                    text={row.name}
                    color="text-textColor"
                    className="text-xs font-medium"
                  />
                ),
                sortId: 'name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue
                        text={'Broker Address'}
                        color="text-[#000000]"
                        className="text-xs font-normal"
                      />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="brokerAddress1"
                          setRedux={setallDepotSettingsFilter}
                          reduxValues={allDepotSettingsFilter || []}
                          options={Array.from(
                            new Set(
                              allDepotSettings.map(
                                (filter) => filter.brokerAddress?.address1,
                              ),
                            ),
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => (
                  <HemaValue
                    text={row.brokerAddress?.address1}
                    color="text-textColor"
                    className="text-xs font-medium"
                  />
                ),
                sortId: 'brokerAddress.address1',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue
                        text={'IOR Address'}
                        color="text-[#000000]"
                        className="text-xs font-normal"
                      />
                      <span className="short-icon">
                        <TableSortIcon />
                      </span>
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <div className="w-fit min-w-[150px]">
                        <FilterColumn
                          columnName="iorAddress1"
                          setRedux={setallDepotSettingsFilter}
                          reduxValues={allDepotSettingsFilter || []}
                          options={Array.from(
                            new Set(
                              allDepotSettings.map(
                                (filter) => filter.ioRAddress?.address1,
                              ),
                            ),
                          )}
                        />
                      </div>
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => (
                  <HemaValue
                    text={row.ioRAddress?.address1 || 'N/A'}
                    color="text-textColor"
                    className="text-xs font-medium"
                  />
                ),
                sortId: 'ioRAddress.address1',
              },

              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      {' '}
                      <HemaValue
                        text={'Actions'}
                        color="text-[#000000]"
                        className="text-xs font-normal pr-7"
                      />{' '}
                    </div>
                  </div>
                ),
                cell: (row, index) => {
                  return (
                    <div className="flex-grow four">
                      <div className="flex justify-center gap-[4px] meta">
                        {common?.permissions?.['Depot Settings']?.read && (
                          <Button
                            Icon={<Binocular color="#775FD5" />}
                            padding={false}
                            color="text-white"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row))
                              setformName('View Depot')
                              setCancelBtnText('Close')
                              setFormIcon(<ExpiryAlertPurple />)
                              ViewDepotSettings[1].initialValue = row?.name
                              ViewDepotSettings[2].initialValue = row?.eorI
                              ViewDepotSettings[3].initialValue =
                                row?.language?.name
                              ViewDepotSettings[4].initialValue =
                                row?.courier?.name
                              ViewDepotSettings[5].initialValue =
                                row?.depotCourierServiceType?.name

                              ViewDepotSettings[7].initialValue =
                                row?.brokerContact

                              ViewDepotSettings[8].initialValue =
                                row?.brokerCompanyName

                              ViewDepotSettings[9].initialValue =
                                row?.brokerAddress?.email
                              ViewDepotSettings[10].initialValue =
                                row?.brokerAddress?.phone
                              ViewDepotSettings[11].initialValue =
                                row?.brokerAddress?.address1

                              ViewDepotSettings[12].initialValue =
                                row?.brokerAddress?.address2

                              ViewDepotSettings[13].initialValue =
                                row?.brokerAddress?.city

                              ViewDepotSettings[14].initialValue =
                                row?.brokerAddress?.stateProvince
                              ViewDepotSettings[15].initialValue =
                                row?.brokerAddress?.country?.name

                              ViewDepotSettings[16].initialValue =
                                row?.brokerAddress?.postalCode
                              ViewDepotSettings[17].initialValue =
                                row?.brokerEORI ||
                                uisettings?.editForm?.brokerEORI
                              ViewDepotSettings[18].initialValue =
                                row?.brokerTaxID ||
                                uisettings?.editForm?.brokerTaxID
                              ViewDepotSettings[20].initialValue =
                                row?.ioRContact
                              ViewDepotSettings[21].initialValue =
                                row?.ioRCompanyName
                              ViewDepotSettings[22].initialValue =
                                row?.ioRAddress?.email
                              ViewDepotSettings[23].initialValue =
                                row?.ioRAddress?.phone

                              ViewDepotSettings[24].initialValue =
                                row?.ioRAddress?.address1
                              ViewDepotSettings[25].initialValue =
                                row?.ioRAddress?.address2
                              ViewDepotSettings[26].initialValue =
                                row?.ioRAddress?.city
                              ViewDepotSettings[27].initialValue =
                                row?.ioRAddress?.stateProvince
                              ViewDepotSettings[28].initialValue =
                                row?.ioRAddress?.country?.name

                              ViewDepotSettings[29].initialValue =
                                row?.ioRAddress?.postalCode
                              ViewDepotSettings[30].initialValue = row?.ioreori
                              ViewDepotSettings[31].initialValue = row?.iorTaxID

                              setUpdatedData(ViewDepotSettings.slice(0, 32))
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'view-depot-settings',
                                }),
                              )
                            }}
                          />
                        )}
                        {common?.permissions?.['Depot Settings']?.update && (
                          <Button
                            Icon={<Edit color="#775FD5" />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={async () => {
                              dispatch(editFormReducer(row))
                              setformName('Edit Depot')
                              setokBtnIcon()
                              setokBtnText('Update')
                              setFormIcon(<ExpiryAlertPurple />)
                              AddDepotSettings[1].initialValue = row?.name
                              AddDepotSettings[2].initialValue = row?.eorI
                              AddDepotSettings[3].initialValue =
                                row?.language?.id
                              AddDepotSettings[3].options =
                                common?.allInventorylanguages
                              AddDepotSettings[4].options =
                                settings?.allCouriers
                              AddDepotSettings[4].initialValue =
                                row?.courier?.id
                              AddDepotSettings[4].placeholder =
                                row?.courier?.name || 'Select'
                              AddDepotSettings[5].initialValue =
                                row?.depotCourierServiceType?.id
                              AddDepotSettings[5].options = orders?.couriertype
                              AddDepotSettings[5].placeholder =
                                row?.depotCourierServiceType?.name || 'Select'

                              AddDepotSettings[7].initialValue =
                                row?.brokerContact
                              AddDepotSettings[8].initialValue =
                                row?.brokerCompanyName

                              AddDepotSettings[9].initialValue =
                                row?.brokerAddress?.email

                              AddDepotSettings[10].initialValue =
                                row?.brokerAddress?.phone
                              AddDepotSettings[11].initialValue =
                                row?.brokerAddress?.address1

                              AddDepotSettings[12].initialValue =
                                row?.brokerAddress?.address2

                              AddDepotSettings[13].initialValue =
                                row?.brokerAddress?.city

                              AddDepotSettings[14].initialValue =
                                row?.brokerAddress?.stateProvince
                              AddDepotSettings[15].initialValue =
                                row?.brokerAddress?.country?.id
                              AddDepotSettings[15].options =
                                common?.allCountries || []
                              AddDepotSettings[16].initialValue =
                                row?.brokerAddress?.postalCode
                              AddDepotSettings[17].initialValue =
                                row?.brokerEORI ||
                                uisettings?.editForm?.brokerEORI
                              AddDepotSettings[18].initialValue =
                                row?.brokerTaxID ||
                                uisettings?.editForm?.brokerTaxID
                              AddDepotSettings[20].initialValue =
                                row?.ioRContact
                              AddDepotSettings[21].initialValue =
                                row?.ioRCompanyName
                              AddDepotSettings[22].initialValue =
                                row?.ioRAddress?.email
                              AddDepotSettings[23].initialValue =
                                row?.ioRAddress?.phone

                              AddDepotSettings[24].initialValue =
                                row?.ioRAddress?.address1
                              AddDepotSettings[25].initialValue =
                                row?.ioRAddress?.address2
                              AddDepotSettings[26].initialValue =
                                row?.ioRAddress?.city
                              AddDepotSettings[27].initialValue =
                                row?.ioRAddress?.stateProvince
                              AddDepotSettings[28].initialValue =
                                row?.ioRAddress?.country?.id
                              AddDepotSettings[28].options =
                                common?.allCountries || []

                              AddDepotSettings[29].initialValue =
                                row?.ioRAddress?.postalCode
                              AddDepotSettings[30].initialValue = row?.ioreori
                              AddDepotSettings[31].initialValue = row?.iorTaxID

                              setUpdatedData([
                                ...AddDepotSettings,
                                {
                                  name: 'changeReason',
                                  label: 'Change Reason',
                                  Icon: <ChangeReason />,
                                  type: 'textarea',
                                  fluid: true,
                                  initialValue: '',
                                  required: true,
                                  placeholder: 'Type reason',
                                },
                              ])

                              setFormValidation(
                                () => (error, values, store) => {
                                  if (values?.name?.length > 20) {
                                    error.name = 'Max 20 characters allowed'
                                  }
                                  return error
                                },
                              )

                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'edit-depot-settings',
                                }),
                              )
                              editdepotSettings(row)
                            }}
                          />
                        )}

                        {common?.permissions?.['Depot Settings']?.delete && (
                          <Button
                            Icon={<img src={deleteIcon} />}
                            padding={false}
                            color="text-[#dc2626]"
                            bg="bg-bgActionDots"
                            cta={() => {
                              dispatch(editFormReducer(row))
                              ViewDepotSettings[1].initialValue = row?.name
                              ViewDepotSettings[2].initialValue = row?.eorI
                              ViewDepotSettings[3].initialValue =
                                row?.language?.name
                              ViewDepotSettings[4].initialValue =
                                row?.courier?.name
                              ViewDepotSettings[5].initialValue =
                                row?.depotCourierServiceType?.name

                              ViewDepotSettings[7].initialValue =
                                row?.brokerContact

                              ViewDepotSettings[8].initialValue =
                                row?.brokerCompanyName

                              ViewDepotSettings[9].initialValue =
                                row?.brokerAddress?.email
                              ViewDepotSettings[10].initialValue =
                                row?.brokerAddress?.phone
                              ViewDepotSettings[11].initialValue =
                                row?.brokerAddress?.address1

                              ViewDepotSettings[12].initialValue =
                                row?.brokerAddress?.address2

                              ViewDepotSettings[13].initialValue =
                                row?.brokerAddress?.city

                              ViewDepotSettings[14].initialValue =
                                row?.brokerAddress?.stateProvince
                              ViewDepotSettings[15].initialValue =
                                row?.brokerAddress?.country?.name

                              ViewDepotSettings[16].initialValue =
                                row?.brokerAddress?.postalCode
                              ViewDepotSettings[17].initialValue =
                                row?.brokerEORI ||
                                uisettings?.editForm?.brokerEORI
                              ViewDepotSettings[18].initialValue =
                                row?.brokerTaxID ||
                                uisettings?.editForm?.brokerTaxID
                              ViewDepotSettings[20].initialValue =
                                row?.ioRContact
                              ViewDepotSettings[21].initialValue =
                                row?.ioRCompanyName
                              ViewDepotSettings[22].initialValue =
                                row?.ioRAddress?.email
                              ViewDepotSettings[23].initialValue =
                                row?.ioRAddress?.phone

                              ViewDepotSettings[24].initialValue =
                                row?.ioRAddress?.address1
                              ViewDepotSettings[25].initialValue =
                                row?.ioRAddress?.address2
                              ViewDepotSettings[26].initialValue =
                                row?.ioRAddress?.city
                              ViewDepotSettings[27].initialValue =
                                row?.ioRAddress?.stateProvince
                              ViewDepotSettings[28].initialValue =
                                row?.ioRAddress?.country?.name

                              ViewDepotSettings[29].initialValue =
                                row?.ioRAddress?.postalCode
                              ViewDepotSettings[30].initialValue = row?.ioreori
                              ViewDepotSettings[31].initialValue = row?.iorTaxID
                              setUpdatedData(ViewDepotSettings)
                              setokBtnIcon()
                              setokBtnText('Confirm')
                              setformName('Delete Depot')
                              setFormIcon(<DeletePurple />)
                              dispatch(
                                setForm({
                                  state: true,
                                  type: 'deleteItem',
                                }),
                              )
                              deleteDepotSettings(row)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            customStyles={{
              rows: {
                style: {
                  // paddingRight: "20px",
                  style: { overflow: 'visible !important' },
                },
              },

              cells: {
                style: { overflow: 'visible !important' },
              },

              responsiveWrapper: {
                style: { overflow: 'visible !important' },
              },
            }}
            pagination
            onSort={(row, direction, sorted) => {
              setDataList(
                sortedData(row.sortId, direction, sorted)?.filter(
                  (data) => Object.keys(data)?.length,
                ),
              )
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />
            }}
          />
        ) : (
          <NoDataComponent text="There are no depot setting to show. Add by clicking on Add Depot." />
        )}
      </>
    </div>
  )
}

export default DepotSettings
