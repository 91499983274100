import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detail: null,
  filters: null,
};

export const audit = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setAudit: (state, action) => {
      state.detail = action.payload;
    },
    setAuditFilters: (state, action) => {
      state.filters = action.payload;
    },
  },

});

export const { setAudit, setAuditFilters } = audit.actions;

export default audit.reducer;
