import React from 'react';

export const LockOpen = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.573 7.30961L19.4957 7.69517L18.573 7.30961ZM9.26002 3.66037C9.04708 4.16995 9.28755 4.75567 9.79714 4.96861C10.3067 5.18155 10.8924 4.94107 11.1054 4.43149L9.26002 3.66037ZM17.6503 6.92404L15.1441 12.9215L16.9895 13.6926L19.4957 7.69517L17.6503 6.92404ZM11.1054 4.43149L11.1916 4.22512L9.34625 3.45399L9.26002 3.66037L11.1054 4.43149ZM15.7704 2.34522C17.554 3.0905 18.3956 5.14051 17.6503 6.92404L19.4957 7.69517C20.6669 4.89248 19.3442 1.67102 16.5416 0.499853L15.7704 2.34522ZM16.5416 0.499853C13.7389 -0.67131 10.5174 0.651304 9.34625 3.45399L11.1916 4.22512C11.9369 2.44159 13.9869 1.59993 15.7704 2.34522L16.5416 0.499853Z"
        fill="#2C2C2C"
      />
      <rect
        x="3.03906"
        y="13"
        width="17.926"
        height="11"
        rx="2"
        stroke="#2C2C2C"
        stroke-width="2"
      />
      <circle cx="12.0039" cy="18.5" r="1.75" fill="#2C2C2C" />
    </svg>
  );
};
