import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
// import { RowExpand } from '../../../HemeIconLibrary';
import { HemaValue, FormCheckbox, FilterColumn } from '../../utils';
import { sortedData } from '../../helpers/sort';
import { setPortalSiteFilter } from '../../Store/reducers/settings';

const CheckboxTable = (props) => {
  const { allSites, name, setFieldValue } = props;
  const { settings } = useSelector((state) => state);
  const { portalSiteFilter } = settings;
  const [filteredData, setfilteredData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(portalSiteFilter)?.length) {
      dispatch(setPortalSiteFilter({}));
    }
  }, []);
  console.log('allSites', allSites);
  useEffect(() => {
    if (Object.keys(portalSiteFilter)?.length) {
      const filterResult = allSites?.filter((port) => {
        if (
          portalSiteFilter.study?.length
            ? portalSiteFilter.study.includes(port.study?.name)
            : true &&
              (portalSiteFilter.sponsor?.length ? portalSiteFilter.sponsor?.includes(port?.sponsor.name) : true) &&
              (portalSiteFilter.site?.length ? portalSiteFilter?.site?.includes(port.name) : true)
        ) {
          return true;
        } else {
          return false;
        }
      });
      setfilteredData(filterResult);
    } else {
      setfilteredData(allSites);
    }
  }, [allSites, JSON.stringify(portalSiteFilter)]);

  const callFilterData = (type, filterdName) => {
    //console.log("filterData",type,filterdName);
  };

  if (allSites?.length > 0 || Object.keys(portalSiteFilter)?.length) {
    return (
      <div className="border min-h-[300px] max-h-[600px] overflow-x-auto border-[#ccc] rounded-[5px] pt-[20px]">
        <DataTable
          className="hdxa-rdt-filter"
          columns={[
            {
              grow: 6,
              name: (
                <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                  <FormCheckbox
                    onClick={(e) => {
                      setFieldValue(
                        name,
                        allSites?.map((site, counter) => {
                          return {
                            ...site,
                            checked: e.target.checked,
                          };
                        }),
                      );
                    }}
                    checked={allSites?.filter((f) => !f.checked)?.length > 0 ? false : true}
                    type="checkbox"
                  />
                  <div className="mr-[20px]">
                    <HemaValue text={'Sponsor'} className="font-normal text-[#000000]" />
                    <FilterColumn
                      columnName="sponsor"
                      setRedux={setPortalSiteFilter}
                      reduxValues={portalSiteFilter || []}
                      options={Array.from(new Set(allSites?.map((filter) => filter.sponsor?.name)))}
                    />
                  </div>
                </div>
              ),
              sortable: true,
              sortId: 'sponsor.name',
              selector: (row, index) => {
                return (
                  <div className="flex-shrink-0 flex items-center gap-[10px] cursor-pointer check_box">
                    <FormCheckbox
                      onClick={(e) => {
                        if (allSites) {
                          setFieldValue(
                            name,
                            allSites?.map((site, counter) => {
                              if (counter === index) {
                                return {
                                  ...site,
                                  checked: e.target?.checked,
                                };
                              } else {
                                return site;
                              }
                            }),
                          );
                        }
                      }}
                      checked={row.checked}
                      type="checkbox"
                    />

                    <HemaValue text={row.sponsor?.name} />
                  </div>
                );
              },
            },
            {
              grow: 6,
              // name: <HemaValue text={'Study'} className="font-normal text-[#000000]" />,
              name: (
                <div className="mr-[20px]">
                  <HemaValue text={'Study'} className="font-normal" />
                  <FilterColumn
                    columnName="study"
                    setRedux={setPortalSiteFilter}
                    reduxValues={portalSiteFilter || []}
                    options={Array.from(new Set(allSites?.map((filter) => filter.study?.name)))}
                  />
                </div>
              ),
              sortable: true,
              selector: (row) => <HemaValue text={row?.study?.name} />,
              sortId: 'study.name',
            },
            {
              grow: 6,
              name: (
                <div className="mr-[20px]">
                  <HemaValue text={'Site'} className="font-normal" />
                  <FilterColumn
                    columnName="site"
                    setRedux={setPortalSiteFilter}
                    reduxValues={portalSiteFilter || []}
                    options={Array.from(new Set(allSites?.map((filter) => filter?.name)))}
                  />
                </div>
              ),
              sortable: true,
              selector: (row) => <HemaValue text={row?.name} />,
              sortId: 'name',
            },
          ]}
          customStyles={{
            responsiveWrapper: {
              style: { overflow: 'visible !important' },
            },
          }}
          data={filteredData || []}
          onSort={(row, direction, sorted) => {
            setfilteredData(sortedData(row.sortId, direction, sorted));
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  }
};

export default CheckboxTable;
