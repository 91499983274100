import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  HemaCard,
  HemaHeadingWithSubText,
  NoPermissionAlert,
} from "../../utils";
import {
  Queue,
  InProcess,
  Shipped,
  Delivered,
  DeliveredIcon,
  RejectedIcon,
} from "../../HemeIconLibrary";
//import Charts from '../../components/Dashbaord/charts';

import {
  getAllDashboardStatsAction,
  getAllOrderAction,
} from "../../Actions/order";
import RequestTable from "./requestTable";
import CreateKit from "../../pages/createKit/createKit";
const Dashboard = ({ setShowDashboard, showDashboard }) => {
  const { orders, common } = useSelector((state) => state);
  const [active, setActive] = useState();
  // const [showDashboard, setShowDashboard] = useState(true);

  useEffect(() => {
    getAllDashboardStatsAction();
    getAllOrderAction();
  }, []);

  useEffect(() => {
    if (active) {
      console.log(active);
    }
  }, [active]);

  if (!common?.permissions?.["Kit Order Process"]?.read) {
    return <NoPermissionAlert />;
  }

  return showDashboard ? (
    <div className="dashboard">
      <HemaHeadingWithSubText
        HeadingFontSize="30px"
        HeadingLineHeight="38px"
        heading="Overview"
      />
      <div className="flex items-center gap-5 justify-between mt-[19px] mb-[24px]">
        <div
          className="  rounded-[15px] flex relative w-full "
          // style={{
          //   background:
          //     'linear-gradient(71.65deg, #B2ADF4 0.38%, #F0DDFF 69.03%)',
          // }}
        >
          {/* <img
          src={RequestImage}
          alt="request"
          className="absolute top-[7px] left-[12px] w-auto h-auto"
        /> */}
          <div className="relative w-full">
            <div className="w-full px-[20px] py-[12px] rounded-[15px]  bg-grayGradient mb-[20px]">
              <p className="m-0 text-base font-bold text-black">
                Request Status
              </p>
            </div>
            <div className=" w-full flex gap-[10px]   flex-wrap">
              <HemaCard
                heading={
                  orders.dashboard?.requestStatus?.filter(
                    (data) => data.label === "New",
                  )[0]?.number || "0"
                }
                sub="New"
                Icon={Queue}
                shadeColor="#3D88E0"
                borderColor="border-[#3D88E0] solid border-[2px]"
                bg="bg-blueGradient"
                className="grow-[1]"
                setActive={setActive}
                active={active}
              />
              <HemaCard
                heading={
                  orders.dashboard?.requestStatus?.filter(
                    (data) => data.label === "In Process",
                  )[0]?.number || "0"
                }
                sub="In Process"
                Icon={InProcess}
                className="grow-[1]"
                shadeColor="#F54C3B"
                bg="bg-pinkGradient"
                borderColor="border-[#F54C3B] solid border-[2px]"
                setActive={setActive}
                active={active}
              />
              <HemaCard
                heading={
                  orders.dashboard?.requestStatus?.filter(
                    (data) => data.label === "Shipped",
                  )[0]?.number || "0"
                }
                sub="Shipped"
                Icon={Shipped}
                className="grow-[1]"
                shadeColor="#775FD5"
                bg="bg-purpleGradient"
                borderColor="border-[#775fd5] solid border-[2px]"
                setActive={setActive}
                active={active}
              />
              <HemaCard
                heading={
                  orders.dashboard?.requestStatus?.filter(
                    (data) => data.label === "Delievered",
                  )[0]?.number || "0"
                }
                sub="Delivered"
                Icon={DeliveredIcon}
                className="grow-[1]"
                shadeColor="#38C280"
                bg="bg-greenGradient"
                borderColor="border-[#38C280] solid border-[2px]"
                setActive={setActive}
                active={active}
              />
              <HemaCard
                heading={
                  orders.dashboard?.requestStatus?.filter(
                    (data) => data.label === "Rejected",
                  )[0]?.number || "0"
                }
                sub="Rejected"
                Icon={RejectedIcon}
                className="grow-[1]"
                shadeColor="#E91E63"
                bg="bg-pinkGradient"
                borderColor="border-[#E91E63] solid border-[2px]"
                setActive={setActive}
                active={active}
              />
            </div>
          </div>
        </div>

        {/* <div className="">
          <Charts data={orders.dashboard} />
        </div> */}
      </div>
      <RequestTable
        activeStage={active}
        data={orders.allOrders || null}
        setShowDashboard={setShowDashboard}
        showDashboard={showDashboard}
      />
    </div>
  ) : (
    <CreateKit setShowDashboard={setShowDashboard} />
  );
};

export default Dashboard;
