import {
  Address,
  ChangeReason,
  FirstName,
  LabelCategory,
  LabelName,
  Location,
  Recipient,
  SiteName,
} from '../../../HemeIconLibrary';

export const RequistionForm = [
  {
    label: '',
    initialValue: 'Shipper Information',
    fluid: true,
    type: 'heading-only',
  },
  {
    label: 'Site Number',
    Icon: <SiteName purple />,
    type: 'text',
    initialValue: '',
    placeholder: 'Site Number',
    required: true,
    fluid: true,
    name: 'siteNumber',
  },
  {
    label: 'Site Name',
    Icon: <ChangeReason />,
    type: 'text',
    initialValue: '',
    placeholder: 'Site Name',
    required: true,
    fluid: true,
    name: 'siteName',
  },
  {
    label: 'Site Address',
    Icon: <Location purple />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    placeholder: 'Site Address',
    required: true,
    name: 'testingLabId',
  },
  {
    label: 'Principal Investigator',
    Icon: <FirstName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Principal Investigator',
    required: true,
    fluid: true,
    name: 'name',
  },
  {
    label: 'Subject Name Prefix',
    Icon: <LabelName />,
    type: 'text',
    initialValue: '',
    placeholder: 'Subject Name Prefix',
    required: false,
    fluid: true,
    name: 'subjectNamePrefix',
  },
  {
    label: '',
    initialValue: 'Recipient Information',
    fluid: true,
    type: 'heading-only',
  },
  {
    label: 'Testing Lab Name',
    Icon: <ChangeReason />,
    type: 'text',
    initialValue: '',
    fluid: true,
    placeholder: 'Testing Lab Name',
    required: true,
    name: 'testingLabName',
  },
  {
    label: 'Testing Lab Address',
    Icon: <Address purple />,
    type: 'textarea',
    initialValue: '',
    fluid: true,
    placeholder: 'Testing Lab Address',
    required: true,
    name: 'testingLabAddress',
  },
];
