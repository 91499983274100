import React from 'react';

export const Country = ({ width, height }) => {
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.72918 0.953125C4.38313 0.953125 3.29168 2.04458 3.29168 3.39062C3.29168 4.76863 4.67694 6.49309 5.35889 7.25846C5.55606 7.48001 5.90229 7.48001 6.09945 7.25846C6.78141 6.49309 8.16668 4.76863 8.16668 3.39062C8.16668 2.04458 7.07522 0.953125 5.72918 0.953125ZM5.72918 2.57812C6.17767 2.57812 6.54168 2.94213 6.54168 3.39062C6.54168 3.83913 6.17767 4.20312 5.72918 4.20312C5.28067 4.20312 4.91668 3.83913 4.91668 3.39062C4.91668 2.94213 5.28067 2.57812 5.72918 2.57812ZM9.54094 3.04785C9.36681 3.08363 9.22811 3.23534 9.22568 3.42871C9.22351 3.61613 9.21633 3.79396 9.20875 3.85083C9.20279 3.88875 9.19781 3.92611 9.19076 3.96403C8.83651 6.02128 6.75296 8.16437 6.49512 8.4222C6.07208 8.84524 5.38627 8.84524 4.96322 8.4222C4.75739 8.21637 3.38806 6.80996 2.66114 5.205V5.20394C2.56364 4.98781 2.47763 4.76901 2.40829 4.54801C2.33788 4.32376 2.0507 4.26515 1.89307 4.4401C1.28316 5.11665 0.76816 5.79649 0.669035 5.99528C0.516827 6.30132 0.577519 6.52152 0.667977 6.66073L2.20834 9.22835V10.1615C2.20834 10.3722 2.33397 10.5656 2.52572 10.6545C4.37552 11.5108 5.13909 11.8161 5.5123 11.8161C5.74034 11.8161 5.82321 11.7016 5.91855 11.5304C6.01226 11.395 6.41959 10.9535 6.66334 10.7412C6.94555 10.8674 7.48348 11.178 7.76677 11.3411C8.28461 11.6395 8.45568 11.7354 8.60572 11.7695C9.0786 11.8746 9.76036 11.4144 10.126 11.1284C10.523 10.8175 10.2993 10.4259 10.016 9.92977C9.9645 9.83931 9.89234 9.71084 9.84034 9.61768C10.0028 9.38638 10.3706 8.96493 10.6073 8.69409C11.3565 7.83609 11.6366 7.51486 11.258 7.07015L10.5291 6.34123C11.1796 5.57748 11.1959 5.52288 11.2284 5.4113C11.3898 4.85771 11.688 3.83896 10.8539 3.44246C10.7331 3.36934 10.4585 3.24922 9.72291 3.05314C9.66062 3.03649 9.59899 3.03593 9.54094 3.04785ZM3.83334 8.53646C4.13234 8.53646 4.37501 8.77913 4.37501 9.07812C4.37501 9.37713 4.13234 9.61979 3.83334 9.61979C3.53434 9.61979 3.29168 9.37713 3.29168 9.07812C3.29168 8.77913 3.53434 8.53646 3.83334 8.53646Z"
        fill="url(#paint0_linear_1986_70343)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1986_70343"
          x1="0.576172"
          y1="6.3846"
          x2="11.426"
          y2="6.3846"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
